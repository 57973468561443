<div class="row">
  <div class="col-sm-12">

    <div class="row">
      <div class="col-6">
        <input type='text' class="form-control" style='padding:8px;' placeholder='buscar'
          (keyup)='updateFilter($event)' />
      </div>
      <div class="col-6 text-right">
        <button class="btn btn-raised btn-primary mr-1" (click)="openInvitePerson()">
          <i class="ft-user"></i> Invitar Socio
        </button>
        <button class="btn btn-raised btn-primary mr-1" (click)="getGuestUsers()">
          <i class="ft-refresh-cw"></i> Actualizar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <div class="d-flex justify-content-center mt-5 mb-5" *ngIf="loading_data && !invited_partners_filtered">
          <i-feather name="loader" class="fa fa-spin black text-center feather-loading"></i-feather>
        </div>

        <ngx-datatable #table class="bootstrap" [rows]="invited_partners_filtered" [columnMode]="'flex'"
          [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10"
          *ngIf="!loading_data && invited_partners_filtered">

          <ngx-datatable-column prop="correlativo" name="No." [flexGrow]="0.3"></ngx-datatable-column>
          <ngx-datatable-column name="ID" [flexGrow]="0.75"></ngx-datatable-column>
          <ngx-datatable-column name="Nombre" [flexGrow]="1.75"></ngx-datatable-column>
          <ngx-datatable-column name="Correo" [flexGrow]="1.25"></ngx-datatable-column>
          <ngx-datatable-column prop="fecha" [flexGrow]="1" name="Fecha Inscripción"></ngx-datatable-column>
          <ngx-datatable-column prop="contrasena" [flexGrow]="1" name="Contraseña"></ngx-datatable-column>
          <ngx-datatable-column name="Acción" [flexGrow]="0.5">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <!--<button (click)="getGuestData(row.id)">imprimir</button>-->
              <div class="fonticon-container">
                <div class="fonticon-wrap">
                  <i-feather name="edit-2" class="success" style="cursor:pointer;"
                    (click)="openRegisterUserForm(row.id,row.correo);"></i-feather>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>


  </div>
</div>