import { Component, OnInit } from '@angular/core';
import { PersonService } from 'app/services/person.service';
import { AppointmentService } from 'app/services/appointment.service';
import { RouteDataService } from 'app/services/route-data.service';

import * as moment from 'moment';

@Component({
    selector: 'app-view-notifications-detail',
    templateUrl: './view-notifications-detail.component.html',
    styleUrls: ['./view-notifications-detail.component.scss']
})
export class ViewNotificationsDetailComponent implements OnInit {

    notifications: any = [];
    current_user: any = [];

    constructor(private router_data_service: RouteDataService, private person_service: PersonService, private appointment_service: AppointmentService, ) { }

    ngOnInit() {
        this.current_user = this.router_data_service.tempData;
        this.loadNotifications();
    }

    loadNotifications() {
        console.log(this.current_user.perfil_nombre);
        this.appointment_service.getNotifications({ user_cat: this.current_user.perfil_nombre }).subscribe((retrived_notifications: any) => {
            this.notifications = [...retrived_notifications];
            console.log(retrived_notifications);
            retrived_notifications.forEach(element => {
                element.time_from_published = moment(element.time_from_published = element.creation_date + "," + element.creation_time, "MMDDYYYY, h:mm a").locale("es").fromNow();
            });
        })
    }
    markNotificationSeen(notification_id, notification_index) {
        console.log(notification_index);
        //  this.notifications.splice(notification_index, 1);
        this.appointment_service.markEventSeenBy({ notification_id: notification_id, seen_by: this.current_user.usuario }).subscribe((data: any) => {
            console.log(data);
            this.loadNotifications();
        });
    }
}
