import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnMode } from "@swimlane/ngx-datatable";
import * as lodash from "lodash";

import { NGXToastrService } from "app/services/toastr.service";
import { AdminService } from "app/services/admin.service";
import { ExportExcelService } from "app/services/export-excel.service";

@Component({
  selector: "app-view-partner-distribution-card",
  templateUrl: "./view-partner-distribution-card.component.html",
  styleUrls: ["./view-partner-distribution-card.component.scss"],
})
export class ViewPartnerDistributionCardComponent implements OnInit {
  ColumnMode = ColumnMode;
  partner_id: any = "";

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: NGXToastrService,
    private admin_service: AdminService,
    private generateExcel: ExportExcelService
  ) {}

  ngOnInit(): void {
    this.loadPartnerData();
  }
  current_participant_detail: any = {};
  participations_filtered: any = [];
  grouped_distributions: any = [];

  loadPartnerData() {
    this.admin_service
      .getDistributionsByPartner({ id: this.partner_id })
      .subscribe((data: any) => {
        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          let partner_distributions = data.response.data.distribution;

          let grouped_distributions = lodash.groupBy(
            partner_distributions,
            (item) => {
              return JSON.stringify({
                distribution_code: item.distribution_id,
              });
            }
          );
          grouped_distributions = Object.keys(grouped_distributions).map(
            (key) => {
              return {
                ...JSON.parse(key),
                distribution_name:
                  grouped_distributions[key][0].distribution_name,
                // wallet: item.wallet,
                // wallet_name: item.wallet_name,
                // situation: item.situation,
              };
            }
          );
          this.current_participant_detail = {
            partner_code: data.response.data.partner_code,
            partner_name: data.response.data.partner_name,
            participations: data.response.data.distribution,
            selected_distribution: "Todas",
            total:
              lodash.sumBy(partner_distributions, (item) =>
                Number(item.amount)
              ) || 0,
          };
          this.grouped_distributions = grouped_distributions;
          this.participations_filtered = partner_distributions;

          console.log(this.current_participant_detail);
        } else {
          this.toastr.typeError();
        }
      });
  }

  current_participant_participations_filter_distribution = "all";
  handleParticipantDistributionFilter(event, filter) {
    let filter_value = event.target.value;
    if (filter === "DISTRIBUCION") {
      this.current_participant_participations_filter_distribution =
        filter_value;
    }
    let filtered = [];
    try {
      filtered = this.current_participant_detail.participations.filter(
        (participation) =>
          this.current_participant_participations_filter_distribution === "all"
            ? participation.distribution_id.toString() !== ""
            : participation.distribution_id.toString() ===
              this.current_participant_participations_filter_distribution
      );
    } catch (err) {
      console.log(err);
    }
    this.participations_filtered = filtered;
    this.current_participant_detail.selected_distribution =
      this.current_participant_participations_filter_distribution === "all"
        ? "Todas"
        : this.grouped_distributions.filter(
            (distribution) =>
              distribution.distribution_code.toString() === filter_value
          )[0].distribution_name;
    this.current_participant_detail.total = lodash.sumBy(filtered, (item) =>
      Number(item.amount)
    );
  }

  generateDistributionByPartnerFile() {
    this.generateExcel.exportExcel({
      data: this.participations_filtered,
    });
  }
  formatNumber(num) {
    return lodash.round(Number(num || 0), 2);
  }
}
