import { Component, OnInit, Input } from '@angular/core';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'app/services/admin.service';
import { RouteDataService } from 'app/services/route-data.service';

@Component({
    selector: 'app-view-partners',
    templateUrl: './view-partners.component.html',
    styleUrls: ['./view-partners.component.scss']
})
export class ViewPartnersComponent implements OnInit {

    current_user: any;


    activeTab = 'invited';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private route_data_service: RouteDataService,
        private admin_service: AdminService, ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.activeTab = this.route.snapshot.data['tab'];
        this.loadPartnersData(this.activeTab == 'invited' ? "new" : this.activeTab == 'enrolled' ? "old" : this.activeTab == 'independent' ? 'independent' : "");
    }

    onTabChange($event: NgbTabChangeEvent) {
        console.log("tab changed: ", $event);
        //his.activeTab = $event.nextId;

        let routes = {
            enrolled: `/fl/admin/partners/enrolled`,
            invited: `/fl/admin/partners/invited`,
            independent: '/fl/admin/partners/independent'
        };

        //this.loadPartnersData(this.activeTab == 'invited' ? "new" : this.activeTab == 'enrolled' ? "old" : "");
        this.router.navigateByUrl(routes[$event.nextId]);
    }

    data_for_child = null;

    loadPartnersData(user_type: any) {

        this.data_for_child = null;
        if (user_type === 'independent') {
            this.admin_service.getIndependentPartner({ user: this.current_user.usuario }).subscribe((data: any) => {
                if (data && data.resultado && data.resultado.header && data.resultado.header.type == "success") {
                    this.data_for_child = data.resultado.artista;
                } else {
                    console.log("No se encontraron registros.");
                }
            });
        } else {
            this.admin_service.getUserList({ "token": this.current_user.token, "action": user_type, "user": this.current_user.usuario }).subscribe((data: any) => {
                //console.log("DATA", data);

                if (data && data.resultado && data.resultado.header && data.resultado.header.type == "success") {
                    this.data_for_child = data.resultado.body;
                } else {
                    console.log("No se encontraron registros.");
                }

            });
        }
    }
}
