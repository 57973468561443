<div class="row">
  <div class="col-3">
    <div class="form-group">
      <label for="">Distribución</label>
      <select
        class="form-control"
        (change)="changeDistributionSelected($event)"
      >
        <option selected value></option>
        <option 
          [value]="distribution.distribucion_correlativo"
          *ngFor="let distribution of distributions; let i = index"
        >
          {{ distribution.distribucion_nombre }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-3">
    <div class="form-group">
      <label for="">Cartera de Distribución</label>
      <select
        class="form-control"
        (change)="changeDistributionWalletSelected($event)"
      >
        <option selected value></option>
        <option
          [value]="wallet.cartera_correlativo"
          *ngFor="let wallet of filteredDistributionsWallets; let i = index"
        >
          {{ wallet.cartera_nombre }}
        </option>
      </select>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <div class="form-group">
      <label for="">Búsqueda</label>
      <input
        type="text"
        class="form-control"
        style="padding: 8px"
        placeholder="buscar"
        (keyup)="savedMonitoringFilter($event)"
      />
    </div>
  </div>
  <div class="col-3">
    <div class="form-group">
      <label for="">Canal de Monitoreo</label>
      <select
        class="form-control"
        (change)="changeSavedMonitoringChannel($event)"
      >
        <option value="" selected>TODOS</option>
        <option
          [value]="channel.channel_code"
          *ngFor="let channel of current_monitoring_channels"
        >
          {{ channel.channel_name }}
        </option>
      </select>
    </div>
  </div>

  <!-- <div class="col-3">
    <div class="form-group">
      <label for="">Año de Monitoreo</label>
      <select class="form-control" (change)="changeSavedMonitoringYear($event)">
        <option selected></option>
        <option
          [value]="year.anio"
          *ngFor="let year of current_monitoring_years"
        >
          {{ year.anio }}
        </option>
      </select>
    </div>
  </div> -->
  <div class="col-3">
    <div class="form-group">
      <label for="">Estado</label>
      <select
        class="form-control"
        (change)="changeSavedMonitoringState($event)"
      >
        <option value="" selected>TODOS</option>
        <option value="NO_REGISTRADO">CORREGIR</option>
      </select>
    </div>
  </div>

  <div class="col-3 text-right">
    <button
      class="btn btn-raised btn-primary mr-1"
      (click)="getCurrentMonitoring()"
    >
      <i class="ft-refresh-cw"></i> Actualizar
    </button>
  </div>
</div>

<div
  class="d-flex justify-content-center mt-5 mb-5"
  *ngIf="!saved_monitoring_filtered"
>
  <i-feather
    name="loader"
    class="fa fa-spin black text-center feather-loading"
  ></i-feather>
</div>

<!-- <p style="max-height: 200px;overflow-y: scroll;">{{saved_monitoring_filtered | json}}</p> -->

<ngx-datatable
  #table
  class="bootstrap"
  [rows]="saved_monitoring_filtered"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="40"
  [rowHeight]="50"
  [rowClass]="getRowClass"
  [limit]="20"
  *ngIf="saved_monitoring_filtered"
  [scrollbarH]="true"
>
  <ngx-datatable-column
    prop="correlative"
    name="#"
    [width]="100"
  ></ngx-datatable-column>

  <!-- <ngx-datatable-column
    prop="year"
    name="Año"
    [width]="100"
  ></ngx-datatable-column> -->

  <ngx-datatable-column
    prop="channel_name"
    name="Canal"
    [width]="150"
  ></ngx-datatable-column>

  <ngx-datatable-column
    prop="song_code"
    name="Código Obra"
    [width]="175"
  ></ngx-datatable-column>

  <ngx-datatable-column prop="song_isrc" name="ISRC" [width]="150">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div class="pr-2" *ngIf="row.artist_code === 'NO_REGISTRADO'">
        <input
          type="text"
          class="form-control"
          value="{{ value }}"
          (change)="row.song_isrc = $event.target.value; row.edited = true"
        />
      </div>
      <p *ngIf="row.artist_code != 'NO_REGISTRADO'">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column prop="song_name" name="Obra" [width]="350">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div class="pr-2" *ngIf="row.artist_code === 'NO_REGISTRADO'">
        <input
          type="text"
          class="form-control"
          value="{{ value }}"
          *ngIf="row.artist_code === 'NO_REGISTRADO'"
          (change)="row.song_name = $event.target.value; row.edited = true"
        />
      </div>
      <p *ngIf="row.artist_code != 'NO_REGISTRADO'">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>

  <!-- <ngx-datatable-column prop="codigo_artista" name="Código Artista"></ngx-datatable-column> -->
  <ngx-datatable-column prop="artist_name" name="Artista" [width]="350">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div class="pr-2" *ngIf="row.artist_code === 'NO_REGISTRADO'">
        <input
          type="text"
          class="form-control"
          value="{{ value }}"
          *ngIf="row.artist_code === 'NO_REGISTRADO'"
          (change)="row.artist_name = $event.target.value; row.edited = true"
        />
      </div>
      <p *ngIf="row.artist_code != 'NO_REGISTRADO'">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>

  <!-- <ngx-datatable-column prop="codigo_disquera" name="Código Disquera"></ngx-datatable-column> -->
  <!-- <ngx-datatable-column prop="disquera" name="Sello" [flexGrow]="0.5"></ngx-datatable-column> -->

  <ngx-datatable-column prop="hits" name="HITS" [width]="100">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div class="pr-2" *ngIf="row.artist_code === 'NO_REGISTRADO'">
        <input
          type="text"
          class="form-control"
          value="{{ value }}"
          *ngIf="row.artist_code === 'NO_REGISTRADO'"
          (change)="row.hits = $event.target.value; row.edited = true"
        />
      </div>
      <p *ngIf="row.artist_code != 'NO_REGISTRADO'">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>

  <!-- <ngx-datatable-column prop="fecha_dia" name="Fecha" [width]="150">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div class="pr-2" *ngIf="row.codigo_artista === 'NO_REGISTRADO'">
        <input
          type="text"
          class="form-control"
          value="{{ value }}"
          *ngIf="row.codigo_artista === 'NO_REGISTRADO'"
          (change)="row.hits = $event.target.value; row.edited = true"
        />
      </div>
      <p *ngIf="row.codigo_artista != 'NO_REGISTRADO'">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column> -->

  <ngx-datatable-column name="Acción" [width]="100">
    <ng-template
      let-rowIndex="rowIndex"
      let-row="row"
      let-value="value"
      ngx-datatable-cell-template
    >
      <div
        class="fonticon-container"
        *ngIf="row.artist_code === 'NO_REGISTRADO'"
      >
        <div class="fonticon-wrap mr-3">
          <i-feather
            name="refresh-cw"
            class="success mr-1"
            style="cursor: pointer"
            (click)="updateMonitoringItem(row)"
          >
          </i-feather>
        </div>
      </div>

      <div
        class="fonticon-container"
        *ngIf="row.artist_code != 'NO_REGISTRADO'"
      >
        <div class="fonticon-wrap mr-3">
          <i-feather
            name="edit-2"
            class="primary"
            (click)="enableItemEdition(row.correlative, rowIndex)"
          >
          </i-feather>
        </div>
      </div>

      <div class="fonticon-container">
        <div class="fonticon-wrap mr-3">
          <i-feather
            name="x"
            class="danger"
            (click)="removeItemFromMonitoring(row.correlative, row.token)"
          ></i-feather>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
