import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";

import { RouteDataService } from 'app/services/route-data.service';
import { AdminService } from 'app/services/admin.service';

declare var require: any;

export interface Chart {
    type: ChartType;
    data: Chartist.IChartistData;
    options?: any;
    responsiveOptions?: any;
    events?: ChartEvent;
}


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    current_user: any;
    stats: any;
    show_stats: boolean = false;

    socios: any;
    distributions: any;
    genero: any;

    WidgetlineChart: Chart = {
        type: 'Line',
        data: {
            series: []
        },
        options: {
            axisX: {
                showGrid: true,
                showLabel: false,
                offset: 0,
            },
            axisY: {
                showGrid: false,
                low: 40,
                showLabel: false,
                offset: 0,
            },
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            fullWidth: true,
        },
    };

    donutChart2: Chart = {
        type: 'Pie',
        data: {
            labels: [],
            series: []
        },
        options: {
            donut: true,
            donutWidth: 40,
            startAngle: 270,
            total: 200,
            showLabel: true,
        },
    };

    donutChart3: Chart = {
        type: 'Pie',
        data: {
            labels: [],
            series: []
        },
        options: {
            donut: true,
            showLabel: true,
            labelDirection: 'explode',

        }, responsiveOptions: [
            ['screen and (min-width: 640px)', {
                chartPadding: 30,
                labelOffset: 90,
                labelDirection: 'explode',
                labelInterpolationFnc: function (value) {
                    return value;
                },
            }],
            ['screen and (min-width: 1024px)', {
                labelOffset: 50,
                chartPadding: 20
            }]
        ],
    };


    worksChart: Chart = {
        type: 'Line',
        data: {
            "labels": [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic"
            ],
            "series": [
                [
                    5,
                    30,
                    25,
                    55,
                    45,
                    65,
                    60,
                    105,
                    80,
                    110,
                    120,
                    150
                ],
                [
                    80,
                    95,
                    87,
                    155,
                    140,
                    147,
                    130,
                    180,
                    160,
                    175,
                    165,
                    200
                ]
            ]
        },
        options: {
            showArea: true,
            fullWidth: true,
            lineSmooth: Chartist.Interpolation.none(),
            axisX: {
                showGrid: false,
            },
            axisY: {
                low: 0,
                scaleMinSpace: 50,
            },
            chartPadding: { top: 0, right: 25, bottom: 0, left: 0 },
        },
        responsiveOptions: [
            ['screen and (max-width: 640px) and (min-width: 381px)', {
                axisX: {
                    labelInterpolationFnc: function (value, index) {
                        return index % 2 === 0 ? value : null;
                    }
                }
            }],
            ['screen and (max-width: 380px)', {
                axisX: {
                    labelInterpolationFnc: function (value, index) {
                        return index % 3 === 0 ? value : null;
                    }
                }
            }]
        ],
        events: {
            created(data: any): void {
                var defs = data.svg.elem('defs');
                defs.elem('linearGradient', {
                    id: 'gradient1',
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                }).elem('stop', {
                    offset: 0.2,
                    'stop-color': 'rgba(255, 255, 255, 1)'
                }).parent().elem('stop', {
                    offset: 1,
                    'stop-color': 'rgba(38, 198, 218, 1)'
                });

                defs.elem('linearGradient', {
                    id: 'gradient2',
                    x1: 0,
                    y1: 1,
                    x2: 0,
                    y2: 0
                }).elem('stop', {
                    offset: 0.5,
                    'stop-color': 'rgba(255, 255, 255, 1)'
                }).parent().elem('stop', {
                    offset: 1,
                    'stop-color': 'rgba(255,141,96, 1)'
                });
            },
            draw(data: any): void {

                var circleRadius = 6;
                if (data.type === 'point') {
                    var circle = new Chartist.Svg('circle', {
                        cx: data.x,
                        cy: data.y,
                        r: circleRadius,
                        class: 'ct-point-circle'
                    });
                    data.element.replace(circle);
                }
            }
        },
    }

    //ngx
    barChartColorScheme = {
        domain: ['#FF5063', '#0CC27E', '#FF586B', '#AAAAAA']
    };

    barChartmulti = [];
    barChartmulti2 = [];
    barChartShowYAxis = true;
    barChartShowXAxis = true;
    barChartGradient = false
    barChartShowLegend = false;
    barChartShowXAxisLabel = true;
    barChartXAxisLabel = 'Año';
    barChartShowYAxisLabel = true;
    barChartYAxisLabel = 'HITS';
    barChartYAxisLabel2 = 'Monto (Q)';


    chartUserInscriptions: Chart = {
        type: 'Pie',
        data: {
            labels: ['Inscritos', 'Invitados'],
            series: []
        },
        options: {
            height: '150px',
            width: '150px',
            donut: true,
            donutWidth: 25,
            startAngle: 270,
            total: 200,
            showLabel: false,
        },
    };
    chartUserTypes: Chart = {
        type: 'Pie',
        data: {
            labels: ['Socios', 'Representados'],
            series: []
        }, options: {
            donut: true,
            height: '150px',
            width: '150px',
            donutWidth: 25,
            startAngle: 200,
            total: 200,
            showLabel: false
        }
    }
    chartUserGender: Chart = {
        type: 'Pie',
        data: {
            labels: ['Masculino', 'Femenino'],
            series: []
        }, options: {
            height: '150px',
            width: '150px',
            donut: true,
            donutWidth: 25,
            startAngle: 270,
            total: 200,
            showLabel: false
        }
    }
    chartRoles: Chart = {
        type: 'Pie',
        data: {
            labels: ['Interprete', 'Ejecutante'],
            series: []
        }, options: {
            height: '150px',
            width: '150px',
            donut: true,
            donutWidth: 25,
            startAngle: 270,
            total: 200,
            showLabel: false
        }
    }
    donutChartE: Chart = {
        type: 'Pie',
        data: {
            "series": [
                20,
                10,
                30,
                40
            ]
        },
        options: {
            donut: true,
            showLabel: true,
            labelDirection: 'implode',

        },
    };



    constructor(
        private route_data_service: RouteDataService,
        private admin_service: AdminService) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        //∫console.log(this.current_user);
        this.setupDistributionCharts();
    }

    setupDistributionCharts() {
        if (this.current_user.perfil == '6' || this.current_user.perfil == '7' || this.current_user.perfil == '9') {
            this.admin_service.getStats({ token: this.current_user.token, user: this.current_user.usuario }).subscribe((data: any) => {

                if (data) {/* */

                    this.stats = data.resultado.body;
                    console.log(this.stats);
                    this.distributions = this.stats.distribucion;



                    this.barChartmulti2.push({
                        name: this.distributions.anio,
                        series: [
                            {
                                name: 'Pendiente',
                                value: this.distributions.incompleto
                            }, {
                                name: 'Pagado',
                                value: this.distributions.pagado
                            }
                        ]
                    });

                    this.socios = this.stats.socios;

                    //  console.log(this.socios);
                    //console.log(parseInt(this.socios.inscritos) + parseInt(this.socios.invitados));
                    this.chartUserInscriptions.options.total = parseInt(this.socios.inscritos) + parseInt(this.socios.invitados);
                    this.chartUserInscriptions.data.series = [parseInt(this.socios.inscritos), parseInt(this.socios.invitados)]

                    this.chartUserTypes.options.total = parseInt(this.socios.tipo.socio) + parseInt(this.socios.tipo.representados)
                    this.chartUserTypes.data.series = [parseInt(this.socios.tipo.socio), parseInt(this.socios.tipo.representados)]

                    this.chartRoles.options.total = parseInt(this.stats.roles.interprete.cantidad) + parseInt(this.stats.roles.ejecutante.cantidad);
                    this.chartRoles.data.series = [parseInt(this.stats.roles.interprete.cantidad), parseInt(this.stats.roles.ejecutante.cantidad)]


                    this.genero = this.stats.socios.genero;
                    // console.log(this.genero);
                    this.chartUserGender.options.total = parseInt(this.genero.femenino) + parseInt(this.genero.masculino);
                    this.chartUserGender.data.series = [this.genero.masculino, this.genero.femenino]

                    this.show_stats = true;

                }
            });
        }
    }


}
