import { Component, HostBinding, OnInit } from '@angular/core';
import { PublicService } from 'app/services/public.service';

@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

    currentDate: Date = new Date();
    current_version: string = '';

    constructor(private public_service: PublicService) { }
    ngOnInit(): void {
        this.public_service.getVersion().subscribe((data: any) => {
           // console.log("version: ", data);
            this.current_version = 'ver. ' + data.version
        })
    }
}
