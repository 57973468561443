import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AdminService } from 'app/services/admin.service';

@Component({
    selector: 'app-create-monitoring-channel',
    templateUrl: './create-monitoring-channel.component.html',
    styleUrls: ['./create-monitoring-channel.component.scss']
})
export class CreateMonitoringChannelComponent implements OnInit {

    current_session: any;

    registerMonitoringChannelForm: FormGroup;

    monitoringChannels = [];

    editing = {};

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private admin_service: AdminService) { }

    ngOnInit() {


        this.registerMonitoringChannelForm = this.formBuilder.group({
            id: this.current_session.codigo,
            name: new FormControl(),
            description: new FormControl()
        });
        this.loadMonitoringChannels();
    }
    loadMonitoringChannels() {
        this.admin_service.getMonitoringChannels(this.current_session.codigo, this.current_session.usuario).subscribe((data: any) => {
            // console.log(data);
            this.monitoringChannels = data.resultado.body.canales;
        });
    }
    updateValue(event, cell, rowIndex) {
        console.log('inline editing rowIndex', rowIndex, cell)
        this.editing[rowIndex + '-' + cell] = false;
        this.monitoringChannels[rowIndex][cell] = event.target.value;
        this.monitoringChannels = [...this.monitoringChannels];
        let channel_to_update = this.monitoringChannels[rowIndex];
        console.log('UPDATED!', channel_to_update);

        this.admin_service.updateMonitoringChannel({ code: channel_to_update.codigo, name: channel_to_update.nombre, description: channel_to_update.descripcion }).subscribe((data: any) => {
            console.log(data);
        });
        console.log(this.monitoringChannels);
    }

    registerMonitoringChannel(): void {
        if (this.registerMonitoringChannelForm.valid) {
            console.log(this.registerMonitoringChannelForm.value);
            this.admin_service.registerMonitoringChannel(this.registerMonitoringChannelForm.value).subscribe((data: any) => {
                console.log(data);
                this.registerMonitoringChannelForm.reset();
                this.loadMonitoringChannels();
            });
        }
    }
}
