<div class="row">
  <div class="col-12">
    <h4 class="content-header">Distribución</h4>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="mb-3 mt-3">
          <div class="row">
            <div class="col-6">
              <!-- <input type='text' class="form-control" style='padding:8px;' placeholder='buscar'
                (keyup)='updateFilter($event)' /> -->
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for=""> Distribución</label>
                    <select
                      class="form-control"
                      (change)="changeDistributionSelected($event)"
                    >
                      <option selected value></option>
                      <!-- <option value="all">TODOS</option> -->
                      <option
                        [value]="distribution.distribucion_correlativo"
                        *ngFor="
                          let distribution of distributions;
                          let i = index
                        "
                      >
                        {{ distribution.distribucion_nombre }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="">Cartera de Distribución</label>
                    <select
                      class="form-control"
                      (change)="changeWalletDistributionSelected($event)"
                    >
                      <option selected value></option>
                      <!-- <option value="all">TODOS</option> -->
                      <option
                        [value]="wallet.cartera_correlativo"
                        *ngFor="
                          let wallet of filteredDistributionsWallets;
                          let i = index
                        "
                      >
                        {{
                          wallet.cartera_nombre +
                            " \n " +
                            wallet.cartera_descripcion
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 text-right">
              <button
                *ngIf="current_user.perfil != '10'"
                class="btn btn-raised btn-primary float-right mr-2"
                (click)="createDistribution()"
              >
                Crear Distribución
              </button>
              <button
                class="btn btn-raised btn-primary float-right mr-2"
                (click)="reloadAll()"
              >
                <i class="ft-refresh-cw"></i> Actualizar
              </button>
            </div>
          </div>

          <!-- <pre>{{ distribution_by_participants | json }}</pre> -->
          <!-- <ngb-tabset [activeId]="activeTab" (tabChange)="onTabChange($event)"> -->

          <div class="d-flex justify-content-center mt-5 mb-5" *ngIf="loading">
            <i-feather
              name="loader"
              class="fa fa-spin black text-center feather-loading"
            ></i-feather>
          </div>

          <ngb-tabset *ngIf="!loading">
            <ngb-tab>
              <ng-template ngbTabTitle>Fonogramas</ng-template>
              <ng-template ngbTabContent>
                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <label for="">Búsqueda</label>
                      <input
                        type="text"
                        class="form-control"
                        style="padding: 8px"
                        placeholder="buscar"
                        (keyup)="filterPhonogramsByText($event)"
                      />
                    </div>
                  </div>

                  <div class="col-2">
                    <div class="form-group">
                      <label for="">Situación</label>
                      <select
                        class="form-control"
                        (change)="changeDistributionState($event)"
                      >
                        <option selected value></option>
                        <option value="Pagado">Pagado</option>
                        <option value="Pendiente">Pendiente</option>
                      </select>
                    </div>
                  </div>
                </div>

                <ngx-datatable
                  #table
                  class="bootstrap"
                  [rows]="distribution_by_phonograms_filtered"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="50"
                  [limit]="10"
                  [rowClass]="getRowClass"
                  [scrollbarH]="true"
                >
                  <!-- <ngx-datatable-column
                    class="display:none;"
                    prop="correlativo"
                    name="#"
                    [width]="50"
                  >
                  </ngx-datatable-column> -->

                  <!-- <ngx-datatable-column
                    prop="anio"
                    name="Año"
                    [width]="75"
                  ></ngx-datatable-column> -->

                  <!-- <ngx-datatable-column
                    prop="obra_codigo"
                    name="Código"
                    [width]="175"
                  ></ngx-datatable-column> -->

                  <ngx-datatable-column
                    prop="phonogram_name"
                    name="Obra"
                    [width]="300"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="artist_name"
                    name="Artista"
                    [width]="300"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="hits"
                    name="Hits"
                    [flexGrow]="0.5"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="total"
                    name="Monto (Q)"
                    [flexGrow]="0.75"
                  >
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="situation"
                    name="Situación"
                    [flexGrow]="1"
                  ></ngx-datatable-column>

                  <!-- <ngx-datatable-column
                    prop="fecha_pagado"
                    name="Fecha Pago"
                    [flexGrow]="1"
                  ></ngx-datatable-column> -->

                  <ngx-datatable-column name="Acción" [flexGrow]="0.5">
                    <ng-template
                      let-row="row"
                      let-value="value"
                      ngx-datatable-cell-template
                    >
                      <div class="fonticon-container">
                        <div class="fonticon-wrap">
                          <i-feather
                            name="eye"
                            class="success"
                            style="cursor: pointer"
                            (click)="viewDistributionItem(row)"
                          ></i-feather>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle>Artistas</ng-template>
              <ng-template ngbTabContent>
                <ngx-datatable
                  #table
                  class="bootstrap"
                  [rows]="distributions_by_artist"
                  [columnMode]="'flex'"
                  [headerHeight]="50"
                  [footerHeight]="40"
                  [rowHeight]="50"
                  [rowClass]="getRowClass"
                  [limit]="10"
                >
                  <ngx-datatable-column
                    class="display:none;"
                    prop="correlativo"
                    name="#"
                    [flexGrow]="0.25"
                  >
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-column
                    prop="anio"
                    name="Año"
                    [flexGrow]="0.5"
                  ></ngx-datatable-column> -->

                  <ngx-datatable-column
                    prop="name"
                    name="Artista"
                    [flexGrow]="1.5"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="total"
                    name="Monto (Q)"
                    [flexGrow]="0.75"
                  >
                  </ngx-datatable-column>
                  <ngx-datatable-column
                    prop="phonograms"
                    name="Fonogramas"
                    [flexGrow]="0.75"
                  >
                  </ngx-datatable-column>

                  <!-- <ngx-datatable-column
                    prop="situacion"
                    name="Situación"
                    [flexGrow]="1"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="fecha_pagado"
                    name="Fecha Pago"
                    [flexGrow]="1"
                  ></ngx-datatable-column> -->
                </ngx-datatable>
              </ng-template>
            </ngb-tab>

            <ngb-tab>
              <ng-template ngbTabTitle>Participantes</ng-template>
              <ng-template ngbTabContent>
                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <label for="">Búsqueda</label>
                      <input
                        type="text"
                        class="form-control"
                        style="padding: 8px"
                        placeholder="buscar"
                        (keyup)="filterDistributionParticipantsByText($event)"
                      />
                    </div>
                  </div>

                  <!-- <div class="col-2">
                    <div class="form-group">
                      <label for="">Año de Distribución</label>
                      <select
                        class="form-control"
                        (change)="filterDistributionParticipantsYear($event)"
                      >
                        <option
                          [value]="i"
                          *ngFor="
                            let summary of distribution_by_participants_main;
                            let i = index
                          "
                        >
                          {{ summary.year }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-2">
                    <div class="form-group">
                      <label for="">Situación</label>
                      <select
                        class="form-control"
                        (change)="filterDistributionParticipantsState($event)"
                      >
                        <option selected value></option>
                        <option value="Pagado">Pagado</option>
                        <option value="Pendiente">Pendiente</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-5">
                    <div class="text-right">
                      <!-- <button
                        class="btn btn-raised btn-primary mr-1"
                        (click)="loadDistributionsParticipants()"
                      >
                        <i-feather name="refresh-cw"></i-feather> Actualizar
                      </button> -->
                      <button
                        class="btn btn-raised btn-primary mr-1"
                        (click)="generateDistributionParticipantsFile()"
                      >
                        <i-feather name="file-text"></i-feather> Exportar
                      </button>
                    </div>
                  </div>
                </div>

                <ngx-datatable
                  #table
                  class="bootstrap"
                  [rows]="distribution_by_participants_filtered"
                  [columnMode]="'flex'"
                  [headerHeight]="50"
                  [footerHeight]="40"
                  [rowHeight]="50"
                  [limit]="10"
                  [rowClass]="getRowClass"
                >
                  <ngx-datatable-column
                    prop="correlativo"
                    name="#"
                    [flexGrow]="0.25"
                  ></ngx-datatable-column>

                  <!-- <ngx-datatable-column
                    prop="anio"
                    name="Año"
                    [flexGrow]="0.25"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="sociedad_nombre"
                    name="Sociedad"
                    [flexGrow]="0.8"
                  ></ngx-datatable-column> -->

                  <ngx-datatable-column
                    prop="code"
                    name="Código"
                    [flexGrow]="0.75"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="name"
                    name="Nombre"
                    [flexGrow]="1.5"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="total"
                    name="Monto(Q)"
                    [flexGrow]="0.75"
                  >
                    <ng-template
                      let-value="value"
                      let-row="row"
                      let-expanded="expanded"
                      ngx-datatable-cell-template
                    >
                      <span>{{ formatNumber(value) }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    prop="appearances"
                    name="Participaciones"
                    [flexGrow]="1"
                  ></ngx-datatable-column>

                  <ngx-datatable-column
                    prop="situacion"
                    name="Situación"
                    [flexGrow]="1"
                  ></ngx-datatable-column>

                  <ngx-datatable-column name="Acción" [flexGrow]="0.5">
                    <ng-template
                      let-row="row"
                      let-value="value"
                      ngx-datatable-cell-template
                    >
                      <div class="fonticon-container">
                        <div class="fonticon-wrap mr-3">
                          <i-feather
                            name="eye"
                            class="primary"
                            style="cursor: pointer"
                            (click)="DistributionsByParticipant(row)"
                          ></i-feather>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalParticipantDistributions let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Distribuciones por Participante</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="c('Cancel')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-left row">
      <div class="col">
        <dl class="row">
          <dt class="col-sm-3 text-right">Distribución</dt>
          <dd class="col-sm-9">{{ selected_distribution.nombre }}</dd>
        </dl>
      </div>
      <div class="col"></div>
    </div>

    <div class="text-left row">
      <div class="col">
        <dl class="row">
          <dt class="col-sm-3 text-right">Código</dt>
          <dd class="col-sm-9">
            <h3>{{ current_participant_detail.code }}</h3>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3 text-right">Nombre</dt>
          <dd class="col-sm-9">
            <h3>{{ current_participant_detail.name }}</h3>
          </dd>
        </dl>
      </div>
      <div class="col">
        <dl class="row">
          <dt class="col-sm-3 text-right">Monto Total</dt>
          <dd class="col-sm-9">
            <h3>Q {{ formatNumber(current_participant_detail.total) }}</h3>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3 text-right">Monto Pagado</dt>
          <dd class="col-sm-9">
            <h3>Q {{ formatNumber(current_participant_detail.paid) }}</h3>
          </dd>
        </dl>
        <!-- <dl class="row">
          <dt class="col-sm-3 text-right">Monto Pendiente</dt>
          <dd class="col-sm-9">
            <h3>
              Q {{ formatNumber(current_participant_detail.monto_nopagado) }}
            </h3>
          </dd>
        </dl> -->
      </div>
    </div>

    <div class="row">
   
   
      <div class="col">
        <div class="form-group">
          <label for="">Fonogramas</label>
          <select
            class="form-control"
            (change)="handleParticipantDistributionFilter($event, 'FONOGRAMA')"
          >
            <option selected value="all">Todos</option>
            <option
              [value]="work.code"
              *ngFor="
                let work of current_participant_detail.works;
                let i = index
              "
            >
              {{ work.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label for="">Convenios</label>
          <select
            class="form-control"
            (change)="handleParticipantDistributionFilter($event, 'CONVENIO')"
          >
            <option selected value="all">Todos</option>
            <option value="0">Fonogramas Sin Convenio</option>
            <option
              [value]="item.publisher_id"
              *ngFor="
                let item of current_participant_detail.agreements;
                let i = index
              "
            >
              {{ item.publisher_name }} - {{ item.agreement_end }}
            </option>
          </select>
        </div>
      </div>


      <div class="col">
        <div class="form-group">
          <label for="">Situación</label>
          <select
            class="form-control"
            (change)="handleParticipantDistributionFilter($event, 'SITUACION')"
          >
            <option selected value="all">Todos</option>
            <option value="pagado">Pagado</option>
            <option value="incompleto">Pendiente</option>
          </select>
        </div>
      </div>
    </div>
    <div class="text-left row">
      <div class="col">
        <div class="row">
          <div class="col-sm-12">
            <ngx-datatable
          class="bootstrap" 
          [headerHeight]="40" 
          [limit]="9" 
          [columnMode]="'force'"
          [footerHeight]="40" 
          [rowHeight]="'auto'" 
          [rows]="current_participant_participations_filtered"
          
        >
          <ngx-datatable-column
            prop="name_channel"
            name="Canal"
          ></ngx-datatable-column>
    
          <ngx-datatable-column
            prop="hits_total"
            name="HITS"
          ></ngx-datatable-column>
    
          <ngx-datatable-column prop="song_name" name="Obra">
          </ngx-datatable-column>
          
          <ngx-datatable-column
            prop="artist_name"
            name="Artista"
          ></ngx-datatable-column>
    
          <ngx-datatable-column prop="rol_name" name="Rol"></ngx-datatable-column>
    
          <ngx-datatable-column
            prop="instrument_name"
            name="Instrumento"
          ></ngx-datatable-column>
    
          <ngx-datatable-column prop="amount_partner" name="Monto(Q)">
            <ng-template
              let-value="value"
              let-row="row"
              let-expanded="expanded"
              ngx-datatable-cell-template
            >
              <span>{{ formatNumber(value) }}</span>
            </ng-template>
          </ngx-datatable-column>
    
          <ngx-datatable-column
            prop="situation"
            name="Situación"
          ></ngx-datatable-column>
    
          <ngx-datatable-column
            prop="publisher_name"
            name="Editorial"
          ></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="current_user.perfil != '10'">
    <button
      type="button"
      class="btn btn-raised btn-primary"
      (click)="generateParticipantPaymentDetail()"
    >
      <i-feather name="file-text"></i-feather> Generar Documento
    </button>
    <!-- <button
      type="button"
      class="btn btn-raised btn-primary btn-success"
      (click)="MarkParticipantAsPaid()"
    >
      <i-feather name="dollar-sign"></i-feather> Pagar Distribución
    </button> -->
  </div>
</ng-template>

<ng-template #modalDistributionItemDetail let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Detalle de Distribución</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="c('Cancel')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col">
          <dl>
            <dt>Obra</dt>
            <dd>
              <h5>{{ selected_distribution_detail.phongram_name }}</h5>
            </dd>
          </dl>
          <dl>
            <dt>Artista</dt>
            <dd>
              <h5>{{ selected_distribution_detail.artist_name }}</h5>
            </dd>
          </dl>
        </div>

        <div class="col">
          <dl>
            <dt>Hits</dt>
            <dd class="">
              <h5>{{ selected_distribution_detail.hits }}</h5>
            </dd>
          </dl>
          <dl>
            <dt>Monto Recaudado</dt>
            <dd class="">
              <h5>Q {{ selected_distribution_detail.total }}</h5>
            </dd>
          </dl>
        </div>

        <div class="col">
          <dl>
            <dt>Situación</dt>
            <dd class="">
              <h5>{{ selected_distribution_detail.situation }}</h5>
            </dd>
          </dl>
          <dl>
            <dt>Fecha Pago</dt>
            <dd class="">
              <h5>{{ selected_distribution_detail.fecha_pagado }}</h5>
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <ngx-datatable
      #table
      *ngIf="selected_distribution_detail.participations_detail.length >= 1"
      class="bootstrap"
      [rows]="selected_distribution_detail.participations_detail"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="50"
      [rowClass]="getRowClass"
      [limit]="5"
    >
      <ngx-datatable-column prop="correlativo" name="#"></ngx-datatable-column>

      <!-- <ngx-datatable-column
        prop="integrante_codigo"
        name="Código"
      ></ngx-datatable-column> -->

      <ngx-datatable-column
        prop="partner_name"
        name="Nombre"
      ></ngx-datatable-column>

      <ngx-datatable-column prop="rol_name" name="Rol"></ngx-datatable-column>

      <ngx-datatable-column
        prop="instrument_name"
        name="Instrumento"
      ></ngx-datatable-column>

      <!-- <ngx-datatable-column
        prop="convenio_distribucion"
        name="Porcentaje (%)"
      ></ngx-datatable-column> -->

      <ngx-datatable-column prop="amount_partner" name="Monto (Q)">
        <ng-template
          let-value="value"
          let-row="row"
          let-expanded="expanded"
          ngx-datatable-cell-template
        >
          <span>{{ formatNumber(value) }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="situation"
        name="Situación"
      ></ngx-datatable-column>

      <!-- <ngx-datatable-column name="Acción" [flexGrow]="0.5">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="fonticon-container ">
            <div class="fonticon-wrap">
              <i-feather name="eye" class="success" style="cursor:pointer;"
                (click)="DistributionsByParticipant(row.integrante_codigo,row.anio);"></i-feather>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column> -->
    </ngx-datatable>
  </div>
  <div class="modal-footer"></div>
</ng-template>
