import { Component, OnInit } from '@angular/core';
import { RouteDataService } from 'app/services/route-data.service';
import { PersonService } from 'app/services/person.service';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
    selector: 'app-person-distributions',
    templateUrl: './person-distributions.component.html',
    styleUrls: ['./person-distributions.component.scss']
})
export class PersonDistributionsComponent implements OnInit {

    current_user: any = [];

    current_participant_distributions: any = [];
    current_participant_detail: any = null;

    special_users: any = [
        'MGT8DW04',
        'MGTAYN39',
        'MGTLQOV2',
        'MGT2Q6E8',
        'MGTWJQP2',
        'MGTVNDKG',
        'MGT63NQF'
    ]

    constructor(
        private route_data_service: RouteDataService,
        private person_service: PersonService,
        private toastr: NGXToastrService) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;


        if (this.special_users.filter(x => x == this.current_user.codigo).length >= 1) {
            this.loadPartnerDistributions();
        }

    }

    loadPartnerDistributions() {
        this.person_service.getPartnerDistributions({ user: this.current_user.usuario, id: this.current_user.codigo }).subscribe((data: any) => {
            console.log(data);
            if (data) {
                this.current_participant_distributions = data.resultado.body.data
                this.current_participant_detail = data.resultado.body.artista;
            } else {
                this.toastr.typeErrorText("");
            }
        });
    }

}
