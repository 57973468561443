<div class="modal-header">
  <h4 class="modal-title text-center">Crear Discografía</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="main-content">
    <div class="row text-left">
      <div class="col-12">
        <form [formGroup]="newDiscographyForm">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Nombre</label>
                <input type="text" class="form-control" formControlName="name">
              </div>
            </div>
            <div class="col">
              <label for="">Año</label>
              <select class="form-control" formControlName="publication">
                <option *ngFor="let year of ' '.repeat(currentYear - 1900).split(''), let x = index">{{currentYear
            - x}}</option>
              </select>
            </div>
            <div class="col">
              <label for="">Tipo</label>
              <select class="form-control" formControlName="type">
                <option>Albúm</option>
                <option>Single</option>
              </select>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col">
            <button class="btn btn-primary btn-raised bnt-block" (click)="addDiscography()">Agregar Discografía</button>
          </div>
        </div>
        <hr>

        <ngx-datatable class="bootstrap" [headerHeight]="40" [limit]="4" [columnMode]="'force'" [footerHeight]="40"
          [rowHeight]="'auto'" [rows]="discographies" *ngIf="discographies.length >= 1">
          <ngx-datatable-column prop="codigo" name="Codigo"></ngx-datatable-column>
          <ngx-datatable-column prop="nombre" name="Nombre"></ngx-datatable-column>
          <ngx-datatable-column prop="fecha" name="Año"></ngx-datatable-column>
          <ngx-datatable-column prop="etiqueta" name="Tipo"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>