<div class="modal-header">
  <h4 class="modal-title text-center" *ngIf="current_work">
    Detalle de Obra
    {{ current_work.obra_codigo + " - " + current_work.obra_nombre }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- <pre  style="max-height: 200px !important;" *ngIf="current_work &&  editingWork">{{editWorkForm.value.artistas | json }}</pre> -->
  <!-- <div class="row">
    <div class="col-3">
      <pre style="max-height: 600px !important;"
        *ngIf="current_work &&  editingWork">{{editWorkForm.value | json }}</pre>
    </div>
    <div class="col-9">
    
    </div>
  </div> -->
  <ngb-tabset *ngIf="current_work">
    <ngb-tab>
      <ng-template ngbTabTitle>Detalle</ng-template>
      <ng-template ngbTabContent>
        <div class="px-3" *ngIf="current_work && !editingWork">
          <div class="row equal">
            <dl class="col">
              <dt>Nombre</dt>
              <dd>
                <h1>
                  {{ current_work.obra_nombre }}
                </h1>
              </dd>
            </dl>

            <dl class="col">
              <dt>Código</dt>
              <dd>
                <div class="d-flex align-items-center h-100">
                  <h3>{{ current_work.obra_codigo }}</h3>
                </div>
              </dd>
            </dl>

            <dl class="col">
              <dt>Duración</dt>
              <dd>
                <div class="d-flex align-items-center h-100">
                  <h3>{{ current_work.obra_duracion }}</h3>
                </div>
              </dd>
            </dl>

            <dl class="col">
              <dt>Publicación</dt>
              <dd>
                <div class="d-flex align-items-center h-100">
                  <h3>{{ current_work.obra_publicacion }}</h3>
                </div>
              </dd>
            </dl>
          </div>

          <div class="row">
            <dl class="col">
              <dt>Artista</dt>
              <dd>
                <h5>{{ current_work.additional.banda_nombre }}</h5>
              </dd>
            </dl>
            <dl class="col">
              <dt>Genero</dt>
              <dd>
                <h5>{{ current_work.obra_genero }}</h5>
              </dd>
            </dl>
            <dl class="col">
              <dt>Discográfia</dt>
              <dd>
                <h5>{{ current_work.additional.discografia_nombre }}</h5>
              </dd>
            </dl>
            <dl class="col">
              <dt>Disquera</dt>
              <dd>
                <h5>
                  {{ current_work.additional.disquera_nombre }}
                </h5>
              </dd>
            </dl>
          </div>

          <hr class="grey" />
          <div class="row">
            <dl class="col">
              <dt>ISRC</dt>
              <dd>{{ current_work.obra_isrc }}</dd>
            </dl>
            <dl class="col">
              <dt>ISWC</dt>
              <dd>{{ current_work.obra_iswc }}</dd>
            </dl>
            <dl class="col">
              <dt>BMAT</dt>
              <dd>{{ current_work.obra_bmat }}</dd>
            </dl>
          </div>
        </div>

        <div class="px-3" *ngIf="editingWork && editWorkForm">
          <form [formGroup]="editWorkForm">
            <div formGroupName="obra" class="row equal">
              <dl class="col">
                <dt>Nombre</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="obra_nombre"
                  />
                </dd>
              </dl>

              <dl class="col">
                <dt>Código</dt>
                <dd>
                  <div class="d-flex align-items-center h-100">
                    <h3>{{ current_work.obra_codigo }}</h3>
                  </div>
                </dd>
              </dl>

              <dl class="col">
                <dt>Duración</dt>
                <dd>
                  <ngb-timepicker
                    [spinners]="false"
                    [seconds]="true"
                    formControlName="obj_obra_duracion"
                  >
                  </ngb-timepicker>
                </dd>
              </dl>

              <dl class="col">
                <dt>Publicación</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="obra_publicacion"
                  />
                </dd>
              </dl>
            </div>

            <div class="row" formGroupName="adicional">
              <dl class="col">
                <dt>Artista</dt>
                <dd>
                  <select
                    class="form-control"
                    #selectArtist
                    formControlName="banda_codigo"
                    (change)="
                      getArtistDiscography(
                        current_work.additional.banda_codigo
                          ? this.current_work.additional.banda_codigo
                          : null
                      )
                    "
                  >
                    <option value=""></option>
                    <option
                      [value]="artist.codigo"
                      *ngFor="let artist of saved_artists"
                    >
                      {{ artist.nombre }}
                    </option>
                  </select>
                </dd>
              </dl>
              <dl class="col">
                <dt>Discográfia</dt>
                <dd>
                  <select
                    class="form-control"
                    formControlName="discografia_codigo"
                  >
                    <option value=""></option>
                    <option
                      [value]="discography.codigo"
                      *ngFor="let discography of selected_artist_discographies"
                    >
                      {{ discography.nombre }}
                    </option>
                  </select>
                </dd>
              </dl>
              <dl class="col">
                <dt>Disquera</dt>
                <dd>
                  <select
                    class="form-control"
                    formControlName="disquera_codigo"
                  >
                    <option value=""></option>
                    <option
                      [value]="rcompany.codigo"
                      *ngFor="let rcompany of saved_record_companies"
                    >
                      {{ rcompany.nombre }}
                    </option>
                  </select>
                </dd>
              </dl>
            </div>

            <div class="row" formGroupName="obra">
              <dl class="col">
                <dt>ISRC</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="obra_isrc"
                  />
                </dd>
              </dl>
              <dl class="col">
                <dt>ISWC</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="obra_iswc"
                  />
                </dd>
              </dl>
              <dl class="col">
                <dt>BMAT</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="obra_bmat"
                  />
                </dd>
              </dl>
            </div>
          </form>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>Convenios</ng-template>
      <ng-template ngbTabContent>
        <form class="form">
          <div class="form-body">
            <div class="row">
              <div class="col-5">
                <div class="form-group" *ngIf="editingWork">
                  <label for="">Buscar Artista</label>
                  <div class="input-group">
                    <div class="position-relative has-icon-left">
                      <input
                        class="form-control"
                        ngui-auto-complete
                        list-formatter="(codigo) nombre_completo"
                        max-num-list="5"
                        display-property-name="nombre_completo"
                        [(ngModel)]="searchArtistForAgreement"
                        [ngModelOptions]="{ standalone: true }"
                        [source]="saved_enrolled_partners"
                        style="width: 55vh !important"
                      />
                      <div class="form-control-position">
                        <i class="ft-search info"></i>
                      </div>
                    </div>
                    <div class="input-group-append">
                      <button
                        class="btn btn-raised btn-primary"
                        type="button"
                        (click)="addAgreement(searchArtistForAgreement)"
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group" *ngIf="editingWork">
                  <label class="d-block">Asignación de Convenios</label>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      class="custom-control-input"
                      value="auto"
                      [(ngModel)]="assign_method"
                      (ngModelChange)="
                        this.editWorkForm['controls'].obra[
                          'controls'
                        ].metodo_asignacion.setValue(assign_method)
                      "
                    />
                    <label class="custom-control-label" for="customRadioInline1"
                      >Automático</label
                    >
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      checked
                      name="customRadioInline1"
                      class="custom-control-input"
                      value="manual"
                      [(ngModel)]="assign_method"
                      (ngModelChange)="
                        this.editWorkForm[
                          'controls'
                        ].obra.controls.metodo_asignacion.setValue(
                          assign_method
                        )
                      "
                    />
                    <label class="custom-control-label" for="customRadioInline2"
                      >Manual</label
                    >
                  </div>
                </div>

                <!-- <div class="d-flex align-items-center justify-content-center">
                          <h1
                            [className]="(currentWorkAgreementPercentage >100 || currentWorkAgreementPercentage <= 0) ? 'percentage text-danger' :  (currentWorkAgreementPercentage === 100) ? 'percentage text-success' : 'percentage' ">
                            {{ currentWorkAgreementPercentage }}%</h1>
                        </div> -->
              </div>
              <div class="col-4">
                <label>Porcentaje Asignado</label>
                <div class="d-flex align-items-center justify-content-center">
                  <h1
                    [className]="
                      current_work_percentage_assigned > 100 ||
                      current_work_percentage_assigned <= 0
                        ? 'percentage text-danger'
                        : current_work_percentage_assigned === 100
                        ? 'percentage text-success'
                        : 'percentage'
                    "
                  >
                    {{ current_work_percentage_assigned }}%
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ngx-datatable
          #workArtistsTable
          class="bootstrap"
          [rows]="
            !editingWork
              ? current_work.artists
              : this.editWorkForm.value.artistas
          "
          [columnMode]="'flex'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="7"
        >
          <ngx-datatable-column
            prop="artista_codigo"
            name="Código"
            [flexGrow]="0.75"
          ></ngx-datatable-column>

          <ngx-datatable-column
            prop="sociedad_nombre"
            name="Sociedad"
            [flexGrow]="1"
          ></ngx-datatable-column>

          <ngx-datatable-column
            prop="artista_nombre"
            name="Nombre"
            [flexGrow]="1.3"
          ></ngx-datatable-column>

          <ngx-datatable-column
            prop="artista_alias"
            name="Alias"
            [flexGrow]="0.75"
          ></ngx-datatable-column>

          <ngx-datatable-column prop="artista_rol" name="Rol" [flexGrow]="1.3">
            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              let-row="row"
            >
              <span *ngIf="!editingWork">
                {{ value }}
              </span>
              <form [formGroup]="editWorkForm" *ngIf="editingWork">
                <div class="mr-1" formArrayName="artistas">
                  <div class="form-group" [formGroupName]="rowIndex">
                    <select
                      class="form-control"
                      formControlName="artista_rol"
                      (change)="
                        this.editWorkForm['controls'].artistas.controls[
                          rowIndex
                        ].controls.artista_rol.setValue($event.target.value);
                        handleAssignMethod()
                      "
                    >
                      <option
                        [selected]="
                          row.artista_rol === role.nombre ? true : false
                        "
                        [value]="role.nombre"
                        *ngFor="let role of saved_artist_roles; let i = index"
                      >
                        {{ role.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            prop="artista_instrumento"
            name="Instrumento"
            [flexGrow]="1.3"
          >
            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              let-row="row"
            >
              <span *ngIf="!editingWork">
                {{ value }}
              </span>
              <form [formGroup]="editWorkForm" *ngIf="editingWork">
                <div class="mr-1" formArrayName="artistas">
                  <div class="form-group" [formGroupName]="rowIndex">
                    <select
                      class="form-control"
                      formControlName="artista_instrumento"
                    >
                      <option
                        [selected]="
                          row.artista_instrumento === instrument.nombre
                            ? true
                            : false
                        "
                        [value]="instrument.nombre"
                        *ngFor="let instrument of saved_instruments"
                      >
                        {{ instrument.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-column [flexGrow]="1" *ngIf="!editingWork"></ngx-datatable-column> -->

          <ngx-datatable-column
            prop="porcentaje"
            name="Convenio(%)"
            [flexGrow]="1.3"
          >
            <ng-template let-column="column" ngx-datatable-header-template>
              <span
                [className]="
                  current_work_percentage_assigned > 100 ||
                  current_work_percentage_assigned <= 0
                    ? 'text-danger'
                    : currentWorkAgreementPercentage === 100
                    ? 'text-success'
                    : ''
                "
              >
                {{
                  "Convenio (%" + current_work_percentage_assigned + ")"
                }}</span
              >
            </ng-template>

            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              let-row="row"
            >
              <span *ngIf="!editingWork"> % {{ value }} </span>
              <span *ngIf="editingWork && assign_method === 'auto'">
                % {{ row.porcentaje_fixed }}
              </span>
              <form
                [formGroup]="editWorkForm"
                *ngIf="editingWork && assign_method === 'manual'"
              >
                <div class="mr-1" formArrayName="artistas">
                  <div class="form-group" [formGroupName]="rowIndex">
                    <input
                      autofocus
                      type="number"
                      step="0.001"
                      class="form-control"
                      [value]="value"
                      formControlName="porcentaje"
                      (change)="handleAssignMethod()"
                    />
                  </div>
                </div>
              </form>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Distribución(%)" [flexGrow]="1.3">
            <ng-template
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
              let-value="value"
              let-row="row"
            >
              <span *ngIf="!editingWork">% {{ row.porcentaje_dist }} </span>
              <span *ngIf="editingWork"
                >% {{ row.porcentaje_dist_fixed }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Acción"
            [flexGrow]="0.8"
            *ngIf="editingWork"
          >
            <ng-template
              let-row="row"
              let-value="value"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <div class="fonticon-container">
                <div class="fonticon-wrap mr-3">
                  <i-feather
                    name="x"
                    class="danger"
                    (click)="removeArtistFromWork(row.artista_codigo)"
                  ></i-feather>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </ng-template>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>Editoriales</ng-template>
      <ng-template ngbTabContent>
        <!-- <pre>{{ work_participants | json }}</pre> -->

        <ngx-datatable
          #workArtistsTable
          class="bootstrap"
          [rows]="workPublisherAgreements"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="7"
        >
          <ngx-datatable-column prop="partner_name" name="Nombre">
          </ngx-datatable-column>

          <!-- <ngx-datatable-column prop="participations" name="Participaciones">
          </ngx-datatable-column> -->

          <ngx-datatable-column prop="publisher_name" name="Editorial">
          </ngx-datatable-column>
          <ngx-datatable-column prop="begining" name="Fecha Comienzo">
          </ngx-datatable-column>
          <ngx-datatable-column prop="deadline" name="Fecha Vencimiento">
          </ngx-datatable-column>
          <ngx-datatable-column prop="flag" name="Estado">
          </ngx-datatable-column>
          <ngx-datatable-column name="Acción" *ngIf="editingWork">
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <i-feather
                name="x-circle"
                class="danger"
                (click)="removePublisherAgreement(row)"
              ></i-feather>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-raised btn-primary"
    (click)="handleAssignMethod()"
    *ngIf="
      editingWork &&
      current_work.artists.length >= 1 &&
      assign_method === 'auto'
    "
  >
    <i-feather name="percent"></i-feather> Asignar Convenios
  </button>

  <button
    type="button"
    class="btn btn-raised btn-primary"
    (click)="handlePublisherAgreement()"
    *ngIf="editingWork && current_work.artists.length >= 1"
  >
    <i-feather name="edit"></i-feather> Agregar Convenio Editorial
  </button>

  <button
    type="button"
    class="btn btn-raised btn-primary"
    (click)="handleAssignMethod()"
    *ngIf="
      editingWork &&
      current_work.artists.length >= 1 &&
      assign_method === 'manual'
    "
  >
    <i-feather name="percent"></i-feather> Asignar Distribución
  </button>

  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary btn-success"
    *ngIf="editingWork"
    (click)="saveWorkChanges()"
  >
    <i-feather name="save"></i-feather> Guardar Cambios
  </button>

  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary btn-danger"
    *ngIf="editingWork"
    (click)="editingWork = false; loadWorkData()"
  >
    <i-feather name="x"></i-feather> Cancelar Edición
  </button>

  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary"
    *ngIf="!editingWork"
    (click)="editWork()"
  >
    <i-feather name="edit"></i-feather> Editar Obra
  </button>
</div>
