import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { PersonService } from '../services/person.service';
import { RouteDataService } from 'app/services/route-data.service';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
    selector: 'app-person-works',
    templateUrl: './person-works.component.html',
    styleUrls: ['./person-works.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PersonWorksComponent implements OnInit {

    current_user: any;

    noData = false;

    tableAgreementRows = [];
    temp = [];

    constructor(
        private person_service: PersonService,
        private route_data_service: RouteDataService,
        private toastr: NGXToastrService) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.loadPartnerWorks();
    }

    loadPartnerWorks(){
        this.person_service.getUserAgreements({ user: this.current_user.usuario, id: this.current_user.codigo }).subscribe((data: any) => {
           // console.log(data);
            if (data && data.header && data.header.type && data.header.type === "success") {
                this.noData = false;
                let agreements = data.body.obras;
                this.temp = [...agreements];
                this.tableAgreementRows = agreements;
            } else {
                this.toastr.typeErrorText(data.header.msg);
                this.noData = true;
            }
        });
    }

}
