<div class="row">
  <div class="col-11">
    <h4 class="content-header">
      Usuarios Administrativos
    </h4>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="px-3 mb-3 mt-3">
          <div class="row">
            <div class="col-6">
              <input type='text' class="form-control" style='padding:8px;' placeholder='buscar'
                (keyup)='updateFilter($event)' />
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-raised btn-primary mr-1" (click)="openCreateAdminUser()">
                <i class="ft-user"></i> Crear Usuario
              </button>
              <button class="btn btn-raised btn-primary mr-1" (click)="getAdminUsers()">
                <i class="ft-refresh-cw"></i> Actualizar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <ngx-datatable #table class="bootstrap" [rows]="adm_users_list" [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="50" [rowHeight]="'auto'" [limit]="10">
                <ngx-datatable-column prop="id" name="ID"></ngx-datatable-column>
                <ngx-datatable-column prop="nombre" name="Nombre">
                  <!-- <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <span title="Double click to edit" (dblclick)="editing[rowIndex + '-nombre'] = true"
                      *ngIf="!editing[rowIndex + '-nombre']">
                      {{value}}
                    </span>
                    <input autofocus (blur)="updateADMUser($event, 'nombre', rowIndex)"
                      *ngIf="editing[rowIndex+ '-nombre']" type="text" [value]="value" />
                  </ng-template> -->
                </ngx-datatable-column>
                <ngx-datatable-column prop="correo" name="Correo">
                  <!-- <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <span title="Double click to edit" (dblclick)="editing[rowIndex + '-correo'] = true"
                      *ngIf="!editing[rowIndex + '-correo']">
                      {{value}}
                    </span>
                    <input autofocus (blur)="updateADMUser($event, 'correo', rowIndex)"
                      *ngIf="editing[rowIndex+ '-correo']" type="text" [value]="value" />
                  </ng-template> -->
                </ngx-datatable-column>
                <ngx-datatable-column prop="perfil" name="Perfil">
                  <!-- <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <span title="Double click to edit" (dblclick)="editing[rowIndex + '-perfil'] = true"
                      *ngIf="!editing[rowIndex + '-perfil']">
                      {{value}}
                    </span>
                    <select autofocus (blur)="updateADMUser($event, 'perfil', rowIndex)"
                      *ngIf="editing[rowIndex+ '-perfil']" type="text" [value]="value">
                      <option value="admin">Administrador</option>
                      <option value="assistant">Asistencia</option>
                      <option value="monitor">Monitor</option>
                    </select>
                  </ng-template> -->
                </ngx-datatable-column>
                <ngx-datatable-column name="Acción">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <!--<button (click)="getGuestData(row.id)">imprimir</button>-->
                    <div class="fonticon-container ">
                      <div class="fonticon-wrap">
                        <i-feather name="lock" title="Enviar Contraseña" class="success" style="cursor:pointer;"
                          (click)="sendUserAdmPassword(row.id,row.correo)"></i-feather>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>