<div class="modal-header">
  <h4 class="modal-title text-center" *ngIf="partner_data">
    Detalle de socio: {{ partner_id }} -
    {{
      partner_data.persona.primer_nombre +
        " " +
        partner_data.persona.primer_apellido
    }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="partner_data" class="">
  <div class="px-3">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4">
        <div class="d-flex justify-content-center mt-3">
          <image-cropper
            style="max-height: 37vh;"
            *ngIf="editingPhoto"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 4"
            [resizeToWidth]="512"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
          >
          </image-cropper>

          <img
            [src]="imageToShow"
            id="userimage"
            (loaded)="userImageLoaded()"
            onerror="this.src = '/assets/img/Profile_avatar_placeholder_large.png';"
            style="height: 37vh;"
            alt="{{ partner_id }}"
            *ngIf="!editingPhoto"
            (click)="editPhoto()"
          />
        </div>

        <br />
        <div class="row d-flex justify-content-end">
          <i-feather
            name="check"
            class="success mr-2"
            style="cursor: pointer;"
            (click)="doneEditingPhoto()"
            *ngIf="editingPhoto"
          ></i-feather>
          <i-feather
            name="edit-2"
            class="success mr-2"
            style="cursor: pointer;"
            (click)="enableEditingPhoto()"
            *ngIf="!editingPhoto && cropperReady"
          ></i-feather>
          <i-feather
            name="upload"
            class="success mr-2"
            style="cursor: pointer;"
            (click)="editPhoto()"
          ></i-feather>
          <i-feather
            name="x-circle"
            class="warning mr-2"
            style="cursor: pointer;"
            (click)="cancelEditingPhoto()"
            *ngIf="editingPhoto"
          ></i-feather>

          <input
            id="photo-input"
            type="file"
            style="display: none;"
            (change)="fileChangeEvent($event)"
          />
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-8">
        <ngb-tabset *ngIf="partner_data" (tabChange)="onTabChange($event)">
          <ngb-tab id="tab-personal">
            <ng-template ngbTabTitle>Personales</ng-template>
            <ng-template ngbTabContent>
              <!--<p *ngIf="editingPartner">{{editPartnerForm.value | json}}</p>-->

              <div class="row">
                <div class="col-6">
                  <dl>
                    <dt *ngIf="!editingPartner">Nombre</dt>
                    <dd *ngIf="!editingPartner">
                      {{
                        partner_data.persona.primer_nombre +
                          "
                    " +
                          partner_data.persona.segundo_nombre +
                          " " +
                          partner_data.persona.primer_apellido +
                          " " +
                          partner_data.persona.segundo_apellido
                      }}
                    </dd>
                    <dt *ngIf="editingPartner && editPartnerForm">Nombres</dt>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <dl formGroupName="persona" class="row">
                          <dd class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="primer_nombre"
                            />
                          </dd>
                          <dd class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="segundo_nombre"
                            />
                          </dd>
                        </dl>
                      </form>
                    </dd>
                    <dt *ngIf="editingPartner && editPartnerForm">Apellidos</dt>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <dl formGroupName="persona" class="row">
                          <dd class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="primer_apellido"
                            />
                          </dd>
                          <dd class="col-6">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="segundo_apellido"
                            />
                          </dd>
                        </dl>
                      </form>
                    </dd>
                    <dt>Nombre Artístico</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.alias }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="alias"
                          />
                        </div>
                      </form>
                    </dd>

                    <dt>Fecha de Nacimiento</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.fecha_nacimiento }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="fecha_nacimiento"
                          />
                        </div>
                      </form>
                    </dd>
                    <dt>Género</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.genero }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <select class="form-control" formControlName="genero">
                            <option value="masculino">Masculino</option>
                            <option value="femenino">Femenino</option>
                          </select>
                        </div>
                      </form>
                    </dd>
                  </dl>
                </div>

                <div class="col-6">
                  <dl>
                    <dt>ID MUSICARTES</dt>
                    <dd>{{ partner_data.persona.id_musicartes }}</dd>

                    <dt>Tipo</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.tipo }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <select class="form-control" formControlName="tipo">
                            <option value="socio">Socio</option>
                            <option value="representado">Representado</option>
                          </select>
                        </div>
                      </form>
                    </dd>

                    <dt>Identificación</dt>
                    <dd>{{ partner_data.persona.identificacion }}</dd>

                    <dt>Fecha de Inscripción</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.fecha_inscripcion }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="inscripcion"
                          />
                        </div>
                      </form>
                    </dd>
                  </dl>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="tab-artist">
            <ng-template ngbTabTitle>Artista</ng-template>
            <ng-template ngbTabContent>
              <dd></dd>
              <dt>Nombre Artístico</dt>
              <dd *ngIf="!editingPartner">{{ partner_data.persona.alias }}</dd>
              <dd *ngIf="editingPartner && editPartnerForm">
                <form [formGroup]="editPartnerForm">
                  <div formGroupName="persona">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="alias"
                    />
                  </div>
                </form>
              </dd>
            </ng-template>
          </ngb-tab>

          <ngb-tab>
            <ng-template ngbTabTitle>Biografía</ng-template>
            <ng-template ngbTabContent>
              <dl>
                <dt>Biografía</dt>
                <dd *ngIf="!editingPartner">
                  <span>
                    {{ partner_data.persona.biografia }}
                  </span>
                </dd>
                <dd *ngIf="editingPartner && editPartnerForm">
                  <form [formGroup]="editPartnerForm">
                    <div formGroupName="persona">
                      <textarea
                        cols="30"
                        rows="10"
                        class="form-control"
                        formControlName="biografia"
                      ></textarea>
                    </div>
                  </form>
                </dd>
              </dl>
            </ng-template>
          </ngb-tab>

          <ngb-tab>
            <ng-template ngbTabTitle>Contacto</ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-12">
                  <dl>
                    <dt>Dirección</dt>
                    <dd *ngIf="!editingPartner">
                      {{ partner_data.persona.direccion }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm">
                        <div formGroupName="persona">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="direccion"
                          />
                          <small
                            class="form-text text-muted danger"
                            *ngIf="
                              editPartnerForm.controls['persona'].get(
                                'direccion'
                              ).errors?.bad_characters &&
                              (editPartnerForm.get('persona').dirty ||
                                editPartnerForm.get('persona').touched)
                            "
                            >¡La dirección incluye argumentos no
                            validos.!</small
                          >
                        </div>
                      </form>
                    </dd>
                    <dt>Correo Electrónico</dt>
                    <dd
                      *ngIf="
                        !editingPartner && partner_data.electronic.length > 0
                      "
                    >
                      {{
                        partner_data.electronic[0].correo_electronico +
                          (partner_data.electronic.length == 2
                            ? " / " +
                              partner_data.electronic[1].correo_electronico
                            : "")
                      }}
                    </dd>

                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm" class="row">
                        <div
                          formArrayName="electronic"
                          class="col-5"
                          *ngFor="
                            let item of editPartnerForm['controls'].electronic[
                              'controls'
                            ];
                            let i = index
                          "
                        >
                          <div [formGroupName]="i">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="correo_electronico"
                            />
                          </div>
                        </div>
                      </form>
                    </dd>

                    <dt>Numéro de Teléfono</dt>
                    <dd *ngIf="!editingPartner && partner_data.voz.length > 0">
                      {{
                        partner_data.voz[0].telefono +
                          (partner_data.voz.length == 2
                            ? " / " + partner_data.voz[1].telefono
                            : "")
                      }}
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm" class="row">
                        <div
                          formArrayName="voz"
                          class="col-5"
                          *ngFor="
                            let item of editPartnerForm['controls'].voz[
                              'controls'
                            ];
                            let i = index
                          "
                        >
                          <div [formGroupName]="i">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="telefono"
                            />
                          </div>
                        </div>
                      </form>
                    </dd>
                    <dt>Redes Sociales</dt>
                    <dd *ngIf="!editingPartner">
                      <a
                        href="{{ social.url }}"
                        target="_blank"
                        *ngFor="let social of partner_data.social"
                      >
                        <i-feather
                          name="facebook"
                          class="mr-2"
                          style="cursor: pointer;"
                          *ngIf="social.producto === 'facebook'"
                        ></i-feather>
                        <i-feather
                          name="instagram"
                          class="mr-2"
                          style="cursor: pointer;"
                          *ngIf="social.producto === 'instagram'"
                        ></i-feather>
                        <i-feather
                          name="twitter"
                          class="mr-2"
                          style="cursor: pointer;"
                          *ngIf="social.producto === 'twitter'"
                        ></i-feather>
                        <i-feather
                          name=" linkedin"
                          class="mr-2"
                          style="cursor: pointer;"
                          *ngIf="social.producto === 'linkedin'"
                        ></i-feather>
                      </a>
                    </dd>
                    <dd *ngIf="editingPartner && editPartnerForm">
                      <form [formGroup]="editPartnerForm" class="row">
                        <div
                          formArrayName="social"
                          class="col-5 mb-2"
                          *ngFor="
                            let item of editPartnerForm['controls'].social[
                              'controls'
                            ];
                            let i = index
                          "
                        >
                          <div [formGroupName]="i">
                            <!-- <div class="input-group"> -->
                            <div class="">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i-feather
                                    name="facebook"
                                    *ngIf="item.value.producto === 'facebook'"
                                  ></i-feather>
                                  <i-feather
                                    name="twitter"
                                    *ngIf="item.value.producto === 'instagram'"
                                  ></i-feather>
                                  <i-feather
                                    name="instagram"
                                    *ngIf="item.value.producto === 'twitter'"
                                  ></i-feather>
                                  <i-feather
                                    name="linkedin"
                                    *ngIf="item.value.producto === 'linkedin'"
                                  ></i-feather>
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="usuario_red"
                                  (keyup)="
                                    item.value.url =
                                      'https://www.' +
                                      item.value.producto +
                                      '.com/' +
                                      item.value.usuario_red
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </dd>
                  </dl>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="tab-beneficiary">
            <ng-template ngbTabTitle>Beneficiarios</ng-template>
            <ng-template ngbTabContent>
              <ngx-datatable
                #beneficiariesTable
                class="bootstrap expandable"
                [columnMode]="'force'"
                [headerHeight]="15"
                [limit]="3"
                [footerHeight]="40"
                [rowHeight]="55"
                [rows]="partner_data.beneficiary"
                [scrollbarH]="false"
              >
                <ngx-datatable-row-detail
                  [rowHeight]="255"
                  #myDetailRow
                  (toggle)="onDetailToggle($event, rowIndex)"
                >
                  <ng-template
                    ngx-datatable-row-detail-template
                    let-index="rowIndex"
                    let-row="row"
                    let-expanded="expanded"
                  >
                    <div class="row px-3" style="overflow-x: hidden;">
                      <div class="col-4">
                        <dl>
                          <dt>Identificación</dt>
                          <dd *ngIf="!editingPartner">
                            {{ row.identificacion }}
                          </dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="identificador"
                                  />
                                </div>
                              </div>
                            </form>
                          </dd>

                          <dt>Nombres</dt>
                          <dd *ngIf="!editingPartner">
                            {{ row.primer_nombre + " " + row.segundo_nombre }}
                          </dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <dl [formGroupName]="row.index" class="row">
                                  <dd class="col-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      formControlName="primer_nombre"
                                    />
                                  </dd>

                                  <dd class="col-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      formControlName="segundo_nombre"
                                    />
                                  </dd>
                                </dl>
                              </div>
                            </form>
                          </dd>

                          <dt>Apellidos</dt>
                          <dd *ngIf="!editingPartner">
                            {{
                              row.primer_apellido +
                                " " +
                                row.segundo_apellido +
                                "
                              " +
                                row.apellido_casada
                            }}
                          </dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <dl [formGroupName]="row.index" class="row">
                                  <dd class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      formControlName="primer_apellido"
                                    />
                                  </dd>

                                  <dd class="col-sm-6">
                                    <input
                                      type="text"
                                      class="form-control"
                                      formControlName="segundo_apellido"
                                    />
                                  </dd>
                                </dl>
                              </div>
                            </form>
                          </dd>
                        </dl>
                      </div>

                      <div class="col-md-4">
                        <dl>
                          <dt>Género</dt>
                          <dd *ngIf="!editingPartner">{{ row.genero }}</dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <select
                                    class="form-control"
                                    formControlName="genero"
                                  >
                                    <option value="masculino">Masculino</option>
                                    <option value="femenino">Femenino</option>
                                  </select>
                                </div>
                              </div>
                            </form>
                          </dd>

                          <dt>Fecha de Nacimiento</dt>
                          <dd *ngIf="!editingPartner">{{ row.nacimiento }}</dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="nacimiento"
                                  />
                                </div>
                              </div>
                            </form>
                          </dd>

                          <dt>Nacionalidad</dt>
                          <dd *ngIf="!editingPartner">
                            {{ row.nacionalidad }}
                          </dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="nacionalidad"
                                  />
                                </div>
                              </div>
                            </form>
                          </dd>
                        </dl>
                      </div>
                      <div class="col-md-4">
                        <dl>
                          <dt>Porcentaje</dt>
                          <dd *ngIf="!editingPartner">{{ row.porcentaje }}</dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="porcentaje"
                                  />
                                </div>
                              </div>
                            </form>
                          </dd>

                          <dt>Parentesco</dt>
                          <dd *ngIf="!editingPartner">{{ row.parentesco }}</dd>
                          <dd *ngIf="editingPartner && editPartnerForm">
                            <form [formGroup]="editPartnerForm">
                              <div formArrayName="beneficiary">
                                <div [formGroupName]="row.index">
                                  <select
                                    class="custom-select form-control"
                                    data-placeholder=""
                                    formControlName="parentesco"
                                  >
                                    <option value="padre/madre"
                                      >Padre/Madre</option
                                    >
                                    <option value="hijo/a">Hijo(a)</option>
                                    <option value="conyuge">Cónyuge</option>
                                    <option value="abuelo/a">Abuelo(a)</option>
                                    <option value="nieto/a">Nieto(a)</option>
                                    <option value="hermano/a"
                                      >Hermano(a)</option
                                    >
                                    <option value="otro">Otro</option>
                                  </select>
                                </div>
                              </div>
                            </form>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>

                <ngx-datatable-column
                  [width]="50"
                  [resizeable]="false"
                  [sortable]="false"
                  [draggable]="false"
                  [canAutoResize]="false"
                >
                  <ng-template
                    let-row="row"
                    let-rowIndex="rowIndex"
                    let-expanded="expanded"
                    ngx-datatable-cell-template
                  >
                    <a
                      href="javascript:void(0)"
                      [class.datatable-icon-right]="!expanded"
                      [class.datatable-icon-down]="expanded"
                      title="Expand/Collapse Row"
                      (click)="toggleExpandRow(row, rowIndex)"
                    >
                    </a>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  prop="identificacion"
                  name="Identificación"
                >
                </ngx-datatable-column>

                <ngx-datatable-column name="Nombre">
                  <ng-template
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <strong>{{
                      row.primer_nombre + " " + row.primer_apellido
                    }}</strong>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  prop="porcentaje"
                  name="Porcentaje (%)"
                ></ngx-datatable-column>
                <ngx-datatable-column
                  prop="parentesco"
                  name="Parentesco"
                ></ngx-datatable-column>

                <ngx-datatable-column name="Acción" *ngIf="editingPartner">
                  <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <i-feather
                      name="x-circle"
                      class="danger"
                      (click)="removePartnerBeneficiary(rowIndex)"
                    ></i-feather>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="tab-banco">
            <ng-template ngbTabTitle>Bancarios</ng-template>
            <ng-template ngbTabContent>
              <div>
                <div class="row" *ngIf="!editingPartner">
                  <div class="col row">
                    <dl class="col">
                      <dt>Nombre</dt>
                      <dd>{{ partner_data.banco.nombre_completo }}</dd>
                    </dl>
                    <dl class="col">
                      <dt>Cuenta</dt>
                      <dd>{{ partner_data.banco.cuenta }}</dd>
                    </dl>
                    <dl class="col">
                      <dt>Banco</dt>
                      <dd>{{ partner_data.banco.banco }}</dd>
                    </dl>
                    <dl class="col">
                      <dt>Moneda</dt>
                      <dd>{{ partner_data.banco.moneda }}</dd>
                    </dl>
                  </div>
                </div>

                <div class="row" *ngIf="editingPartner && editPartnerForm">
                  <div class="col row">
                    <dl class="col">
                      <dt>Nombre</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="banco">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="nombre"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                    <dl class="col">
                      <dt>Cuenta</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="banco">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="cuenta"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                    <dl class="col">
                      <dt>Banco</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="banco">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="banco"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                    <dl class="col">
                      <dt>Tipo de Cuenta</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="banco">
                            <select
                              class="form-control"
                              formControlName="producto"
                            >
                              <option value="monetaria">Monetaria</option>
                              <option value="ahorro">Ahorro</option>
                            </select>
                          </div>
                        </form>
                      </dd>
                    </dl>
                    <dl class="col">
                      <dt>Moneda</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="banco">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="moneda"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="tab-extra">
            <ng-template ngbTabTitle>Fiscales</ng-template>
            <ng-template ngbTabContent>
              <div>
                <div class="" *ngIf="!editingPartner">
                  <div class="row">
                    <dl class="col">
                      <dt>Nombre</dt>
                      <dd>{{ partner_data.fiscal.nombre_completo }}</dd>
                    </dl>
                    <dl class="col">
                      <dt>NIT</dt>
                      <dd>{{ partner_data.fiscal.nit }}</dd>
                    </dl>
                  </div>

                  <div class="row">
                    <dl class="col">
                      <dt>Dirección</dt>
                      <dd>{{ partner_data.fiscal.direccion }}</dd>
                    </dl>
                    <dl class="col">
                      <dt>Facturación</dt>
                      <dd>{{ partner_data.fiscal.facturacion }}</dd>
                    </dl>
                  </div>
                </div>

                <div class="" *ngIf="editingPartner && editPartnerForm">
                  <div class="row">
                    <dl class="col">
                      <dt>Nombre</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="fiscal">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="nombre"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                    <dl class="col">
                      <dt>NIT</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="fiscal">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="nit"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                  </div>

                  <div class="row">
                    <dl class="col">
                      <dt>Dirección</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="fiscal">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="direccion"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>

                    <dl class="col">
                      <dt>Facturación</dt>
                      <dd>
                        <form [formGroup]="editPartnerForm">
                          <div formGroupName="fiscal">
                            <input
                              type="text"
                              class="form-control"
                              formControlName="facturacion"
                            />
                          </div>
                        </form>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab id="tab-files">
            <ng-template ngbTabTitle>Archivos</ng-template>
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col-12">
                  <dl>
                    <dt>
                      Documento de Identificación
                      <i-feather
                        name="upload-cloud"
                        class="success ml-2"
                        style="cursor: pointer;"
                        (click)="openIdentifyDocumentSelection()"
                        *ngIf="editingPartner"
                      ></i-feather>
                    </dt>
                    <dd>
                      <img
                        [src]="identify_document_image"
                        onerror="this.src = 'assets/img/Profile_avatar_placeholder_large.png';"
                        style="height: 30vh;"
                        class="mt-2"
                      />

                      <input
                        id="identify_document_image_input"
                        type="file"
                        style="display: none;"
                        (change)="changeIdentifyDocument($event)"
                      />
                    </dd>
                  </dl>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>
<img alt="codigobarras" id="barcode" style="display: none;" />
<div class="modal-footer" *ngIf="partner_data">
  <div class="px-3">
    <button
      type="button"
      class="btn btn-raised btn-primary"
      *ngIf="editingPartner && currentTab == 'tab-beneficiary'"
      (click)="addPartnerBeneficiary()"
    >
      <i-feather name="plus-circle"></i-feather> Agregar Beneficiario
    </button>

    <button
      type="button"
      class="btn btn-raised btn-raised btn-primary btn-success"
      *ngIf="editingPartner"
      (click)="savePartnerChanges()"
    >
      <i-feather name="save"></i-feather> Guardar Cambios
    </button>

    <button
      type="button"
      class="btn btn-raised btn-raised btn-primary btn-danger"
      *ngIf="editingPartner"
      (click)="editingPartner = false"
    >
      <i-feather name="x"></i-feather> Cancelar Edición
    </button>

    <button
      type="button"
      class="btn btn-raised btn-raised btn-primary"
      *ngIf="!editingPartner"
      (click)="editPartner()"
    >
      <i-feather name="edit"></i-feather> Editar Socio
    </button>

    <button
      type="button"
      class="btn btn-raised btn-raised btn-primary"
      *ngIf="!editingPartner && partner_data.electronic.length > 0"
      (click)="sendWelcomeMessage()"
    >
      <i-feather name="navigation"></i-feather> Enviar Bienvenida
    </button>

    <button
      type="button"
      class="btn btn-raised btn-raised btn-primary"
      *ngIf="!editingPartner && canPrintCard"
      (click)="generateUserContract()"
    >
      <i-feather name="printer"></i-feather> Imprimir Ficha
    </button>
  </div>

  <!--
  <button type="button" class="btn btn-raised btn-raised btn-primary" *ngIf="!editingPartner && (partner_data.electronic.length > 0)"
    (click)="sendUserPassword()">
    <i-lock></i-lock> Enviar Contraseña
  </button>-->
</div>
