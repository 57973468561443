import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { httpOptions } from "app/shared/consts";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getStats(data: any) {
    return this.http.post("/getStats", data, httpOptions);
  }
  getUsersType(data: any) {
    return this.http.post("/getUsersType", data, httpOptions);
  }

  getUserList(data: any) {
    return this.http.post("/getUserList", data, httpOptions);
  }
  getIndependentPartner(data: any) {
    return this.http.post("/get-independent-partner", data, httpOptions);
  }
  getGuestUserData(id: any) {
    return this.http.post("/getGuestUserData", id, httpOptions);
  }
  sendInvitation(data: any) {
    return this.http.post("/sendInvitation", data, httpOptions);
  }
  createIndependentInvitation(data: any) {
    return this.http.post(
      "/create-independent-user-invitation",
      data,
      httpOptions
    );
  }

  getDataForContract(data: any) {
    return this.http.post("/getDataForContract", data, httpOptions);
  }
  /*sendPasswordToPartner(user: string, id: string, user_code: string, email: string) {
        return this.http.post('/sendPasswordToPartner', { user: user, id: id, code: user_code, email: email }, httpOptions);
    }*/
  sendWelcomeEmailToPartner(
    current_user: string,
    id_musicartes: string,
    access_user: string,
    name: string
  ) {
    return this.http.post(
      "/sendWelcomeEmail",
      {
        user: current_user,
        id_musicartes: id_musicartes,
        access_user: access_user,
        name: name,
      },
      httpOptions
    );
  }
  savePartnerUpdates(
    user: string,
    id_musicartes,
    identifier,
    partner_data: any
  ) {
    return this.http.post(
      "/savePartnerChanges",
      {
        user: user,
        id_musicartes: id_musicartes,
        identifier: identifier,
        partner_data: partner_data,
      },
      httpOptions
    );
  }

  saveSong(data: any) {
    return this.http.post("/saveSong", data, httpOptions);
  }
  saveWorkUpdates(data: any) {
    return this.http.post("/saveWorkUpdates", data, httpOptions);
  }
  getArtistsNameList(user: any) {
    return this.http.post("/getArtistsNameList", { user: user }, httpOptions);
  }
  getSongList(data: any) {
    return this.http.post("/getSongList", data, httpOptions);
  }
  getRecordCompanies(data: any) {
    return this.http.post("/getRecordCompanies", data, httpOptions);
  }

  saveRecordCompanies(data: any) {
    return this.http.post("/saveRecordCompany", data, httpOptions);
  }

  getPublishers(data: any) {
    return this.http.post("/getPublishers", data, httpOptions);
  }

  savePublisher(data: any) {
    return this.http.post("/savePublisher", data, httpOptions);
  }
  updatePublisher(data: any) {
    return this.http.post("/updatePublisher", data, httpOptions);
  }
  getPublisherAgreement(data: any) {
    return this.http.post("/getPublisherAgreement", data, httpOptions);
  }
  savePublisherAgreement(data: any) {
    return this.http.post("/savePublisherAgreement", data, httpOptions);
  }
  deletePublisherAgreement(data: any) {
    return this.http.post("/deletePublisherAgreement", data, httpOptions);
  }

  saveBand(data: any) {
    return this.http.post("/saveBand", data, httpOptions);
  }
  getBandsList(data: any) {
    return this.http.post("/getBandsList", data, httpOptions);
  }

  getArtistDiscographies(data: any) {
    return this.http.post("/getArtistDiscographies", data, httpOptions);
  }
  saveArtistDiscographies(data: any) {
    return this.http.post("/saveArtistDiscographies", data, httpOptions);
  }
  getRoles(data: any) {
    return this.http.post("/getRoles", data, httpOptions);
  }
  getInstruments(data: any) {
    return this.http.post("/getInstruments", data, httpOptions);
  }
  getSocieties(data: any) {
    return this.http.post("/getSocieties", data, httpOptions);
  }

  getWorkAgreement(user: string, workID: string) {
    return this.http.post(
      "/getWorkAgreement",
      { user: user, id: workID },
      httpOptions
    );
  }

  getMonitoringChannels(codigo: string, user: string) {
    return this.http.post(
      "/getMonitoringChannels",
      { id: codigo, user: user },
      httpOptions
    );
  }

  registerMonitoringChannel(data: any) {
    return this.http.post("/registerMonitoringChannel", data, httpOptions);
  }

  updateMonitoringChannel(data: any) {
    return this.http.post("/updateMonitoringChannel", data, httpOptions);
  }
  getMonitoringRepertories() {
    return this.http.post("/getRepertory", null, httpOptions);
  }
  getMonitoringYears(data: any) {
    return this.http.post("/get-monitoring-years", data, httpOptions);
  }
  saveMonitoring(data: any) {
    return this.http.post("/saveMonitoring", data, httpOptions);
  }
  getMonitoring(data: any) {
    return this.http.post("/getMonitoring", data, httpOptions);
  }
  updateMonitoringItem(data: any) {
    return this.http.post("/updateMonitoringItem", data, httpOptions);
  }
  deleteMonitoringItem(data: any) {
    return this.http.post("deleteMonitoringItem", data, httpOptions);
  }
  getMonitoringData(data: any) {
    return this.http.post("getMonitoringData", data, httpOptions);
  }

  createAdminUser(user: any) {
    return this.http.post("/createAdminUser", user, httpOptions);
  }
  getAdminUsers(user: any) {
    return this.http.post("/getAdminUsers", user, httpOptions);
  }

  /*DISTRIBUTION */
  getDistributionWallets(data: any) {
    return this.http.post("/getDistributionWallets", data, httpOptions);
  }
  getDistributions(data: any) {
    return this.http.post("/getDistributions", data, httpOptions);
  }
  getDistributionsByPartner(data: any) {
    return this.http.post("/getDistributionsByPartner", data, httpOptions);
  }

  calculateDistribution(data: any) {
    return this.http.post("/calculateDistribution", data, httpOptions);
  }
  saveDistribution(data: any) {
    return this.http.post("/saveDistribution", data, httpOptions);
  }
  integrateDistribution(data: any) {
    return this.http.post("/integrateDistribution", data, httpOptions);
  }
  listDistributions(data: any) {
    return this.http.post("/listDistribution", data, httpOptions);
  }
  listDistributionByArtist(data: any) {
    return this.http.post("/listDistributionByArtist", data, httpOptions);
  }
  listDistributionByParticipants(data: any) {
    return this.http.post("/listDistributionByParticipants", data, httpOptions);
  }
  getDistributionItemParticipants(data: any) {
    return this.http.post(
      "/getDistributionItemParticipants",
      data,
      httpOptions
    );
  }
  generateDistributionByParticipantsFile(data: any) {
    return this.http.post(
      "/generate_distribution_by_participants_file",
      data,
      httpOptions
    );
  }
  listDistributionByChannels(data: any) {
    return this.http.post("/listDistributionByChannels", data, httpOptions);
  }

  getDistributionsByParticipant(data: any) {
    return this.http.post("/getDistributionsByParticipant", data, httpOptions);
  }

  markParticipantDistributionPaid(data: any) {
    return this.http.post(
      "/markParticipantDistributionPaid",
      data,
      httpOptions
    );
  }

  /* REPORTS */
  reportGetUtils(data) {
    return this.http.post("/reportGetUtils", data, httpOptions);
  }

  reportDistributionsByYear(data: any) {
    return this.http.post("/reportDistributionsByYear", data, httpOptions);
  }
  reportDistributionsByChannel(data: any) {
    return this.http.post("/reportDistributionsByChannel", data, httpOptions);
  }
  reportPaidDistributionsByPartner(data: any) {
    return this.http.post(
      "/reportPaidDistributionsByPartner",
      data,
      httpOptions
    );
  }
  reportPartnersByGender(data: any) {
    return this.http.post("/reportPartnersByGender", data, httpOptions);
  }
  reportPartnersEnrolled(data: any) {
    return this.http.post("/reportPartnersEnrolled", data, httpOptions);
  }
  reportRegisteredWorks(data: any) {
    return this.http.post("/reportRegisteredWorks", data, httpOptions);
  }
  reportWorksByPartner(data: any) {
    return this.http.post("/reportWorksByPartner", data, httpOptions);
  }
  reportPartnersByInscriptionDate(data: any) {
    return this.http.post(
      "/reportPartnersByInscriptionDate",
      data,
      httpOptions
    );
  }

  // MUSIGESTOR
  getIntigratePerformer(data: any) {
    return this.http.post("/getIntigratePerformer", data, httpOptions);
  }
  getWalletDistributionDetail(data: any) {
    return this.http.post("/getWalletDistributionDetail", data, httpOptions);
  }

  generateXLSX(data: any) {
    return this.http.post("/generateXLSX", data, httpOptions);
  }
}
