import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'app/services/admin.service';
import { PersonService } from 'app/services/person.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAdminUserComponent } from 'app/create-admin-user/create-admin-user.component';
import { NGXToastrService } from 'app/services/toastr.service';

import * as alertFunctions from 'app/shared/data/sweet-alerts';
import { RouteDataService } from 'app/services/route-data.service';

@Component({
    selector: 'app-view-admin-users',
    templateUrl: './view-admin-users.component.html',
    styleUrls: ['./view-admin-users.component.scss']
})
export class ViewAdminUsersComponent implements OnInit {

    current_user: any;

    adm_users_list = [];
    temp = [];
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private route_data_service: RouteDataService,
        private admin_service: AdminService,
        private ngbModalService: NgbModal,
        private toastr: NGXToastrService) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.getAdminUsers();
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.temp.filter(function (d) {
            return d.id.toLowerCase().indexOf(val) !== -1 || d.nombre.toLowerCase().indexOf(val) !== -1 || d.correo.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.adm_users_list = temp;
        this.table.offset = 0;
    }

    getAdminUsers() {
        this.admin_service.getAdminUsers({ user: this.current_user.usuario, token: this.current_user.token, action: 'new' }).subscribe((data: any) => {
            console.log(data);
            let userList = data.resultado.body;
            this.temp = [...userList];
            this.adm_users_list = userList;
        });
    }
    openCreateAdminUser() {
        const modal = this.ngbModalService.open(CreateAdminUserComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            centered: true,
            windowClass: 'hugeModal'
        });
        (<CreateAdminUserComponent>modal.componentInstance).user = this.current_user.usuario;
        modal.result.then((result) => {
            console.log("Resultado:", result);
            if (result === "Ok") {
                this.getAdminUsers();
                this.toastr.defaultSuccess();
            }
        }, (reason) => {
            console.log(reason);
        });
    }
    sendUserAdmPassword(user_code, user_email) {
        alertFunctions.sendPasswordToPartner(() => {
            this.admin_service.sendWelcomeEmailToPartner(this.current_user.usuario, this.current_user.codigo, user_email, user_email).subscribe((data: any) => {
                console.log(data);
            });
        });
    }
    openEditAdmUser(adm_user) {
        console.log(adm_user);
    }

    editing = {};
    updateADMUser(event, cell, rowIndex) {
        console.log('inline editing rowIndex', rowIndex, cell)
        this.editing[rowIndex + '-' + cell] = false;
        this.adm_users_list[rowIndex][cell] = event.target.value;
        this.adm_users_list = [...this.adm_users_list];
        let channel_to_update = this.adm_users_list[rowIndex];
        console.log('UPDATED!', channel_to_update);


        console.log(this.adm_users_list);
    }
}
