<div class="row">
  <div class="col-12">
    <h4 class="content-header">Socios</h4>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="px-3 mb-3 mt-3">
          <ngb-tabset [activeId]="activeTab" (tabChange)="onTabChange($event)">
            <ngb-tab id="invited">
              <ng-template ngbTabTitle>Invitados</ng-template>
              <ng-template ngbTabContent>
                <app-view-invited-partners [current_user]="current_user" *ngIf="data_for_child"
                  [invited_partners]="data_for_child"></app-view-invited-partners>
              </ng-template>
            </ngb-tab>
            <ngb-tab id="enrolled">
              <ng-template ngbTabTitle>Inscritos</ng-template>
              <ng-template ngbTabContent>
                <app-view-partners-enrolled [current_user]="current_user" *ngIf="data_for_child"
                  [enrolled_partners]="data_for_child"></app-view-partners-enrolled>
              </ng-template>
            </ngb-tab>
            <ngb-tab id="independent">
              <ng-template ngbTabTitle>Declarados</ng-template>
              <ng-template ngbTabContent>
                <app-view-independent-partners [current_user]="current_user" *ngIf="data_for_child"
                  [main_list]="data_for_child">
                </app-view-independent-partners>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>