<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Editorial Musical</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="publisherForm">
    <div class="form-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Nombre</label>
            <input type="text" class="form-control" formControlName="name" />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary"
    (click)="saveRecordCompany()"
    *ngIf="publisherForm.valid && !updating"
  >
    Crear Disquera
  </button>
  <button
    type="button"
    class="btn btn-raised btn-raised btn-primary"
    (click)="updateModel()"
    *ngIf="publisherForm.valid && updating"
  >
    Guardar
  </button>
</div>
