import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';


@Injectable()
export class AWSService {
    FOLDER = '';
    bucket: S3;
    constructor() {

    }
    private getS3Bucket(): any {
        const bucket = new S3(
            {
                accessKeyId: 'AKIAIYT3BMXU3ADEFQFQ',
                secretAccessKey: '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',
                region: 'us-east-1'
            }
        );
        return bucket;
    }
    getGalerryImages(id_musicartes): Observable<Array<string>> {
        console.log("cargando imagenes de galeria de:" + id_musicartes);
        let Gallery = new Array<string>();
        const params = {
            Bucket: 'files-musicartes',
            Prefix: id_musicartes + '/Gallery'
        }
        this.getS3Bucket().listObjects(params, function (err, data) {
            if (err) {
                console.log("Hubo un problema obteniendo las imagenes de la galeria de: " + id_musicartes, err);
                return;
            }
            console.log('Successfully get files.', data);
            const fileDatas = data.Contents;
            fileDatas.forEach(function (file) {
                //fileUploads.push(new FileUpload(file.Key, 'https://s3.amazonaws.com/' + params.Bucket + '/' + file.Key));
                //Gallery.push('https://' + params.Bucket + '.s3.amazonaws.com/' + file.Key);
                Gallery.push('https://s3.us-east-2.amazonaws.com/' + params.Bucket + '/' + file.Key);
            });
            console.log(JSON.stringify(Gallery));
        });
        /* Gallery.push("https://s3.us-east-2.amazonaws.com/files-musicartes/MGTJKVGC34/Gallery/0.jpg");
         Gallery.push("https://s3.us-east-2.amazonaws.com/files-musicartes/MGTJKVGC34/Gallery/1.jpg");
         Gallery.push("");
         Gallery.push("");*/
        return of(Gallery);
    }
    uploadFile(file, folder_name, file_name): Observable<string> {
        let url: string;
        //JOnYkmI$ttt%
        console.log("Subiendo archivo.");
        const params = {
            Bucket: 'files-musicartes',
            Key: folder_name + "/" + file_name + '.' + file.name.split('.').pop(),
            Body: file,
            ACL: 'public-read'
        };

        return of(this.getS3Bucket().upload(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                url = null;
                return false;
            }

            console.log('Successfully uploaded file...', data);
            url = data.location;
            return url;
        }));

    }
    getSignedUrl(id_musicartes): any {
        console.log("Creando url para de galeria para:" + id_musicartes);
        this.getS3Bucket().getSignedUrl('putObject', {
            Bucket: 'files-musicartes',
            Expires: 60 * 60,
            Key: id_musicartes + "/",
            ACL: 'bucket-owner-full-control'

        }, function (err, url) {
            if (err) {
                console.log(err);
                return false;
            } else {
                console.log(url);
                return url;
            }
        })
    }

}