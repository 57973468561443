import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-view-monitoring-channels",
  templateUrl: "./view-monitoring-channels.component.html",
  styleUrls: ["./view-monitoring-channels.component.scss"]
})
export class ViewMonitoringChannelsComponent implements OnInit {
  current_user: any;

  main_list: any = [];
  main_list_filtered: any = [];

  constructor() {}

  ngOnInit() {
    this.loadMainList();
  }
  loadMainList() {
    // this.admin_service
    //   .getCatalogList({
    //     user: this.current_user.usuario,
    //     profile: this.current_user.perfil
    //   })
    //   .subscribe((data: any) => {
    //     if (
    //       data &&
    //       data.resultado &&
    //       data.resultado.header &&
    //       data.resultado.header.type === "success"
    //     ) {
    //       this.main_list = this.main_list_filtered =
    //         data.resultado.body.catalogo;
    //     } else {
    //       this.toastr.toast_error("No se ha recuperado información");
    //     }
    //   });
  }
  filterMainList(event: any) {
    const val = event.target.value.toLowerCase();

    const temp = this.main_list.filter(function(d: any) {
      return (
        d.codigo_producto.toLowerCase().indexOf(val) !== -1 ||
        d.marca_comercial.toLowerCase().indexOf(val) !== -1 ||
        d.principio_activo.toLowerCase().indexOf(val) !== -1 ||
        d.fabricante.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });

    this.main_list_filtered = temp;
  }
}
