<div *ngIf="personModel">
  <section id="user-profile">
    <div class="row">
      <div class="col-12">
        <div class="card profile-with-cover">
          <div class="card-img-top img-fluid bg-cover height-300 " [style.background]="'url(' + tempBannerPic + ')'"
            style="background-repeat: 50%">
            <input type="file" style="display: none" (change)=onBannerImageChanged($event) accept="image/*"
              #bannerImage>
            <!--<button class="btn btn-success" (click)="uploadBanner()">Upload</button>-->
          </div>
          <div class="media profil-cover-details row">
            <div class="col-12 col-lg-5">
              <div class="align-self-start halfway-fab pl-3">
                <div class="text-left">
                  <h1 class="card-title white" style="font-size: 25px;">
                    {{personModel.persona[0].primer_nombre+" "+personModel.persona[0].primer_apellido}}
                  </h1>
                </div>

              </div>
            </div>
            <div class="col-12 col-lg-2">
              <div class="align-self-center halfway-fab text-center">
                <a class="profile-image">

                  <img [src]="partner_picture" class="rounded-circle img-border gradient-summer width-150"
                    style="cursor: pointer;" alt="{{personModel.persona[0].id_musicartes}}"
                    onError="this.src='assets/musicartes/profile_avatar.jpg'">

                  <!--  <img [src]="tempProfilePic" class="rounded-circle img-border gradient-summer width-150"
                    style="cursor: pointer;" alt="{{personModel.persona[0].id_musicartes}}"
                    onError="this.src='assets/musicartes/profile_avatar.jpg'">-->
                  <!--(click)="profileImage.click()"-->
                  <input type="file" style="display: none" (change)=onProfileImageChanged($event) accept="image/*"
                    #profileImage>

                </a>
              </div>
            </div>

          </div>
          <div class="profile-section">
            <div class="row ">
              <div class="col-lg-5 col-6 text-center mb-2 profile-menu">

                <a (click)="showPage('Resumen')"
                  [ngClass]="currentPage === 'Resumen' ? 'primary font-medium-2 font-weight-600 active' : 'primary font-medium-2 font-weight-600'">Resumen</a>

              </div>
              <div class="col-lg-2 d-none d-lg-block text-center mb-2">
                <!-- <span
                  class="font-medium-2 text-uppercase">{{personModel.persona[0].primer_nombre+" "+personModel.persona[0].primer_apellido}}</span> -->
              </div>
              <div class="col-lg-5 col-6 text-center mb-2 profile-menu">
                <a (click)="showPage('Galeria')"
                  [ngClass]="currentPage === 'Galeria' ? 'primary font-medium-2 font-weight-600 active' : 'primary font-medium-2 font-weight-600'">Galería</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="Resumen" *ngIf="currentPage === 'Resumen'">
    <div class="row">
      <div class="col-12">
        <div class="content-header">Perfil</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card" *ngIf="!editPersonalData">
          <div class="card-header">
            Información Personal
            <div class="actions float-right">
              <i class="ft-edit success" style="cursor: pointer;"
                (click)="activeEditPersonalData();createFormEditPersonalDataForm();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;Nombre Completo</a>
                      </span>
                      <span class="display-block overflow-hidden"> {{personModel.persona[0].primer_nombre + " "+ personModel.persona[0].segundo_nombre+ " "+ personModel.persona[0].primer_apellido+
                        " "+ personModel.persona[0].segundo_apellido }}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-4 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;Documento de Identificación</a>
                      </span>
                      <span class="display-block overflow-hidden"> {{personModel.persona[0].identificacion }}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-4 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;ID Musicartes</a>
                      </span>
                      <span class="display-block overflow-hidden"> {{personModel.persona[0].id_musicartes }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 col-md-4 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-3">
                      <span class="text-bold-500 primary">Biografía</span>
                      <span class="display-block overflow-hidden">
                        {{personModel.persona[0].biografia}}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Fecha de Nacimiento</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].fecha_nacimiento}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-map-pin font-small-3"></i>&nbsp;País de Nacimiento</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].pais_nacimiento}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-briefcase font-small-3"></i>&nbsp;Tipo de Socio</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].tipo}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;Género</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].genero}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-book font-small-3"></i>&nbsp;Nacionalidad</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].nacionalidad}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-book font-small-3"></i>&nbsp;Fecha de Actualización</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].fecha_alta}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-briefcase font-small-3"></i>&nbsp;Estado Civil</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].estado_civil}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-globe font-small-3"></i>&nbsp;Residencia</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.persona[0].direccion}}</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="editPersonalData && formEditPersonalData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar tus datos personales,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditPersonalData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditPersonalData">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="proposalTitle2">Nombre Artístico</label>
                      <input type="text" class="form-control" formControlName="alias">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label for="shortDescription2">Biografía</label>
                      <textarea name="shortDescription" id="shortDescription2" rows="8" class="form-control"
                        formControlName="bio"></textarea>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="firstName2">Nacionalidad</label>
                      <input type="text" class="form-control" name="nationality" formControlName="nationality" required>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="firstName2">Dirección de Residencia</label>
                      <input type="address" class="form-control" name="address" formControlName="address" required>
                      <!-- <small class="form-text text-muted danger" *ngIf="!personalDataForm.get('address').valid && (personalDataForm.get('address').dirty|| personalDataForm.get('address').touched)">¡Este campo es requerido!</small>-->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="radios">Estado Civil</label>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioCivilStatus1" name="civil_status" class="custom-control-input"
                          value="soltero" formControlName="civil_status">
                        <label class="custom-control-label" for="radioCivilStatus1">Soltero</label>
                      </div>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioCivilStatus2" name="civil_status" class="custom-control-input"
                          value="casado" formControlName="civil_status">
                        <label class="custom-control-label" for="radioCivilStatus2">Casado</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3"
                    *ngIf="formEditPersonalData.get('civil_status').value === 'casado' &&  personModel.persona[0].genero === 'femenino'">
                    <div class="form-group">
                      <label for="firstName2">Apellido de casada</label>
                      <input type="text" class="form-control" formControlName="married_name">
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updatePersonalData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="personModel.electronic.length>=1 && personModel.voz.length>=1">
      <div class="col-12">
        <div class="card" *ngIf="!editContactData">
          <div class="card-header">
            Información de Contacto
            <div class="actions float-right">
              <i class="ft-edit success" style="cursor: pointer;"
                (click)="activeEditContactData();createFormEditContactDataForm();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;Correo Electrónico</a>
                      </span>
                      <span
                        class="display-block overflow-hidden">{{personModel.electronic[0].correo_electronico}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-book font-small-3"></i>&nbsp;Número de Teléfono</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.voz[0].telefono}}</span>
                    </li>

                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4"
                  *ngIf="personModel.electronic.length>1 && personModel.voz.length >1">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-user font-small-3"></i>&nbsp;Correo Electrónico Alterno</a>
                      </span>
                      <span
                        class="display-block overflow-hidden">{{personModel.electronic[1].correo_electronico}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-book font-small-3"></i>&nbsp;Número de Teléfono Alterno</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.voz[1].telefono}}</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="editContactData && formEditContactData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar tus datos bancarios,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditContactData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditContactData">
                <div class="row">
                  <div class="col-md-6" formArrayName="electronic"
                    *ngFor="let electronic_contact of formEditContactData.get('electronic').controls; let i = index;">
                    <div [formGroupName]="i">
                      <div class="form-group ">
                        <label for="email ">Correo Electrónico</label>
                        <input type="email" class="form-control " name="email" formControlName="email">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" formArrayName="voice"
                    *ngFor="let voice_contact of formEditContactData.get('voice').controls; let i = index;">
                    <div [formGroupName]="i">
                      <div class="form-group ">
                        <label for="phone">Teléfono Celular</label>
                        <input type="number" class="form-control " name="phone" formControlName="telephony">
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updateContactData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="personModel.social.length>= 1">
      <div class="col-12">
        <div class="card" *ngIf="!editSocialData">
          <div class="card-header">
            Redes Sociales
            <i class="ft-edit success float-right" style="cursor: pointer;"
              (click)="activeEditSocialData();createFormEditSocialDataForm();"></i>
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-3" *ngFor="let social of personModel.social; let i =index;">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          {{social.producto}}</a>
                      </span>
                      <span>
                        <a href="{{social.url}}" target="_blank"
                          class="display-block overflow-hidden">{{social.usuario_red}}</a>
                      </span>

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="editSocialData && formEditSocialData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar tus redes sociales,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditSocialData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditSocialData">
                <div class="row">
                  <div class="col-md-6" formArrayName="social"
                    *ngFor="let social_network of formEditSocialData.get('social').controls; let i = index;">
                    <div [formGroupName]="i">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">https://{{social_network.value.producto}}.com/</span>
                        </div>
                        <input type="text" class="form-control" formControlName="usuario_red"
                          (keyup)="social_network.value.url = 'https://www.'+social_network.value.producto+'.com/'+social_network.value.usuario_red">
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updateSocialData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="personModel.banco.length >=1 ">
      <div class="col-12">
        <div class="card" *ngIf="!editBankData">
          <div class="card-header">
            Información Bancaria
            <!-- <i class="ft-edit success float-right" style="cursor: pointer;"
              (click)="activeEditBankData();createFormEditBankDataForm();"></i> -->
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Nombre de Cuentahabiente:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.banco[0].nombre_completo}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-map-pin font-small-3"></i>&nbsp;Entidad Bancaria:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.banco[0].banco}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Tipo de Cuenta:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.banco[0].producto}}</span>
                    </li>
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="ft-map-pin font-small-3"></i>&nbsp;Tipo de Moneda:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.banco[0].moneda}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Número de Cuenta:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.banco[0].cuenta}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="editBankData && formEditBankData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar tus datos bancarios,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditBankData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditBankData">
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="eventName2 ">Entidad Bancaria</label>
                      <input type="text " class="form-control " name="eventName2" formControlName="bank">
                    </div>
                    <div class="form-group ">
                      <label for="eventType2 ">Tipo de Cuenta</label>
                      <select class="custom-select form-control " data-placeholder=" " formControlName="product">
                        <option value="monetaria ">Monetaria</option>
                        <option value="ahorro ">Ahorro</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="eventName2 ">Numero de Cuenta</label>
                      <input type="number" class="form-control " formControlName="account">
                    </div>
                    <div class="form-group ">
                      <label for="eventType2 ">Tipo de Moneda</label>
                      <select class="custom-select form-control " data-placeholder=" " formControlName="coin">
                        <option value="Quetzales">Quetzales</option>
                        <option value="Dólares">Dólares</option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updateBankData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="personModel.fiscal.length >= 1 ">
      <div class="col-12">
        <div class="card" *ngIf="!editTaxData">
          <div class="card-header">
            Datos Tributarios
            <!--<i class="ft-edit success float-right" style="cursor: pointer;"
              (click)="activeEditTaxData();createFormEditTaxDataForm();"></i>-->
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Nombre Fiscal:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.fiscal[0].nombre_completo}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Dirección Fiscal</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.fiscal[0].direccion}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <ul class="no-list-style">
                    <li class="mb-2">
                      <span class="text-bold-500 primary">
                        <a>
                          <i class="icon-present font-small-3"></i>&nbsp;Tipo de Facturación:</a>
                      </span>
                      <span class="display-block overflow-hidden">{{personModel.fiscal[0].facturacion}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="editTaxData && formEditTaxData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar tus datos tributarios,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditTaxData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditTaxData">
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="participants2 ">Dirección Fiscal</label>
                      <input type="text" class="form-control" name="address" formControlName="address">
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="participants2 ">Registro Tributario Unificado (RTU)</label>
                      <input type="file" class="form-control-file" accept=".pdf">
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updateTaxData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="personModel.beneficiary.length >= 1">
      <div class="col-12">
        <div class="card" *ngIf="!editBeneficiaryData">
          <div class="card-header">
            Beneficiarios
            <i class="ft-edit success float-right" style="cursor: pointer;"
              (click)="activeEditBeneficiaryData();createEditBeneficiaryDataForm();"></i>
          </div>
          <div class="card-body">
            <div class="card-block">
              <div *ngFor="let beneficiary of personModel.beneficiary; let i =index;">
                <div class="mb-3">
                  <span class="text-bold-500 primary">Nombre:</span>
                  <span class="display-block overflow-hidden">
                    {{beneficiary.primer_nombre+" "+beneficiary.segundo_nombre+" "+beneficiary.primer_apellido+" "+beneficiary.segundo_apellido}}
                  </span>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <ul class="no-list-style">
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="icon-present font-small-3"></i>&nbsp;Identificación:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.identificacion}}</span>
                      </li>
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="ft-map-pin font-small-3"></i>&nbsp;Nacionalidad:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.nacionalidad}}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <ul class="no-list-style">
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="icon-present font-small-3"></i>Género:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.genero}}</span>
                      </li>
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="ft-map-pin font-small-3"></i>&nbsp;Fecha de Nacimiento:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.nacimiento}}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <ul class="no-list-style">
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="icon-present font-small-3"></i>&nbsp;Parentesco:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.parentesco}}</span>
                      </li>
                      <li class="mb-2">
                        <span class="text-bold-500 primary">
                          <a>
                            <i class="ft-map-pin font-small-3"></i>&nbsp;Porcentaje:</a>
                        </span>
                        <span class="display-block overflow-hidden">{{beneficiary.porcentaje}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="editBeneficiaryData && formEditBeneficiaryData">
          <div class="card-header">
            Edición
            <i class="ft-alert-circle warning ml-1"
              ngbPopover="Aqui puedes editar a tus beneficiarios,ten encuenta que no todos pueden ser editados."
              triggers="mouseenter:mouseleave" popoverTitle="Edición"></i>
            <div class="actions float-right">
              <i class="ft-x warning" style="cursor: pointer;" (click)="activeEditBeneficiaryData();"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <form [formGroup]="formEditBeneficiaryData">
                <ngb-tabset (tabChange)="beneficiaryChange($event)">
                  <ngb-tab title="Beneficiario #{{i}}" formArrayName="beneficiary"
                    *ngFor="let beneficiary of formEditBeneficiaryData.get('beneficiary').controls; let i = index;">
                    <ng-template ngbTabContent>

                      <div [formGroupName]="i" *ngIf="beneficiary.value.flag == 'u'">

                        <div class="row justify-content-end">
                          <button class="btn btn-raised btn-danger" type="button" (click)="removeBeneficiary(i);">

                            <i-feather name="x"></i-feather>
                            Remover
                          </button>
                        </div>

                        <div class="row">
                          <div class="col-12 col-md-3 col-lg-3">
                            <div class="form-group ">
                              <label for="eventType2 ">Parentesco</label>
                              <select class="custom-select form-control" data-placeholder=""
                                formControlName="parentesco">
                                <option value="padre/madre">Padre/Madre</option>
                                <option value="hijo/a">Hijo(a)</option>
                                <option value="conyuge">Cónyuge</option>
                                <option value="abuelo/a">Abuelo(a)</option>
                                <option value="nieto/a">Nieto(a)</option>
                                <option value="hermano/a">Hermano(a)</option>
                                <option value="otro">Otro</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Porcentaje</label>
                              <input type="number" class="form-control" formControlName="porcentaje">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Primer Nombre</label>
                              <input type="text " class="form-control" formControlName="primer_nombre">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Segundo Nombre</label>
                              <input type="text " class="form-control" formControlName="segundo_nombre">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Primer Apellido</label>
                              <input type="text " class="form-control" formControlName="primer_apellido">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Segundo Apellido</label>
                              <input type="text " class="form-control" formControlName="segundo_apellido">
                            </div>
                          </div>
                        </div>
                        <div class="row ">
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for="date2 ">Fecha de Nacimiento</label>
                              <input type="date" class="form-control" formControlName="nacimiento">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for="radios ">Género</label>
                              <div class="custom-control custom-radio display-block ">
                                <input type="radio" id="radioGenderBeneficiary1" class="custom-control-input"
                                  value="masculino" formControlName="genero"
                                  (checked)="beneficiary.genero === 'masculino'">
                                <label class="custom-control-label" for="radioGenderBeneficiary1">Masculino</label>
                              </div>
                              <div class="custom-control custom-radio display-block ">
                                <input type="radio" id="radioGenderBeneficiary2" class="custom-control-input"
                                  value="femenino" formControlName="genero"
                                  (checked)="beneficiary.genero === 'femenino'">
                                <label class="custom-control-label" for="radioGenderBeneficiary2">Femenino</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label>Nacionalidad</label>
                              <input type="text" class="form-control" formControlName="nacionalidad">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">CUI/DPI/PASAPORTE</label>
                              <input type="text" class="form-control" formControlName="identificador">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="beneficiary.value.flag == 'd'">
                        <h5 class="text-center text-muted">Se ha removido este beneficiario, desaparecerá al guadar.
                        </h5>
                      </div>
                    </ng-template>
                  </ngb-tab>

                  <ngb-tab id="bar" title="+">
                    <ng-template ngTabTitle>
                      <i-feather name="plus-circle"></i-feather>
                    </ng-template>
                    <ng-template ngbTabContent>
                    </ng-template>
                  </ngb-tab>

                </ngb-tabset>
                <hr>
                <div class="row justify-content-center">
                  <button class="btn btn-raised btn-primary" type="button" (click)="updateBeneficiaryData();">
                    <i class="fa fa-check-square-o"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <section id='Galeria' *ngIf="currentPage === 'Galeria'">
    <div class="row">
      <div class="col-12">
        <div class="content-header">Galería</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5> Imagenes subidas</h5>
            <!--{{Gallery | async | json}}-->

          </div>
          <div class="card-body">
            <div class="card-block">
      

              <div class="row justify-content-center" *ngIf="Gallery | async">

                <figure class="col-lg-3 col-md-6 col-12 text-center">
                  <img [src]="(Gallery|async)[0]" class="img-thumbnail img-fluid" style="z-index: -1;"
                    (click)="uploadImage0.click()" *ngIf="(Gallery|async)[0]">
                  <i class="ft-upload" style="font-size: 90px !important; position: relative; z-index: 1;"
                    (click)="uploadImage0.click()" *ngIf="!(Gallery|async)[0]"></i>
                  <input type="file" style="display: none" (change)="onUploadImageChanged($event, 0)"
                    accept="image/jpeg" #uploadImage0>
                </figure>
                <figure class="col-lg-3 col-md-6 col-12 text-center">
                  <img [src]="(Gallery|async)[1]" class="img-thumbnail img-fluid" style="z-index: -1;"
                    (click)="uploadImage1.click()" *ngIf="(Gallery|async)[1]">
                  <i class="ft-upload" style="font-size: 90px !important; position: relative; z-index: 1;"
                    (click)="uploadImage1.click()" *ngIf="!(Gallery|async)[1]"></i>
                  <input type="file" style="display: none" (change)="onUploadImageChanged($event, 1)"
                    accept="image/jpeg" #uploadImage1>
                </figure>
                <figure class="col-lg-3 col-md-6 col-12 text-center">
                  <img [src]="(Gallery|async)[2]" class="img-thumbnail img-fluid" style="z-index: -1;"
                    (click)="uploadImage2.click()" *ngIf="(Gallery|async)[2]">
                  <i class="ft-upload" style="font-size: 90px !important; position: relative; z-index: 1;"
                    (click)="uploadImage2.click()" *ngIf="!(Gallery|async)[2]"></i>
                  <input type="file" style="display: none" (change)="onUploadImageChanged($event, 2)"
                    accept="image/jpeg" #uploadImage2>
                </figure>
                <figure class="col-lg-3 col-md-6 col-12 text-center">
                  <img [src]="(Gallery|async)[3]" class="img-thumbnail img-fluid" style="z-index: -1;"
                    (click)="uploadImage3.click()" *ngIf="(Gallery|async)[3]">
                  <i class="ft-upload" style="font-size: 90px !important; position: relative; z-index: 1;"
                    (click)="uploadImage3.click()" *ngIf="!(Gallery|async)[3]"></i>
                  <input type="file" style="display: none" (change)="onUploadImageChanged($event, 3)"
                    accept="image/jpeg" #uploadImage3>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>