import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { FileUploader } from "ng2-file-upload";

import { PersonService } from "app/services/person.service";
import { AdminService } from "app/services/admin.service";
import { CreateMonitoringChannelComponent } from "app/create-monitoring-channel/create-monitoring-channel.component";
import { NGXToastrService } from "app/services/toastr.service";
import { PublicService } from "app/services/public.service";
import { Toast } from "ngx-toastr";

import * as moment from "moment";
import { group } from "@angular/animations";
import { RouteDataService } from "app/services/route-data.service";
import { pad } from "app/shared/consts";
const URL = "http://localhost:6001/read-excel";

class Monitoring {
  user: String;
  code_channel: String;
  applicate_year: String;
  distribution: string;
  wallet: string;
  info: any;
  token: string;

  public constructor(init?: Partial<Monitoring>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: "app-view-monitoring",
  templateUrl: "./view-monitoring.component.html",
  styleUrls: ["./view-monitoring.component.scss"],
})
export class ViewMonitoringComponent implements OnInit {
  today = moment();

  current_user: any;

  formMonitoring: FormGroup;

  server_name: any;
  public uploader: FileUploader = new FileUploader({
    url: "",
    itemAlias: "file",
  });
  can_send = false;

  editing = {};
  monitoringChannels = [];
  monitoringRepertories = [];

  distributions = [];
  distributionsWallets = [];
  filteredDistributionsWallets = [];

  monitoringSaved = [];
  registerMonitoringPlayForm: FormGroup;
  registerMonitoringChannelForm: FormGroup;

  currentYear = moment().year();

  //@ViewChild(DatatableComponent,{static: false}) table: DatatableComponent;
  @ViewChild("monitoring_uploader") uploaderElement: ElementRef;

  saving = false;

  constructor(
    private route_data_service: RouteDataService,
    private formBuilder: FormBuilder,
    private ngbModalService: NgbModal,
    private person_service: PersonService,
    private admin_service: AdminService,
    private public_service: PublicService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;

    this.public_service.getSeverName().subscribe((data: any) => {
      this.server_name = data.SERVER_NAME;
      this.uploader.options.url = data.SERVER_NAME + "/read-excel";
    });

    this.admin_service
      .getMonitoringChannels(
        this.current_user.codigo,
        this.current_user.usuario
      )
      .subscribe((data: any) => {
        // console.log(data);
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type === "success"
        ) {
          this.monitoringChannels = data.resultado.body.canales;
        } else {
          this.toastr.typeErrorText("No se encontraron canales de monitoreo.");
        }
      });

    this.admin_service
      .getDistributions({
        user: this.current_user.usuario,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type === "success"
        ) {
          this.distributions = data.resultado.body;
        } else {
          this.toastr.typeErrorText("No se encontraron distribuciones.");
        }
      });

    this.admin_service
      .getDistributionWallets({
        user: this.current_user.usuario,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type === "success"
        ) {
          this.distributionsWallets = data.resultado.body;
        } else {
          this.toastr.typeErrorText("No se encontraron carteras.");
        }
      });

    this.formMonitoring = this.formBuilder.group({
      data: this.formBuilder.group({
        user: this.current_user.usuario,
        code_channel: new FormControl("", [Validators.required]),
        // date_start: new FormControl({
        //   year: this.today.year(),
        //   month: this.today.month() + 1,
        //   day: this.today.date()
        // }),
        // date_end: new FormControl({
        //   year: this.today.year(),
        //   month: this.today.month() + 1,
        //   day: this.today.date()
        // }),
        applicate_year: new FormControl(""),

        distribution: new FormControl("", [Validators.required]),
        wallet: new FormControl("", [Validators.required]),

        info: new FormArray([], [Validators.required]),
      }),
    });

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log("ImageUpload:uploaded:", item, status, response);

      try {
        this.clearFormArray(
          this.formMonitoring.get("data")["controls"].info as FormArray
        );
        console.log('ver monitoreo', this.monitoringChannels)
        console.log('no se q es', this.formMonitoring)
        let foundedChannelData = this.monitoringChannels.filter(
          (item) =>
            item.correlativo?.toLowerCase() ===
            this.formMonitoring.value.data.code_channel
        )[0];

        JSON.parse(response).forEach((element, index) => {
          if (element.title && element.artist && element.hits) {
            let channelData = foundedChannelData
              ? foundedChannelData
              : this.monitoringChannels.filter(
                  (item) =>
                    item.abreviado?.toLowerCase() ===
                    element.channel?.toLowerCase()
                )[0];
            console.log('channelData', channelData)
            console.log('filtetr channelData', this.monitoringChannels.filter(
              (item) =>
                item.abreviado?.toLowerCase() ===
                element.channel?.toLowerCase()
            ))
            
            if (!channelData) {
              this.toastr.toast_error(
                "No se encontro un canal de monitoreo Válido: " +
                  JSON.stringify(element)
              );
              throw new Error("no_channel");
            }
            (
              this.formMonitoring.get("data")["controls"].info as FormArray
            ).push(
              this.formBuilder.group({
                key: index,
                title: element.title,
                isrc: element.isrc,
                artist: element.artist,
                hits: element.hits,
                publisher: element.publisher,
                group: element.group,
                original_channel: element.channel,
                channel: channelData.correlativo,
                channel_name: channelData.nombre,
              })
            );
          }
        });

        this.can_send = true;
        this.uploader.queue.pop();
        this.uploaderElement.nativeElement.value = "";
      } catch (e) {
        console.log(e.message);

        if (e.message === "no_channel") {
          this.clearFormArray(
            this.formMonitoring.get("data")["controls"].info as FormArray
          );
        }
      }
    };
    this.uploader.onSuccessItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log("success", item, status, response);
      this.uploaderElement.nativeElement.value = "";
    };
  }

  tasks = [];
  index = -1;
  steps = 0;

  saveMonitoring() {
    this.can_send = false;
    this.saving = true;

    this.tasks =[];
    if (this.formMonitoring.dirty && this.formMonitoring.valid) {
      let group_limit = 50;
      this.steps = Math.ceil(
        this.formMonitoring.value.data.info.length / group_limit
      );
      const token = this.generateRandomString(20);

      for (let index = 0; index < this.steps; index++) {
        let group = this.formMonitoring.value.data.info.slice(
          group_limit * index,
          group_limit * (index + 1)
        );
        let monitoring_to_save = new Monitoring({
          ...this.formMonitoring.value.data,
          token,
          info: group,
        });
        // console.log("GROUP ", index, group.length, JSON.stringify(group));

        //console.log(monitoring_to_save);
        this.tasks.push(monitoring_to_save);
      }
      //console.log(this.tasks);
      this.processNextTask();

      // if (last_response) {
      //   this.clearFormArray(
      //     this.formMonitoring.get("data")["controls"].info as FormArray
      //   );
      //   this.uploaderElement.nativeElement.value = "";
      // }
      // this.admin_service.saveMonitoring(data_to_save).subscribe((data: any) => {
      //   console.log(data);
      //   this.saving = false;
      //   if (data && data.response && data.response.db) {
      //     if (
      //       data.response.db &&
      //       data.response.db.typ &&
      //       data.response.db.typ == "success"
      //     ) {
      //       this.toastr.toast_sucess(data.response.db.msg);
      //       //this.formMonitoring.reset();

      //       this.clearFormArray(
      //         this.formMonitoring.get("data")["controls"].info as FormArray
      //       );
      //       this.uploaderElement.nativeElement.value = "";
      //     } else {
      //       this.toastr.toast_error(data.response.db.msg);
      //     }
      //   } else {
      //     this.toastr.typeError();
      //   }
      // });
    }
  }

  processNextTask() {
    this.index = this.index + 1;
    //console.log("doing task ", this.index, "of ", this.tasks.length);

    if (this.index == this.tasks.length) {
      // no more tasks
      this.tasks = [];
      this.index = -1;
      this.steps = 0;
      this.saving = false;
      this.clearFormArray(
        this.formMonitoring.get("data")["controls"].info as FormArray
      );
      this.uploaderElement.nativeElement.value = "";
      this.formMonitoring.reset();
      return;
    }
    this.admin_service
      .saveMonitoring({ data: this.tasks[this.index] })
      .subscribe((data: any) => {
        //  console.log(data);

        if (data && data.response && data.response.db) {
          if (
            data.response.db &&
            data.response.db.typ &&
            data.response.db.typ == "success"
          ) {
            this.toastr.toast_sucess(
              this.index +
                1 +
                "/" +
                this.tasks.length +
                " " +
                data.response.db.msg
            );
            this.processNextTask();
          } else {
            this.toastr.toast_error(data.response.db.msg);
            return;
          }
        } else {
          this.toastr.typeError();
          return;
        }
      });
  }

  generateRandomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result.toUpperCase();
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  openCreateMonitoringChannel() {
    const modal = this.ngbModalService.open(CreateMonitoringChannelComponent, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      centered: true,
    });
    (<CreateMonitoringChannelComponent>(
      modal.componentInstance
    )).current_session = this.current_user;
  }

  changeMonitoringChannel(event: any) {
    let value = event.target.value;

    if (value === "multiple") {
      for (let control of (
        this.formMonitoring.get("data")["controls"].info as FormArray
      ).controls) {
        let foundedChannelData = this.monitoringChannels.filter(
          (item) =>
            item.abreviado?.toLowerCase() ===
            control.value.original_channel?.toLowerCase()
        )[0];

        (control["controls"].channel as FormControl).setValue(
          foundedChannelData.correlativo
        );
        (control["controls"].channel_name as FormControl).setValue(
          foundedChannelData.nombre
        );
      }
    } else {
      let selectedChanelData = this.monitoringChannels.filter(
        (item) => item.correlativo?.toLowerCase() === value
      )[0];

      for (let control of (
        this.formMonitoring.get("data")["controls"].info as FormArray
      ).controls) {
        (control["controls"].channel as FormControl).setValue(
          selectedChanelData.correlativo
        );
        (control["controls"].channel_name as FormControl).setValue(
          selectedChanelData.nombre
        );
      }
    }
  }

  changeDistributionSelected(event: any) {
    console.log('evento', event.target.value);

    this.filteredDistributionsWallets = this.distributionsWallets.filter(
      (item) => item.cartera_distribucion === event.target.value.toString()
    );
    console.log('array', this.distributions)
    let year = this.distributions.filter(
      (item) => item.distribucion_correlativo === event.target.value.toString()
    )[0].distribucion_anio;

    this.formMonitoring.get("data")["controls"].applicate_year.setValue(year);
  }

  // cleanUploadedMonitoring() {
  //     (this.formMonitoring.get('data')['controls'].info as FormArray).setValue(null);
  // }
}
