<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Canal de Monitoreo</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <div class="row">
    <div class="col-4">
      <h5>Crear Canal de Monitoreo</h5>
      <form class="form" [formGroup]="registerMonitoringChannelForm">
        <div class="form-body">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Nombre</label>
                <input type="text" class="form-control" formControlName="name">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Descripción</label>

                <textarea class="form-control" rows="4" formControlName="description"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-block btn-success btn-raised" (click)="registerMonitoringChannel()">Crear
                Canal de Monitoreo</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-8">
      <ngx-datatable #mtc class="bootstrap" [headerHeight]="50" [limit]="5" [columnMode]="'force'" [footerHeight]="50"
        [rowHeight]="'auto'" [rows]="monitoringChannels">
        <ngx-datatable-column name="codigo"></ngx-datatable-column>
        <ngx-datatable-column prop="nombre" name="Nombre">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span title="Double click to edit" (dblclick)="editing[rowIndex + '-nombre'] = true" *ngIf="!editing[rowIndex + '-nombre']">
              {{value}}
            </span>
            <input autofocus (blur)="updateValue($event, 'nombre', rowIndex)" *ngIf="editing[rowIndex+ '-nombre']" type="text"
              [value]="value" />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="descripcion" name="Descripción">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span title="Double click to edit" (dblclick)="editing[rowIndex + '-descripcion'] = true" *ngIf="!editing[rowIndex + '-descripcion']">
              {{value}}
            </span>
            <input autofocus (blur)="updateValue($event, 'descripcion', rowIndex)" *ngIf="editing[rowIndex+ '-descripcion']"
              type="text" [value]="value" />
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>



  </div>

</div>
