<div ngbDropdown [placement]="placement">
  <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>

    <i-feather name="bell" class="font-medium-3 blue-grey darken-4"></i-feather>
    <span class="notification badge badge-pill badge-danger">{{notifications.length}}</span>
    <p class="d-none">Notifications</p>
  </a>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
    <div class="noti-list">
      <div class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4"
        *ngFor="let notification of notifications; let notification_index = index;"
        (click)="viewEvent(notification,notification_index)">
        <i-feather name="bell" class="info float-left d-block font-large-1 mt-1 mr-2"></i-feather>
        <span class="noti-wrapper">
          <span class="primary float-right">{{notification.date.day +"-"+ notification.date.month +"-"+
            notification.date.year}}</span>
          <span class="noti-title line-height-1 d-block text-bold-400 info">{{notification.subject}}</span>
          <span class="noti-text">{{notification.comment}}</span>
        </span>
      </div>
    </div>
    <a class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1"
      (click)="loadNotifications()">Actualizar</a>
  </div>
</div>