import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
  AbstractControl
} from "@angular/forms";
import {
  NgbActiveModal,
  NgbTabChangeEvent,
  NgbDatepickerConfig
} from "@ng-bootstrap/ng-bootstrap";
import { FormArray } from "@angular/forms";
import { FileUploader } from "ng2-file-upload";

import * as moment from "moment";

import * as alertFunctions from "app/shared/data/sweet-alerts";
import { PersonService } from "../services/person.service";
import { AWSService } from "../services/aws.services";
import { RouteDataService } from "app/services/route-data.service";

import { pad } from "app/shared/consts";

@Component({
  selector: "app-register-person-modal",
  templateUrl: "./register-person-modal.component.html",
  styleUrls: ["./register-person-modal.component.scss"]
})
export class RegisterPersonModalComponent implements OnInit {
  model;
  current_user: any;

  public id_musicartes: string;
  public user_email: string;
  hasNit = false;

  formRegister: FormGroup;
  personalDataForm: FormGroup;
  contactDataForm: FormGroup;
  bankingDataForm: FormGroup;
  legalDataForm: FormGroup;
  beneficiaries: any = [];
  Identify_Document: File;

  today = moment();

  public uploader: FileUploader;

  constructor(
    private route_data_service: RouteDataService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private personService: PersonService,
    private aws: AWSService,
    datepicker_config: NgbDatepickerConfig
  ) {
    datepicker_config.minDate = { year: 1900, month: 0, day: 0 };
    datepicker_config.maxDate = {
      year: this.today.year(),
      month: this.today.month() + 1,
      day: this.today.date()
    };
  }

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;

    if (this.id_musicartes) {
      $.getScript("assets/js/jquery-steps2.min.js");
      setTimeout(function() {
        $.getScript("assets/js/wizard-steps.js");
      }, 250);

      this.createRegisterForm();
      let that = this;

      // setTimeout(function() {
      //   that.buildPersonHolder();
      // }, 550);
    } else {
      this.activeModal.close("no id");
    }
  }
  createRegisterForm(): void {
    this.personalDataForm = this.formBuilder.group({
      id_musicartes: new FormControl(this.id_musicartes),
      identifier_type: new FormControl("", [
        Validators.required,
        Validators.minLength(5)
      ]),
      identifier: new FormControl("", [Validators.required]),
      first_name: new FormControl("", [
        Validators.required,
        Validators.minLength(2)
      ]),
      middle_name: new FormControl(""),
      last_name: new FormControl("", [Validators.required]),
      second_last_name: new FormControl(""),
      married_name: new FormControl(""),
      gender: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      extended: new FormControl(""),
      // birthday: new FormControl('', [Validators.required, Validators.pattern(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)]),
      birthday_model: new FormControl({
        year: this.today.year(),
        month: this.today.month() + 1,
        day: this.today.date()
      }),
      nationality: new FormControl("", [Validators.required]),
      birth_country: new FormControl("", [Validators.required]),
      alias: new FormControl(""),
      bio: new FormControl(""),
      civil_status: new FormControl("", [Validators.required]),
      regime: new FormControl("", [Validators.required]),
      registration_date: new FormControl({
        year: this.today.year(),
        month: this.today.month() + 1,
        day: this.today.date()
      })
    });

    this.bankingDataForm = this.formBuilder.group({
      full_name: new FormControl("", [Validators.required]),
      account: new FormControl("", [Validators.required]),
      bank: new FormControl("", [Validators.required]),
      type: new FormControl("", [Validators.required]),
      coin: new FormControl("", [Validators.required])
    });

    this.legalDataForm = this.formBuilder.group({
      nit: new FormControl("", [Validators.required]),
      full_name: new FormControl("", [Validators.required]),
      fiscal_address: new FormControl("", [Validators.required]),
      facturing: ["pequeño contribuyente"]
    });

    this.contactDataForm = this.formBuilder.group({
      electronic: this.formBuilder.array([
        this.formBuilder.group({
          type_contact: new FormControl("p"),
          type_email: new FormControl("p"),
          email: new FormControl("", [Validators.required, Validators.email])
        })
      ]),
      voice: this.formBuilder.array([
        this.formBuilder.group({
          type_contact: new FormControl("p"),
          type_phone: new FormControl("m"),
          code_area: new FormControl("000"),
          telephony: new FormControl("", [
            Validators.required,
            Validators.minLength(8)
          ])
        })
      ])
    });

    this.formRegister = this.formBuilder.group({
      person: this.personalDataForm,
      extra: {
        birth_place: null,
        extended: null
      },
      electronic: this.formBuilder.array([]),
      voice: this.formBuilder.array([]),
      social: this.formBuilder.array([
        this.createSocialContact("facebook"),
        this.createSocialContact("twitter"),
        this.createSocialContact("instagram"),
        this.createSocialContact("linkedin")
      ]),
      banking: this.bankingDataForm,
      tax: this.legalDataForm,
      beneficiary: this.formBuilder.array([this.createBeneficiary()])
    });
  }
  createSocialContact(product): FormGroup {
    return this.formBuilder.group({
      user: new FormControl(""),
      product: product,
      link: new FormControl("")
    });
  }

  createBeneficiary(): FormGroup {
    // console.log("creando beneficiario");
    return this.formBuilder.group({
      identifier: new FormControl("", [Validators.required]),
      first_name: new FormControl(),
      middle_name: new FormControl(),
      last_name: new FormControl("", [Validators.required]),
      second_last_name: new FormControl(),
      gender: new FormControl("", [Validators.required]),
      // birthday: new FormControl('', [Validators.required, Validators.pattern(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)]),
      birthday: new FormControl({
        year: this.today.year(),
        month: this.today.month() + 1,
        day: this.today.date()
      }),
      birth_place: new FormControl(),
      relationship: new FormControl("", [Validators.required]),
      percentage: new FormControl("", [Validators.required])
    });
  }
  addBeneficiary(): void {
    this.beneficiaries = this.formRegister.get("beneficiary") as FormArray;
    this.beneficiaries.push(this.createBeneficiary());
  }

  removeBeneficiary(index: number): void {
    // console.log("Eliminando beneficiario: " + index);
    this.beneficiaries = this.formRegister.get("beneficiary") as FormArray;
    this.beneficiaries.removeAt(index);
  }

  addVoiceForm(): void {
    (this.contactDataForm.get("voice") as FormArray).push(
      this.formBuilder.group({
        type_contact: new FormControl("p"),
        type_phone: new FormControl("m"),
        code_area: new FormControl("000"),
        telephony: new FormControl("", [Validators.minLength(8)])
      })
    );
  }

  addElectronicForm(): void {
    (this.contactDataForm.get("electronic") as FormArray).push(
      this.formBuilder.group({
        type_contact: new FormControl("p"),
        type_email: new FormControl("p"),
        email: new FormControl("", [Validators.email])
      })
    );
  }

  submitRegisterForm(): void {
    if (
      this.id_musicartes &&
      this.id_musicartes != "" &&
      this.id_musicartes != undefined &&
      this.id_musicartes != "undefined"
    ) {
      let person_to_save: any = {
        person: {}
      };

      if (this.personalDataForm.dirty && this.personalDataForm.valid) {
        Object.assign(person_to_save.person, this.personalDataForm.value);
        //person_to_save.person = this.personalDataForm.value;

        person_to_save.person.birthday =
          person_to_save.person.birthday_model.year +
          "-" +
          pad(person_to_save.person.birthday_model.month) +
          "-" +
          pad(person_to_save.person.birthday_model.day);

        person_to_save.person.registration_date =
          person_to_save.person.registration_date.year +
          "-" +
          pad(person_to_save.person.registration_date.month) +
          "-" +
          pad(person_to_save.person.registration_date.day);

        person_to_save.extra = {
          full_name:
            person_to_save.person.first_name +
            " " +
            (person_to_save.person.middle_name
              ? person_to_save.person.middle_name
              : "") +
            " " +
            person_to_save.person.last_name +
            " " +
            (person_to_save.person.second_last_name
              ? person_to_save.person.second_last_name
              : ""),
          birth_place: person_to_save.person.birth_country,
          extended: this.formRegister.value.extra.extended = this.personalDataForm.value.extended,
          type_doc: person_to_save.person.identifier_type
        };
      } else {
        console.log("Datos de persona no validos o vacios");
        alertFunctions.customAlert(
          "¡Error!",
          null,
          "error",
          true,
          true,
          null,
          "Ha ocurrido un error guardando tu información. Comprueba tus <b>Datos Personales</b>."
        );
        Object.keys(this.personalDataForm.controls).forEach(field => {
          const control = this.personalDataForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        return;
      }

      if (this.formRegister.controls.social.dirty) {
        let social = [];
        this.formRegister.value.social.forEach(element => {
          if (element.user != "") {
            social.push(element);
          }
        });
        person_to_save.social = social;
      } else {
        alertFunctions.customAlert(
          "¡Error!",
          null,
          "error",
          true,
          true,
          null,
          "Ha ocurrido un error guardando tu información. Necesitamos al menos <b>Una red social</b>."
        );
        return;
      }

      if (this.hasNit && this.legalDataForm.dirty) {
        if (this.legalDataForm.valid) {
          person_to_save.tax = this.legalDataForm.value;
        } else {
          console.log("Datos fiscales no validos o vacios");
          alertFunctions.customAlert(
            "¡Error!",
            null,
            "error",
            true,
            true,
            null,
            "Ha ocurrido un error guardando tu información. Comprueba tus <b>Datos Tributarios</b>."
          );
          return;
        }
      }

      if (this.formRegister.get("banking").dirty) {
        if (this.formRegister.get("banking").valid) {
          person_to_save.banking = this.bankingDataForm.value;
        } else {
          alertFunctions.customAlert(
            "¡Error!",
            null,
            "error",
            true,
            true,
            null,
            "Ha ocurrido un error guardando tu información.<b> Los datos bancarios no son validos. </b>"
          );
          return;
        }
      }

      if (this.formRegister.get("beneficiary").dirty) {
        if (this.formRegister.get("beneficiary").valid) {
          console.log("entro beneficiary");
          let beneficiaries_tmp = [];

          this.formRegister
            .get("beneficiary")
            .value.forEach((element, index) => {
              let beneficiary_form = (this.formRegister.get(
                "beneficiary"
              ) as FormArray).at(index);

              let beneficiary: any = {};

              Object.assign(beneficiary, beneficiary_form.value);

              beneficiary.birthday =
                beneficiary.birthday.year +
                "-" +
                pad(beneficiary.birthday.month) +
                "-" +
                pad(beneficiary.birthday.day);

              beneficiaries_tmp.push(beneficiary);
            });
          person_to_save.beneficiary = beneficiaries_tmp;
        } else {
          alertFunctions.customAlert(
            "¡Error!",
            null,
            "error",
            true,
            true,
            null,
            "Ha ocurrido un error guardando tu información.<b> Uno o más benefciarios tienen error. </b>"
          );
          return;
        }
      }

      if (this.contactDataForm.controls["voice"].dirty) {
        if (this.contactDataForm.controls["voice"].valid) {
          let voice = [];
          this.contactDataForm.value.voice.forEach(element => {
            if (element.telephony != "") {
              voice.push(element);
            }
          });

          person_to_save.voice = voice;
        } else {
          console.log("Datos de voz no son validos");
          alertFunctions.customAlert(
            "¡Error!",
            null,
            "error",
            true,
            true,
            null,
            "Ha ocurrido un error guardando tu información. Comprueba tus <b>Datos de Contacto</b>."
          );
          return;
        }
      }

      if (this.contactDataForm.controls["electronic"].dirty) {
        if (this.contactDataForm.controls["electronic"].valid) {
          let electronic = [];
          this.contactDataForm.value.electronic.forEach(element => {
            if (element.email) {
              electronic.push(element);
            }
          });
          person_to_save.electronic = electronic;
        } else {
          console.log("Datos de correo no son validos");
          alertFunctions.customAlert(
            "¡Error!",
            null,
            "error",
            true,
            true,
            null,
            "Ha ocurrido un error guardando tu información. Comprueba tus <b>Datos de Contacto</b>."
          );
          return;
        }
      }

      // console.log(person_to_save);

      this.uploader = new FileUploader({
        url: "https://sociosmusicartes.org/file-upload/" + this.id_musicartes,
        itemAlias: "image"
      });
      // this.uploader = new FileUploader({ url: 'http://localhost:5000/file-upload/' + this.id_musicartes, itemAlias: 'image' });
      // this.uploader = new FileUploader({ url: 'http://localhost:5000/file-upload/MGTDEV', itemAlias: 'image' });

      this.uploader.onAfterAddingFile = file => {
        file.withCredentials = false;
      };

      // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      //     if (status === 200 && response) {
      //         console.log("File uploaded:", item, status, response);
      //         person_to_save.extra.identify_document_url = response.imageUrl;
      //     }
      // };

      // console.log(person_to_save);
      person_to_save.user = this.current_user.usuario;
      console.log(person_to_save);

      this.personService
        .registerPerson({ data: person_to_save })
        .subscribe((data: any) => {
          if (data && data.resultado && data.resultado.header) {
            if (data.resultado.header.type == "success") {
              console.log("Usuario guardado con exito");
              this.activeModal.close("SUCCESS");
              alertFunctions.customAlert(
                "¡Registro Exitoso!",
                "Se ha registrado la información del socio",
                "success",
                true,
                false,
                2000
              );
            } else {
              alertFunctions.customAlert(
                "¡Error!",
                "Ha ocurrido un error guardando tu información. " +
                  data.resultado.header.msg,
                "error",
                true,
                true
              );
            }
          } else {
            alertFunctions.customAlert(
              "¡Error!",
              "Ha ocurrido un error guardando tu información.",
              "error",
              true,
              true
            );
          }
        });

      // if (this.Identify_Document) {
      //     this.uploader.addToQueue([this.Identify_Document]);
      //     this.uploader.uploadAll();
      // } else {
      //     alertFunctions.customAlert("¡Error!", null, "error", true, true, null, "Debes agregar una <b>Fotografía</b> de tu <b>Documento Personal</b>.");
      //     return;
      // }
    } else {
      alertFunctions.customAlert(
        "¡Error!",
        "No hemos podido comprobar tu ID de musicartes.",
        "error",
        true,
        true
      );
    }
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === "bar") {
      $event.preventDefault();
      this.addBeneficiary();
    }
  }
  validateContactPhoneNumbers(ac: AbstractControl) {
    let first_phone = ac.get("first_phone_number").value;
    let second_phone = ac.get("second_phone_number").value;
    // console.log("validando telefonos: ", first_phone, second_phone);
    if (first_phone == second_phone) {
      ac.get("second_phone_number").setErrors({
        samePhone: true
      });
    } else {
      return null;
    }
  }
  validateContactEmails(ac: AbstractControl) {
    let first_email = ac["first_email"].value;
    let second_email = ac["second_email"].value;
    if (first_email == second_email) {
      ac.get("second_email").setErrors({
        sameEmail: true
      });
    } else {
      return null;
    }
  }
  buildPersonHolder() {
    this.personalDataForm.controls.first_name.setValue("Usuario");
    this.personalDataForm.controls.last_name.setValue("Prueba");
    this.personalDataForm.controls.birthday_model.setValue({
      year: 1900,
      month: 1,
      day: 1
    });
    this.personalDataForm.controls.registration_date.setValue({
      year: 2018,
      month: 11,
      day: 1
    });
    this.personalDataForm.controls.gender.setValue("Masculino");
    this.personalDataForm.controls.civil_status.setValue("Soltero");
    this.personalDataForm.controls.nationality.setValue("Guatemalteco");
    this.personalDataForm.controls.birth_country.setValue("Guatemala");
    this.personalDataForm.controls.address.setValue("dir. 1");
    this.personalDataForm.controls.identifier_type.setValue("CUI/DPI");
    this.personalDataForm.controls.identifier.setValue("prueba");
    this.personalDataForm.controls.alias.setValue("UP");
    this.personalDataForm.controls.regime.setValue("Socio");
    this.personalDataForm.controls.bio.setValue("Esta es una biografia.");

    this.contactDataForm.controls.electronic
      .get("0")
      ["controls"].email.setValue("prueba@correo.gt");
    this.contactDataForm.controls.voice
      .get("0")
      ["controls"].telephony.setValue("00000000");

    this.addElectronicForm();
    this.addVoiceForm();

    this.contactDataForm.controls.electronic
      .get("1")
      ["controls"].email.setValue("prueba2@correo.gt");
    this.contactDataForm.controls.voice
      .get("1")
      ["controls"].telephony.setValue("00000000");

    this.formRegister.controls.social
      .get("0")
      ["controls"].user.setValue("uprueba");
    this.formRegister.controls.social
      .get("1")
      ["controls"].user.setValue("uprueba");

    /* this.bankingDataForm.controls.full_name.setValue('Usuario Prueba');
         this.bankingDataForm.controls.account.setValue('985487800');
         this.bankingDataForm.controls.bank.setValue('GyT');
         this.bankingDataForm.controls.type.setValue('monetaria');
         this.bankingDataForm.controls.coin.setValue('gtq');*/

    /*  this.legalDataForm.controls.nit.setValue('4684516');
          this.legalDataForm.controls.full_name.setValue('Usuario Prueba');
          this.legalDataForm.controls.fiscal_address.setValue('Ciudad');
          this.legalDataForm.controls.facturing.setValue('');*/

    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].identifier.setValue("BEN-1");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].first_name.setValue("prueba");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].last_name.setValue("Pruebpruebaa");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].gender.setValue("masculino");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].birthday.setValue({
        year: 1995,
        month: 8,
        day: 23
      });
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].birth_place.setValue("Guatemalteca");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].relationship.setValue("hermano/a");
    this.formRegister.controls.beneficiary
      .get("0")
      ["controls"].percentage.setValue("50.00");

    this.addBeneficiary();
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].identifier.setValue("BEN-2");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].first_name.setValue("prueba");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].last_name.setValue("prueba");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].gender.setValue("femenino");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].birthday.setValue({
        year: 1995,
        month: 9,
        day: 15
      });
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].birth_place.setValue("Guatemalteca");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].relationship.setValue("padre/madre");
    this.formRegister.controls.beneficiary
      .get("1")
      ["controls"].percentage.setValue("50.00");

    this.personalDataForm.markAsDirty();
    this.contactDataForm.markAsDirty();
    this.formRegister.controls.social.markAsDirty();
    this.contactDataForm.controls.electronic.markAsDirty();
    this.contactDataForm.controls.voice.markAsDirty();
    //this.bankingDataForm.markAsDirty();
    //this.legalDataForm.markAsDirty();
    this.formRegister.controls.beneficiary.markAsDirty();
  }

  onIdentifierImageChange(event) {
    console.log(event.target.files[0]);
    this.Identify_Document = new File(
      [event.target.files[0]],
      "DPI-" + this.id_musicartes,
      {
        type: event.target.files[0].type
      }
    ); // event.target.files[0];
  }
}
