import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FullLayoutPageComponent } from "app/pages/full-layout-page/full-layout-page.component";
import { PersonProfileComponent } from "app/person-profile/person-profile.component";
import { DashboardComponent } from "app/dashboard/dashboard.component";
import { CreatePersonInvitationComponent } from "app/create-person-invitation/create-person-invitation.component";
import { ViewInvitedPartnersComponent } from "app/view-partners-invited/view-invited-partners.component";
import { ViewEnrolledPartnersComponent } from "app/view-partners-enrolled/view-partners-enrolled.component";
import { AgendaComponent } from "app/agenda/agenda.component";
import { ViewWorksComponent } from "app/view-works/view-works.component";
import { ViewRecordCompaniesComponent } from "app/view-record-companies/view-record-companies.component";
import { ViewPublishers } from "app/view-publishers/view-publishers.component";
import { ViewArtistsComponent } from "app/view-artists/view-artists.component";
import { PersonWorksComponent } from "../../person-works/person-works.component";
import { ViewMonitoringComponent } from "app/view-monitoring/view-monitoring.component";
import { ViewPartnersComponent } from "app/view-partners/view-partners.component";
import { AuthGuard } from "app/shared/auth/auth.guard";
import { ViewAdminUsersComponent } from "app/view-admin-users/view-admin-users.component";
import { ViewNotificactionsComponent } from "app/view-notificactions/view-notificactions.component";
import { ViewNotificationsDetailComponent } from "app/view-notifications-detail/view-notifications-detail.component";
import { ViewDistributionComponent } from "app/view-distribution/view-distribution.component";
import { PersonDistributionsComponent } from "app/person-distributions/person-distributions.component";
import { ViewReportsComponent } from "app/view-reports/view-reports.component";
import { ViewIndependentPartnersComponent } from "app/view-partners-independent/view-independent-partners.component";
import { ViewInstrumentsComponent } from "app/view-instruments/view-instruments.component";
import { ViewMonitoringChannelsComponent } from "app/view-monitoring-channels/view-monitoring-channels.component";

const routes: Routes = [
  {
    path: "",
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        component: DashboardComponent,
        data: { title: "Inicio" }
      },
      {
        path: "profile",
        component: PersonProfileComponent,
        data: { title: "Perfil" }
      },
      {
        path: "distributions",
        component: PersonDistributionsComponent,
        data: { title: "Distribuciones" }
      },
      {
        path: "works",
        component: PersonWorksComponent,
        data: { title: "Obras" }
      },
      {
        path: "notifications",
        component: ViewNotificationsDetailComponent,
        data: { title: "Notificaciones" }
      },
      {
        path: "admin",
        children: [
          { path: "users", component: ViewAdminUsersComponent },
          {
            path: "partners",
            children: [
              {
                path: "invited",
                component: ViewPartnersComponent,
                data: { tab: "invited" }
              },
              {
                path: "enrolled",
                component: ViewPartnersComponent,
                data: { tab: "enrolled" }
              },
              {
                path: "independent",
                component: ViewPartnersComponent,
                data: { tab: "independent" }
              },
              {
                path: "",
                redirectTo: "/fl/admin/partners/invited",
                pathMatch: "prefix"
              }
            ]
          },
          { path: "invitation", component: CreatePersonInvitationComponent },
          { path: "invited-users", component: ViewInvitedPartnersComponent },
          {
            path: "registered-users",
            component: ViewEnrolledPartnersComponent
          },
          { path: "agenda", component: AgendaComponent },
          { path: "rcompanies", component: ViewRecordCompaniesComponent },
          { path: "publisher", component: ViewPublishers },
          { path: "bands", component: ViewArtistsComponent },
          { path: "works", component: ViewWorksComponent },
          {
            path: "monitoring",
            children: [
              {
                path: "channels",
                component: ViewMonitoringChannelsComponent
              },
              {
                path: "",
                component: ViewMonitoringComponent
              },
              {
                path: "**",
                redirectTo: "/fl/admin/monitoring"
              }
            ]
          },
          { path: "distribution", component: ViewDistributionComponent },
          { path: "reports", component: ViewReportsComponent },
          { path: "instruments", component: ViewInstrumentsComponent },
          { path: "**", redirectTo: "/fl", pathMatch: "prefix" }
        ]
      },
      {
        path: "**",
        redirectTo: "/fl"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FullPagesRoutingModule {}
