<div
  class="row justify-content-center text-center"
  *ngIf="!show_stats && current_user"
>
  <div class="col-md-12">
    <img
      class="img-fluid mb-3"
      src="assets/img/MUSICARTES_LOGO_color_white-01.png"
      alt="logo musicartes"
    />
    <br />
    <div *ngIf="current_user.perfil == '1'">
      <p>
        Te damos la más cordial bienvenida al nuevo sistema de gestión de
        repertorios de la sociedad de artistas de la música y obras
        audiovisuales.
      </p>
      <p>
        En MUSICARTES, estamos migrando a una nueva plataforma digital para
        gestionar tus derechos conexos de una manera más eficiente y
        modernizada.
      </p>
      <p>
        Si deseas visitarnos puedes dirigirte a la siguiente dirección:
      </p>
      <p class="text-bold-600">21 avenida “B” 0-10 zona 15 Vista Hermosa 2</p>
      <p>
        También puedes comunicarte con nosotros llamando al número telefónico:
      </p>
      <p class="text-bold-600">
        502 2297-4700
      </p>
      <p>En los horarios:</p>
      <p class="text-bold-600">lunes a viernes de 9:00am – 4:00pm</p>
      <p>¡Sera un gusto atenderte!</p>
    </div>
  </div>
</div>

<div *ngIf="show_stats && current_user">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Inicio</h4>
    </div>
  </div>

  <!-- <div class="card-content">
      <div class="card-body">
          <div id="donut-chart2" class="height-400">
              <x-chartist [data]="donutChartE.data" [type]="donutChartE.type" [options]="donutChartE.options" [responsiveOptions]="donutChartE.responsiveOptions"
                  [events]="donutChartE.events">
              </x-chartist>
          </div>
      </div>
  </div> -->

  <!--Donut Chart 1 Starts-->
  <div class="row text-left">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Estado de Socios</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="px-3 mb-3 mt-3">
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <h4>Inscripciones</h4>
                  <div id="donut-chart1" class="height-200 donut-inscriptions">
                    <x-chartist
                      [data]="chartUserInscriptions.data"
                      [type]="chartUserInscriptions.type"
                      [options]="chartUserInscriptions.options"
                      [responsiveOptions]="
                        chartUserInscriptions.responsiveOptions
                      "
                      [events]="chartUserInscriptions.events"
                    >
                    </x-chartist>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <a routerLink="/fl/admin/partners/enrolled">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.inscritos }} - <br/> Inscritos</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #ffc107"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>

                    <div class="col">
                      <a routerLink="/fl/admin/partners/invited">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.invitados }} - <br/> Invitados</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #009da0"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3">
                  <h4>Tipo</h4>
                  <div id="donut-chart1" class="height-200 donut-type">
                    <x-chartist
                      [data]="chartUserTypes.data"
                      [type]="chartUserTypes.type"
                      [options]="chartUserTypes.options"
                      [responsiveOptions]="chartUserTypes.responsiveOptions"
                      [events]="chartUserTypes.events"
                    >
                    </x-chartist>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <a routerLink="/fl/admin/partners/enrolled">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.tipo.socio }} - <br/> Socios</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #0cc27e"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>

                    <div class="col">
                      <a routerLink="/fl/admin/partners/invited">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.tipo.representados }} - <br/> Representados</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #ff586b"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-lg-3">
                  <h4>Género</h4>
                  <div id="donut-chart2" class="height-200 donut-gender">
                    <x-chartist
                      [data]="chartUserGender.data"
                      [type]="chartUserGender.type"
                      [options]="chartUserGender.options"
                      [responsiveOptions]="chartUserGender.responsiveOptions"
                      [events]="chartUserGender.events"
                    >
                    </x-chartist>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <a routerLink="/fl/admin/partners/enrolled">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.genero.masculino }} - <br/> Masculino</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #38b8f2"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>

                    <div class="col">
                      <a routerLink="/fl/admin/partners/invited">
                        <span class="mb-1 text-muted d-block"
                          >{{ socios.genero.femenino }} - <br/>Femenino</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #FF396f"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>


                <div class="col-md-4 col-lg-3">
                  <h4>Roles</h4>
                  <div id="donut-chart2" class="height-200 donut-roles">
                    <x-chartist
                      [data]="chartRoles.data"
                      [type]="chartRoles.type"
                      [options]="chartRoles.options"
                      [responsiveOptions]="chartRoles.responsiveOptions"
                      [events]="chartRoles.events"
                    >
                    </x-chartist>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <a routerLink="/fl/admin/partners/enrolled">
                        <span class="mb-1 text-muted d-block"
                          >{{ stats.roles.interprete.cantidad }} - <br/> Interprete</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #E53D00"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>

                    <div class="col">
                      <a routerLink="/fl/admin/partners/enrolled">
                        <span class="mb-1 text-muted d-block"
                          >{{ stats.roles.ejecutante.cantidad }} - <br/> Ejecutante</span
                        >
                        <div class="progress" style="height: 5px;">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style="width: 100%; background-color: #FFE900"
                            aria-valuenow="23"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </a>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Donut Chart 1 Ends-->

  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Distribución {{ distributions.anio }}</h4>
          <h3>Monto Asignado: {{ distributions.total }}</h3>
        </div>
        <div class="card-body">
          <div class="card-block">
            <div id="vartical-bar-chart" class="height-350">
              <ngx-charts-bar-vertical-2d
                [scheme]="barChartColorScheme"
                [results]="barChartmulti2"
                [gradient]="barChartGradient"
                [xAxis]="barChartShowXAxis"
                [yAxis]="barChartShowYAxis"
                [legend]="barChartShowLegend"
                [showXAxisLabel]="barChartShowXAxisLabel"
                [showYAxisLabel]="barChartShowYAxisLabel"
                [xAxisLabel]="barChartXAxisLabel"
                [yAxisLabel]="'Monto (Q)'"
                (select)="onSelect($event)"
              >
              </ngx-charts-bar-vertical-2d>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
