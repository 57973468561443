<section id="agenda">
  <div class="row">
    <div class="col-sm-12">
        <h4 class="content-header">Agenda</h4>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-block">
        <div class="d-flex flex-wrap justify-content-md-between mb-3 no-gutters">
          <div class="col">
            <div class="btn-group d-none d-sm-none d-md-none d-lg-block">
              <a class="btn btn-raised btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
                Anterior
              </a>
              <a class="btn btn-raised btn-danger" mwlCalendarToday [(viewDate)]="viewDate">
                Hoy
              </a>
              <a class="btn btn-raised btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                Siguiente
              </a>
            </div>

            <div class="btn-group d-block d-sm-block d-md-block d-lg-none">
              <a class="btn btn-raised btn-primary ft-chevron-left" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
                <i class="icon icon-arrows-left"></i>
              </a>
              <a class="btn btn-raised btn-danger ft-bookmark" mwlCalendarToday [(viewDate)]="viewDate">
                <i class="icon icon-arrows-sign-down"></i>
              </a>
              <a class="btn btn-raised btn-primary ft-chevron-right" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                <i class="icon icon-arrows-right"></i>
              </a>
            </div>
          </div>
          <div class="col text-center align-self-center">
            <h6 class="text-uppercase mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h6>
          </div>
          <div class="col text-right">
            <button class="btn btn-raised btn-primary mr-1" (click)="updateEvents()">
              Actualizar
            </button>
            <button class="btn btn-raised btn-primary mr-1" (click)="openCreateAppointment()" *ngIf="validateUserCategory()" >
              Crear Evento
            </button>
          </div>
        </div>
        <div [ngSwitch]='view'>
          <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent($event.event)"
            (eventTimesChanged)="eventTimesChanged($event)" [locale]="locale">
          </mwl-calendar-month-view>
        </div>
      </div>
    </div>
  </div>

  
</section>
