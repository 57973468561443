import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  Input,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
//import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";

import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { PersonService } from "../services/person.service";
import { RouteDataService } from "app/services/route-data.service";

import { ViewPartnerCardComponent } from "app/view-partner-card/view-partner-card.component";
import { ViewPartnerDistributionCardComponent } from "app/view-partner-distribution-card/view-partner-distribution-card.component";

@Component({
  selector: "app-view-partners-enrolled",
  templateUrl: "./view-partners-enrolled.component.html",
  styleUrls: ["./view-partners-enrolled.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ViewEnrolledPartnersComponent implements OnInit {
  loading_data: boolean = true;

  @Input() current_user: any;

  @ViewChild("modalUserAgreement") modalUserAgreement: ElementRef;
  @ViewChild("content") content: ElementRef;
  @ViewChild("barcode") barcode: ElementRef;

  @Input() enrolled_partners = [];
  enrolled_partners_filtered = null;
  partner_to_filter = "";

  constructor(
    private person_service: PersonService,
    private admin_service: AdminService,
    private modalService: NgbModal,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.enrolled_partners_filtered = this.enrolled_partners;
    this.loading_data = false;
  }

  getRegisteredUsers() {
    this.partner_to_filter = "";
    this.loading_data = true;
    this.enrolled_partners = this.enrolled_partners_filtered = null;
    this.admin_service
      .getUserList({
        token: this.current_user.token,
        action: "old",
        user: this.current_user.usuario,
      })
      .subscribe((data: any) => {
        this.loading_data = false;
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type == "success"
        ) {
          this.enrolled_partners = this.enrolled_partners_filtered =
            data.resultado.body;
        } else {
          this.toastr.toast_error("No se encontraron Socios.");
          this.enrolled_partners = this.enrolled_partners_filtered = [];
        }
      });
  }

  updateUsersEnrolledFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.enrolled_partners.filter(function (d) {
      return (
        d.id.toLowerCase().indexOf(val) !== -1 ||
        d.nombre.toLowerCase().indexOf(val) !== -1 ||
        d.fecha.toLowerCase().indexOf() !== -1 ||
        d.correo.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });

    this.enrolled_partners_filtered = temp;
  }

  show_agreements = false;

  current_agreement_detail: any = {};
  tableAgreementRows = [];
  temp2 = [];

  viewUserAgreements(id: string) {
    this.person_service
      .getUserAgreements({ user: this.current_user.usuario, id: id })
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type === "success"
        ) {
          this.current_agreement_detail = data.body;
          this.tableAgreementRows = this.current_agreement_detail.obras;
          this.show_agreements = true;
        } else {
          this.toastr.userHasNoAgreements();
        }
      });
    const modal = this.modalService.open(this.modalUserAgreement, {
      size: "lg",
      centered: true,
      windowClass: "modalUserAgreements",
    });

    modal.result.then((result) => {
      console.log(result);
    });
  }

  viewPartnerCard(user_id: any) {
    console.log("View card from: ", user_id);
    let modalRef = this.modalService.open(ViewPartnerCardComponent, {
      size: "lg",
      windowClass: "modalPartnerCard",
    });
    modalRef.componentInstance.partner_id = user_id;
    // modalRef.componentInstance.sendPasswordToPartner = this.sendUserPassword;
  }
  viewUserDistributions(user_id: any) {
    console.log("View card from: ", user_id);
    let modalRef = this.modalService.open(
      ViewPartnerDistributionCardComponent,
      {
        size: "lg",
        windowClass: "modalPartnerCard",
      }
    );
    modalRef.componentInstance.partner_id = user_id;
  }
}
