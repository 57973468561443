import { Component, OnInit, Input } from '@angular/core';
import { PersonService } from 'app/services/person.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewAppointmentComponent } from 'app/view-appointment/view-appointment.component';
import { AppointmentService } from 'app/services/appointment.service';

@Component({
    selector: 'app-view-notificactions',
    templateUrl: './view-notificactions.component.html',
    styleUrls: ['./view-notificactions.component.scss'],

})
export class ViewNotificactionsComponent implements OnInit {

    @Input() placement: any;
    current_user: any;
    notifications: any = [];


    constructor(
        private person_service: PersonService,
        private appointment_service: AppointmentService,
        private ngbModalService: NgbModal, ) { }

    ngOnInit() {
        this.person_service.getSessionPerson().subscribe((data: any) => {
            this.current_user = data;
            this.loadNotifications();
        });

    }
    loadNotifications() {
        this.appointment_service.getNotificationsByUserCat({ user_cat: this.current_user.perfil_nombre, current_user: this.current_user.usuario }).subscribe((retrived_notifications: any) => {
            this.notifications = [...retrived_notifications];
           // console.log(retrived_notifications);
        })
    }
    markNotificationSeen(notification_id, notification_index) {
        console.log(notification_index);
        //  this.notifications.splice(notification_index, 1);
        this.appointment_service.markEventSeenBy({ notification_id: notification_id, seen_by: this.current_user.usuario }).subscribe((data: any) => {
           // console.log(data);
            this.loadNotifications();
        });
    }

    viewEvent(notification, notification_index) {
        let modalRef = this.ngbModalService.open(ViewAppointmentComponent, { size: 'lg' });

        modalRef.componentInstance.currentEvent = notification;
        modalRef.componentInstance.current_user = this.current_user;
        modalRef.componentInstance.show_buttons = false;
        modalRef.componentInstance.show_mark_seen = true;

        modalRef.result.then((result) => {
           // console.log("Resultado view appointment:", result);
            if (result === "mark_as_seen") {
                this.markNotificationSeen(notification._id, notification_index);

            }
        }, (reason) => {
          //  console.log(reason);
        });

    }
}
