<div class="wrapper">
  <div class="content-wrapper">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>

  </div>
  <p class="text-center">Sociedad de Artistas de la Música y Obras Audiovisuales. Todos los derechos reservados
    &copy; {{currentDate | date: 'yyyy'}}.</p>
</div>
