import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpOptions } from 'app/shared/consts';
@Injectable({
    providedIn: 'root'
})
export class PersonService {

    constructor(private http: HttpClient) { }

    getSessionPerson(user?: any) {
        if (localStorage.getItem("tkn") === null) {
            return null;
        }
        return this.http.post('/getSession', { token: localStorage.getItem("tkn"), usuario: user }, httpOptions);
    }
    getCurrentUserBoard(data: any) {
        return this.http.post('/getSideBar', data, httpOptions);
    }
    scheduleLogout(tkn: any) {
        return this.http.post('/scheduleLogout', { tkn: tkn }, httpOptions);
    };
    registerPerson(data: any) {
        return this.http.post('/registerUser', data, httpOptions);
    }

    addPersonBeneficiary(data: any) {
        return this.http.post('/addPersonBeneficiary', data, httpOptions);
    }

    getDataPerson(data: any) {
        return this.http.post('/getDataPerson', { user: data.usuario, id_musicartes: data.codigo, token: data.token }, httpOptions);
    }
    doLoginUser(data: any) {
        return this.http.post('/loginUser', data, httpOptions);
    }
    doLoginUser2(data: any) {
        return this.http.post('/upToken', data, httpOptions);
    }
    manageAccess(data: any) {
        return this.http.post('/manageAccess', data, httpOptions);
    }


    changePassword(data: any) {
        return this.http.post('/changePassword', data, httpOptions);
    }
    modifyPassword(data: any) {
        return this.http.post('/modifyPassword', data, httpOptions);
    }
    recoverPassword(data: any) {
        return this.http.post('/recoverPassword', data, httpOptions);
    }
    updateData(data: any) {
        return this.http.post('/updatePersonData', data, httpOptions);
    }

    getUserAgreements(data: any) {
        return this.http.post('/getPersonAgreements', data, httpOptions);
    }
    getUserDistributions(data: any) {
        return this.http.post('/getDistributionsByParticipant', data, httpOptions);
    }

    getPartnerDistributions(data: any) {
        return this.http.post('/getPartnerDistributions', data, httpOptions);
    }

}
