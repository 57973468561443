import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-create-publisher",
  templateUrl: "./create-publisher.component.html",
  styleUrls: ["./create-publisher.component.scss"],
})
export class CreatePublisherComponent implements OnInit {
  current_user: any;

  publisherForm: FormGroup;

  updating: Boolean;
  existingData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private route_data_service: RouteDataService,
    private formBuilder: FormBuilder,
    private admin_service: AdminService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.publisherForm = this.formBuilder.group({
      name: new FormControl(this.updating ? this.existingData.name : "", [
        Validators.required,
      ]),
      // address: new FormControl(),
      // phone: new FormControl()
    });
    console.log("existing", this.existingData);
  }
  saveRecordCompany(): void {
    if (this.publisherForm.valid) {
      let data_to_save = this.publisherForm.value;
      data_to_save.user = this.current_user.usuario;
      this.admin_service.savePublisher(data_to_save).subscribe((data: any) => {
        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          this.toastr.dataSavedSuccess();
          this.activeModal.close("Ok");
        }
      });
    }
  }
  updateModel(): void {
    if (this.publisherForm.valid) {
      let data_to_save = this.publisherForm.value;
      data_to_save.user = this.current_user.usuario;
      data_to_save.correlative = this.existingData.correlative;
      this.admin_service
        .updatePublisher(data_to_save)
        .subscribe((data: any) => {
          if (
            data &&
            data.response &&
            data.response.db &&
            data.response.db.typ === "success"
          ) {
            this.toastr.dataSavedSuccess();
            this.activeModal.close("Ok");
          }
        });
    }
  }
}
