<div class="row">
  <div class="col-12">
    <h4 class="content-header">Instrumentos</h4>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="px-2 mb-2 mt-2">
          <div class="row">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                style="padding:8px;"
                placeholder="buscar"
                (keyup)="filterMainList($event)"
              />
            </div>

            <div class="col-6 text-right">
              <button
                type="button"
                class="btn btn-raised btn-raised btn-primary float-right"
           
              >
                Ingresar Producto
              </button>
              <button
                class="btn btn-raised btn-primary float-right mr-2"
                (click)="loadMainList()"
              >
                Actualizar Listado
              </button>
            </div>
          </div>

          <ngx-datatable
            class="bootstrap"
            [columnMode]="'flex'"
            [headerHeight]="30"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="10"
            [rows]="main_list_filtered"
          >
            <ngx-datatable-column
              prop="correlativo"
              name="#"
              [flexGrow]="0.5"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="codigo_producto"
              name="Código"
              [flexGrow]="0.5"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              prop="marca_comercial"
              name="Marca"
              [flexGrow]="1"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              prop="principio_activo"
              name="Principio Activo"
              [flexGrow]="0.9"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              prop="fabricante"
              name="Fabricante"
              [flexGrow]="0.5"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="tipo"
              name="Tipo"
              [flexGrow]="0.5"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="clasificacion_nombre"
              name="Clasificación"
              [flexGrow]="0.5"
            >
            </ngx-datatable-column>

            <ngx-datatable-column name="Acción" [flexGrow]="0.5">
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div class="fonticon-container">
                  <div class="fonticon-wrap">
                    <i-feather
                      name="eye"
                      class="primary mr-2"
                      style="cursor:pointer;"
                      (click)="ViewRowDetail(row)"
                    >
                    </i-feather>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
