<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-6">
        <input
          type="text"
          class="form-control"
          style="padding: 8px"
          placeholder="buscar"
          (keyup)="updateUsersEnrolledFilter($event)"
          [(ngModel)]="partner_to_filter"
        />
      </div>
      <div class="col-6 text-right">
        <button
          class="btn btn-raised btn-primary mr-1"
          (click)="getRegisteredUsers()"
        >
          <i class="ft-refresh-cw"></i> Actualizar
        </button>
      </div>
    </div>

    <div
      class="d-flex justify-content-center mt-5 mb-5"
      *ngIf="loading_data && !enrolled_partners_filtered"
    >
      <i-feather
        name="loader"
        class="fa fa-spin black text-center feather-loading"
      ></i-feather>
    </div>

    <ngx-datatable
      class="bootstrap"
      [columnMode]="'flex'"
      [headerHeight]="30"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [limit]="10"
      [rows]="enrolled_partners_filtered"
      *ngIf="!loading_data && enrolled_partners_filtered"
    >
      <ngx-datatable-column
        prop="correlativo"
        name="No."
        [flexGrow]="0.3"
      ></ngx-datatable-column>
      <ngx-datatable-column name="ID" [flexGrow]="0.75"></ngx-datatable-column>
      <ngx-datatable-column
        name="Nombre"
        [flexGrow]="1.25"
      ></ngx-datatable-column>
      <ngx-datatable-column
        name="Correo"
        [flexGrow]="1.25"
      ></ngx-datatable-column>
      <ngx-datatable-column
        prop="fecha"
        name="Fecha Inscripción"
        [flexGrow]="1"
      ></ngx-datatable-column>
      <ngx-datatable-column name="Acción" [flexGrow]="0.75">
        <ng-template
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <!-- <i-navigation class="success mr-2" style="cursor:pointer;" (click)="sendUserPassword(row.id,row.correo)"></i-navigation> -->
          <div class="fonticon-container">
            <div class="fonticon-wrap mr-3">
              <i-feather
                name="eye"
                class="primary"
                style="cursor: pointer"
                (click)="viewPartnerCard(row.id)"
              >
              </i-feather>
            </div>
          </div>

          <div class="fonticon-container">
            <div class="fonticon-wrap mr-3">
              <i-feather
                name="percent"
                class="primary"
                style="cursor: pointer"
                (click)="viewUserAgreements(row.id)"
              >
              </i-feather>
            </div>
          </div>

          <div class="fonticon-container">
            <div class="fonticon-wrap mr-3">
              <i-feather
                name="bar-chart-2"
                class="primary"
                style="cursor: pointer"
                (click)="viewUserDistributions(row.id)"
              >
              </i-feather>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

<ng-template #modalUserAgreement let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Listado De Convenios</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="c('Cancel')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="main-content">
      <div class="row text-left" *ngIf="show_agreements">
        <div class="col-12">
          <dl>
            <div class="col-6">
              <dt>Código Socio:</dt>
              <dd>{{ current_agreement_detail.artista.codigo_persona }}</dd>
            </div>
            <div class="col-6">
              <dt>Nombre Socio:</dt>
              <dd>{{ current_agreement_detail.artista.nombre_persona }}</dd>
            </div>
          </dl>
        </div>
        <div class="col-12">
          <ngx-datatable
            class="bootstrap"
            [rows]="tableAgreementRows"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="10"
          >
            <ngx-datatable-column
              prop="codigo_obra"
              name="ID"
              [flexGrow]="0.9"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="artista"
              name="Artista"
              [flexGrow]="1"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="publicacion"
              name="Año"
              [flexGrow]="0.5"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="obra"
              name="Obra"
              [flexGrow]="1"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="rol"
              name="Rol"
              [flexGrow]="1"
            ></ngx-datatable-column>
            <ngx-datatable-column
              prop="instrumento"
              name="Instrumento"
              [flexGrow]="0.75"
            ></ngx-datatable-column>

            <ngx-datatable-column
              prop="porcentaje"
              name="Convenio"
              [flexGrow]="1"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <span>% {{ value }}</span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</ng-template>
