import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";

import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadModule } from "ng2-file-upload";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ArchwizardModule } from "angular-archwizard";
//import { ImageCropperModule } from "ngx-img-cropper";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NguiAutoCompleteModule } from "@ngui/auto-complete";
import { ImageCropperModule } from "ngx-image-cropper";
import { ChartistModule } from "ng-chartist";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FeatherIconsModule } from "app/shared/feather-icons/feather-icons.module";

import { FullPagesRoutingModule } from "./full-pages-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FullLayoutPageComponent } from "./full-layout-page.component";
import { PersonProfileComponent } from "app/person-profile/person-profile.component";
import { DashboardComponent } from "app/dashboard/dashboard.component";
import { CreatePersonInvitationComponent } from "app/create-person-invitation/create-person-invitation.component";
import { ViewInvitedPartnersComponent } from "app/view-partners-invited/view-invited-partners.component";
import { ViewEnrolledPartnersComponent } from "app/view-partners-enrolled/view-partners-enrolled.component";
import { ViewIndependentPartnersComponent } from "app/view-partners-independent/view-independent-partners.component";
import { AgendaComponent } from "app/agenda/agenda.component";
import { ViewWorksComponent } from "app/view-works/view-works.component";
import { ViewRecordCompaniesComponent } from "app/view-record-companies/view-record-companies.component";
import { ViewPublishers } from "app/view-publishers/view-publishers.component";
import { ViewArtistsComponent } from "app/view-artists/view-artists.component";
import { PersonWorksComponent } from "app/person-works/person-works.component";

import { RegisterPersonModalComponent } from "app/register-person-modal/register-person-modal.component";

import { ViewMonitoringComponent } from "app/view-monitoring/view-monitoring.component";
import { CreateArtistComponent } from "app/create-artist/create-artist.component";
import { CreateAppoinmentComponent } from "app/create-appoinment/create-appoinment.component";
import { CreateDiscographyComponent } from "app/create-discography/create-discography.component";
import { ViewPartnersComponent } from "app/view-partners/view-partners.component";

import { ViewAdminUsersComponent } from "app/view-admin-users/view-admin-users.component";
import { CreateAdminUserComponent } from "app/create-admin-user/create-admin-user.component";
import { ViewNotificactionsComponent } from "app/view-notificactions/view-notificactions.component";
import { CreatePersonBeneficiaryComponent } from "app/create-person-beneficiary/create-person-beneficiary.component";
import { CreateWorkComponent } from "app/create-work/create-work.component";
import { ViewAppointmentComponent } from "app/view-appointment/view-appointment.component";
import { CreateMonitoringChannelComponent } from "app/create-monitoring-channel/create-monitoring-channel.component";
import { ViewNotificationsDetailComponent } from "app/view-notifications-detail/view-notifications-detail.component";
import { ViewDistributionComponent } from "app/view-distribution/view-distribution.component";
import { ViewMonitoringSavedComponent } from "app/view-monitoring-saved/view-monitoring-saved.component";
import { CreateDistributionComponent } from "app/create-distribution/create-distribution.component";
import { PersonDistributionsComponent } from "app/person-distributions/person-distributions.component";
import { CreateRecordCompanyComponent } from "app/create-record-company/create-record-company.component";
import { CreatePublisherComponent } from "app/create-publisher/create-publisher.component";
import { CreatePublisherAgreementComponent } from "app/create-publisher-agreement/create-publisher-agreement.component";
import { ViewReportsComponent } from "app/view-reports/view-reports.component";

import { ViewPartnerCardComponent } from "app/view-partner-card/view-partner-card.component";
import { ViewWorkCardComponent } from "app/view-work-card/view-work-card.component";
import { ViewInstrumentsComponent } from "app/view-instruments/view-instruments.component";
import { ViewMonitoringChannelsComponent } from "app/view-monitoring-channels/view-monitoring-channels.component";
import { ViewPartnerDistributionCardComponent } from "../../view-partner-distribution-card/view-partner-distribution-card.component";

//registerLocaleData(localeGT);
@NgModule({
  imports: [
    CommonModule,
    FullPagesRoutingModule,
    NgbModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ArchwizardModule,
    ImageCropperModule,
    HttpClientModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NguiAutoCompleteModule,
    ChartistModule,
    NgxChartsModule,
    FeatherIconsModule,
  ],
  declarations: [
    PersonProfileComponent,
    PersonWorksComponent,
    PersonDistributionsComponent,

    FullLayoutPageComponent,
    DashboardComponent,
    CreatePersonInvitationComponent,
    ViewInvitedPartnersComponent,
    ViewEnrolledPartnersComponent,
    ViewIndependentPartnersComponent,
    RegisterPersonModalComponent,
    AgendaComponent,
    ViewWorksComponent,
    ViewRecordCompaniesComponent,
    ViewArtistsComponent,
    ViewMonitoringComponent,
    CreateArtistComponent,
    CreateAppoinmentComponent,
    CreateDiscographyComponent,
    ViewPartnersComponent,
    ViewPartnerCardComponent,
    ViewAdminUsersComponent,
    CreateAdminUserComponent,
    ViewNotificactionsComponent,
    CreatePersonBeneficiaryComponent,
    CreateWorkComponent,
    ViewAppointmentComponent,
    CreateMonitoringChannelComponent,
    ViewNotificationsDetailComponent,
    ViewDistributionComponent,
    ViewMonitoringSavedComponent,
    CreateDistributionComponent,
    ViewReportsComponent,

    ViewWorkCardComponent,
    CreateRecordCompanyComponent,
    ViewInstrumentsComponent,
    ViewMonitoringChannelsComponent,

    ViewPublishers,
    CreatePublisherComponent,
    CreatePublisherAgreementComponent,
    ViewPartnerDistributionCardComponent,
  ],
  exports: [ViewNotificactionsComponent],
  entryComponents: [
    RegisterPersonModalComponent,
    CreateArtistComponent,
    CreateDiscographyComponent,
    CreateAppoinmentComponent,
    CreatePersonInvitationComponent,
    ViewPartnerCardComponent,
    CreateAdminUserComponent,
    CreatePersonBeneficiaryComponent,
    CreateWorkComponent,
    ViewAppointmentComponent,
    CreateMonitoringChannelComponent,
    CreateDistributionComponent,
    ViewWorkCardComponent,
    CreateRecordCompanyComponent,
    CreatePublisherComponent,
    CreatePublisherAgreementComponent,
    ViewPartnerDistributionCardComponent,
  ],
})
export class FullPagesModule {}
