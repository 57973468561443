import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from 'app/services/toastr.service';

import { AdminService } from '../services/admin.service';
import { RouteDataService } from 'app/services/route-data.service';
import { CreateRecordCompanyComponent } from 'app/create-record-company/create-record-company.component';


@Component({
    selector: 'app-view-record-companies',
    templateUrl: './view-record-companies.component.html',
    styleUrls: ['./view-record-companies.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewRecordCompaniesComponent implements OnInit {

    current_user: any;

    rows = [];
    temp = [];
    columns = [
        { prop: 'codigo', name: 'ID' },
        { prop: 'nombre', name: 'Nombre' },
        { prop: 'direccion', name: 'Dirección' },
        { prop: 'telefono', name: 'Teléfono' },
    ];
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('content') content: ElementRef;
    
    constructor(
        private route_data_service: RouteDataService,
        private admin_service: AdminService,
        private formBuilder: FormBuilder,
        private ngbModalService: NgbModal,
        private toastr: NGXToastrService, ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.getCompaniesList();
    }
    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.temp.filter(function (d) {
            return d.id.toLowerCase().indexOf(val) !== -1 || d.nombre.toLowerCase().indexOf(val) !== -1 || d.correo.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.rows = temp;
        this.table.offset = 0;
    }
    getCompaniesList() {
        this.admin_service.getRecordCompanies({ user: this.current_user.usuario }).subscribe((data: any) => {
            console.log(data);
            if (data && data.header && data.header.type && data.header.type == "success") {
                let recordCompanies_list = data.body;
                this.temp = [...recordCompanies_list];
                this.rows = recordCompanies_list;
            }
        });

    }
    openRegisterNewCompany() {
        const modal = this.ngbModalService.open(CreateRecordCompanyComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            centered: true,
        });

        modal.result.then((result) => {
            if (result === 'Ok') {
                this.getCompaniesList();
            }
        }, (reason) => {
            console.log(reason);
        });
    }

}
