<section id="person-works">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Repertorio</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="px-3 mb-3 mt-3">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6 text-right">
                <button
                  class="btn btn-raised btn-primary float-right mr-2"
                  (click)="loadPartnerWorks()"
                >
                  Actualizar
                </button>
              </div>
            </div>

            <ngx-datatable
              #table
              class="bootstrap"
              [rows]="tableAgreementRows"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="'auto'"
              [limit]="10"
            >
              <ngx-datatable-column
                prop="codigo_obra"
                name="ID"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="obra"
                name="Canción"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="artista"
                name="Artista"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="publicacion"
                name="Publicación"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="rol"
                name="Rol"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="instrumento"
                name="Instrumento"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="porcentaje"
                name="Convenio (%)"
              ></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
