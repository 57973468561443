import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'app/services/admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePersonInvitationComponent } from 'app/create-person-invitation/create-person-invitation.component';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
  selector: 'app-view-independent-partners',
  templateUrl: './view-independent-partners.component.html',
  styleUrls: ['./view-independent-partners.component.scss']
})
export class ViewIndependentPartnersComponent implements OnInit {

  loading_data: boolean = true;

  @Input() current_user: any;

  @Input() main_list = [];
  main_list_filtered = null;
  partner_to_filter = '';

  constructor(
    private admin_service: AdminService,
    private ngbModalService: NgbModal,
    private toastr: NGXToastrService
  ) { }

  ngOnInit() {
    this.main_list_filtered = this.main_list;
    this.loading_data = false;
  }

  loadMainList() {
    this.partner_to_filter = '';
    this.loading_data = true;
    this.main_list = this.main_list_filtered = null;

    this.admin_service.getIndependentPartner({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        this.loading_data = false;
        if (data && data.resultado && data.resultado.header && data.resultado.header.type == "success") {
          this.main_list = this.main_list_filtered = data.resultado.artista;
        } else {
          this.toastr.toast_error("No se encontraron Socios.");
          this.main_list = this.main_list_filtered = [];
        }
      });
  }

  FilterMainList(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.main_list.filter(function (d) {
      return d.codigo.toLowerCase().indexOf(val) !== -1 ||
        d.nombre.toLowerCase().indexOf(val) !== -1 ||
        d.alias.toLowerCase().indexOf() !== -1 || !val;
    });

    this.main_list_filtered = temp;
  }

  createIndependentPartnerInvitation(independent_partner) {
    const modalRef = this.ngbModalService.open(CreatePersonInvitationComponent, { size: 'lg' });
    modalRef.result.then((result) => {
      console.log(result);
      if (result === 'Ok') {
        this.loadMainList();
      } else if (result === 'Error') {

      }
    }, (reason) => {
      console.log(reason);
    })
    modalRef.componentInstance.invitation_to_create = independent_partner;
  }

}
