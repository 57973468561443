<div class="modal-header">
  <h4 class="modal-title">Formulario de Registro</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- <pre class="pre-scrollable" style="font-size: 11px;">
        {{formRegister.value | json }}
      </pre> -->

  <section id="register-person-model" *ngIf="current_user">
    <div class="main-content container">
      <div class="row">
        <div class="col-12">
          <div class="icons-tab-steps wizard-circle">
            <h6>Personales</h6>
            <fieldset>
              <form [formGroup]="personalDataForm">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first_name">Primer nombre Legal</label>
                      <input type="text" class="form-control" id="first_name" formControlName="first_name" required>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('first_name').valid && (personalDataForm.get('first_name').dirty|| personalDataForm.get('first_name').touched)">¡Este
                        campo es requerido!</small>

                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="middle_name">Segundo Nombre Legal</label>
                      <input type="text" class="form-control" id="middle_name" name="middle_name"
                        formControlName="middle_name">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first_last_name">Primer Apellido Legal</label>
                      <input type="text" class="form-control" name="last_name" formControlName="last_name" required>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('last_name').valid && (personalDataForm.get('last_name').dirty|| personalDataForm.get('last_name').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="first_last_name">Segundo Apellido Legal</label>
                      <input type="text" class="form-control" formControlName="second_last_name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">

                    <div class="form-group">
                      <label>Fecha de Nacimiento</label>

                      <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker #d="ngbDatepicker"
                          formControlName="birthday_model">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <i-feather name="calendar"></i-feather>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="radios">Género</label>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="customRadioInline3" class="custom-control-input" value="Masculino"
                          formControlName="gender">
                        <label class="custom-control-label" for="customRadioInline3">Masculino</label>
                      </div>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="customRadioInline4" class="custom-control-input" value="Femenino"
                          formControlName="gender">
                        <label class="custom-control-label" for="customRadioInline4">Femenino</label>
                      </div>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('gender').valid && (personalDataForm.get('gender').dirty|| personalDataForm.get('gender').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="radios">Estado Civil</label>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioCivilStatus1" name="civil_status" class="custom-control-input"
                          value="Soltero" formControlName="civil_status">
                        <label class="custom-control-label" for="radioCivilStatus1">Soltero</label>
                      </div>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioCivilStatus2" name="civil_status" class="custom-control-input"
                          value="Casado" formControlName="civil_status">
                        <label class="custom-control-label" for="radioCivilStatus2">Casado</label>
                      </div>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('civil_status').valid && (personalDataForm.get('civil_status').dirty|| personalDataForm.get('civil_status').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-3"
                    *ngIf="personalDataForm.get('civil_status').value === 'Casado' &&  personalDataForm.get('gender').value === 'Femenino'">
                    <div class="form-group">
                      <label for="firstName2">Apellido de casada</label>
                      <input type="text" class="form-control" formControlName="married_name">
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Nacionalidad</label>
                      <input type="text" class="form-control" formControlName="nationality">
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('nationality').valid && (personalDataForm.get('nationality').dirty|| personalDataForm.get('nationality').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="firstName2">País de Nacimiento</label>
                      <input type="text" class="form-control" formControlName="birth_country">
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('birth_country').valid && (personalDataForm.get('birth_country').dirty|| personalDataForm.get('birth_country').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="firstName2">Dirección de Residencia</label>
                      <input type="address" class="form-control" name="address" formControlName="address" required>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('address').valid && (personalDataForm.get('address').dirty|| personalDataForm.get('address').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="radios">Tipo de Identificación</label>
                      <i class="ft-alert-circle warning ml-1"
                        ngbPopover="DPI/CUI para Nacionales y pasaporte para Internacionales." placement="right"
                        triggers="mouseenter:mouseleave" popoverTitle="Documento de Identificación"></i>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="readioIdentifier1" name="identifier_type" class="custom-control-input"
                          value="CUI/DPI" formControlName="identifier_type">
                        <label class="custom-control-label" for="readioIdentifier1">CUI/DPI</label>
                      </div>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="readioIdentifier2" name="identifier_type" class="custom-control-input"
                          value="Pasaporte" formControlName="identifier_type">
                        <label class="custom-control-label" for="readioIdentifier2">Pasaporte</label>
                      </div>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('identifier_type').valid && (personalDataForm.get('identifier_type').dirty|| personalDataForm.get('identifier_type').touched)">¡Este
                        campo es requerido!</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="identifier">No. Identificación</label>
                      <input type="text" class="form-control" name="identifier" formControlName="identifier"
                        minlength="5" required>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('identifier').valid && (personalDataForm.get('identifier').dirty|| personalDataForm.get('identifier').touched)">¡Este
                        campo es requerido!</small>
                      <small class="form-text text-muted danger"
                        *ngIf="personalDataForm.get('identifier').hasError('minlength')">¡Este
                        campo requiere al menos 5 carácteres!</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group" *ngIf="personalDataForm.get('identifier_type').value === 'Pasaporte'">
                      <label for="firstName2">Extendido En</label>
                      <input type="text" class="form-control" formControlName="extended">
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <div class="form-group ">
                      <label for="participants2 ">Fotografía del Documento</label>
                      <input type="file" class="form-control-file" (change)="onIdentifierImageChange($event)">
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <h6>Artísticos</h6>
            <fieldset>
              <div class="row">
                <div class="col-md-6">
                  <form [formGroup]="personalDataForm">
                    <div class="form-group">
                      <label>Fecha de Inscripción</label>
                      <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker #d2="ngbDatepicker"
                          formControlName="registration_date">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                            <i-feather name="calendar"></i-feather>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="proposalTitle2">Nombre Artístico</label>
                      <input type="text" class="form-control" formControlName="alias">
                    </div>
                    <div class="form-group">
                      <label for="radios">Tipo de Socio</label>
                      <i class="ft-alert-circle warning ml-1" [ngbPopover]="popSocios" triggers="mouseenter:mouseleave"
                        placement="right" popoverTitle="Tipo de socios" popoverClass="increase-popover-size"></i>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioPartnerType1" class="custom-control-input" value="Socio"
                          formControlName="regime">
                        <label class="custom-control-label" for="radioPartnerType1">Socio</label>
                      </div>
                      <div class="custom-control custom-radio display-block">
                        <input type="radio" id="radioPartnerType2" class="custom-control-input" value="Representado"
                          formControlName="regime">
                        <label class="custom-control-label" for="radioPartnerType2">Socio Representado</label>
                      </div>
                      <small class="form-text text-muted danger"
                        *ngIf="!personalDataForm.get('regime').valid && (personalDataForm.get('regime').dirty|| personalDataForm.get('regime').touched)">¡Este
                        campo es requerido!</small>
                    </div>

                  </form>
                </div>
                <div class="col-md-6">
                  <form [formGroup]="personalDataForm">
                    <div class="form-group">
                      <label for="shortDescription2">Biografia</label>
                      <textarea name="shortDescription" id="shortDescription2" rows="8" class="form-control"
                        formControlName="bio"></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </fieldset>
            <h6>Contacto</h6>
            <fieldset>
              <form [formGroup]="contactDataForm" class="row">

                <div formArrayName="electronic" class="col-md-6"
                  *ngFor="let item of contactDataForm['controls'].electronic['controls']; let i = index;">
                  <div class="form-group" [formGroupName]="i">
                    <label for="email">Correo Electrónico <small *ngIf="i > 0">(Opcional)</small></label>
                    <input type="email" class="form-control " name="email" formControlName="email">
                    <!-- <small class="form-text text-muted danger" *ngIf="!contactDataForm.get('first_email').valid && (contactDataForm.get('first_email').dirty|| contactDataForm.get('first_email').touched)">¡Este
                      campo es requerido!</small>
                    <small class="form-text text-muted danger" *ngIf="contactDataForm.get('first_email').hasError('email')">¡Debes
                      ingresar un correo electrónico valido!</small> -->
                  </div>
                </div>

                <div class="col-md-6" *ngIf="contactDataForm['controls'].electronic.length <= 1">
                  <div class="d-flex h-100">
                    <div class="row align-self-center w-100">
                      <div class="col-6 mx-auto text-center">
                        <i-feather name="plus-circle" style="cursor:pointer;" class="iconAdd"
                          (click)="addElectronicForm()"></i-feather>
                      </div>
                    </div>
                  </div>
                </div>

                <div formArrayName="voice" class="col-md-6"
                  *ngFor="let item of contactDataForm['controls'].voice['controls']; let i = index;">
                  <div class="form-group" [formGroupName]="i">
                    <label>Teléfono Movíl<small *ngIf="i > 0">(Opcional)</small></label>
                    <input type="number" class="form-control" formControlName="telephony">
                    <!-- <small class="form-text text-muted danger" *ngIf="!contactDataForm.get('voice').controls.get(i).valid && (contactDataForm.get('voice').controls.get(i).dirty|| contactDataForm.get('voice').controls.get(i).touched)">¡Este
                            campo es requerido!</small>
                          <small class="form-text text-muted danger" *ngIf="contactDataForm.get('voice').controls.get(i).hasError('minlength')">¡Este
                            campo requiere al menos 8 carácteres!</small> -->
                  </div>
                </div>


                <div class="col-md-6" *ngIf="contactDataForm['controls'].voice.length <= 1">
                  <div class="d-flex h-100">
                    <div class="row align-self-center w-100">
                      <div class="col-6 mx-auto text-center">
                        <i-feather name="plus-circle" style="cursor:pointer;" class="iconAdd" (click)="addVoiceForm()">
                        </i-feather>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
              <hr>
              <form [formGroup]="formRegister">
                <div class="row">
                  <div class="col-6" formArrayName="social"
                    *ngFor="let social_network of formRegister['controls'].social['controls']; let i = index;">
                    <div [formGroupName]="i">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">

                            <i-feather name="facebook" *ngIf="social_network.value.product === 'facebook'"></i-feather>
                            <i-feather name="twitter" *ngIf="social_network.value.product === 'instagram'"></i-feather>
                            <i-feather name="linkedin" *ngIf="social_network.value.product === 'twitter'"></i-feather>
                            <i-feather name="instagram" *ngIf="social_network.value.product === 'linkedin'"></i-feather>
                          </span>

                        </div>
                        <input type="text" class="form-control" formControlName="user"
                          (keyup)="social_network.value.link = 'https://www.'+social_network.value.product+'.com/'+social_network.value.user">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <h6>Bancarios</h6>
            <fieldset>
              <form [formGroup]="bankingDataForm">
                <div class="row">
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="eventName2 ">Entidad Bancaria</label>
                      <input type="text " class="form-control " name="eventName2" formControlName="bank">
                    </div>
                    <div class="form-group ">
                      <label for="eventType2 ">Tipo de Cuenta</label>
                      <select class="custom-select form-control " data-placeholder=" " formControlName="type">
                        <option value="monetaria">Monetaria</option>
                        <option value="ahorro">Ahorro</option>
                      </select>
                    </div>
                    <div class="form-group ">
                      <label>Tipo de Moneda</label>
                      <select class="custom-select form-control " data-placeholder=" " formControlName="coin">
                        <option value="gtq">Quetzales</option>
                        <option value="usd">Dólares</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="eventName2 ">Nombre de Cuentahabiente</label>
                      <input type="text " class="form-control" formControlName="full_name">
                    </div>
                    <div class="form-group ">
                      <label for="eventName2 ">Número de Cuenta</label>
                      <input type="text" class="form-control " formControlName="account">
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
            <h6>Tributarios</h6>
            <fieldset>
              <form [formGroup]="legalDataForm" *ngIf="hasNit">
                <div class="row ">
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="meetingName2 ">Número de Identificación Tributaria</label>
                      <input type="text" class="form-control" formControlName="nit">
                    </div>
                    <div class="form-group ">
                      <label for="meetingLocation2 ">Nombre Fiscal</label>
                      <input type="text" class="form-control" formControlName="full_name">
                    </div>
                    <!--<div class="form-group ">
                      <label for="participants2 ">Régimen Tributario</label>
                      <select class="custom-select form-control" data-placeholder=" " formControlName="facturing">
                        <option value="pequeno_contribuyente" selected>Pequeño contribuyente</option>
                        <option value="retencion_definitiva">Retención definitiva</option>
                        <option value="pagos_trimestrale">Pagos Trimestrales</option>
                      </select>
                    </div>-->
                  </div>
                  <div class="col-md-6 ">
                    <div class="form-group ">
                      <label for="participants2 ">Dirección Fiscal</label>
                      <input type="text" class="form-control" name="address" formControlName="fiscal_address">
                    </div>
                    <div class="col-md-6 ">
                      <div class="form-group ">
                        <label>Registro Tributario Unificado (RTU)</label>
                        <input type="file" class="form-control-file">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row justify-content-center" *ngIf="!hasNit">
                <div class="form-group">
                  <label for="radios">¿Tienes Número de Identificación Tributaria (NIT)?</label>
                  <div class="custom-control custom-radio display-block">
                    <input type="radio" id="radioHasNit1" name="hast_nit" class="custom-control-input" value="Si"
                      (change)="hasNit = true">
                    <label class="custom-control-label" for="radioHasNit1">Si</label>
                  </div>
                </div>
              </div>
            </fieldset>

            <h6>Beneficiarios</h6>
            <fieldset>
              <form [formGroup]="formRegister">
                <ngb-tabset (tabChange)="beforeChange($event)">
                  <ngb-tab title="Beneficiario #{{i+1}}" formArrayName="beneficiary"
                    *ngFor="let beneficiary of formRegister['controls'].beneficiary['controls']; let i = index;">
                    <ng-template ngbTabContent>
                      <div [formGroupName]="i">
                        <div class="actions float-right">
                          <i *ngIf="i >= 1" class="ft-trash-2 danger" style="cursor: pointer;"
                            (click)="removeBeneficiary()"></i>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-3 col-lg-3">
                            <div class="form-group ">
                              <label for="eventType2 ">Parentesco</label>
                              <select class="custom-select form-control" data-placeholder=""
                                formControlName="relationship">
                                <option value="padre/madre">Padre/Madre</option>
                                <option value="hijo/a">Hijo(a)</option>
                                <option value="conyuge">Cónyuge</option>
                                <option value="abuelo/a">Abuelo(a)</option>
                                <option value="nieto/a">Nieto(a)</option>
                                <option value="hermano/a">Hermano(a)</option>
                                <option value="otro">Otro</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Porcentaje</label>
                              <input type="number" class="form-control" formControlName="percentage">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Primer Nombre</label>
                              <input type="text " class="form-control" formControlName="first_name">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Segundo Nombre</label>
                              <input type="text " class="form-control" formControlName="middle_name">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Primer Apellido</label>
                              <input type="text " class="form-control" formControlName="last_name">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">Segundo Apellido</label>
                              <input type="text " class="form-control" formControlName="second_last_name">
                            </div>
                          </div>
                        </div>
                        <div class="row ">
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label>Fecha de Nacimiento</label>
                              <!-- <input type="date" class="form-control" formControlName="birthday" placeholder="yyyy-mm-dd"
                                required> -->
                              <div class="input-group">
                                <input class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker #d="ngbDatepicker"
                                  formControlName="birthday">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                    <i-feather name="calendar"></i-feather>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for="radios ">Género</label>
                              <div class="custom-control custom-radio display-block ">
                                <input type="radio" id="radioGenderBeneficiary1" class="custom-control-input"
                                  value="masculino" formControlName="gender">
                                <label class="custom-control-label" for="radioGenderBeneficiary1">Masculino</label>
                              </div>
                              <div class="custom-control custom-radio display-block ">
                                <input type="radio" id="radioGenderBeneficiary2" class="custom-control-input"
                                  value="femenino" formControlName="gender">
                                <label class="custom-control-label" for="radioGenderBeneficiary2">Femenino</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for="firstName2">Nacionalidad</label>
                              <input type="text" class="form-control" formControlName="birth_place">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-3">
                            <div class="form-group ">
                              <label for=" ">CUI/DPI/PASAPORTE</label>
                              <input type="text" class="form-control" formControlName="identifier">
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-tab>

                  <ngb-tab id="bar" title="Agregar Beneficiario">
                    <ng-template ngbTabContent>
                      <p>Agregar Beneficiario</p>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>

              </form>

              <form action="#" id="formRegister" (ngSubmit)="submitRegisterForm()">
                <button id="btnFormRegister" type="submit" style="display: none;"></button>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #popSocios>
  <b>Socios Representados:</b>
  <small> Aquellos titulares de obras que quieren tener vínculos económicos con la entidad más no vínculos de carácter
    asociativo,
    ni desean derechos políticos.</small>
  <br>
  <b>Socios:</b>
  <small>Aquellos titulares de derechos de obra que quieren tener vínculos económicos con la entidad, y también desean
    tener vínculos
    asociativos, y ostentar derechos políticos.</small>
</ng-template>