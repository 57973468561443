import { Component, OnInit, ViewEncapsulation, ApplicationRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

import { pad } from 'app/shared/consts';
import { NGXToastrService } from 'app/services/toastr.service';
import { AdminService } from 'app/services/admin.service';
import { CreateArtistComponent } from 'app/create-artist/create-artist.component';
import { CreateDiscographyComponent } from 'app/create-discography/create-discography.component';
import { RouteDataService } from 'app/services/route-data.service';
import { CreateRecordCompanyComponent } from 'app/create-record-company/create-record-company.component';

@Component({
    selector: 'app-create-work',
    templateUrl: './create-work.component.html',
    styleUrls: ['./create-work.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateWorkComponent implements OnInit {

    current_user: any;
    works_saved = 0;

    assign_method: any = 'auto';
    songForm: FormGroup;

    work_duration: NgbTimeStruct;
    distribution_collapse = true;
    currentWorkAgreementPercentage = 0;
    searchArtistForAgreement: string = "";
    editing = {};
    saving_work_message = "";

    artists = null;
    selected_artist = null;
    artist_discographies = null;
    record_companies = null;
    enrolled_partners = [];
    partners_agreement = [];

    roles = [];
    instruments = [];
    societies = [];
    loading_discographies: boolean = false;


    constructor(
        private route_data_service: RouteDataService,
        public activeModal: NgbActiveModal,
        private ngbModalService: NgbModal,
        private formBuilder: FormBuilder,
        private admin_service: AdminService,
        private toastr: NGXToastrService,
    ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;

        this.loadArtists();
        this.loadUtils();

        this.songForm = this.formBuilder.group({
            recordered: this.formBuilder.group({
                code_band: new FormControl(),
                code_disc: new FormControl(),
                name: new FormControl('', [Validators.required]),
                publication: new FormControl('', [Validators.required]),
                gender: new FormControl(),
                duration: new FormControl({ hour: 0, minute: 0, second: 0 }, [Validators.required]),
                record_company: new FormControl('', [Validators.required]),
                assignment_method: new FormControl(this.assign_method, [Validators.required])
            }),
            distribution: this.formBuilder.group({
                isrc: new FormControl('GT-MGT-' + moment().locale("es").format("YY") + "-" + (this.works_saved + 1), [Validators.required]),
                iswc: new FormControl(),
                bmat: new FormControl(),
            }),
            aggrement: new FormArray([])

        });

        // setTimeout(() => {
        //     this.fillWorkTemp();
        // }, 3000);

        this.songForm.get('aggrement').valueChanges.subscribe(() => {
            //  this.handleAssignMethod();
        });
    }


    loadUtils() {
        this.loadRecordCompanies();
        this.admin_service.getArtistsNameList(this.current_user.usuario).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                this.enrolled_partners = data.body;
            }
        });
        this.admin_service.getRoles({ user: this.current_user.usuario }).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                //  console.log(data);
                this.roles = data.body;
            }
        })
        this.admin_service.getInstruments({ user: this.current_user.usuario }).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                // console.log(data);
                this.instruments = data.body;
            }
        })
        /* this.admin_service.getSocieties({ user: this.current_user.usuario }).subscribe((data: any) => {
             if (data && data.header && data.header.type && data.header.type == "success") {
                 // console.log(data);
                 this.societies = data.body;
             }
         })*/
    }

    //#region ARTIST
    loadArtists() {
        this.artists = null;
        this.admin_service.getBandsList({ user: this.current_user.usuario }).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                //console.log("\n ARTISTS", data);
                this.artists = data.body.bandas;
            } else {
                this.toastr.toast_error("No se encontraron artistas.");
                this.artist_discographies = [];
            }
        });
    }
    openCreateArtist() {
        this.ngbModalService.open(CreateArtistComponent, { size: 'lg', windowClass: 'hugeModal' }).result.catch((err) => {
            console.log(err);
        }).then((result) => {
            console.log(result);
            if (result === "Ok") {
                this.loadArtists();
            }
        });
    }

    //#endregion 

    //#region DISCOGRAPHY
    openCreateDiscography() {
        console.log("crear discografia de: ", this.selected_artist);
        const modalRef = this.ngbModalService.open(CreateDiscographyComponent, { size: 'lg', windowClass: 'hugeModal' });
        modalRef.result.catch((err) => {
            console.log(err);
        }).then((result) => {
            console.log(result);
            if (result === "Ok") {
                this.loadArtists();
            }
        });
        modalRef.componentInstance.discographies = this.artist_discographies;
        modalRef.componentInstance.idArtist = this.selected_artist;
    }
    getArtistDiscography(idArtist: string) {
        this.selected_artist = idArtist;
        this.loading_discographies = true;
        if (idArtist) {
            this.artist_discographies = null;
            this.admin_service.getArtistDiscographies({ user: this.current_user.usuario, id: idArtist }).subscribe((data: any) => {
                //console.log(data);
                this.loading_discographies = false;
                if (data && data.header && data.header.type && data.header.type == "success") {
                    this.artist_discographies = data.body;
                } else {
                    //console.log("No se recupero discografia");
                    this.toastr.toast_error("Artista no cuenta con Discografias.");
                    this.artist_discographies = [];
                }

            });
        }
    }
    //#endregion

    //#region AGREEMENT

    addAgreement(person: any): void {
        this.searchArtistForAgreement = null;
        if (person != "") {
            (this.songForm.controls['aggrement'] as FormArray).push(this.formBuilder.group({
                key: (((this.songForm.controls['aggrement'] as FormArray).length - 1) + 1).toString(),
                society: new FormControl(person.nombre_sociedad ? person.nombre_sociedad : 'Independiente', [Validators.required]),
                code_artist: new FormControl(person.codigo ? person.codigo.toString() : ''),
                name: new FormControl(person.nombre_completo ? person.nombre_completo.toString() : person, [Validators.required]),
                alias: new FormControl(person.alias ? person.alias : ''),
                code_rol: new FormControl('', [Validators.required]),
                code_instrument: new FormControl('', [Validators.required]),

                percent_origin: new FormControl(0, [Validators.required]),
                percent_origin_fixed: new FormControl(0),

                percent: new FormControl(0, [Validators.required]),
                percent_fixed: new FormControl(0),

            }));

        }
    }

    calculateAgreementPercentage() {
        this.currentWorkAgreementPercentage = 0;
        this.songForm.get('aggrement').value.forEach(element => {
            // console.log(this.currentWorkAgreementPercentage, element.percent);
            this.currentWorkAgreementPercentage += Number(element.percent_origin);
        });

        this.currentWorkAgreementPercentage = Number(this.currentWorkAgreementPercentage.toFixed(2))
    }
    removeAgreementFromList(agreement, agreement_index: any) {
        // let artist_index = this.songForm.controls.aggrement.value.findIndex((artist, index) => index == agreement_index);
        // console.log(artist_index);

        (this.songForm.controls['aggrement'] as FormArray).removeAt(agreement.key);
        this.handleAssignMethod();
    }

    //#endregion

    //#region RECORD_COMPANY
    openCreateRecordCompany() {
        this.ngbModalService.open(CreateRecordCompanyComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            centered: true,
        }).result.catch((err) => {
            console.log(err);
        }).then((result) => {
            console.log(result);
            if (result == "Ok") {
                console.log("reload record companies");
                this.loadRecordCompanies();
            }
        });
    }
    loadRecordCompanies() {
        this.record_companies = null;
        this.admin_service.getRecordCompanies({ user: this.current_user.usuario }).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                this.record_companies = data.body;
            } else {
                this.toastr.toast_error("No se encontraron disqueras.");
                this.record_companies = [];
            }
        });
    }
    //#endregion

    saveWork() {
        this.saving_work_message = "";
        if (this.songForm.dirty) {

            if (this.songForm.valid) {
                let work_to_save: any = {
                    user: this.current_user.usuario,
                    recordered: {},
                    distribution: this.songForm.controls.distribution.value,
                    aggrement: this.songForm.controls.aggrement.value,
                };

                Object.assign(work_to_save.recordered, this.songForm.controls.recordered.value);

                console.log(work_to_save);

                work_to_save.recordered.duration = pad(work_to_save.recordered.duration.hour) + ":" + pad(work_to_save.recordered.duration.minute) + ":" + pad(work_to_save.recordered.duration.second);

                if (work_to_save.recordered.duration != '00:00:00' || work_to_save.agreement.duration != 'undefined:undefined:undefined') {
                    if (work_to_save.aggrement.length > 0) {

                        console.log("valid");
                        this.admin_service.saveSong(work_to_save).subscribe((data: any) => {
                            console.log(data);
                            if (data && data.header && data.header.type) {
                                if (data.header.type == "success") {
                                    this.activeModal.close('Ok');
                                    this.toastr.dataSavedSuccess();
                                } else {
                                    this.toastr.typeErrorText(data.header.msg);
                                }
                            } else {
                                this.toastr.typeErrorText("");
                            }

                        });
                    } else {
                        this.toastr.toast_error("Debe asignarse almenos un convenio");
                    }
                } else {
                    this.toastr.toast_error("La duración de la obra no es valida");
                }
            } else {
                this.toastr.toast_error("El formulario no es valido.");
            }
        } else {
            this.toastr.toast_error("El formulario no esta completo");
        }
    }

    fillWorkTemp() {
        // this.songForm.controls.recordered['controls'].code_band.setValue("MABC910QB37");
        // this.songForm.controls.recordered['controls'].code_disc.setValue("DRMCKIK01W3");
        // this.songForm.controls.recordered['controls'].name.setValue("Obra 1");
        // this.songForm.controls.recordered['controls'].publication.setValue("2018");
        // this.songForm.controls.recordered['controls'].duration.setValue({ hour: 0, minute: 4, second: 25 });
        // this.songForm.controls.recordered['controls'].gender.setValue("indie");
        // this.songForm.controls.recordered['controls'].record_company.setValue("MRCXRSBYLRS");
        // this.songForm.controls.distribution['controls'].isrc.setValue('GT-MGT-' + moment().locale("es").format("YY") + "-" + (this.works_saved + 1), [Validators.required]);


        let aggrement = [
            {
                "key": "0",
                "society": "Musicartes",
                "code_artist": "MGTYBFVT",
                "name": "Magda Angélica García Von Hoegen",
                "alias": "Magda Angélica",
                "code_rol": "1",
                "code_instrument": "",
                "percent_origin": 60,
                "percent_origin_fixed": 60,
                "percent": 78.57142857142857,
                "percent_fixed": "78.57"
            },
            {
                "key": "1",
                "society": "Independiente",
                "code_artist": "MGT5AQI2GVM",
                "name": "Fernando Scheel",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "2",
                "society": "Independiente",
                "code_artist": "MGT021P33UO",
                "name": "Lenin Fernandez",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "3",
                "society": "Independiente",
                "code_artist": "MGTSY4HQX4L",
                "name": "Ton Toy",
                "alias": "(brasileÑo)",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "4",
                "society": "Independiente",
                "code_artist": "MGT6GGLDSG",
                "name": "Lester Godinez",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "5",
                "society": "Independiente",
                "code_artist": "MGTXWCTOP5B",
                "name": "Manuel Garcia Ovalle",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "6",
                "society": "Musicartes",
                "code_artist": "MGTLV73L",
                "name": "Miguel Angel Villagran Galindo",
                "alias": "Malin",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 21.428571428571427,
                "percent_fixed": "21.43"
            },
            {
                "key": "7",
                "society": "Independiente",
                "code_artist": "MGTS3VOKG9N",
                "name": "Patrice Fischer",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "8",
                "society": "Independiente",
                "code_artist": "MGTF1OYEW14",
                "name": "Benjamin Carazo",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "9",
                "society": "Independiente",
                "code_artist": "MGTTZ5K3HTB",
                "name": "Paulo Alvarado",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "10",
                "society": "Independiente",
                "code_artist": "MGTF8L0YG67",
                "name": "Adriana Valdez",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "11",
                "society": "Independiente",
                "code_artist": "MGT9F2SEEHV",
                "name": "Luisa Cancinos",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "12",
                "society": "Independiente",
                "code_artist": "MGTTK2FK9DS",
                "name": "Luis Valdez",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "13",
                "society": "Independiente",
                "code_artist": "MGTL15BUZ3A",
                "name": "Luis Cacacho",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            },
            {
                "key": "14",
                "society": "Independiente",
                "code_artist": "MGTVGC47E3Y",
                "name": "Armando Mazariegos Fernandez",
                "alias": "",
                "code_rol": "2",
                "code_instrument": "",
                "percent_origin": 2.857142857142857,
                "percent_origin_fixed": 2.86,
                "percent": 0,
                "percent_fixed": 0
            }
        ]
        aggrement.forEach(aggre => {
            this.addAgreement({
                nombre_sociedad: aggre.society,
                codigo: aggre.code_artist,
                nombre_completo: aggre.name,
                alias: aggre.alias,
                code_rol: aggre.code_rol,
                code_instrument: aggre.code_instrument,
                percent: aggre.percent,
                percent_origin: aggre.percent_origin,
            });
        })
        // for (let i = 0; i < 5; i++) {
        //     this.addAgreement({
        //         tipo: 'Socio',
        //         codigo: 'MGTSBV3NLST',
        //         nombre_completo: 'Usuario Prueba' + i,
        //         alias: 'Up',
        //         code_rol: "1",
        //         code_instrument: '3',
        //         percent: 0,
        //         percent_origin: 0,
        //     });
        // }
    }

    handleAssignMethod() {
        // console.log(this.assign_method);
        if (this.assign_method === 'auto') {
            this.assignAgreements();
        } else {
            this.calculateAgreementPercentage();
            this.assignDistribution();
        }
    }

    assignAgreements() {
        let rule_for_independents = 6;
        let percent_from_performers_remain = 0;
        let apply_rule_for_independents = false;

        if ((this.songForm.controls['aggrement'] as FormArray).length > 0) {
            this.currentWorkAgreementPercentage = 0;

            let interpreters_percent = 0;
            let performers_percent = 0;

            let interpreters = 0;
            let performers = 0;

            this.songForm.get('aggrement').value.forEach(partner => {
                if (partner.code_rol === '1') {
                    interpreters++;
                } else if (partner.code_rol === '2' || partner.code_rol === '3' || partner.code_rol === '4') {
                    performers++;
                } else {
                    return;
                }
            });


            if (performers == 0) {
                interpreters_percent = 100;
                performers_percent = 0;
            } else if (performers > 0) {

                if (performers < rule_for_independents) {
                    apply_rule_for_independents = true;
                    performers_percent = 40 / rule_for_independents;
                    percent_from_performers_remain = 40 - (performers_percent * performers);
                    interpreters_percent = 60 + percent_from_performers_remain;
                    //console.log("apply rule of six", performers_percent, "remain", percent_from_performers_remain);
                } else {
                    interpreters_percent = 60;
                    performers_percent = 40;
                }
            } else {
                return;
            }


            // console.log("Integrantes", interpreters_percent, performers_percent);

            let interpreters_percentages = [interpreters];
            let arr = [performers]


            for (let i = 0; i < interpreters; i++) {
                interpreters_percentages[i] = Number((interpreters_percent / interpreters));
            }

            for (let i = 0; i < performers; i++) {
                if (performers < rule_for_independents) {
                    arr[i] = performers_percent;
                } else {
                    arr[i] = Number((performers_percent / performers));
                }
            }

            let interpreters_percent_assigned_index = 0;
            let performer_percent_assigned_index = 0;

            this.songForm.get('aggrement').value.forEach((partner, index) => {
                if (partner.code_rol === '1') {
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_origin').setValue(interpreters_percentages[interpreters_percent_assigned_index]);
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_origin_fixed').setValue(Number(interpreters_percentages[interpreters_percent_assigned_index].toFixed(2)));

                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent').setValue(interpreters_percentages[interpreters_percent_assigned_index]);
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_fixed').setValue(Number(interpreters_percentages[interpreters_percent_assigned_index].toFixed(2)));

                    //   partner.percent_origin = partner.percent = interpreters_percentages[interpreters_percent_assigned_index];
                    if (partner.society === 'Independiente') {
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent').setValue(0);
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent_fixed').setValue(0);
                        //partner.percent = 0;
                    }
                    interpreters_percent_assigned_index++;
                } else if (partner.code_rol === '2' || partner.code_rol === '3' || partner.code_rol === '4') {
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_origin').setValue(arr[performer_percent_assigned_index]);
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_origin_fixed').setValue(Number(arr[performer_percent_assigned_index].toFixed(2)));

                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent').setValue(arr[performer_percent_assigned_index]);
                    (this.songForm.get('aggrement') as FormArray).at(index).get('percent_fixed').setValue(Number(arr[performer_percent_assigned_index].toFixed(2)));

                    // partner.percent_origin = partner.percent = arr[performer_percent_assigned_index];
                    if (partner.society === 'Independiente') {
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent').setValue(0);
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent_fixed').setValue(0);
                        // partner.percent = 0;
                    }
                    performer_percent_assigned_index++;
                } else {
                    return;
                }
            });

            this.calculateAgreementPercentage();
            this.assignDistribution();
            console.log("\n");
        } else {
            return;
        }
    }
    assignDistribution() {
        if ((this.songForm.controls['aggrement'] as FormArray).length > 0) {

            let remain_percentage = 0;
            let valid_partners = [];
            this.songForm.get('aggrement').value.forEach((partner, index) => {
                if (partner.society == 'Independiente') {
                    //  console.log("asignando: ", partner.percent_origin);
                    remain_percentage += Number(partner.percent_origin);
                } else {
                    valid_partners.push(index);
                }
            });

            // console.log("valid", valid_partners);
            //console.log("Residuo", remain_percentage);
            let percent_to_assign = remain_percentage / valid_partners.length;

            //console.log("Distribuir: ", percent_to_assign, "en", valid_partners.length);

            this.songForm.get('aggrement').value.forEach((partner, index) => {
                valid_partners.forEach(valid_partner_index => {
                    if (index === valid_partner_index) {
                        // partner.percent = Number(partner.percent_origin) + percent_to_assign;
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent').setValue(Number(partner.percent_origin) + percent_to_assign);
                        (this.songForm.get('aggrement') as FormArray).at(index).get('percent_fixed').setValue(Number(Number(partner.percent_origin) + percent_to_assign).toFixed(2));
                    }
                    return;
                });
            });

        } else { return; }

    }
    formatNumber(data) {
        console.log(data);
        if (Number(data) > 0) {
            return Number(data.toFixed(2))
        } else {
            return 0;
        }
    }
}
