import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
// import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../services/admin.service';
import { CreateWorkComponent } from 'app/create-work/create-work.component';
import { RouteDataService } from 'app/services/route-data.service';
import { ViewWorkCardComponent } from 'app/view-work-card/view-work-card.component';

import { Page } from 'app/shared/models/page';
import { PagedData } from 'app/shared/models/paged-data';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-works',
    templateUrl: './view-works.component.html',
    styleUrls: ['./view-works.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ViewWorksComponent implements OnInit {

    current_user: any;

    main_list = [];
    main_list_filtered = [];

    page = new Page();
    cache: any = {};

    constructor(
        private route_data_service: RouteDataService,
        private admin_service: AdminService,
        private ngbModalService: NgbModal) {
    }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.getSongs();
    }

    getSongs() {
        this.main_list = this.main_list_filtered = [];
        this.admin_service.getSongList({ user: this.current_user.usuario }).subscribe((data: any) => {
            if (data && data.header && data.header.type && data.header.type == "success") {
                console.log("loaded");
                this.main_list = this.main_list_filtered = data.body;

                // this.setPage({ offset: 0, pageSize: this.main_list.length  });
            } else {

            }
        });
    }

    getResults(page: Page): Observable<PagedData> {
        return of(this.main_list).pipe(map(data => this.getSongsPaged(page)));
    }

    getSongsPaged(page: Page) {
        const pagedData = new PagedData();

        page.totalElements = this.main_list.length;
        page.totalPages = Math.ceil(page.totalElements / page.size);

        console.log(page);
        console.log(page.pageNumber, page.size);
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        console.log(start, end);

        for (let i = start; i < end; i++) {
            pagedData.data.push(this.main_list[i]);
        }

        pagedData.page = page;
        //console.log(pagedData);
        return pagedData;
    }

    getPage(page: Page) { }

    OpenRegisterWork() {
        const create_work_modal = this.ngbModalService.open(CreateWorkComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            centered: false,
            windowClass: 'WorkModal'
        });
        create_work_modal.result.then((result) => {
            console.log("Resultado create_work_modal:", result);
            if (result === "Ok") {
                this.getSongs();
            }
        }, (reason) => {
            console.log(reason);
        });

        create_work_modal.componentInstance.works_saved = this.main_list.length;

    }

    openWorkDetail(workID: any) {
        let modalRef = this.ngbModalService.open(ViewWorkCardComponent, {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            centered: false,
            windowClass: 'WorkModal'
        });
        modalRef.componentInstance.work_id = workID;
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.main_list.filter(function (d) {
            return d.obra_codigo.toLowerCase().indexOf(val) !== -1 ||
                d.banda_nombre.toLowerCase().indexOf(val) !== -1 ||
                d.obra_nombre.toLowerCase().indexOf(val) !== -1 ||
                d.obra_publicacion.toLowerCase().indexOf(val) !== -1 ||
                d.obra_publicacion.toLowerCase().indexOf(val) !== -1 ||
                d.obra_isrc.toLowerCase().indexOf(val) !== -1 ||
                d.obra_iswc.toLowerCase().indexOf(val) !== -1 ||
                d.obra_bmat.toLowerCase().indexOf(val) !== -1 ||
                d.fecha.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.main_list_filtered = temp;
    }



    setPage(pageInfo) {

        console.log("PAGE INFO: ", pageInfo);

        this.page.pageNumber = pageInfo.offset;
        this.page.size = 10;

        console.log("PAGE NUMBER: ", pageInfo.offset);

        this.getResults(this.page).subscribe(PagedData => {

            console.log(PagedData);

            this.page = PagedData.page;

            const start = this.page.pageNumber * this.page.size;

            const rows = [...this.main_list_filtered];

            rows.splice(start, 0, ...PagedData.data);

            this.main_list_filtered = rows;

            this.cache[this.page.pageNumber] = true;
        })

    }
}
