<section id="bands">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Artistas</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="px-3 mb-3 mt-3">
            <div class="row">
              <div class="col-6">
                <input type='text' class="form-control" style='padding:8px;' placeholder='buscar'
                  (keyup)='updateFilter($event)' />
              </div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-raised btn-primary float-right mr-2" (click)="openCreateArtist()">
                  Crear Artista
                </button>
                <button type="button" class="btn btn-raised btn-primary float-right mr-2" (click)="getBandsList()">
                  Actualizar Lista
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ngx-datatable #table class="bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                  [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10">
                  <ngx-datatable-column prop="codigo" name="ID"></ngx-datatable-column>
                  <ngx-datatable-column prop="nombre" name="Nombre"></ngx-datatable-column>
                  <ngx-datatable-column prop="fundacion" name="Fundación"></ngx-datatable-column>
                  <ngx-datatable-column name="Acción">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <div class="fonticon-container ">
                        <div class="fonticon-wrap">
                          <i-feather name="disc" title="Ver Discografía" class="success" style="cursor:pointer;"
                            (click)="openDiscography(row);"></i-feather>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>

<ng-template #modalRegisterArtist let-c=" close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Registro de Artista</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="createArtistForm">
    <!--<pre>{{createArtistForm.value | json}}</pre>-->
    <aw-wizard #wizard2 class="custom-wizard" [navBarLayout]="'large-empty-symbols'" [navigationMode]="'free'">
      <aw-wizard-step [stepTitle]="'Artista'" [navigationSymbol]="{ symbol: '1' }">
        <form class="form" [formGroup]="createArtistForm">
          <div class="form-body" formGroupName="artist">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Nombre</label>
                  <input type="text" class="form-control" formControlName="name">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Año de Fundación</label>
                  <input type="number" class="form-control" formControlName="fundation">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <textarea cols="30" rows="10" class="form-control" formControlName="bio"></textarea>
              </div>
            </div>
          </div>
        </form>
      </aw-wizard-step>
      <aw-wizard-step [stepTitle]="'Discografía'" [navigationSymbol]="{ symbol: '2' }">
        <button (click)="addDiscography()">Añadir Discografía</button>
        <form class="form" [formGroup]="createArtistForm">
          <div class="form-body">
            <div formArrayName="disc"
              *ngFor="let disc of createArtistForm['controls'].disc['controls']; let i = index;">
              <div [formGroupName]="i">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Nombre</label>
                      <input type="text" class="form-control" formControlName="name">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Año Publicación</label>
                      <input type="number" class="form-control" formControlName="publication">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Etiqueta</label>
                      <select formControlName="label" class="form-control">
                        <option value="albúm">Albúm</option>
                        <option value="single">Single</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </aw-wizard-step>
    </aw-wizard>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="c('Ok')">
      Crear Artista
    </button>
  </div>
</ng-template>