import { Component, OnInit, Input } from "@angular/core";
import { AdminService } from "app/services/admin.service";
import { PersonService } from "app/services/person.service";

import * as alertFunctions from "app/shared/data/sweet-alerts";
import { NGXToastrService } from "app/services/toastr.service";

import * as _ from "lodash";

@Component({
  selector: "app-view-monitoring-saved",
  templateUrl: "./view-monitoring-saved.component.html",
  styleUrls: ["./view-monitoring-saved.component.scss"],
})
export class ViewMonitoringSavedComponent implements OnInit {
  @Input() distributions: any;
  @Input() distributionsWallets: any;

  filteredDistributionsWallets = [];
  selectedDistribution = 0;
  selectedDistributionWallet = 0;

  currentUserSession: any;

  saved_monitoring = [];

  constructor(
    private admin_service: AdminService,
    private person_service: PersonService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    console.log("distributions 1", this.distributions);

    this.person_service.getSessionPerson().subscribe((data: any) => {
      this.currentUserSession = data;
      this.getCurrentMonitoring();
    });
  }

  current_monitoring_channels: any;
  current_monitoring_years: any;
  saved_monitoring_filtered = null;

  getCurrentMonitoring() {
    this.saved_monitoring = this.saved_monitoring_filtered = null;
    // this.admin_service
    //   .getMonitoring({
    //     user: this.currentUserSession.usuario,
    //     token: this.currentUserSession.token,
    //   })
    //   .subscribe((data: any) => {
    //     if (
    //       data &&
    //       data.resultado.header &&
    //       data.resultado.header.type &&
    //       data.resultado.header.type == "success"
    //     ) {
    //       this.current_monitoring_channels = data.resultado.body.channel;
    //       this.current_monitoring_years = data.resultado.body.years;
    //       this.saved_monitoring = this.saved_monitoring_filtered =
    //         data.resultado.body.data;
    //     } else {
    //       this.saved_monitoring_filtered = [];
    //       this.toastr.toast_warning("No se encontraron monitoreos.");
    //     }
    //   });

    this.admin_service
      .getMonitoringData({
        data: {
          user: this.currentUserSession.usuario,
          distribution: Number(this.selectedDistribution),
          wallet: Number(this.selectedDistributionWallet),
          token: null,
        },
      })
      .subscribe((data: any) => {
        console.log("monitoring saved", data);

        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          //this.current_monitoring_channels = data.resultado.body.channel;
          //this.current_monitoring_years = data.resultado.body.years;
          this.saved_monitoring = this.saved_monitoring_filtered =
            data.response.data;
          this.current_monitoring_channels = _.groupBy(
            this.saved_monitoring,
            "channel_code"
          );

          this.current_monitoring_channels = Object.keys(
            this.current_monitoring_channels
          ).map((key, index) => {
            return {
              channel_code: key,
              channel_name:
                this.current_monitoring_channels[key][0].channel_name,
            };
          });
          this.filterSavedMonitoring();
        }
      });
  }

  typed_search: any = "";
  selected_year: any = "";
  selected_channel: any = "";
  selected_state: any = "";

  table_offset: any = 0;
  savedMonitoringFilter(event) {
    const val = event.target.value.toLowerCase();
    this.typed_search = val;
    this.filterSavedMonitoring();
  }

  changeSavedMonitoringChannel(event: any) {
    const val = event.target.value.toLowerCase();
    this.selected_channel = val;
    this.filterSavedMonitoring();
  }

  changeSavedMonitoringYear(event: any) {
    const val = event.target.value.toLowerCase();
    this.selected_year = val;
    this.filterSavedMonitoring();
  }
  changeSavedMonitoringState(event: any) {
    const val = event.target.value.toLowerCase();
    this.selected_state = val;
    this.filterSavedMonitoring();
  }

  filterSavedMonitoring() {
    // console.log(this.selected_channel, this.selected_year, this.typed_search);
    let that = this;
    const temp = this.saved_monitoring.filter(function (d) {
      return (
        // d.year.toLowerCase().indexOf(that.selected_year.toString()) !== -1 &&
        d.channel_code.toLowerCase().indexOf(that.selected_channel) !== -1 &&
        d.artist_code.toLowerCase().indexOf(that.selected_state) !== -1 &&
        (d?.song_isrc?.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d?.artist_code?.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d?.song_name?.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d?.artist_name?.toLowerCase().indexOf(that.typed_search) !== -1)
      );
    });
    this.saved_monitoring_filtered = temp;
  }

  getRowClass(row) {
    //console.log("getRowClass", row);
    return {
      danger: row.artist_code === "NO_REGISTRADO",
    };
  }
  setPage(pageInfo) {
    console.log(pageInfo);

    this.table_offset = pageInfo.offset;

    const start = this.table_offset * 10;
    console.log(start);

    const data = this.saved_monitoring.slice(start, 10);

    console.log(data);

    const rows = [...this.saved_monitoring_filtered];

    rows.splice(start, 0, ...data);

    this.saved_monitoring_filtered = rows;
  }

  updateMonitoringItem(item: any, index) {
    console.log(item);

    this.saved_monitoring_filtered.forEach((element) => {
      if (element.edited) {
        item = element;
        let data_to_update = {
          data: {
            user: this.currentUserSession.usuario,
            code_channel: item.channel_code,
            name_channel: item.channel_name,
            date_start: "",
            date_end: "",
            applicate_year: item.year,
            info: [
              {
                correlative: item.correlative,
                title: item.song_name,
                isrc: item.song_isrc,
                artist: item.artist_name,
                hits: item.hits,
                publisher: "",
                group: "",
              },
            ],
          },
        };
        console.log(data_to_update);

        this.admin_service
          .updateMonitoringItem(data_to_update)
          .subscribe((data: any) => {
            if (
              data &&
              data.resultado.header &&
              data.resultado.header.type &&
              data.resultado.header.type === "success"
            ) {
              console.log(data);
              this.toastr.typeSuccess();
              this.getCurrentMonitoring();
            } else {
              this.toastr.toast_error("Información no valida para actualizar.");
            }
          });
      }
    });
    // item.obra = this.saved_monitoring[Number(item.correlativo) -1].obra;

    //console.log(item);
  }

  removeItemFromMonitoring(id, token) {
    let that = this;
    alertFunctions.customAlert(
      "Eliminar Registro",
      "Se borrará el item seleccionado",
      "error",
      true,
      true,
      null,
      null,
      function (response) {
        console.log(id, token, response);

        if (response) {
          that.admin_service
            .deleteMonitoringItem({
              data: {
                user: that.currentUserSession.usuario,
                id: id,
                token: token,
              },
            })
            .subscribe((data: any) => {
              if (data) {
                console.log(data);
                that.getCurrentMonitoring();
                alertFunctions.customAlert(
                  "Éxito",
                  "La petición ha sido realizada.",
                  "success",
                  false,
                  true,
                  2000
                );
              }
            });
        }
      }
    );
  }
  enableItemEdition(item, index) {
    console.log(item, index);
    this.saved_monitoring_filtered[index].artist_code = "NO_REGISTRADO";
  }

  changeDistributionSelected(event: any) {
    console.log('evento', event.target.value);
    this.selectedDistribution = event.target.value;
 
    this.filteredDistributionsWallets = this.distributionsWallets.filter(
      (item) => item.cartera_distribucion === event.target.value.toString()
    );
    console.log('array', this.distributions)
    let year = this.distributions.filter(
      (item) => item.distribucion_correlativo === event.target.value.toString()
    )[0].distribucion_anio;

    //this.formMonitoring.get("data")["controls"].applicate_year.setValue(year);
  }
  changeDistributionWalletSelected(event: any) {
    console.log(event.target.value);
    this.selectedDistributionWallet = event.target.value;
    this.getCurrentMonitoring();
  }
}
