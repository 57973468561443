<div class="row">
  <div class="col-12">
    <h4 class="content-header">Monitoreo</h4>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <!-- <p>{{ formMonitoring.value | json }}</p> -->
        <div class="mb-3 mt-3">
          <!-- <ngb-tabset [activeId]="activeTab" (tabChange)="onTabChange($event)"> -->
          <ngb-tabset>
            <ngb-tab>
              <ng-template ngbTabTitle>Crear Monitoreo</ng-template>
              <ng-template ngbTabContent>
                <div class="row">
                  <div class="col-12 text-right">
                    <a
                      class="mr-2"
                      href="{{
                        server_name + '/download-monitoring-format/bmat'
                      }}"
                      >Formato BMAT</a
                    >
                    |
                    <a
                      class="mr-2"
                      href="{{
                        server_name + '/download-monitoring-format/etn'
                      }}"
                      >Formato ENCUESTA TOP NACIONAL</a
                    >
                    |
                    <a
                      class="mr-2"
                      href="{{
                        server_name + '/download-monitoring-format/multiple'
                      }}"
                      >Formato Multiple</a
                    >
                  </div>
                </div>

                <hr />
                <form [formGroup]="formMonitoring" *ngIf="formMonitoring">
                  <div class="row" formGroupName="data">
                    <div class="col-lg-4 col-md-6">
                      <div class="form-group">
                        <label for="file">Archivo de Monitoreo</label>
                        <div class="input-group">
                          <input
                            #monitoring_uploader
                            type="file"
                            class="form-control"
                            ng2FileSelect
                            [uploader]="uploader"
                          />
                          <div class="input-group-append">
                            <!-- <button class="btn btn-primary btn-raised" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length"> -->
                            <button
                              class="btn btn-primary btn-raised"
                              (click)="uploader.uploadAll()"
                            >
                              <i-feather name="upload"></i-feather>
                            </button>
                            <!-- <button
                              class="btn btn-primary btn-raised"
                              (click)="uploader.uploadAll()"
                              [disabled]="
                                !uploader.getNotUploadedItems().length
                              "
                            >
                              <i-feather name="upload"></i-feather>
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Canal de Monitoreo</label>
                        <div class="input-group">
                          <select
                            class="form-control"
                            *ngIf="monitoringChannels"
                            formControlName="code_channel"
                            (change)="changeMonitoringChannel($event)"
                          >
                            <option value selected></option>
                            <option
                              [value]="channel.correlativo"
                              *ngFor="
                                let channel of monitoringChannels;
                                let i = index
                              "
                            >
                              {{ channel.nombre }}
                            </option>
                            <option value="multiple">Canales Multiples</option>
                          </select>
                          <div class="input-group-append">
                            <span ngbDropdown placement="top-right">
                              <button
                                class="btn btn-primary btn-raised"
                                ngbDropdownToggle
                              >
                                <i class="fa fa-cog"></i>
                              </button>
                              <div ngbDropdownMenu class="dropdown-menu-right">
                                <button
                                  class="dropdown-item"
                                  (click)="openCreateMonitoringChannel()"
                                >
                                  Crear Canal de Monitoreo
                                </button>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                      <div class="form-group">
                        <label for=""> Distribución</label>
                        <select
                          class="form-control"
                          formControlName="distribution"
                          (change)="changeDistributionSelected($event)"
                        >
                          <option selected value></option>
                          <option
                            [value]="distribution.distribucion_correlativo"
                            *ngFor="
                              let distribution of distributions;
                              let i = index
                            "
                          >
                            {{ distribution.distribucion_nombre }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                      <div class="form-group">
                        <label for="">Cartera de Distribución</label>
                        <select class="form-control" formControlName="wallet">
                          <option selected value></option>
                          <option
                            [value]="wallet.cartera_correlativo"
                            *ngFor="
                              let wallet of filteredDistributionsWallets;
                              let i = index
                            "
                          >
                            {{
                              wallet.cartera_nombre 
                            }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- <div class="col-lg-2 col-md-12">
                      <div class="form-group">
                        <label for="">Cartera</label>
                        <select
                          class="form-control"
                          formControlName="applicate_year"
                        >
                          <option selected value></option>
                          <option
                            *ngFor="
                              let year of ' '
                                .repeat(currentYear - (currentYear - 5))
                                .split('');
                              let x = index
                            "
                          >
                            {{ currentYear - x }}
                          </option>
                        </select>
                      </div>
                    </div> -->
                  </div>
                </form>

                <ngx-datatable
                  #mtc
                  class="bootstrap"
                  [headerHeight]="50"
                  [limit]="10"
                  [columnMode]="'standard'"
                  [footerHeight]="40"
                  [rowHeight]="'auto'"
                  [rows]="this.formMonitoring.value.data.info"
                  [scrollbarH]="true"
                >
                  <ngx-datatable-column
                    prop="key"
                    name="#"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="isrc"
                    name="ISRC"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="title"
                    name="Nombre"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="artist"
                    name="Artista"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="hits"
                    name="HITS"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="publisher"
                    name="Sello"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="group"
                    name="Grupo"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="channel_name"
                    name="Canal"
                  ></ngx-datatable-column>
                </ngx-datatable>

                <button
                  type="button"
                  class="btn btn-raised btn-primary btn-block mt-3"
                  (click)="saveMonitoring()"
                >
                  Guardar Monitoreo
                </button>
                <br />
                <p *ngIf="saving">
                  Realizando {{ index + 1 }} / {{ steps }} peticiones...
                </p>
                <br />
                <div class="d-flex justify-content-center" *ngIf="saving">
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <!-- <button type="button" class="btn btn-raised btn-primary btn-block mt-3"
                  (click)="cleanUploadedMonitoring()">Limpiar Monitoreo</button> -->
              </ng-template>
            </ngb-tab>
            <ngb-tab>
              <ng-template ngbTabTitle>Monitoreos Guardados</ng-template>
              <ng-template ngbTabContent>
                <app-view-monitoring-saved
                  [distributions]="distributions"
                  [distributionsWallets]="distributionsWallets"
                ></app-view-monitoring-saved>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <p>{{formMonitoring.value | json}}</p> -->
