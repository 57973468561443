import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateDistributionComponent } from "app/create-distribution/create-distribution.component";
import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import * as _ from "lodash";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import * as moment from "moment";
import { MUSICARTES_LOGO_B64 } from "app/shared/consts";
import { NGXToastrService } from "app/services/toastr.service";
import { ExportExcelService } from "app/services/export-excel.service";

@Component({
  selector: "app-view-distribution",
  templateUrl: "./view-distribution.component.html",
  styleUrls: ["./view-distribution.component.scss"],
})
export class ViewDistributionComponent implements OnInit {
  current_user: any;
  loading: any = false;

  distribution_summary = [];
  selected_year: Number = 0;

  distribution_by_year: any = "";

  distributions = [];
  distributionsWallets = [];
  filteredDistributionsWallets = [];
  selected_distribution = null;
  selectedWallet = null;
  grouped_by_participant = null;

  distribution_by_phonograms = [];
  distribution_by_phonograms_filtered = [];

  constructor(
    private ngbModalService: NgbModal,
    private admin_service: AdminService,
    private route_data_service: RouteDataService,
    private toastr: NGXToastrService,
    private generateExcel: ExportExcelService
  ) { }

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.reloadAll();

    this.admin_service
      .getDistributions({
        user: this.current_user.usuario,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type === "success"
        ) {
          this.distributions = data.resultado.body?.filter(
            (i) => i.distribucion_flag === "corriente"
          );
        } else {
          this.toastr.typeErrorText("No se encontraron distribuciones.");
        }
      });

    console.log('hola', this.distributions)

    this.admin_service
      .getDistributionWallets({
        user: this.current_user.usuario,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type === "success"
        ) {
          this.distributionsWallets = data.resultado.body;
        } else {
          this.toastr.typeErrorText("No se encontraron carteras.");
        }
      });
  }
  reloadAll() {
    this.loadDistributions();
    this.searchDistributionByArtist();
    // this.loadDistributionsParticipants();
    this.loadDistributionByChannels();
    // this.changeWalletDistributionSelected({
    //   target: {
    //     value: this.selectedWallet,
    //   },
    // });
  }
  createDistribution() {
    let modalRef = this.ngbModalService.open(CreateDistributionComponent, {
      size: "lg",
      windowClass: "hugeModal",
    });
    modalRef.componentInstance.current_user = this.current_user;
  }

  /******************** */
  saved_distributions = [];
  saved_distributions_filtered = [];
  typed_search: string = "";
  selected_state = "";

  distribution_work = "";

  loadDistributions() {
    this.saved_distributions = this.saved_distributions_filtered = [];
    this.admin_service
      .listDistributions({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        // console.log(data);
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.distribution_summary = data.resultado.body.resumen;
          this.saved_distributions = this.saved_distributions_filtered =
            data.resultado.body.data;

          let amount = 0;
          let hits = 0;
          this.saved_distributions_filtered.forEach((element) => {
            hits += Number(element.hits_total);
            amount += Number(element.monto_recaudado);
          });
          console.log("Hits: ", hits);
          console.log("Amount: ", amount);
        } else {
        }
      });
  }

  changeDistributionYear(event: any) {
    const val = (this.distribution_work = event.target.value.toLowerCase());
    this.selected_year = val;
    this.filterDistributions();
  }
  changeDistributionState(event: any) {
    const val = event.target.value.toLowerCase();
    this.selected_state = val;
    this.filterPhonograms();
  }
  filterDistributionByText(event) {
    const val = event.target.value.toLowerCase();
    this.typed_search = val;
    this.filterDistributions();
  }
  filterDistributions() {
    // console.log(this.selected_channel, this.selected_year, this.typed_search);
    let that = this;
    const temp = this.saved_distributions.filter(function (d) {
      return (
        d.anio.toLowerCase().indexOf(that.selected_year.toString()) !== -1 &&
        d.situacion.toLowerCase().indexOf(that.selected_state.toString()) !==
        -1 &&
        (d.correlativo.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d.obra_nombre.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d.artista_nombre.toLowerCase().indexOf(that.typed_search) !== -1)
        //|| d.discografia_nombre.toLowerCase().indexOf(that.typed_search) !== -1
      );
    });
    this.saved_distributions_filtered = temp;
  }

  filterPhonogramsByText(event) {
    const val = event.target.value.toLowerCase();
    this.typed_search = val;
    this.filterPhonograms();
  }
  filterPhonograms() {
    console.log("entro en filterPhonograms");
    let that = this;
    const temp = this.distribution_by_phonograms.filter(function (d) {
      return (
        //d.anio.toLowerCase().indexOf(that.selected_year.toString()) !== -1 &&
        d.situation.toLowerCase().indexOf(that.selected_state.toString()) !==
        -1 && // d?.correlativo?.toLowerCase()?.indexOf(that.typed_search) !== -1 ||
        (d.phonogram_name.toLowerCase().indexOf(that.typed_search) !== -1 ||
          d.artist_name.toLowerCase().indexOf(that.typed_search) !== -1)
        //|| d.discografia_nombre.toLowerCase().indexOf(that.typed_search) !== -1
      );
    });
    this.distribution_by_phonograms_filtered = temp;
  }

  selected_distribution_detail = {};
  selected_distribution_participants = [];
  @ViewChild("modalDistributionItemDetail")
  modalDistributionItemDetail: ElementRef;
  viewDistributionItem(item: any) {
    this.selected_distribution_participants = [];
    console.log(item);
    this.selected_distribution_detail = item;
    // this.admin_service
    //   .getDistributionItemParticipants({
    //     user: this.current_user.usuario,
    //     id: item.obra_codigo,
    //     year: this.distribution_work,
    //   })
    //   .subscribe((response: any) => {
    //     console.log(response);
    //     this.selected_distribution_participants =
    //       response.resultado.body.participants;
    //   });

    let modalRef = this.ngbModalService.open(this.modalDistributionItemDetail, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      centered: true,
      windowClass: "hugeModal",
    });
    modalRef.result.then((result) => {
      console.log(result);
      if (result === "Ok") {
      }
    });
  }

  /******************** */
  distributions_by_artist = [];
  distributions_by_artist_filter = [];
  typed_search_2 = "";
  searchDistributionByArtist() {
    this.distributions_by_artist = this.distributions_by_artist_filter = [];
    let search = this.distribution_summary[this.distribution_by_year];
    this.admin_service
      .listDistributionByArtist({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.distributions_by_artist = this.distributions_by_artist_filter =
            data.resultado.body.band;
        }
      });
  }

  /*----- Distribucion por canales ----- */
  distribution_by_channels = [];
  distribution_by_channels_filtered = [];
  distribution_by_channels_summary = [];
  distribution_by_channels_selected_year = "";
  distribution_by_channels_selected_channel = "";
  typed_search_4 = "";

  loadDistributionByChannels() {
    this.distribution_by_channels = this.distribution_by_channels_filtered = [];
    this.admin_service
      .listDistributionByChannels({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.distribution_by_channels_summary = data.resultado.body.resumen;
          this.distribution_by_channels =
            this.distribution_by_channels_filtered = data.resultado.body.data;
        }
      });
  }
  filterDistributionByChannelsChannel(event: any) {
    const val = event.target.value.toLowerCase();
    this.distribution_by_channels_selected_channel = val;
    this.filterDistributionByChannels();
  }
  filterDistributionByChannelsYear(event: any) {
    const val = event.target.value.toLowerCase();
    this.distribution_by_channels_selected_year = val;
    this.filterDistributionByChannels();
  }
  filterDistributionByChannelsByText(event) {
    const val = event.target.value.toLowerCase();
    this.typed_search_4 = val;
    this.filterDistributionByChannels();
  }
  filterDistributionByChannels() {
    let that = this;
    const temp = this.distribution_by_channels.filter(function (d) {
      return (
        d.token
          .toLowerCase()
          .indexOf(that.distribution_by_channels_selected_year.toString()) !==
        -1 &&
        (d.obra_codigo.toLowerCase().indexOf(that.typed_search_4) !== -1 ||
          d.obra_nombre.toLowerCase().indexOf(that.typed_search_4) !== -1 ||
          d.artista_codigo.toLowerCase().indexOf(that.typed_search_4) !== -1 ||
          d.artista_nombre.toLowerCase().indexOf(that.typed_search_4) !== -1 ||
          d.monto_recaudado.toLowerCase().indexOf(that.typed_search_4) !==
          -1) &&
        d.canal_codigo
          .toLowerCase()
          .indexOf(
            that.distribution_by_channels_selected_channel.toString()
          ) !== -1
      );
    });
    this.distribution_by_channels_filtered = temp;
  }

  //#region  Distribucion por participantes

  distribution_by_participants_main = [];
  distribution_by_participants = [];
  distribution_by_participants_filtered = [];
  distribution_by_participants_summary = [];
  distribution_by_participants_selected_year = 0;
  distribution_by_participants_selected_state = "";
  typed_search_3 = "";

  loadDistributionsParticipants() {
    this.distribution_by_participants =
      this.distribution_by_participants_filtered = [];
    this.admin_service
      .listDistributionByParticipants({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.distribution_by_participants_summary =
            data.resultado.body.resumen;

          let grouped = _.groupBy(data.resultado.body.data, "anio");

          grouped = _.map(grouped, (value, key) => ({
            year: key,
            participants: _.map(
              _.groupBy(value, "integrante_codigo"),
              (value2, key2) =>
                _.mapKeys(value2, (value3, key3) => value3[key3])["undefined"]
            ),
          }));

          this.distribution_by_participants_main = grouped;
          this.distribution_by_participants =
            this.distribution_by_participants_filtered =
            grouped[0].participants;

          //   let amount = 0;
          //   let participants = 0;
          //   this.distribution_by_participants_filtered.forEach((element) => {
          //     participants += Number(element.cantidad);
          //     amount += Number(element.monto_integrante);
          //   });
          //   console.log("participaciones: ", participants);
          //   console.log("participantes amount: ", amount);
        }
      });
  }
  filterDistributionParticipantsYear(event: any) {
    const val = event.target.value.toLowerCase();
    this.distribution_by_participants_selected_year = val;
    this.distribution_by_participants =
      this.distribution_by_participants_filtered =
      this.distribution_by_participants_main[val].participants;
    console.log(val);

    let sum = 0;
    this.distribution_by_participants.forEach((element) => {
      sum += parseFloat(element.monto_integrante);
    });
    console.log(sum);
    this.filterDistributionParticipants();
  }

  filterDistributionParticipantsState(event: any) {
    const val = event.target.value.toLowerCase();
    this.distribution_by_participants_selected_state = val;
    this.filterDistributionParticipants();
  }
  filterDistributionParticipantsByText(event) {
    const val = event.target.value.toLowerCase();
    this.typed_search_3 = val;
    this.filterDistributionParticipants();
  }
  filterDistributionParticipants() {
    let that = this;
    const temp = this.distribution_by_participants.filter(function (d) {
      return (
        // d?.anio
        //   ?.toLowerCase()
        //   ?.indexOf(
        //     that.distribution_by_participants_selected_year.toString()
        //   ) !== -1 &&
        d.situacion
          ?.toLowerCase()
          ?.indexOf(
            that.distribution_by_participants_selected_state.toString()
          ) !== -1 &&
        (d.code.toLowerCase().indexOf(that.typed_search_3) !== -1 ||
          d.name.toLowerCase().indexOf(that.typed_search_3) !== -1)
      );
    });
    this.distribution_by_participants_filtered = temp;
  }
  generateDistributionParticipantsFile() {
    this.generateExcel.exportExcel({
      data: this.wallet_detail_holders,
    });
    // this.admin_service
    //   .generateXLSX(this.distribution_by_participants_filtered)
    //   .subscribe((data: any) => {
    //     if (data) {
    //       window.open(data.url, "_blank");
    //     } else {
    //       this.toastr.toast_error("No se obtuvieron datos para el reporte.");
    //     }
    //   });

    // this.admin_service
    //   .generateDistributionByParticipantsFile({
    //     user: this.current_user.usuario,
    //   })
    //   .subscribe((data: any) => {
    //     if (data) {
    //       window.open(data.url, "_blank");
    //     } else {
    //       this.toastr.toast_error("No se obtuvieron datos para el reporte.");
    //     }
    //   });
  }
  //#endregion

  current_participant_participations = [];
  current_participant_participations_filter_work = "all";
  current_participant_participations_filter_situation = "all";
  current_participant_participations_filter_agreement = "all";
  current_participant_participations_filtered = [];

  current_participant_distributions = [];
  current_participant_detail: any = {};
  current_participant_year = "";
  @ViewChild("modalParticipantDistributions")
  modalParticipantDistributions: ElementRef;
  DistributionsByParticipant(participant) {
    console.log("detail", participant);

    let modalRef = this.ngbModalService.open(
      this.modalParticipantDistributions,
      {
        backdrop: "static",
        keyboard: false,
        size: "lg",
        centered: true,
        windowClass: "hugeModal",
      }
    );

    modalRef.result.then((result) => {
      // console.log(result);
      if (result === "Ok") {
      }
    });

    this.current_participant_detail = participant;
    this.current_participant_participations_filtered =
      participant.participations;

    let works = _.groupBy(participant.participations, (item) =>
      JSON.stringify({ code: item.song_code })
    );

    works = Object.keys(works).map((key, index) => {
      return {
        ...JSON.parse(key),
        name: works[key][0].song_name,
        detail: works[key],
      };
    });

    this.current_participant_detail.works = works;

    let agreements = _.groupBy(
      participant.participations.filter((item) => item.publisher_id !== 0),
      (item) => JSON.stringify({ publisher_id: item.publisher_id })
    );

    agreements = Object.keys(agreements).map((key, index) => {
      return {
        ...JSON.parse(key),
        publisher_name: agreements[key][0].publisher_name,
        agreement_end: agreements[key][0].agreement_end,
        publisher_flag: agreements[key][0].publisher_flag,
      };
    });

    this.current_participant_detail.agreements = agreements;

    console.log("current_participant_detail", participant);

    // this.current_participant_distributions = [];

    // //this.current_participant_year = year;
    // this.admin_service
    //   .getDistributionsByParticipant({
    //     user: this.current_user.usuario,
    //     id: participant.code,
    //     year: this.selected_distribution.anio,
    //   })
    //   .subscribe((data: any) => {
    //     if (data) {
    //       this.current_participant_participations_filtered =
    //         this.current_participant_detail?.participations;

    //       let works = _.groupBy(
    //         this.current_participant_detail?.participations,
    //         (item) => JSON.stringify({ code: item.song_code })
    //       );

    //       works = Object.keys(works).map((key, index) => {
    //         return {
    //           ...JSON.parse(key),
    //           name: works[key][0].song_name,
    //           detail: works[key],
    //         };
    //       });

    //       this.current_participant_detail = {
    //         ...this.current_participant_detail,
    //         associate: data.resultado.body.artista,
    //         works,
    //       };

    //       console.log("with associate", this.current_participant_detail);
    //     }
    //   });
  }
  handleParticipantDistributionFilter(event, filter) {
    let filter_value = event.target.value;
    console.log('variable para test', filter_value, 'filter', filter)
    if (filter === "FONOGRAMA") {
      this.current_participant_participations_filter_work = filter_value;
    } else if (filter === "SITUACION") {
      this.current_participant_participations_filter_situation = filter_value;
    } else if (filter === "CONVENIO") {
      this.current_participant_participations_filter_agreement = filter_value;
    }

    console.log(
      "filters",
      this.current_participant_participations_filter_work,
      this.current_participant_participations_filter_situation,
      this.current_participant_participations_filter_agreement
    );

    let filtered = [];
    try {
      let by_work = this.current_participant_detail?.participations.filter(
        (participation) =>
          (this.current_participant_participations_filter_work === "all"
            ? participation.song_code !== ""
            : participation.song_code ===
            this.current_participant_participations_filter_work) &&
          (this.current_participant_participations_filter_situation === "all"
            ? participation.situation === "pagado" ||
            participation.situation === "incompleto"
            : participation.situation ===
            this.current_participant_participations_filter_situation) &&
          (this.current_participant_participations_filter_agreement === "all"
            ? participation.publisher_id === 0 ||
            participation.publisher_id !== 0
            : participation.publisher_id.toString() ===
            this.current_participant_participations_filter_agreement?.toString())
      );
      filtered = by_work;
    } catch { }

    this.current_participant_participations_filtered = filtered;
    this.current_participant_detail.total = _.sumBy(filtered, (item) =>
      Number(item.amount_partner)
    );
    console.log("filtered", this.current_participant_participations_filtered);
  }

  generateParticipantPaymentDetail() {
    let participant: any = {
      detail: this.current_participant_detail,
      //distributions: this.current_participant_distributions,
    };
    console.log(participant, 'distribucion', this.selected_distribution);
    // You'll need to make your image into a Data URL
    // Use http://dataurl.net/#dataurlmaker
    var imgData = MUSICARTES_LOGO_B64;
    var doc = new jsPDF();
    let y = 0;

    doc.setFontSize(16);
    //doc.text(35, 25, 'Paranyan loves jsPDF')
    doc.addImage(imgData, "JPEG", 15, 15, 20, 20);
    doc.setFontSize(8);
    doc.text("MUSICARTES", 16, 40);

    // doc.setFontSize(8);
    // let musicartes_name = 'Sociedad de Artistas de la Música y Obras Audiovisuales -MUSICARTES-'
    // doc.text((doc.internal.pageSize.width / 2) - musicartes_name.length, 280, musicartes_name)
    // doc.text(65, 285, '[21 avenida “B” 0-10,zona 15 Vista Hermosa 2]');

    doc.setFontSize(14);
    doc.text("DETALLE DE DISTRIBUCIÓN", 125, 25);
    doc.setFontSize(10);
    // //doc.setFontStyle("bold");
    // // doc.text(125, 37, 'FECHA');
    // // doc.text(145, 37, moment().format('DD/MM/YYYY'));

    // // doc.text(10, 55, 'DETALLE DE DISTRUBCIÓN:');
    // //doc.line(10, 55, 200, 55) // horizontal line
    doc.setFont("helvetica", "regular", "normal");
    doc.setFontSize(10);
    doc.text("Código Socio", 15, 60);
    doc.text("Nombre Socio", 55, 60);
    // //doc.text(160, 65, 'Año Distribución');

    doc.setFont("helvetica", "regular", "bold");
    doc.setFontSize(14);
    doc.text(participant.detail.code, 15, 68);
    doc.setFontSize(18);
    doc.text(participant.detail.name, 55, 68);
    // //doc.text(160, 73, participant.detail.anio);
    doc.text(this.selected_distribution.distribucion_anio, 149, 37);
    // doc.text('nombre nuevo que se requiere y mas no mas', 125, 49);
    console.log('ver datos para editorial', this?.current_participant_participations_filter_agreement)
   

    if (
      this.current_participant_participations_filter_agreement !== "all" &&
      this.current_participant_participations_filter_agreement.toString() !==
      "0"
    ) {
      doc.setFont("helvetica", "regular", "normal");
      doc.setFontSize(10);
      doc.text("Editorial", 15, 76);
      doc.setFont("helvetica", "regular", "bold");
      doc.setFontSize(14);
      doc.text(
        this.current_participant_detail.agreements.filter(
          (item) =>
            item.publisher_id.toString() ===
            this.current_participant_participations_filter_agreement
        )[0]?.publisher_name,
        15,
        84
      );

      y += 18;
    }

console.log('datos de tabla', this.current_participant_participations_filtered)
    doc.setFont("helvetica", "regular", "normal");
    let items_height_base = 145;
    y += 78;
    let that = this;
    let doc2 = autoTable(doc, {
      startY: y,
      columnStyles: { 0: { halign: "center" } },
      body: that.current_participant_participations_filtered,

      // .filter(
      //   (item) => item.situacion == "Pendien"
      // ),
      //pageBreak: 'avoid' ,
      rowPageBreak: "avoid",
      // allSectionHooks: true,
      columns: [
        { header: "#", dataKey: "correlativo" },
        { header: "Canal", dataKey: "name_channel" },
        { header: "HITS", dataKey: "hits_total" },
        { header: "Artista", dataKey: "artist_name" },
        { header: "Canción", dataKey: "song_name" },
        // { header: "Editorial", dataKey: "publisher_name" },
        { header: "Rol", dataKey: "rol_name" },
        { header: "Instrumento", dataKey: "instrument_name" },
        { header: "Monto (Q)", dataKey: "amount_partner" },
      ],
      willDrawCell: function (HookData) {
        //console.log(HookData);
        if (HookData.column.index === 8 && HookData.section === "body") {
          //console.log(HookData.cell.text[0]);
          HookData.cell.text[0] = that
            .formatNumber(HookData.cell.text[0])
            .toString();
          //console.log(HookData.cell.text[0]);
        } else if (HookData.column.index === 0 && HookData.section === "body") {
          HookData.cell.text[0] = (HookData.row.index + 1).toString();
        }
      },
      didDrawCell: function (HookData) {
        //console.log(HookData, HookData.row.index % 20);
        if (
          HookData.section === "body" &&
          HookData.column.index === 4 &&
          HookData.row.index > 0 &&
          HookData.row.index % 25 === 0
        ) {
          //doc.autoTable.addPage();
          //  doc.addPage();
        }
      },
      didDrawPage: function (HookData) {
        console.log(HookData);
      },
    });
    let auto_table_end_y = 5;

    if ((doc as any).lastAutoTable.finalY > 230) {
      doc.addPage();
    } else {
      console.log((doc as any).lastAutoTable.finalY);
      auto_table_end_y = Number((doc as any).lastAutoTable.finalY);
    }

    doc.line(10, auto_table_end_y + 10, 200, auto_table_end_y + 10); // horizontal line
    doc.text("Total:", 140, auto_table_end_y + 20);
    doc.setFont("helvetica", "regular", "bold");
    doc.text(
      "Q " + _.round(Number(participant.detail.total), 2),
      160,
      auto_table_end_y + 20
    );

    doc.setFont("helvetica", "regular", "normal");

    // doc.setFontSize(12);
    // doc.line(20, auto_table_end_y + 50, 80, auto_table_end_y + 50);
    // doc.line(120, auto_table_end_y + 50, 180, auto_table_end_y + 50);

    // doc.text("MUSICARTES", 36, auto_table_end_y + 55);
    // doc.text(
    //   participant.detail.name,
    //   150 - participant.detail.name.length,
    //   auto_table_end_y + 55
    // );

    doc.setFontSize(10);
    doc.text(
      "Guatemala, " + moment().locale("es").format("LLL"),
      72,
      auto_table_end_y + 60
    );

    let text = `Yo ${participant.detail?.name.toUpperCase()} me identifico con Documento Personal de Identificación con Código Único de identificación  ${participant.detail.identifier.toUpperCase()} manifiesto que al recibir el pago del total del monto  indicado en este documento, implica que expresamente acepto y recibo a mi entera satisfacción y de plena conformidad de parte de la Sociedad de Artistas de la Música y Obras Audiovisuales – MUSICARTES- el pago correspondiente a los derechos de comunicación pública de fonogramas del año de ${this.selected_distribution.distribucion_anio} por los conceptos acá detallados y extiendo el más completo y eficaz finiquito sobre cualquier reclamación futura a MUSICARTES, de cualquier tipo, materia o cuantía sobre los conceptos que en este documento se mencionan, y que los pago se tendrá como bien hecho para todos los efectos que correspondan.`;

    var splitTitle = doc.splitTextToSize(text, 190);
    y = auto_table_end_y + 80;
    for (var i = 0; i < splitTitle.length; i++) {
      if (y > 260) {
        y = 25;
        doc.addPage();
      }
      doc.text(splitTitle[i], 10, y);
      y = y + 7;
    }
    var centeredText = function (text, y) {
      var textWidth =
        (doc.getStringUnitWidth(text) * (doc as any).internal.getFontSize()) /
        doc.internal.scaleFactor;

      var textOffset = ((doc as any).internal.pageSize.width - textWidth) / 2;

      doc.text(text, textOffset, y);
    };

    // doc.setFont("helvetica", "regular", "bold");
    // centeredText(`${participant.detail?.name.toUpperCase()}`, y + 30);

    doc.save("DetalleDistribucion-" + participant.detail.code + ".pdf");
  }

  MarkParticipantAsPaid() {
    let participant = {
      detail: this.current_participant_detail,
      distributions: this.current_participant_distributions,
    };
    this.admin_service
      .markParticipantDistributionPaid({
        user: this.current_user.usuario,
        id: this.current_participant_detail["integrante_codigo"],
        year: this.current_participant_year,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.toastr.typeSuccess();
        } else {
          this.toastr.typeError();
        }
      });
  }

  @ViewChild("ParticipantDistributions") table: any;

  getRowClass(row) {
    // console.log("getRowClass", row.situacion);
    return {
      danger: row.situacion == "Pendiente",
    };
  }

  formatNumber(num) {
    return _.round(Number(num), 2);
    // if (num) {
    //   num = this.toFixed(num, 2);
    //   if (num) {
    //     return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    //   }
    // }
  }
  toFixed(num, fixed) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }

  changeDistributionSelected(event: any) {
    console.log(event.target.value);

    let filter = event.target.value.toString();
    if (filter === "all") {
      this.filteredDistributionsWallets = this.distributionsWallets;
    } else {
      this.filteredDistributionsWallets = this.distributionsWallets.filter(
        (item) => item.cartera_distribucion === filter
      );

      this.selected_distribution = this.distributions.filter(
        (item) => item.distribucion_correlativo === filter
      )[0];
    }

    // let year = this.distributions.filter(
    //     (item) => item.correlativo === event.target.value.toString()
    //   )[0].anio;

    // this.formMonitoring.get('data')['controls'].applicate_year.setValue(year);
  }

  wallet_detail_holders: any;
  changeWalletDistributionSelected(event: any) {
    this.loading = true;
    console.log(event.target.value);
    let wallet = (this.selectedWallet = event.target.value);
    this.loadWalletDistributionDetail(wallet);
  }

  loadWalletDistributionDetail(wallet: any) {
    if (wallet) {
      this.admin_service
        .getWalletDistributionDetail({
          wallet,
          user: this.current_user.usuario,
        })
        .subscribe((data: any) => {
          if (
            data &&
            data.response &&
            data.response.db &&
            data.response.db.typ === "success"
          ) {
            if (data.response.data?.taskStatus === "Done") {
              this.loading = false;
              this.wallet_detail_holders = data.response.data.holders;
              console.log("holders", this.wallet_detail_holders);

              let grouped_by_participant = _.groupBy(
                this.wallet_detail_holders,
                (item) => JSON.stringify({ code: item.partner_code })
              );

              console.log('datos ver', this.grouped_by_participant)
              this.grouped_by_participant =
                this.distribution_by_participants =
                this.distribution_by_participants_filtered =
                Object.keys(grouped_by_participant).map((key, index) => {
                  return {
                    correlativo: index,
                    ...JSON.parse(key),
                    name: grouped_by_participant[key][0].partner_name,
                    identifier: grouped_by_participant[key][0].identifier,
                    appearances: grouped_by_participant[key].length,
                    total: _.sumBy(grouped_by_participant[key], (item) =>
                      Number(item.amount_partner)
                    ),
                    paid: _.sumBy(grouped_by_participant[key], (item) =>
                      item.situation === "pagado"
                        ? Number(item.amount_partner)
                        : 0
                    ),
                    situacion: _.every(grouped_by_participant[key], {
                      situation: "pagado",
                    })
                      ? "Pagado"
                      : "Pendiente",
                    participations: grouped_by_participant[key],
                  };
                });

              let grouped_by_phonogram = _.groupBy(
                this.wallet_detail_holders,
                (item) => JSON.stringify({ phonogram_code: item.song_code })
              );
              console.log("grouped_by_phonogram", grouped_by_phonogram);

              grouped_by_phonogram =
                this.distribution_by_phonograms =
                this.distribution_by_phonograms_filtered =
                Object.keys(grouped_by_phonogram).map((key, index) => {
                  let vali = grouped_by_phonogram[key].every(objeto => objeto.situation === "pagado")
                  // console.log('validacion', grouped_by_phonogram[key], 'validacion', vali)

                  return {
                    ...JSON.parse(key),
                    artist_code: grouped_by_phonogram[key][0].artist_code,
                    artist_name: grouped_by_phonogram[key][0].artist_name,
                    phonogram_name: grouped_by_phonogram[key][0].song_name,
                    hits: grouped_by_phonogram[key][0].hits_total,
                    total: _.sumBy(grouped_by_phonogram[key], (item) =>
                      Number(item.amount_partner)
                    ).toFixed(2),
                    situation: vali ? 'Pagado' : 'Pendiente',
                    // _.every(grouped_by_participant[key], {
                    //   situation: "pagado",
                    // })
                    //   ? "Pagado"
                    //   : "Pendiente",
                    participations: grouped_by_phonogram[key].length,
                    participations_detail: grouped_by_phonogram[key],
                  };
                });

              let grouped_by_artist = _.groupBy(
                this.wallet_detail_holders,
                (item) => JSON.stringify({ code: item.artist_code })
              );

              grouped_by_artist = this.distributions_by_artist = Object.keys(
                grouped_by_artist
              ).map((key, index) => {
                return {
                  ...JSON.parse(key),
                  name: grouped_by_artist[key][0].artist_name,

                  total: _.sumBy(grouped_by_artist[key], (item) =>
                    Number(item.amount_partner)
                  ).toFixed(2),
                  phonograms: grouped_by_phonogram.filter(
                    (i) => i.artist_code === JSON.parse(key).code
                  ).length,
                  participations: grouped_by_artist[key],
                };
              });

              console.log("grouped_by_participant", grouped_by_participant);
              console.log("by artists", grouped_by_artist);
              console.log("by phonogram", grouped_by_phonogram);
            } else if (data.response.data?.taskStatus === "Loading") {
              setTimeout(() => {
                this.loadWalletDistributionDetail(wallet);
                console.log("reintentando");
              }, 10000);
            }
          } else {
            this.toastr.typeErrorText("No se encontro detalle de cartera.");
          }
        });
    }
  }
}
