import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit
} from "@angular/core";

import { ROUTES } from "./sidebar-routes.config";
import { Router, ActivatedRoute } from "@angular/router";
//import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "app/shared/animations/custom-animations";
import { ConfigService } from "app/services/config.service";
import { NGXToastrService } from "app/services/toastr.service";
import { RouteDataService } from "app/services/route-data.service";
import { PersonService } from "app/services/person.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {
  current_user: any;

  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = "assets/musicartes/logo-musicartes/10.png";
  public config: any = {};
  user_data: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    // public translate: TranslateService,
    private configService: ConfigService,

    private toastr: NGXToastrService,
    private router_data_service: RouteDataService,
    private person_service: PersonService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
    this.user_data = this.router_data_service.tempData;
   // this.menuItems = ROUTES;
    this.person_service.getCurrentUserBoard({ user: this.user_data.usuario, profile: this.user_data.perfil_nombre, id: this.user_data.codigo })
        .subscribe((data: any) => {
            if (data && data.resultado && data.resultado.header && data.resultado.header.type === 'success') {
                this.menuItems = data.resultado.detalle;
            } else {
                this.toastr.toast_error("No se ha recuperado la información de menù.");
            }
        });

    this.config = this.configService.templateConf;

    if (this.config.layout.sidebar.backgroundColor === "white") {
      //this.logoUrl = '';
    } else {
      // this.logoUrl = '';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-left"
          );
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-right"
          );
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-left"
          );
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-right"
          );
          this.nav_collapsed_open = false;
        }
      }
    }, 0);
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
