import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { httpOptions, IPSTACK_KEY, httpOptionsAWS } from 'app/shared/consts';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PublicService {

    constructor(private http: HttpClient) { }



    public getIpAddress() {
        return this.http.get('http://api.ipstack.com/check?access_key=' + IPSTACK_KEY);
    }
    public getSeverName() {
        return this.http.post('/getSeverName', null, httpOptions);
    }
    public getVersion() {
        return this.http.post('/getVersion', null, httpOptions);
    }
    public getUserImage(id_musicartes) {
        return this.http.post('/get-file', { key: 'cards/' + id_musicartes }, httpOptions);
        //  return this.http.get('https://files-musicartes.s3.amazonaws.com/cards/' + id_musicartes, { responseType: 'blob' });
        //return this.http.get('https://files-musicartes.s3.amazonaws.com/cards/' + id_musicartes + '.jpg', { responseType: 'blob' });
    }
    public getUserIdentifyDocument(id_musicartes) {
        return this.http.post('/get-file', { key: id_musicartes + '/DPI-' + id_musicartes }, httpOptions);
    }
}
