import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpOptions } from 'app/shared/consts';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    constructor(private http: HttpClient) { }

    public createAppointment(data: any) {
        return this.http.post('/createAppointment', data, httpOptions);
    }
    getAppointments(data:any) {
        return this.http.post('/getAppointments', data, httpOptions);
    }
    updateAppointment(event: any) {
        return this.http.post('/updateAppointment', event, httpOptions);
    }
    cancelAppointment(event: any) {
        return this.http.post('/cancelAppointment', event, httpOptions);
    }
    sendAppointmentConfirmation(event: any) {
        return this.http.post('/sendAppointmentConfirmation', event, httpOptions);
    }
    sendAppoinmentEmail(event: any) {
        return this.http.post('/sendAppoinmentEmail', event, httpOptions);
    }

    getNotificationsByUserCat(data: any) {
        return this.http.post('getAppointmentByUserCat', data, httpOptions);
    }
    getNotifications(data: any) {
        return this.http.post('/getAppointments', data, httpOptions);
    }
    markEventSeenBy(data: any) {
        return this.http.post('markEventSeenBy', data, httpOptions);
    }

}
