import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-create-distribution",
  templateUrl: "./create-distribution.component.html",
  styleUrls: ["./create-distribution.component.scss"],
})
export class CreateDistributionComponent implements OnInit {
  public current_user: any;

  monitoring_years: any;

  formCalculateDistribution: FormGroup;

  distribution_calculated = [];
  distribution_temp_detail: any;

  percentage_error = "";
  show_percentage_error: boolean = false;
  loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private admin_service: AdminService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.admin_service
      .getMonitoringYears({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          //this.monitoring_years = data.resultado.body;
          let years = data.resultado.body;
          console.log(years);
          this.monitoring_years = years.reduce((acc, item) => {
            if (!acc[item.canal]) {
              acc[item.canal] = [];
            }
            acc[item.canal].push(item);
            return acc;
          }, []);

          console.log(this.monitoring_years);
          console.log(this.monitoring_years.BMAT);
          console.log(this.monitoring_years.BMAT[0]);

          this.formCalculateDistribution = this.formBuilder.group({
            //applicate_year: new FormControl('', [Validators.required]),
            amount: new FormControl("", [Validators.required]),
            bmat: new FormControl("", [Validators.required]),
            encuesta: new FormControl("", [Validators.required]),
            tokenb: new FormControl(""),
            tokene: new FormControl(""),
          });
        } else {
          this.toastr.toast_error("No se encontraron monitoreos.");
        }
      });
  }

  show_distribution_calculated: boolean = false;

  calculateDistribution() {
    this.loading = true;
    this.distribution_temp_detail = null;

    let data_to_save = this.formCalculateDistribution.value;
    data_to_save.user = this.current_user.usuario;
    this.admin_service
      .calculateDistribution(data_to_save)
      .subscribe((data: any) => {
        this.loading = false;
        if (
          data &&
          data.resultado.header &&
          data.resultado.header.type &&
          data.resultado.header.type == "success"
        ) {
          this.toastr.toast_sucess("Distribución Calculada con Exito.");
          console.log(data.resultado);
          this.distribution_temp_detail = data.resultado.resumen[0];
          this.distribution_calculated = data.resultado.body;
          this.show_distribution_calculated = true;
        } else {
          this.toastr.toast_error("Error Calculando Distribución.");
          this.show_distribution_calculated = false;
        }
      });
  }
  saveDistribution() {
    this.loading = true;
    let data_to_send = {
      user: this.current_user.usuario,
      /* applicate_year: this.distribution_temp_detail.distribucion_anio, */ token: this
        .distribution_temp_detail.token,
    };
    this.admin_service.saveDistribution(data_to_send).subscribe((data: any) => {
        console.log(data);
        
      if (
        data &&
        data.resultado &&
        data.resultado.type &&
        data.resultado.type == "success"
      ) {
        this.toastr.toast_sucess("Distribución Guardada con Exito.");
        this.activeModal.close("Ok");

        // this.admin_service.integrateDistribution(data_to_send).subscribe((integration_result: any) => {
        //     if (integration_result && integration_result.resultado.header && integration_result.resultado.header.type && integration_result.resultado.header.type == "success") {
        //         this.activeModal.close("Ok");
        //         this.toastr.toast_sucess("Distribución Integrada con exito.");
        //     } else {
        //         this.toastr.toast_error("Error Integrando Distribución.");
        //     }
        // });
      } else {
        this.toastr.toast_error("Error Guardando Distribución.");
      }
      this.loading = false;
    });
  }
  validateDistributionPercentage() {
    //console.log("validando");
    let ac: AbstractControl = this.formCalculateDistribution;
    let percentage_bmat = ac.get("bmat").value;
    let percentage_encuesta = ac.get("encuesta").value;

    if (percentage_bmat + percentage_encuesta > 100) {
      //console.log("upper than 100");
      this.percentage_error = "Los porcentajes asignados superan el 100%";
      return false;
    } else if (percentage_bmat + percentage_encuesta < 100) {
      this.percentage_error = "Los porcentajes asignados no completan el 100%";
      return false;
    } else if (percentage_bmat + percentage_encuesta == 100) {
      return true;
    }
  }
}
