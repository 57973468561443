import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbActiveModal,
  NgbTabChangeEvent,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  ValidationErrors,
} from "@angular/forms";
import { FileUploader } from "ng2-file-upload";
import { ImageCroppedEvent } from "ngx-image-cropper";

import { AdminService } from "app/services/admin.service";
import { PersonService } from "app/services/person.service";
import { RouteDataService } from "app/services/route-data.service";

import { PublicService } from "app/services/public.service";
import { CreatePersonBeneficiaryComponent } from "app/create-person-beneficiary/create-person-beneficiary.component";

import { jsPDF } from "jspdf";
import * as JsBarcode from "jsbarcode";
import * as moment from "moment";

import { MUSICARTES_LOGO_B64, pad } from "app/shared/consts";
import * as alertFunctions from "app/shared/data/sweet-alerts";
import { NGXToastrService } from "app/services/toastr.service";
import { log } from "console";

@Component({
  selector: "app-view-partner-card",
  templateUrl: "./view-partner-card.component.html",
  styleUrls: ["./view-partner-card.component.scss"],
})
export class ViewPartnerCardComponent implements OnInit {
  server_name: any;
  current_user: any;
  sendPasswordToPartner: any;
  editingPartner = false;
  editingPhoto = false;
  canPrintCard = false;

  partner_id = "";
  partner_identifier = "";
  partner_data: any;
  editPartnerForm: FormGroup;

  imageChangedEvent: any = "";
  cropperReady = false;

  croppedImageFile: File;

  user_image: any;
  identify_document_image: any;
  imageToShow: any;

  public uploader: FileUploader = new FileUploader({
    url: "",
    itemAlias: "image",
  });

  @ViewChild("beneficiariesTable") table: any;

  editing = {};
  //#endregion

  //#region UPLOAD IDENTIFY DOCUMENT
  identify_document_image_file;
  //#endregion

  currentTab = "tab-personal";

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private route_data_service: RouteDataService,
    private public_service: PublicService,
    private admin_service: AdminService,
    private person_service: PersonService,
    private toastr: NGXToastrService,
    private ngbModalService: NgbModal
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;

    this.loadPartnerData();
    this.public_service.getSeverName().subscribe((data: any) => {
      this.server_name = data.SERVER_NAME;
    });

    // this.uploader.options.additionalParameter = { folder: 'tests2' }
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log("ImageUpload:uploaded:", item, status, response);
    };
  }
  loadPartnerData() {
    this.partner_data = null;
    this.identify_document_image = null;
    this.imageToShow = null;
    this.person_service
      .getDataPerson({
        usuario: this.current_user.usuario,
        codigo: this.partner_id,
        token: this.current_user.token,
      })
      .subscribe((partner_data_res: any) => {
        // console.log(partner_data_res);

        const res = partner_data_res.resultado;

        this.partner_data = {
          persona: res.persona[0],
          banco: res.banco[0] ? res.banco[0] : {},
          fiscal: res.fiscal[0] ? res.fiscal[0] : {},
          extra: res.extra[0] ? res.extra[0] : {},
          social: res.social,
          voz: res.voz,
          electronic: res.electronic,
          beneficiary: res.beneficiary,
        };
      });

    this.public_service
      .getUserImage(this.partner_id)
      .subscribe((response: any) => {
        // console.log("imagen de usuario:", response);
        this.imageToShow = "data:image/jpeg;base64," + response.base;
        this.canPrintCard = true;
        /* let that = this;
             this.createImageFromBlob(response, function (res) {
                // console.log(res);

                 that.imageToShow = "data:image/jpeg;base64," + res;
             });*/
      });
  }
  userImageLoaded() {
    this.canPrintCard = true;
  }

  createImageFromBlob(image: Blob, callback) {
    const reader = new FileReader();
    const that = this;
    reader.onload = function () {
      const dataUrl = reader.result;

      const base64 = dataUrl.toString().split(",")[1];
      // console.log(base64);
      callback(base64);
    };
    reader.readAsDataURL(image);
  }

  generateUserContract() {
    try {
      JsBarcode("#barcode", this.partner_id);

      const doc = new jsPDF();
      doc.setFont("helvetica", "regular", "normal");

      doc.setFontSize(18);
      doc.text("FICHA DE SOCIO", 80, 20);
      doc.setFontSize(8);
      doc.text("MUSICARTES", 11, 34);
      doc.addImage(MUSICARTES_LOGO_B64, "JPEG", 10, 10, 20, 20);

      doc.setFontSize(12);
      doc.rect(145, 45, 55, 55);
      doc.rect(145, 105, 55, 30);

      // BARCODE
      const barcode: any = document.querySelector("img#barcode");
      doc.addImage(barcode.src, 145, 105, 55, 30);

      doc.setFont("helvetica", "regular", "bold");

      doc.setFontSize(12);
      doc.text("INFORMACIÓN DE SOCIO", 10, 50);
      doc.text("INFORMACIÓN PERSONAL", 10, 85);
      doc.text("INFORMACIÓN DE CONTACTO", 10, 160);
      // doc.text(10,195,"INFORMACIÓN DE BENEFICIARIOS");
      doc.setLineWidth(1);
      doc.line(10, 51, 140, 51);
      doc.line(10, 86, 140, 86);
      doc.line(10, 161, 140, 161);
      // doc.line(10, 196, 140, 196)

      doc.setFont("helvetica", "regular", "normal");
      doc.setFontSize(10);

      doc.text("NO.", 10, 60);
      doc.text("TIPO", 36, 60);
      doc.text("ID MUSICARTES", 72, 60);
      doc.text("FECHA DE ALTA", 110, 60);

      doc.setFontSize(10);
      doc.text("NOMBRE", 10, 95);
      doc.text("ALIAS", 10, 105);
      doc.text("NACIONALIDAD", 10, 115);
      doc.text("IDENTIFICACIÓN", 10, 125);
      doc.text("GÉNERO", 10, 135);
      doc.text("FECHA DE NACIMIENTO", 10, 145);

      doc.text("DIRECCIÓN", 10, 170);
      doc.text("TELÉFONO 1", 10, 180);
      doc.text("E-MAIL 1", 10, 190);

      doc.setFontSize(14);
      doc.setFont("helvetica", "regular", "bold");
      /*doc.text(17, 69, this.partner_data.persona.tipo.toUpperCase());
        doc.text(51, 69, this.partner_data.persona.id_musicartes);
        doc.text(106, 69, this.partner_data.persona.fecha_inscripcion);*/

      doc.text(
        this.partner_data.persona.correlativo,
        12 - this.partner_data.persona.correlativo.length,
        69
      );
      doc.text(
        this.partner_data.persona.tipo.toUpperCase(),
        33 - this.partner_data.persona.tipo.length,
        69
      );
      doc.text(
        this.partner_data.persona.id_musicartes,
        80 - this.partner_data.persona.id_musicartes.length,
        69
      );
      doc.text(
        this.partner_data.persona.fecha_inscripcion,
        121 - this.partner_data.persona.fecha_inscripcion.length,
        69
      );

      doc.text(
        this.partner_data.persona.primer_nombre +
          " " +
          this.partner_data.persona.segundo_nombre +
          " " +
          this.partner_data.persona.primer_apellido +
          " " +
          this.partner_data.persona.segundo_apellido,
        55,
        95
      );
      doc.text(this.partner_data.persona.alias, 55, 105);
      doc.text(this.partner_data.persona.nacionalidad, 55, 115);
      doc.text(this.partner_data.persona.identificacion, 55, 125);
      doc.text(this.partner_data.persona.genero, 55, 135);
      doc.text(this.partner_data.persona.fecha_nacimiento, 55, 145);

      doc.text(this.partner_data.persona.direccion, 36, 170);
      doc.text(
        this.partner_data.voz.length >= 1
          ? this.partner_data.voz[0].telefono
          : "",
        36,
        180
      );
      // doc.text(105, 170, "35198690")
      doc.text(
        this.partner_data?.electronic[0]?.correo_electronico || "",
        36,
        190
      );

      doc.setFont("helvetica", "regular", "normal");
      doc.setFontSize(11);
      // doc.text(15, 255, "Comprendo que solicito formalmente mi admisión a la SOCIEDAD DE ARTISTAS DE LA MÚSICA y\nOBRAS AUDIOVISUALES -MUSICARTES- como socio (con derechos) o únicamente representado\nde esta entidad.")
      if (this.partner_data.persona.tipo == "socio") {
        doc.text(
          "Declaro que he solicitado mi admisión a la SOCIEDAD DE ARTISTAS DE LA MÚSICA y\nOBRAS AUDIOVISUALES -MUSICARTES- y he sido admitido como socio de esta entidad.",
          15,
          255
        );
      } else if (this.partner_data.persona.tipo == "representado") {
        doc.text(
          "Declaro que he solicitado mi admisión a la SOCIEDAD DE ARTISTAS DE LA MÚSICA y\nOBRAS AUDIOVISUALES -MUSICARTES- y he sido admitido como socio representado\nde esta entidad.",
          15,
          255
        );
      }

      doc.text(
        "Manifiestamente expreso, que con esta solicitud, me adhiero y acepto a todo lo regulado en los\nestatutos y reglamentos de MUSICARTES.",
        15,
        270
      );

      doc.line(120, 230, 180, 230);
      const partner_full_name =
        this.partner_data.persona.primer_nombre +
        " " +
        this.partner_data.persona.segundo_nombre +
        " " +
        this.partner_data.persona.primer_apellido +
        " " +
        this.partner_data.persona.segundo_apellido;
      doc.text(partner_full_name, 150 - partner_full_name.length, 235);

      doc.line(20, 230, 80, 230);
      doc.text("MUSICARTES", 36, 235);

      doc.setFontSize(10);
      doc.text("Guatemala " + moment().locale("es").format("LLL"), 69, 290);

      doc.addImage(this.imageToShow, 145, 45, 55, 55);
      doc.save("Constancia-" + this.partner_id + ".pdf");
    } catch (e) {
      this.toastr.typeErrorText(e);
    }
  }

  sendWelcomeMessage() {
    alertFunctions.sendWelcomeMessage((result) => {
      this.admin_service
        .sendWelcomeEmailToPartner(
          this.current_user.usuario,
          this.partner_id,
          this.partner_data.electronic[0].correo_electronico,
          this.partner_data.persona.primer_nombre +
            " " +
            this.partner_data.persona.primer_apellido
        )
        .subscribe((data: any) => {
          console.log(data);
          if (
            data &&
            data.resultado &&
            data.resultado.header &&
            data.resultado.header.type &&
            data.resultado.header.type === "success"
          ) {
            this.toastr.defaultSuccess();
          } else {
            this.toastr.typeError();
          }
        });
    });
  }

  addPartnerBeneficiary() {
    const modalRef = this.ngbModalService.open(
      CreatePersonBeneficiaryComponent,
      { size: "sm", windowClass: "hugeModal" }
    );
    modalRef.result
      .catch((err) => {
        console.log(err);
      })
      .then((result) => {
        console.log(result);
        if (result === "Ok") {
          this.toastr.defaultSuccess();
          this.loadPartnerData();
        }
      });
    modalRef.componentInstance.partner_id = this.partner_id;
    modalRef.componentInstance.partner_identifier =
      this.partner_data.persona.identificacion;
  }
  removePartnerBeneficiary(index) {
    console.log(index);
    // ((this.formEditBeneficiaryData.get('beneficiary') as FormArray).at(pos) as FormGroup).controls['flag'].setValue('d');
  }

  getFormValidationErrors() {
    Object.keys(this.editPartnerForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.editPartnerForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
  editPartner() {
    console.log(this.partner_data);
    this.editingPartner = true;
    this.editPartnerForm = this.formBuilder.group({
      persona: new FormGroup({
        id_musicartes: new FormControl(this.partner_data.persona.id_musicartes),
        identificacion: new FormControl(
          this.partner_data.persona.identificacion
            ? this.partner_data.persona.identificacion
            : ""
        ),

        primer_nombre: new FormControl(
          this.partner_data.persona.primer_nombre
            ? this.partner_data.persona.primer_nombre
            : ""
        ),
        segundo_nombre: new FormControl(
          this.partner_data.persona.segundo_nombre
            ? this.partner_data.persona.segundo_nombre
            : ""
        ),
        primer_apellido: new FormControl(
          this.partner_data.persona.primer_apellido
            ? this.partner_data.persona.primer_apellido
            : ""
        ),
        segundo_apellido: new FormControl(
          this.partner_data.persona.segundo_apellido
            ? this.partner_data.persona.segundo_apellido
            : ""
        ),
        apellido_casada: new FormControl(
          this.partner_data.persona.apellido_casada
            ? this.partner_data.persona.apellido_casada
            : ""
        ),

        alias: new FormControl(
          this.partner_data.persona.alias ? this.partner_data.persona.alias : ""
        ),

        fecha_nacimiento: new FormControl(
          this.partner_data.persona.fecha_nacimiento
            ? this.partner_data.persona.fecha_nacimiento
            : ""
        ),
        nacionalidad: new FormControl(
          this.partner_data.persona.nacionalidad
            ? this.partner_data.persona.nacionalidad
            : ""
        ),
        pais_nacimiento: new FormControl(
          this.partner_data.persona.pais_nacimiento
            ? this.partner_data.persona.pais_nacimiento
            : ""
        ),

        tipo: new FormControl(
          this.partner_data.persona.tipo ? this.partner_data.persona.tipo : ""
        ),
        fecha_alta: new FormControl(
          this.partner_data.persona.fecha_alta
            ? this.partner_data.persona.fecha_alta
            : ""
        ),
        inscripcion: new FormControl(
          this.partner_data.persona.fecha_inscripcion
            ? this.partner_data.persona.fecha_inscripcion
            : ""
        ),
        genero: new FormControl(
          this.partner_data.persona.genero
            ? this.partner_data.persona.genero
            : ""
        ),
        estado_civil: new FormControl(
          this.partner_data.persona.estado_civil
            ? this.partner_data.persona.estado_civil
            : ""
        ),
        direccion: new FormControl(
          this.partner_data.persona.direccion
            ? this.partner_data.persona.direccion
            : "",
          [this.validateChars]
        ),

        biografia: new FormControl(
          this.partner_data.persona.biografia
            ? this.partner_data.persona.biografia
            : ""
        ),
        // estado_persona: new FormControl(this.partner_data.persona.estado_persona ? this.partner_data.persona.estado_persona : 'v')
      }),

      voz: this.formBuilder.array([
        this.formBuilder.group({
          correlativo: new FormControl(
            this.partner_data.voz.length > 0
              ? this.partner_data.voz[0].correlativo
              : ""
          ),
          id_musicartes: new FormControl(
            this.partner_data.persona.id_musicartes
          ),
          codigo_identificacion: new FormControl(
            this.partner_data.persona.identificacion
              ? this.partner_data.persona.identificacion
              : ""
          ),
          tipo_contacto: new FormControl("p"),
          codigo_area: new FormControl(""),
          telefono: new FormControl(
            this.partner_data.voz.length > 0
              ? this.partner_data.voz[0].telefono
              : ""
          ),
        }),
        this.formBuilder.group({
          correlativo: new FormControl(
            this.partner_data.voz.length > 1
              ? this.partner_data.voz[1].correlativo
              : ""
          ),
          id_musicartes: new FormControl(
            this.partner_data.persona.id_musicartes
          ),
          codigo_identificacion: new FormControl(
            this.partner_data.persona.identificacion
              ? this.partner_data.persona.identificacion
              : ""
          ),
          tipo_contacto: new FormControl("p"),
          codigo_area: new FormControl(""),
          telefono: new FormControl(
            this.partner_data.voz.length > 1
              ? this.partner_data.voz[1].telefono
              : ""
          ),
        }),
      ]),

      electronic: this.formBuilder.array([
        this.formBuilder.group({
          correlativo: new FormControl(
            this.partner_data.electronic.length > 0
              ? this.partner_data.electronic[0].correlativo
              : ""
          ),
          id_musicartes: new FormControl(
            this.partner_data.persona.id_musicartes
          ),
          codigo_identificacion: new FormControl(
            this.partner_data.persona.identificacion
              ? this.partner_data.persona.identificacion
              : ""
          ),
          tipo_contacto: new FormControl("p"),
          tipo_correo: new FormControl("p"),
          correo_electronico: new FormControl(
            this.partner_data.electronic.length > 0
              ? this.partner_data.electronic[0].correo_electronico
              : ""
          ),
        }),
        this.formBuilder.group({
          correlativo: new FormControl(
            this.partner_data.electronic.length > 1
              ? this.partner_data.electronic[1].correlativo
              : ""
          ),
          id_musicartes: new FormControl(
            this.partner_data.persona.id_musicartes
          ),
          codigo_identificacion: new FormControl(
            this.partner_data.persona.identificacion
              ? this.partner_data.persona.identificacion
              : ""
          ),
          tipo_contacto: new FormControl("p"),
          tipo_correo: new FormControl("p"),
          correo_electronico: new FormControl(
            this.partner_data.electronic.length > 1
              ? this.partner_data.electronic[1].correo_electronico
              : ""
          ),
        }),
      ]),
      social: this.formBuilder.array([
        this.createSocialContact("facebook"),
        this.createSocialContact("twitter"),
        this.createSocialContact("instagram"),
        this.createSocialContact("linkedin"),
      ]),
      beneficiary: this.formBuilder.array([]),

      banco: new FormGroup({
        correlativo: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.correlativo : ""
        ),
        nombre: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.nombre_completo : ""
        ),
        banco: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.banco : ""
        ),
        cuenta: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.cuenta : ""
        ),
        producto: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.producto : ""
        ),
        moneda: new FormControl(
          this.partner_data.banco ? this.partner_data.banco.moneda : ""
        ),
      }),
      fiscal: new FormGroup({
        id_musicartes: new FormControl(
          this.partner_data.persona
            ? this.partner_data.persona.id_musicartes
            : ""
        ),
        codigo_identificacion: new FormControl(
          this.partner_data.persona
            ? this.partner_data.persona.identificacion
            : ""
        ),
        nit: new FormControl(
          this.partner_data.fiscal ? this.partner_data.fiscal.nit : ""
        ),
        nombre: new FormControl(
          this.partner_data.fiscal
            ? this.partner_data.fiscal.nombre_completo
            : ""
        ),
        direccion: new FormControl(
          this.partner_data.fiscal ? this.partner_data.fiscal.direccion : ""
        ),
        facturacion: new FormControl(
          this.partner_data.fiscal ? this.partner_data.fiscal.facturacion : ""
        ),
      }),
    });

    // if (this.partner_data.voz.length > 0) {
    //     this.partner_data.voz.forEach(voice_contact => {
    //         (this.editPartnerForm.get('voz') as FormArray).push(this.formBuilder.group({
    //             correlativo: new FormControl(voice_contact.correlativo),
    //             id_musicartes: new FormControl(this.partner_data.persona.id_musicartes),
    //             codigo_identificacion: new FormControl(this.partner_data.persona.identificacion ? this.partner_data.persona.identificacion : ''),
    //             tipo_contacto: new FormControl('p'),
    //             codigo_area: new FormControl(''),
    //             telefono: new FormControl(voice_contact.telefono)
    //         }));
    //     });
    // }

    // if (this.partner_data.electronic.length > 0) {
    //     this.partner_data.electronic.forEach(electronic_contact => {
    //         (this.editPartnerForm.get('electronic') as FormArray).push(this.formBuilder.group({
    //             correlativo: new FormControl(electronic_contact.correlativo),
    //             id_musicartes: new FormControl(this.partner_data.persona.id_musicartes),
    //             codigo_identificacion: new FormControl(this.partner_data.persona.identificacion ? this.partner_data.persona.identificacion : ''),
    //             tipo_contacto: new FormControl('p'),
    //             tipo_correo: new FormControl('p'),
    //             correo_electronico: new FormControl(electronic_contact.correo_electronico)
    //         }));
    //     });
    // }

    if (this.partner_data.social.length > 0) {
      this.editPartnerForm.get("social").value.forEach((element, index) => {
        this.partner_data.social.forEach((social_network) => {
          if (
            social_network.producto ===
            (this.editPartnerForm.get("social") as FormArray).at(index).value
              .producto
          ) {
            (<FormArray>this.editPartnerForm.controls["social"])
              .at(index)
              .setValue({
                correlativo: social_network.correlativo,
                producto: social_network.producto,
                usuario_red: social_network.usuario_red,
                url: social_network.url,
              });
          }
        });
      });
    }

    this.partner_data.beneficiary.forEach((partner_beneficiary) => {
      // console.log(partner_beneficiary);
      (this.editPartnerForm.get("beneficiary") as FormArray).push(
        this.formBuilder.group({
          correlativo: new FormControl(
            partner_beneficiary.correlativo
              ? partner_beneficiary.correlativo
              : ""
          ),
          // id_musicartes: new FormControl(this.partner_data.persona.id_musicartes),
          persona_identificacion: new FormControl(
            this.partner_data.persona.identificacion
              ? this.partner_data.persona.identificacion
              : ""
          ),
          identificador: new FormControl(
            partner_beneficiary.identificacion
              ? partner_beneficiary.identificacion
              : ""
          ),
          primer_nombre: new FormControl(
            partner_beneficiary.primer_nombre
              ? partner_beneficiary.primer_nombre
              : ""
          ),
          segundo_nombre: new FormControl(
            partner_beneficiary.segundo_nombre
              ? partner_beneficiary.segundo_nombre
              : ""
          ),
          primer_apellido: new FormControl(
            partner_beneficiary.primer_apellido
              ? partner_beneficiary.primer_apellido
              : ""
          ),
          segundo_apellido: new FormControl(
            partner_beneficiary.segundo_apellido
              ? partner_beneficiary.segundo_apellido
              : ""
          ),
          apellido_casada: new FormControl(
            partner_beneficiary.apellido_casada
              ? partner_beneficiary.apellido_casada
              : ""
          ),
          nacionalidad: new FormControl(
            partner_beneficiary.nacionalidad
              ? partner_beneficiary.nacionalidad
              : ""
          ),
          nacimiento: new FormControl(
            partner_beneficiary.nacimiento ? partner_beneficiary.nacimiento : ""
          ),
          genero: new FormControl(
            partner_beneficiary.genero ? partner_beneficiary.genero : ""
          ),
          porcentaje: new FormControl(
            partner_beneficiary.porcentaje ? partner_beneficiary.porcentaje : ""
          ),
          parentesco: new FormControl(
            partner_beneficiary.parentesco ? partner_beneficiary.parentesco : ""
          ),
          fallecimiento: new FormControl(""),
        })
      );
    });
  }
  createSocialContact(product): FormGroup {
    return this.formBuilder.group({
      // id_musicartes: new FormControl(this.partner_data.persona.id_musicartes),
      // codigo_identificacion: new FormControl(this.partner_data.persona.identificacion ? this.partner_data.persona.identificacion : ''),
      correlativo: new FormControl("0"),
      usuario_red: new FormControl(""),
      producto: product,
      url: new FormControl(""),
    });
  }
  savePartnerChanges() {
    const fieldsToSave = [];

    if (this.editPartnerForm.controls["persona"].dirty) {
      fieldsToSave.push([
        "persona",
        this.editPartnerForm.controls["persona"].value,
      ]);
    }

    if (this.editPartnerForm.controls["voz"].dirty) {
      const current_value = this.editPartnerForm.controls["voz"].value;
      const value_to_send = [];
      current_value.forEach((element) => {
        if (element.telefono) {
          value_to_send.push(element);
        }
      });
      fieldsToSave.push(["voz", value_to_send]);
    }

    if (this.editPartnerForm.controls["electronic"].dirty) {
      const current_value = this.editPartnerForm.controls["electronic"].value;
      const value_to_send = [];
      current_value.forEach((element) => {
        if (element.correo_electronico) {
          value_to_send.push(element);
        }
      });
      fieldsToSave.push(["electronic", value_to_send]);
    }

    if (this.editPartnerForm.controls["beneficiary"].dirty) {
      fieldsToSave.push([
        "beneficiary",
        this.editPartnerForm.controls["beneficiary"].value,
      ]);
    }

    if (this.editPartnerForm.controls["social"].dirty) {
      //const current_value = this.editPartnerForm.controls['social'].value;
      const value_to_send = [];
      this.editPartnerForm.get("social").value.forEach((element, index) => {
        if ((this.editPartnerForm.get("social") as FormArray).at(index).dirty) {
          value_to_send.push(element);
        }
      });
      fieldsToSave.push(["social", value_to_send]);
    }

    if (this.editPartnerForm.controls["banco"].dirty) {
      fieldsToSave.push([
        "bank",
        [this.editPartnerForm.controls["banco"].value],
      ]);
    }

    if (this.editPartnerForm.controls["fiscal"].dirty) {
      fieldsToSave.push([
        "tax",
        [this.editPartnerForm.controls["fiscal"].value],
      ]);
    }

    //  console.log(fieldsToSave);
    this.uploadIdentifyDocument();
    this.admin_service
      .savePartnerUpdates(
        this.current_user.usuario,
        this.partner_data.persona.id_musicartes,
        this.partner_data.persona.identificacion,
        fieldsToSave
      )
      .subscribe((data: any) => {
        console.log(data);
        if (data.state) {
          this.editingPartner = false;
          this.toastr.defaultSuccess();
          this.loadPartnerData();
        } else {
          this.toastr.typeError();
        }
      });
  }
  updateValue(event, cell, rowIndex) {
    console.log("inline editing rowIndex", rowIndex, cell);
    this.editing[rowIndex + "-" + cell] = false;
    this.partner_data.beneficiary[rowIndex][cell] = event.target.value;
    this.partner_data.beneficiary = [...this.partner_data.beneficiary];
  }
  onDetailToggle(event, index) {
    console.log("Detail Toggled", event, index);
  }

  toggleExpandRow(row, index) {
    console.log("Toggled Expand Row!", row, index);
    row.index = index;
    this.table.rowDetail.toggleExpandRow(row);
  }

  //#region partner_photo
  editPhoto() {
    const photo_input: HTMLElement = document.getElementById(
      "photo-input"
    ) as HTMLElement;
    photo_input.click();
  }
  doneEditingPhoto() {
    this.editingPhoto = false;
    this.canPrintCard = true;
    this.imageToShow = this.croppedImageFile;
    //  console.log("Subir imagen");
    if (this.croppedImageFile) {
      this.uploader.options.url = this.server_name + "/image-upload";
      this.uploader.addToQueue([this.croppedImageFile]);
      this.uploader.uploadAll();
    }
  }
  enableEditingPhoto() {
    this.editingPhoto = true;
  }
  cancelEditingPhoto() {
    this.editingPhoto = false;
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.editingPhoto = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageToShow = this.croppedImageFile;
    this.croppedImageFile = new File([event.file], this.partner_id);
  }
  imageLoaded() {
    this.cropperReady = true;
  }
  loadImageFailed() {
    console.log("Load failed");
  }
  uploadIdentifyDocument() {
    if (this.identify_document_image_file) {
      this.uploader.options.url =
        this.server_name + "/file-upload/" + this.partner_id;
      this.uploader.addToQueue([this.identify_document_image_file]);
      this.uploader.uploadAll();
    }
  }
  openIdentifyDocumentSelection() {
    const photo_input: HTMLElement = document.getElementById(
      "identify_document_image_input"
    ) as HTMLElement;
    photo_input.click();
  }
  changeIdentifyDocument(event: any) {
    // console.log(event);

    this.identify_document_image = event.target.files[0];
    this.identify_document_image_file = new File(
      [event.target.files[0]],
      "DPI-" + this.partner_id
    );
  }
  onTabChange(event: NgbTabChangeEvent) {
    // console.log(event);
    this.currentTab = event.nextId;
    if (this.currentTab == "tab-files") {
      this.public_service
        .getUserIdentifyDocument(this.partner_id)
        .subscribe((response: any) => {
          this.identify_document_image =
            "data:image/jpeg;base64," + response.base;
        });
    }
  }
  validateChars(control: FormControl) {
    const item = control.value;
    if (item && item.indexOf("#") != -1) {
      return {
        bad_characters: true,
      };
    }
    return null;
  }
}
