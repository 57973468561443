import { Component, OnInit } from "@angular/core";
import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";

@Component({
  selector: "app-view-reports",
  templateUrl: "./view-reports.component.html",
  styleUrls: ["./view-reports.component.scss"]
})
export class ViewReportsComponent implements OnInit {
  current_user: any;

  distribution_situations: any = [];
  report_years: any = [];
  report_channels: any = [];
  report_partners: any = [];

  partner_gender: any;
  partner_for_works_report;
  distribution_by_channel_year;
  distribution_channel;
  distribution_year;

  distribution_by_paid_distributions_year;

  distibution_report: any = {
    year: "",
    situation: ""
  };

  start_date;
  end_date;

  constructor(
    private route_data_service: RouteDataService,
    private admin_service: AdminService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.admin_service
      .reportGetUtils({
        user: this.current_user.usuario,
        info: { associated: "all" }
      })
      .subscribe((data: any) => {
        console.log(data);
        if (data) {
          this.report_years = data.body.info.anios;
          this.report_channels = data.body.info.canal;
          this.report_partners = data.body.info.socios;
          this.distribution_situations = data.body.info.situacion;

          //   console.log(this.report_years);
          //   console.log(this.report_channels);
          //   console.log(this.report_partners);
        }
      });
  }

  GenerateReportDistributionsByYear(year: any) {
    this.admin_service
      .reportDistributionsByYear({
        user: this.current_user.usuario,
        info: { year: year }
      })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }
  GenerateReportDistributionsByChannel(year: any, channel: any) {
    this.admin_service
      .reportDistributionsByChannel({
        user: this.current_user.usuario,
        info: { year: year, channel: channel }
      })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }
  GenerateReportDistributionsByPartner(year: any) {
    this.admin_service
      .reportPaidDistributionsByPartner({
        user: this.current_user.usuario,
        year: this.distibution_report.year,
        situation: this.distibution_report.situation
      })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }

  GenerateReportPartnersByGender(gender: any) {
    this.admin_service
      .reportPartnersByGender({
        user: this.current_user.usuario,
        info: { gender: gender }
      })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }
  GenerateReportPartnersEnrolled() {
    this.admin_service
      .reportPartnersEnrolled({
        user: this.current_user.usuario,
        info: { associated: "all" }
      })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }
  GenerateReportRegisteredWorks() {
    this.admin_service
      .reportRegisteredWorks({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }
  GenerateReportWorksByPartner(id: any) {
    this.admin_service
      .reportWorksByPartner({ user: this.current_user.usuario, id: id })
      .subscribe((data: any) => {
        if (data) {
          window.open(data.url, "_blank");
        } else {
          this.toastr.toast_error("No se obtuvieron datos para el reporte.");
        }
      });
  }

  GenerateReportPartnersByDate(date_start, date_end) {
    console.log(date_start, date_end);
    if (date_start && date_end) {
      this.admin_service
        .reportPartnersByInscriptionDate({
          user: this.current_user.usuario,
          profile: 1,
          date_start:
            date_start.year + "-" + date_start.month + "-" + date_start.day,
          date_end: date_end.year + "-" + date_end.month + "-" + date_end.day
        })
        .subscribe((data: any) => {
          if (data) {
            window.open(data.url, "_blank");
          } else {
            this.toastr.toast_error("No se obtuvieron datos para el reporte.");
          }
        });
    }
  }
}
