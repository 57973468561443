import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";
import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-create-record-company",
  templateUrl: "./create-record-company.component.html",
  styleUrls: ["./create-record-company.component.scss"]
})
export class CreateRecordCompanyComponent implements OnInit {
  current_user: any;

  recordCompanyForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private route_data_service: RouteDataService,
    private formBuilder: FormBuilder,
    private admin_service: AdminService,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.recordCompanyForm = this.formBuilder.group({
      name: new FormControl("", [Validators.required]),
      address: new FormControl(),
      phone: new FormControl()
    });
  }
  saveRecordCompany(): void {
    if (this.recordCompanyForm.valid) {
      let data_to_save = this.recordCompanyForm.value;
      data_to_save.user = this.current_user.usuario;
      this.admin_service
        .saveRecordCompanies(data_to_save)
        .subscribe((data: any) => {
          if (
            data &&
            data.header &&
            data.header.type &&
            data.header.type == "success"
          ) {
            this.toastr.dataSavedSuccess();
            this.activeModal.close("Ok");
          }
        });
    }
  }
}
