<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle d-lg-none float-left"
        data-toggle="collapse"
        (click)="toggleSidebar()"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <span class="d-lg-none navbar-right navbar-collapse-toggle">
        <a
          class="open-navbar-container"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarSupportedContent"
        >
          <i-feather name="more-vertical"></i-feather>
        </a>
      </span>
    </div>
    <div class="navbar-container">
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        [ngbCollapse]="isCollapsed"
      >
        <ul class="navbar-nav">
          <li class="nav-item mr-2  d-none d-lg-block">
            <a
              href="javascript:;"
              class="nav-link"
              id="navbar-fullscreen"
              appToggleFullscreen
              (click)="ToggleClass()"
            >
              <i-feather
                name="maximize"
                class="font-medium-3 blue-grey darken-4"
              ></i-feather>
              <p class="d-none">fullscreen</p>
            </a>
          </li>

          <li class="nav-item mr-2">
            <app-view-notificactions
              [placement]="placement"
            ></app-view-notificactions>
          </li>

          <li class="nav-item" ngbDropdown [placement]="placement">
            <a
              class="nav-link position-relative"
              id="dropdownBasic3"
              ngbDropdownToggle
            >
              <i-feather
                name="user"
                class="font-medium-3 blue-grey darken-4"
              ></i-feather>
              <p class="d-none">User Settings</p>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic3"
              class="text-left"
            >
              <!--<a class="dropdown-item py-1" routerLink="/pages/profile">
                <i class="ft-edit mr-2"></i>
                <span>My Profile</span>
              </a>-->
              <span class="dropdown-item">{{ username }}</span>
              <div class="dropdown-divider"></div>

              <a class="dropdown-item" (click)="openChangePassword()">
                <i-feather name="lock" class="mr-2"></i-feather>
                <span>Cambiar Contraseña</span>
              </a>

              <a class="dropdown-item" href="javascript:;" (click)="doLogout()">
                <i-feather name="power" class="mr-2"></i-feather>
                <span>Cerrar Sesión</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- Navbar (Header) Ends -->
