<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Repertorio</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- <pre style="max-height: 200px;">{{songForm.value.aggrement | json}}</pre> -->
  <!-- <div class="row">
    <div class="col-3">
      <p>{{songForm.valid}}</p>
      <pre style="max-height: 600px;">{{songForm.value | json}}</pre>
    </div>
    <div class="col-9">
  
    </div>

  </div> -->

  <aw-wizard
    #wizard2
    class="custom-wizard"
    navBarLayout="large-empty-symbols"
    [awNavigationMode]
    navigateBackward="allow"
    navigateForward="allow"
  >
    <aw-wizard-step stepTitle="Obra" [navigationSymbol]="{ symbol: '1' }">
      <div class="wizard-content">
        <div class="px-3">
          <form class="form" [formGroup]="songForm">
            <div class="form-body">
              <div formGroupName="recordered">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Titulo</label>
                      <input
                        type="text"
                        class="form-control form-control-xl"
                        formControlName="name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Duración</label>
                      <ngb-timepicker
                        [spinners]="false"
                        [seconds]="true"
                        formControlName="duration"
                      >
                      </ngb-timepicker>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>Artista</label>

                      <div class="input-group" *ngIf="artists">
                        <select
                          class="form-control"
                          (change)="getArtistDiscography($event.target.value)"
                          formControlName="code_band"
                        >
                          <option value=""></option>
                          <option
                            [value]="artist.codigo"
                            *ngFor="let artist of artists"
                          >
                            {{ artist.nombre }}
                          </option>
                        </select>
                        <div class="input-group-append">
                          <span ngbDropdown placement="top-right">
                            <button
                              class="btn btn-primary btn-raised"
                              ngbDropdownToggle
                            >
                              <i class="fa fa-cog"></i>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu-right">
                              <button
                                class="dropdown-item"
                                (click)="openCreateArtist()"
                              >
                                Crear Artista
                              </button>
                            </div>
                          </span>
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-center"
                        *ngIf="!artists"
                      >
                        <i-feather
                          name="loader"
                          class="fa fa-spin black text-center"
                        ></i-feather>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label>Discografía</label>

                      <div
                        class="d-flex justify-content-center"
                        *ngIf="loading_discographies"
                      >
                        <i-feather
                          name="loader"
                          class="fa fa-spin black text-center"
                        ></i-feather>
                      </div>

                      <div class="input-group" *ngIf="artist_discographies">
                        <select
                          class="form-control"
                          formControlName="code_disc"
                        >
                          <option value=""></option>
                          <option
                            [value]="discography.codigo"
                            *ngFor="let discography of artist_discographies"
                          >
                            {{ discography.nombre }}
                          </option>
                        </select>
                        <div
                          class="input-group-append"
                          *ngIf="artist_discographies"
                        >
                          <span ngbDropdown placement="top-right">
                            <button
                              class="btn btn-primary btn-raised"
                              ngbDropdownToggle
                            >
                              <i class="fa fa-cog"></i>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu-right">
                              <button
                                class="dropdown-item"
                                (click)="openCreateDiscography()"
                              >
                                Crear Discografía
                              </button>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Disquera</label>

                          <div
                            class="d-flex justify-content-center"
                            *ngIf="!record_companies"
                          >
                            <i-feather
                              name="loader"
                              class="fa fa-spin black text-center"
                            ></i-feather>
                          </div>

                          <div class="input-group" *ngIf="record_companies">
                            <select
                              class="form-control"
                              formControlName="record_company"
                            >
                              <option value=""></option>
                              <option
                                [value]="company.codigo"
                                *ngFor="let company of record_companies"
                              >
                                {{ company.nombre }}
                              </option>
                            </select>
                            <div class="input-group-append">
                              <span ngbDropdown placement="top-right">
                                <button
                                  class="btn btn-primary btn-raised"
                                  ngbDropdownToggle
                                >
                                  <i class="fa fa-cog"></i>
                                </button>
                                <div
                                  ngbDropdownMenu
                                  class="dropdown-menu-right"
                                >
                                  <button
                                    class="dropdown-item"
                                    (click)="openCreateRecordCompany()"
                                  >
                                    Crear Disquera
                                  </button>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Año de Publicación</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="publication"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Genero</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="gender"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-flex justify-content-center">
                  <div class="col-md-12 col-lg-12">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      (click)="distribution_collapse = !distribution_collapse"
                      [attr.aria-expanded]="!distribution_collapse"
                      aria-controls="distribution_ngbc"
                    >
                      Códigos de Distribución
                    </button>
                    <form
                      class="form"
                      [formGroup]="songForm"
                      id="distribution_ngbc"
                      [ngbCollapse]="distribution_collapse"
                    >
                      <div class="form-body">
                        <div class="row" formGroupName="distribution">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">ISRC</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="isrc"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">ISWC</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="iswc"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="">BMAT</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="bmat"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Convenio" [navigationSymbol]="{ symbol: '2' }">
      <div class="wizard-content">
        <!-- <pre style="max-height: 200px;">{{ partners_agreement | json }}</pre> -->
        <div class="px-3">
          <form class="form">
            <div class="form-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Buscar Artista</label>
                    <div class="input-group">
                      <div class="position-relative has-icon-left">
                        <input
                          class="form-control"
                          ngui-auto-complete
                          list-formatter="( nombre_sociedad ) nombre_completo"
                          max-num-list="5"
                          display-property-name="nombre_completo"
                          [(ngModel)]="searchArtistForAgreement"
                          [ngModelOptions]="{ standalone: true }"
                          [source]="enrolled_partners"
                          style="
                            width: 25vw !important;
                            max-width: 25vw !important;
                          "
                        />
                        <div class="form-control-position">
                          <i class="ft-search info"></i>
                        </div>
                      </div>
                      <div class="input-group-append">
                        <button
                          class="btn btn-raised btn-primary"
                          type="button"
                          (click)="addAgreement(searchArtistForAgreement)"
                        >
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block">Asignación de Convenios</label>
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="auto"
                        [(ngModel)]="assign_method"
                        (ngModelChange)="
                          this.songForm['controls'].recordered[
                            'controls'
                          ].assignment_method.setValue(assign_method);
                          handleAssignMethod()
                        "
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline1"
                        >Automático</label
                      >
                    </div>
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadioInline2"
                        checked
                        name="customRadioInline1"
                        class="custom-control-input"
                        value="manual"
                        [(ngModel)]="assign_method"
                        (ngModelChange)="
                          this.songForm['controls'].recordered[
                            'controls'
                          ].assignment_method.setValue(assign_method);
                          handleAssignMethod()
                        "
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline2"
                        >Manual</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="px-3">
          <!-- 
                  <p *ngIf="songForm">{{partners_agreement | json}}</p> -->

          <form [formGroup]="songForm">
            <div formArrayName="aggrement">
              <ngx-datatable
                #table
                class="bootstrap"
                [columnMode]="'flex'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [limit]="10"
                [rows]="this.songForm.value.aggrement"
              >
                <ngx-datatable-column [flexGrow]="0.2" name="#">
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span>{{ rowIndex + 1 }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="1.5"
                  prop="name"
                  name="Nombre"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span>{{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="0.7"
                  prop="alias"
                  name="Alias"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span *ngIf="row.alias !== ''">{{ row.alias }}</span>
                    <div *ngIf="row.alias === ''" class="mr-1">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          [value]="
                            this.songForm.value.aggrement[rowIndex].alias
                          "
                          (change)="
                            this.songForm.controls.aggrement.controls[
                              rowIndex
                            ].controls.alias.setValue($event.target.value)
                          "
                        />
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="0.75"
                  prop="society"
                  name="Sociedad"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span>{{ value }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="0.75"
                  prop="code_rol"
                  name="Rol"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <div class="ml-2 mr-2">
                      <div class="form-group">
                        <select
                          class="form-control"
                          (change)="
                            this.songForm.controls.aggrement.controls[
                              rowIndex
                            ].controls.code_rol.setValue($event.target.value);
                            handleAssignMethod()
                          "
                        >
                          <option value></option>
                          <option
                            [selected]="
                              row.code_rol === role.correlativo ? true : false
                            "
                            [value]="role.correlativo"
                            *ngFor="let role of roles"
                          >
                            {{ role.nombre }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="1"
                  prop="code_instrument"
                  name="Instrumento"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <div class="ml-2 mr-2">
                      <div class="form-group">
                        <select
                          class="form-control"
                          (change)="
                            this.songForm.controls.aggrement.controls[
                              rowIndex
                            ].controls.code_instrument.setValue(
                              $event.target.value
                            )
                          "
                        >
                          <option value></option>
                          <option
                            [selected]="
                              row.code_instrument === instrument.correlativo
                                ? true
                                : false
                            "
                            [value]="instrument.correlativo"
                            *ngFor="let instrument of instruments"
                          >
                            {{ instrument.nombre }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [flexGrow]="0.67" prop="percent_origin">
                  <ng-template
                    let-column="column"
                    ngx-datatable-header-template
                  >
                    <span
                      [className]="
                        currentWorkAgreementPercentage > 100 ||
                        currentWorkAgreementPercentage <= 0
                          ? 'text-danger'
                          : currentWorkAgreementPercentage === 100
                          ? 'text-success'
                          : ''
                      "
                    >
                      {{
                        "Convenio (%" + currentWorkAgreementPercentage + ")"
                      }}</span
                    >
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span *ngIf="assign_method === 'auto'">{{
                      row.percent_origin_fixed
                    }}</span>
                    <div *ngIf="assign_method === 'manual'" class="mr-1">
                      <div class="form-group">
                        <input
                          type="number"
                          step="0.01"
                          class="form-control"
                          [value]="
                            this.songForm.value.aggrement[rowIndex]
                              .percent_origin
                          "
                          (change)="
                            this.songForm.controls.aggrement.controls[
                              rowIndex
                            ].controls.percent_origin.setValue(
                              $event.target.value
                            );
                            handleAssignMethod()
                          "
                        />
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [flexGrow]="0.6"
                  prop="percent_fixed"
                  name="Distribución (%)"
                >
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-row="row"
                  >
                    <span>{{
                      this.songForm.value.aggrement[rowIndex].percent_fixed
                    }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [flexGrow]="0.5" name="Acción">
                  <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                  >
                    <div class="px-3">
                      <i-feather
                        name="x-circle"
                        class="danger mr-2"
                        style="cursor: pointer"
                        (click)="removeAgreementFromList(row, rowIndex)"
                      ></i-feather>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </form>
        </div>
      </div>
    </aw-wizard-step>
  </aw-wizard>
</div>

<div class="modal-footer">
  <!-- <span class="danger mr-auto" *ngIf="saving_work_message !='' ">{{saving_work_message}}</span> -->

  <!-- <button type="button" class="btn btn-raised btn-primary" (click)="assignDistribution()"
    *ngIf="partners_agreement.length>=1">
    <i-percent></i-percent> Validar Convenio
  </button> -->

  <button type="button" class="btn btn-success btn-raised" (click)="saveWork()">
    Guardar
  </button>
</div>
