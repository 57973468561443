import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDatepickerConfig, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NGXToastrService } from 'app/services/toastr.service';
import { AppointmentService } from 'app/services/appointment.service';

@Component({
    selector: 'app-view-appointment',
    templateUrl: './view-appointment.component.html',
    styleUrls: ['./view-appointment.component.scss']
})
export class ViewAppointmentComponent implements OnInit {

    current_user: any;

    currentEvent: any;
    editEventForm: FormGroup;
    canEditEvent: boolean = false;

    show_buttons: boolean = true;
    show_mark_seen: boolean = false;

    today: Date = new Date();
    limit_date: Date = new Date();


    constructor(
        public activeModal: NgbActiveModal,
        private appointment_service: AppointmentService,
        private formBuilder: FormBuilder,
        private calendar: NgbCalendar,
        private datepicker_config: NgbDatepickerConfig,
        private toastr: NGXToastrService,
    ) { }

    ngOnInit() {
        console.log("Abriendo en nuevo");
    }
    editEvent(event: any) {
        console.log(event);


        this.limit_date.setMonth(this.today.getMonth() + 1);
        this.datepicker_config.minDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
        this.datepicker_config.maxDate = { year: this.limit_date.getFullYear(), month: this.limit_date.getMonth() + 1, day: this.limit_date.getDate() };
        this.datepicker_config.outsideDays = 'hidden';
        this.datepicker_config.markDisabled = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

        this.editEventForm = this.formBuilder.group({
            _id: new FormControl(),
            fullname: new FormControl(),
            phone_number: new FormControl(),
            email: new FormControl(),
            date: new FormControl(),
            time: new FormControl(),
            subject: new FormControl(),
            comment: new FormControl(),
            creator: new FormControl(),
            state: new FormControl(),
            recipients: new FormControl()
        });

        this.canEditEvent = true;

        this.editEventForm.controls._id.setValue(event._id);
        this.editEventForm.controls.creator.setValue(event.creator);
        this.editEventForm.controls.state.setValue(event.state);
        this.editEventForm.controls.fullname.setValue(event.fullname);
        this.editEventForm.controls.phone_number.setValue(event.phone_number);
        this.editEventForm.controls.email.setValue(event.email);
        this.editEventForm.controls.date.setValue(event.date);
        this.editEventForm.controls.time.setValue(event.time);
        this.editEventForm.controls.comment.setValue(event.comment);
        this.editEventForm.controls.subject.setValue(event.subject);
        this.editEventForm.controls.recipients.setValue(event.recipients);
    }
    cancelEditEvent() {
        this.canEditEvent = false;
    }
    updateCurrentEvent() {

        this.appointment_service.updateAppointment(this.editEventForm.value).subscribe((data: any) => {
            console.log(data);
            this.currentEvent = this.editEventForm.value;
            this.editEventForm.reset();
            this.canEditEvent = false;
        });
    }
    cancelCurrentEvent(event: any) {
        this.appointment_service.cancelAppointment(this.currentEvent).subscribe((data: any) => {
            console.log(data);
            this.activeModal.close('EventCanceled');
            this.toastr.defaultSuccess();
        });
        this.activeModal.close('CancelUpdate');
    }

    sendCurrentEventConfirmation(event: any) {
        this.appointment_service.sendAppointmentConfirmation(this.currentEvent).subscribe((data: any) => {
            console.log(data);
        });
    }

    markAsSeen() {
        this.activeModal.close('mark_as_seen');
    }
}