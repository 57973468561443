<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Artista</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="createArtistForm">
  <!--<pre>{{createArtistForm.value | json}}</pre>-->
  <div class="row">
    <div class="col-12 col-md-5">
      <div class="card">
        <div class="card-header">

          <h4 class="card-title ">Datos Básicos</h4>
        </div>
        <div class="card-body">
          <div class="card-block">
            <form class="form" [formGroup]="createArtistForm">
              <div class="form-body" formGroupName="artist">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Nombre</label>
                      <input type="text" class="form-control" formControlName="name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Año de Fundación</label>
                      <select class="form-control" formControlName="fundation">
                        <option *ngFor="let year of ' '.repeat(currentYear - 1900).split(''), let x = index">{{currentYear
                          - x}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Biografía</label>
                      <textarea cols="30" rows="9" class="form-control" formControlName="bio"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 col-md-7">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title ">Discografía</h4>
        </div>
        <div class="card-body">
          <div class="card-block">
            <form class="form" [formGroup]="createDiscographyForm">
              <div class="form-body">
                <div class="row">
                  <div class="col-md">
                    <div class="form-group">
                      <label for="">Nombre</label>
                      <input type="text" name="" id="" class="form-control" formControlName="name" />
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <label for="">Año</label>
                      <select class="form-control" formControlName="publication">
                        <option *ngFor="let year of ' '.repeat(currentYear - 1900).split(''), let x = index">{{currentYear
                          - x}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <label for="">Tipo</label>
                      <select class="form-control" formControlName="label">
                        <option value="Albúm">Albúm</option>
                        <option value="Single">Single</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn btn-success btn-raised btn-block" (click)="addDiscography()"
                      *ngIf="createDiscographyForm.valid">Agregar
                      Discografía</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <ngx-datatable class="bootstrap" [headerHeight]="40" [limit]="4" [columnMode]="'force'"
                      [footerHeight]="40" [rowHeight]="'auto'" [rows]="discographies">
                      <ngx-datatable-column prop="name" name="Nombre"></ngx-datatable-column>
                      <ngx-datatable-column prop="publication" name="Año"></ngx-datatable-column>
                      <ngx-datatable-column prop="label" name="Tipo"></ngx-datatable-column>
                      <ngx-datatable-column name="Acción">
                        <ng-template let-rowIndex="rowIndex" let-row="row" let-value="value"
                          ngx-datatable-cell-template>
                          <div class="fonticon-container ">
                            <div class="fonticon-wrap">
                              <i-feather name="x" class="danger" style="cursor:pointer;"
                                (click)="removeDiscography(row,rowIndex)"></i-feather>
                            </div>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="modal-footer">
  <span class="danger mr-auto" *ngIf="creating_artist_result !='' ">{{creating_artist_result}}</span>
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="saveArtist()"
    *ngIf="createArtistForm.valid">
    Crear Artista
  </button>
</div>