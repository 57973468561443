<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-6">
        <input
          type="text"
          class="form-control"
          style="padding:8px;"
          placeholder="buscar"
          (keyup)="FilterMainList($event)"
        />
      </div>
      <div class="col-6 text-right">
        <button
          class="btn btn-raised btn-primary mr-1"
          (click)="loadMainList()"
        >
          <i class="ft-refresh-cw"></i> Actualizar
        </button>
      </div>
    </div>

    <div
      class="d-flex justify-content-center mt-5 mb-5"
      *ngIf="loading_data && !main_list_filtered"
    >
      <i-feather
        name="loader"
        class="fa fa-spin black text-center feather-loading"
      ></i-feather>
    </div>

    <ngx-datatable
      class="bootstrap"
      [columnMode]="'flex'"
      [headerHeight]="30"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [limit]="10"
      [rows]="main_list_filtered"
      *ngIf="!loading_data && main_list_filtered"
    >
      <ngx-datatable-column name="No." [flexGrow]="0.3">
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          let-value="value"
          ngx-datatable-cell-template
        >
          <span>{{ rowIndex + 1 }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        prop="codigo"
        name="Código"
        [flexGrow]="0.75"
      ></ngx-datatable-column>
      <ngx-datatable-column
        prop="nombre"
        name="Nombre"
        [flexGrow]="1"
      ></ngx-datatable-column>
      <ngx-datatable-column
        prop="alias"
        name="Alias"
        [flexGrow]="0.5"
      ></ngx-datatable-column>
      <ngx-datatable-column prop="obra" name="Obras" [flexGrow]="1.75">
        <ng-template
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <span *ngFor="let work of row.repertorio">
            {{ work.nombre_repertorio }} /
          </span>
        </ng-template>
      </ngx-datatable-column>
      

      <ngx-datatable-column name="Acción" [flexGrow]="0.5">
        <ng-template
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <div class="fonticon-container">
            <div class="fonticon-wrap">
              <i-feather
                name="user-plus"
                class="primary"
                (click)="createIndependentPartnerInvitation(row)"
              ></i-feather>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
