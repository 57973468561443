import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';

import {
    Facebook, Instagram, Linkedin, Twitter,
    Printer, Edit, Save, X, Lock, XCircle, PlusCircle,
    Calendar, UploadCloud, Upload, Edit2, Check, Share2,
    Navigation, Eye, Percent, Disc, User, Maximize, Bell,
    MoreVertical, Power, RefreshCw, FileText, DollarSign,
    PieChart, Users, Home, Circle, UserPlus, Activity,
    BarChart2, Globe, Book, Loader, Music
} from 'angular-feather/icons';

const icons = {
    Facebook, Instagram, Linkedin, Twitter, Printer, Edit,
    Save, X, Lock, XCircle, PlusCircle, Calendar,
    UploadCloud, Upload, Edit2, Check, Share2, Navigation,
    Eye, Disc, UserPlus, Maximize, Bell,
    MoreVertical, Power, RefreshCw, FileText, DollarSign, PieChart,
    Users, Home, Circle, Percent, User, Activity, BarChart2, Globe,
    Book, Loader, Music
}

@NgModule({
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ]
})
export class FeatherIconsModule { }
