<div class="row">
  <div class="col-sm-12">
    <h4 class="content-header">Reportes</h4>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body ">
        <div class="card-block">
          <div class="px-3 mb-3 mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 20%">Reporte</th>
                  <th style="width: 60%">Parámetros</th>
                  <th style="width: 20%">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Socios Inscritos
                  </td>
                  <td></td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="GenerateReportPartnersEnrolled()"
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Socios por Género
                  </td>
                  <td>
                    <select class="form-control" [(ngModel)]="partner_gender">
                      <option value="" disabled selected>Género</option>
                      <option value="masculino">Masculino</option>
                      <option value="femenino">Femenino</option>
                    </select>
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="GenerateReportPartnersByGender(partner_gender)"
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Obras Registradas
                  </td>
                  <td></td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="GenerateReportRegisteredWorks()"
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Obras Por Socios
                  </td>
                  <td>
                    <input
                      class="form-control"
                      ngui-auto-complete
                      list-formatter="(id_musicartes) nombre_completo"
                      display-property-name="nombre_completo"
                      [(ngModel)]="partner_for_works_report"
                      [ngModelOptions]="{ standalone: true }"
                      [source]="report_partners"
                      auto-complete-placeholder="Nombre de Socio"
                    />
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary"
                      (click)="
                        GenerateReportWorksByPartner(
                          partner_for_works_report.id_musicartes
                        )
                      "
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Distribuciónes por Año
                  </td>
                  <td>
                    <select
                      [(ngModel)]="distribution_year"
                      class="form-control"
                    >
                      <option value="" disabled selected>Año</option>
                      <option
                        [value]="year.anio"
                        *ngFor="let year of report_years"
                      >
                        {{ year.anio }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="
                        GenerateReportDistributionsByYear(distribution_year)
                      "
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Distribuciónes por Canal
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-lg-6">
                        <select
                          [(ngModel)]="distribution_by_channel_year"
                          class="form-control"
                        >
                          <option value="" disabled selected>Año</option>
                          <option
                            [value]="year.anio"
                            *ngFor="let year of report_years"
                          >
                            {{ year.anio }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-6">
                        <select
                          [(ngModel)]="distribution_channel"
                          class="form-control"
                        >
                          <option value="" disabled selected
                            >Canal de Distribución</option
                          >
                          <option
                            [value]="channel.canal_nombre"
                            *ngFor="let channel of report_channels"
                          >
                            {{ channel.canal_nombre }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="
                        GenerateReportDistributionsByChannel(
                          distribution_by_channel_year,
                          distribution_channel
                        )
                      "
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Distribuciones
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-lg-6">
                        <select
                          [(ngModel)]="distibution_report.year"
                          class="form-control"
                        >
                          <option value="" disabled selected>Año</option>
                          <option
                            [value]="year.anio"
                            *ngFor="let year of report_years"
                          >
                            {{ year.anio }}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-6">
                        <select
                          class="form-control"
                          [(ngModel)]="distibution_report.situation"
                        >
                          <option value="" disabled selected>Situación</option>
                          <option
                            [value]="situation.nombre"
                            *ngFor="let situation of distribution_situations"
                            >{{ situation.nombre }}</option
                          >
                        </select>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="
                        GenerateReportDistributionsByPartner(
                          distribution_by_paid_distributions_year
                        )
                      "
                    >
                      Generar
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    Socios Inscritos Por Fecha
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="input-group">
                          <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            
                            ngbDatepicker
                            #d2="ngbDatepicker"
                            [(ngModel)]="start_date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary"
                              (click)="d2.toggle()"
                              type="button"
                            >
                              <i-feather name="calendar"></i-feather>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="input-group">
                          <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            ngbDatepicker
                            #d="ngbDatepicker"
                            [(ngModel)]="end_date"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary"
                              (click)="d.toggle()"
                              type="button"
                            >
                              <i-feather name="calendar"></i-feather>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn btn-block btn-raised btn-primary mr-1"
                      (click)="GenerateReportPartnersByDate(start_date, end_date)"
                    >
                      Generar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
