import { Injectable } from "@angular/core";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  constructor() {}

  exportExcel(excelData) {
    //Title, Header & Data
    const data = excelData.data;

    let headers = Object.keys(excelData.data[0]).map((key, index) => {
      return {
        header: key,
        key: key,
      };
    });

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("Participantes");

    worksheet.columns = headers;
    //let headerRow = worksheet.addRow(headers);
    //headerRow.eachCell((cell, number) => {});

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      console.log(d);
      let row = worksheet.addRow(d);
    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "reporte" + ".xlsx");
    });
  }
}
