import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../services/admin.service';
import { NGXToastrService } from 'app/services/toastr.service';
import { CreateArtistComponent } from 'app/create-artist/create-artist.component';
import * as  moment from 'moment';
import { CreateDiscographyComponent } from 'app/create-discography/create-discography.component';
import { RouteDataService } from 'app/services/route-data.service';

@Component({
    selector: 'app-view-artists',
    templateUrl: './view-artists.component.html',
    styleUrls: ['./view-artists.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewArtistsComponent implements OnInit {

    current_user: any;

    rows = [];
    temp = [];
    columns = [
        { prop: 'codigo', name: 'ID' },
        { prop: 'nombre', name: 'Nombre' },
        { prop: 'fundacion', name: 'Fundación' },
        { name: 'Acción' },
    ];
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('content') content: ElementRef;
    @ViewChild('modalRegisterArtist') registerWork: ElementRef;

    currentYear = moment().year();

    constructor(
        private route_data_service: RouteDataService,
        private admin_service: AdminService,
        private toastr: NGXToastrService,
        private ngbModalService: NgbModal,
    ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.getBandsList();
    }
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return d.codigo.toLowerCase().indexOf(val) !== -1 || d.nombre.toLowerCase().indexOf(val) !== -1 || d.fundacion.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.rows = temp;
        this.table.offset = 0;
    }
    getBandsList() {
        this.admin_service.getBandsList({ user: this.current_user.usuario }).subscribe((data: any) => {
            //console.log("Recupero bandas: ", data);
            if (data && data.header && data.header.type && data.header.type == "success") {

                let bands_list = data.body.bandas;
                this.temp = [...bands_list];
                this.rows = bands_list;
            }
        })
    }

    currentArtist: any;

    openDiscography(artist: any) {
        this.currentArtist = artist;

        const modalRef = this.ngbModalService.open(CreateDiscographyComponent, { size: 'lg', windowClass: 'hugeModal' });
        modalRef.result.catch((err) => {
            console.log(err);
        }).then((result) => {
            if (result === "Ok") {
                //this.loadArtists();
            }
        });

        modalRef.componentInstance.idArtist = this.currentArtist.codigo;

    }
    openCreateArtist() {
        this.ngbModalService.open(CreateArtistComponent, {
            windowClass: 'hugeModal',
        }).result.catch((err) => {
            console.log(err);
        }).then((result) => {
            console.log(result);
            if (result === "Ok") {
                this.toastr.dataSavedSuccess();
                this.getBandsList();
            }
        });
    }

}
