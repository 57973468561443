<!-- <div class="row">
  <div class="col-12">
    <h4 class="content-header">Distribuciones </h4>
  </div>
</div>
<br> -->

<div class="row full-height-vh" *ngIf="current_participant_distributions.length == 0">
  <div class="col-12 d-flex align-items-center justify-content-center">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="w-75 mx-auto">
          <h1>Area en Construcción</h1>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="current_participant_distributions.length >= 1">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="px-3 mb-3 mt-3">
          <div class="row">
            <div class="col-6">

            </div>
            <div class="col-6 text-right">
              <button class="btn btn-raised btn-primary float-right mr-2"
                (click)="loadPartnerDistributions()">Actualizar</button>
            </div>
          </div>
          <div *ngIf="current_participant_detail">
            <div class="text-left row">
              <div class="col">
              
                <dl class="row">
                  <dt class="col-sm-3 text-right">Nombre</dt>
                  <dd class="col-sm-9">
                    <h3>{{current_participant_detail.integrante_nombre}}</h3>
                  </dd>
                </dl>
              </div>
              <div class="col">
              
                <dl class="row">
                  <dt class="col-sm-3 text-right">Monto Total</dt>
                  <dd class="col-sm-9">
                    <h3>Q {{current_participant_detail.monto_total}}</h3>
                  </dd>
                </dl>
              </div>
            </div>
            <ngx-datatable #table class="bootstrap" [rows]="current_participant_distributions" [columnMode]="'flex'"
              [headerHeight]="50" [footerHeight]="40" [rowHeight]="50" [limit]="10">

              <ngx-datatable-column class="display:none;" prop="correlativo" name="#" [flexGrow]="0.25">
              </ngx-datatable-column>

              <ngx-datatable-column prop="anio" name="Año" [flexGrow]="0.5"></ngx-datatable-column>

              <ngx-datatable-column prop="obra_codigo" name="Código" [flexGrow]="1"></ngx-datatable-column>

              <ngx-datatable-column prop="obra_nombre" name="Obra" [flexGrow]="1.5"></ngx-datatable-column>

              <ngx-datatable-column prop="artista_nombre" name="Artista" [flexGrow]="1.5"></ngx-datatable-column>

              <ngx-datatable-column prop="roles_nombre" name="Rol" [flexGrow]="1"></ngx-datatable-column>

              <ngx-datatable-column prop="instrumento_nombre" name="Instrumento" [flexGrow]="1"></ngx-datatable-column>

              <ngx-datatable-column prop="monto_integrante" name="Monto(Q)" [flexGrow]="1"></ngx-datatable-column>

              <ngx-datatable-column prop="situacion" name="Situación" [flexGrow]="1"></ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>