import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PersonService } from 'app/services/person.service';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
    selector: 'app-create-person-beneficiary',
    templateUrl: './create-person-beneficiary.component.html',
    styleUrls: ['./create-person-beneficiary.component.scss']
})
export class CreatePersonBeneficiaryComponent implements OnInit {

    current_user: any;
    partner_id: string = "";
    partner_identifier = "";

    beneficiaryForm: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private toastr: NGXToastrService,
        private person_service: PersonService) { }

    ngOnInit() {
        this.person_service.getSessionPerson().subscribe((data: any) => {
            if (data) {
                this.current_user = data;
            }
        })
        console.log(this.partner_id);
        console.log(this.partner_identifier);
        this.beneficiaryForm = this.formBuilder.group({
            identifier: new FormControl('', [Validators.required, Validators.minLength(5)]),
            first_name: new FormControl('', [Validators.required]),
            middle_name: new FormControl(''),
            last_name: new FormControl('', [Validators.required]),
            second_last_name: new FormControl(''),
            married_name: new FormControl(''),
            gender: new FormControl('', [Validators.required]),
            birthday: new FormControl(''),
            birth_place: new FormControl(''),
            percentage: new FormControl('', [Validators.required]),
            relationship: new FormControl('', [Validators.required]),
        });
    }

    saveBeneficiary() {
        if (this.beneficiaryForm.valid) {
            let beneficiary_to_save = this.beneficiaryForm.value;
            beneficiary_to_save.birthday = beneficiary_to_save.birthday.year + "-" +
                ((beneficiary_to_save.birthday.month.toString().length > 1) ? beneficiary_to_save.birthday.month : ("0" + beneficiary_to_save.birthday.month)) + "-" +
                ((beneficiary_to_save.birthday.day.toString().length > 1) ? beneficiary_to_save.birthday.day : ("0" + beneficiary_to_save.birthday.day));

            this.person_service.addPersonBeneficiary(
                { user: this.current_user.usuario, id_musicartes: this.partner_id, socio_identificacion: this.partner_identifier, beneficiary: [beneficiary_to_save] },
            ).subscribe((data: any) => {
                if (data && data.header && data.header.type) {
                    if (data.header.type == "success") {
                        this.activeModal.close('Ok');
                    } else {
                        this.toastr.typeErrorText(data.header.msg);
                    }
                } else {
                    this.toastr.typeError();
                }

            });

        }
    }
}
