<section id="companies">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Editoriales Musicales</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="px-3 mb-3 mt-3">
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  style="padding: 8px"
                  placeholder="buscar"
                  (keyup)="updateFilter($event)"
                />
              </div>
              <div class="col-6 text-right">
                <button
                  class="btn btn-raised btn-primary mr-1"
                  (click)="openRegisterNewCompany()"
                >
                  <i-feather name="user"></i-feather> Crear Editorial
                </button>
                <button
                  class="btn btn-raised btn-primary mr-1"
                  (click)="getCompaniesList()"
                >
                  <i-feather name="refresh-cw"></i-feather> Actualizar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ngx-datatable
                  #table
                  class="bootstrap"
                  [rows]="rows"
                  [columns]="columns"
                  [columnMode]="'force'"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="'auto'"
                  [limit]="10"
                >
                  <ngx-datatable-column
                    prop="correlative"
                    name="#"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="name"
                    name="Nombre"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    prop="accion"
                    name="Acción"
                    [flexGrow]="0.8"
                  >
                    <ng-template
                      let-row="row"
                      let-value="value"
                      let-rowIndex="rowIndex"
                      ngx-datatable-cell-template
                    >
                      <div class="fonticon-container">
                        <div class="fonticon-wrap mr-3">
                          <i-feather
                            name="edit"
                            class="warning"
                            (click)="editModel(row)"
                          ></i-feather>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
