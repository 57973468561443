import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation, Input } from '@angular/core';

import { AdminService } from 'app/services/admin.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterPersonModalComponent } from 'app/register-person-modal/register-person-modal.component';
import { RouteDataService } from 'app/services/route-data.service';
import { CreatePersonInvitationComponent } from 'app/create-person-invitation/create-person-invitation.component';
import { NGXToastrService } from 'app/services/toastr.service';

@Component({
    selector: 'app-view-invited-partners',
    templateUrl: './view-invited-partners.component.html',
    styleUrls: ['./view-invited-partners.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ViewInvitedPartnersComponent implements OnInit {

    loading_data: boolean = true;

    @Input() current_user: any;

    @Input() invited_partners = [];
    invited_partners_filtered = null;
    partner_to_filter = '';


    @ViewChild('content') content: ElementRef;
    constructor(
        private admin_service: AdminService,
        private ngbModalService: NgbModal,
        private toastr: NGXToastrService
    ) { }


    ngOnInit() {

        this.invited_partners_filtered = this.invited_partners;
        this.loading_data = false;
    }

    getGuestUsers() {
        this.partner_to_filter = '';
        this.loading_data = true;
        this.invited_partners = this.invited_partners_filtered = null;
        this.admin_service.getUserList({ token: this.current_user.token, action: "new", user: this.current_user.usuario })
            .subscribe((data: any) => {
                this.loading_data = false;
                if (data && data.resultado && data.resultado.header && data.resultado.header.type == "success") {
                    this.invited_partners = this.invited_partners_filtered = data.resultado.body;
                } else {
                    this.toastr.toast_error("No se encontraron Socios.");
                    this.invited_partners = this.invited_partners_filtered = [];
                }

            });
    }
    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.invited_partners.filter(function (d) {
            return d.id.toLowerCase().indexOf(val) !== -1 ||
                d.nombre.toLowerCase().indexOf(val) !== -1 ||
                d.fecha.toLowerCase().indexOf(val) !== -1 ||
                d.correo.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.invited_partners_filtered = temp;
    }

    public getGuestData(id: string) {
        this.admin_service.getGuestUserData({ id: id }).subscribe((data: any) => {
            console.log(data);

        });
    }

    openRegisterUserForm(id_musicartes: string, user_email: string) {
        if (id_musicartes && user_email) {
            const modal = this.ngbModalService.open(RegisterPersonModalComponent, {
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                centered: true,
                windowClass: 'hugeModal'
            });
            (<RegisterPersonModalComponent>modal.componentInstance).id_musicartes = id_musicartes;
            (<RegisterPersonModalComponent>modal.componentInstance).user_email = user_email;
            modal.result.then((result) => {
                console.log(result);
                if (result === "SUCCESS") {
                    this.getGuestUsers();
                }
            }, (reason) => {
                console.log(reason);
                this.getGuestUsers();
            });
        } else {
            console.log("No se obtuvo id musicartes o user email", id_musicartes, user_email);
        }
    }
    openInvitePerson() {
        const modalRef = this.ngbModalService.open(CreatePersonInvitationComponent, { size: 'lg' });
        modalRef.result.then((result) => {
            console.log(result);
        }, (reason) => {
            console.log(reason);
        })
    }
}
