<div class="row">
  <div class="col-12">
    <h4 class="content-header">Notificaciones</h4>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-12">
    <div *ngFor="let notification of notifications; let notification_index = index;">
      <div class="card">

        <div class="card-header text-center">
          <h3 class="card-title <text-bold-500 primary float-left">
            {{notification.subject}}
          </h3>
        </div>

        <div class="card-block pt-3 text-left">

          <div class="row">
            <div class="col-md-12 col-lg-2">
              <dl>
                <dt>Fecha:</dt>
                <dl>{{notification.date.day +"/"+ notification.date.month +"/"+ notification.date.year}}</dl>
                <dt>Horario:</dt>
                <dl>{{notification.time}}</dl>
              </dl>
            </div>
            <div class="col-md-12 col-lg-10">
              <dl>
                <dt>Notas:</dt>
                <dl>{{notification.comment}}</dl>

              </dl>
            </div>
          </div>

          <div class="actions float-right"
            *ngIf="notification.seen_by && !notification.seen_by.includes(current_user.usuario)">
            <i-feather name="check" class="mr-2 info" style="cursor:pointer;"
              (click)="markNotificationSeen(notification._id,notification_index)"></i-feather>
          </div>

        </div>

        <div class="card-footer text-muted">
          <div class="row">
            <div class="col">
              <p class="text-left">
                <small>{{notification.creator}}</small>
              </p>
            </div>
            <div class="col">
              <p class="text-right">
                <small>{{notification.time_from_published}}</small>
              </p>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>