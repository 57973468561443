import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { Observable } from "rxjs";

import { Person } from "app/shared/models/person";

import { PersonService } from "app/services/person.service";
import { RouteDataService } from "app/services/route-data.service";
import { UploadFileService } from "app/services/upload-file.service";
import { AWSService } from "app/services/aws.services";
import { NGXToastrService } from "app/services/toastr.service";
import { PublicService } from "app/services/public.service";
import { NgbTabChangeEvent, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreatePersonBeneficiaryComponent } from "app/create-person-beneficiary/create-person-beneficiary.component";

@Component({
  selector: "app-person-profile",
  templateUrl: "./person-profile.component.html",
  styleUrls: ["./person-profile.component.scss"],
})
export class PersonProfileComponent implements OnInit {
  @ViewChild("uploadImageInput")
  uploadImageInput: ElementRef;

  current_user: any;

  local_id: string = "";
  currentPage: string = "Resumen";
  personModel: Person;

  selectedFile: File;
  selectedBannerFile: File;

  tempProfilePic: any;
  tempBannerPic: any;

  GalleryImages: any;
  Gallery: Observable<Array<string>>;
  uploadStatus: Observable<boolean>;

  editPersonalData: boolean = false;
  editSocialData: boolean = false;
  editBankData: boolean = false;
  editTaxData: boolean = false;
  editContactData: boolean = false;
  editBeneficiaryData: boolean = false;

  formEditPersonalData: FormGroup;
  formEditSocialData: FormGroup;
  formEditBankData: FormGroup;
  formEditTaxData: FormGroup;
  formEditContactData: FormGroup;
  formEditBeneficiaryData: FormGroup;

  constructor(
    private route_data_service: RouteDataService,
    private personService: PersonService,
    private ufs: UploadFileService,
    private aws: AWSService,
    private formBuilder: FormBuilder,
    private public_service: PublicService,
    private toastr: NGXToastrService,
    private ngbModalService: NgbModal
  ) {
    this.tempBannerPic = "assets/musicartes/profile_banner_3.jpg";
    this.tempProfilePic = "assets/musicartes/profile_avatar.jpg";
  }

  ngOnInit() {
    this.loadUserData();
  }

  partner_picture: any;

  loadUserData() {
    this.current_user = this.route_data_service.tempData;

    this.local_id = this.current_user.id;

    this.tempBannerPic = "assets/musicartes/profile_banner_3.jpg";
    this.personService
      .getDataPerson(this.current_user)
      .subscribe((result_data_person: any) => {
        if (
          result_data_person &&
          result_data_person.resultado &&
          result_data_person.resultado.persona
        ) {
          //  console.log("result_data_person: " + JSON.stringify(result_data_person));
          this.personModel = result_data_person.resultado;
          //console.log(this.personModel.persona.id_musicartes);

          this.public_service
            .getUserImage(this.personModel.persona[0].id_musicartes)
            .subscribe((response_image: any) => {
              console.log("imagen de usuario:", response_image);
              if (response_image) {
                this.partner_picture =
                  "data:image/jpeg;base64," + response_image.base;
              }
            });
        } else {
          this.toastr.typeErrorText("No se han podido recuperar tus datos.");
        }
      });
  }
  showPage(page: string) {
    this.currentPage = page;
    if (page === "Galeria") {
      this.Gallery = this.aws.getGalerryImages(this.local_id);
    }
  }

  onBannerImageChanged(event): void {
    this.selectedBannerFile = event.target.files[0];
    var file: File = event.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      that.tempBannerPic = loadEvent.target.result;
    };
    myReader.readAsDataURL(file);
  }
  uploadBanner() {
    this.ufs.uploadFile(this.selectedBannerFile, this.local_id, "banner");
  }
  onProfileImageChanged(event): void {
    this.selectedFile = event.target.files[0];
    var file: File = event.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      that.tempProfilePic = loadEvent.target.result;
    };

    myReader.readAsDataURL(file);
    this.ufs.uploadFile(this.selectedFile, this.local_id, "profile");
    this.toastr.UploadSuccess();
  }
  onUploadImageChanged(event, id): void {
    this.selectedFile = event.target.files[0];
    var file: File = event.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      that.Gallery[id] = loadEvent.target.result;
    };

    myReader.readAsDataURL(file);
    if (file.name.split(".").pop().toLocaleLowerCase() === "jpg") {
      this.ufs.uploadFile(this.selectedFile, this.local_id + "/Gallery", id);
      this.toastr.UploadSuccess();
    } else {
      console.log("no jpg");
    }
  }

  upload() {
    console.log(this.selectedFile);
    const file = this.selectedFile;
    this.aws.uploadFile(
      this.selectedFile,
      this.local_id + "/Gallery",
      "profile"
    );
  }
  uploadImage(id: number) {
    console.log("actualizando imagen: " + id);
    this.uploadImageInput.nativeElement.click();
  }

  //#region  UPDATE
  /*-----PERSONAL DATA -----*/
  activeEditPersonalData() {
    this.editPersonalData = !this.editPersonalData;
  }
  createFormEditPersonalDataForm() {
    console.log("creando form");
    this.formEditPersonalData = this.formBuilder.group({
      alias: new FormControl(this.personModel.persona[0].alias),
      bio: new FormControl(this.personModel.persona[0].biografia),
      nationality: new FormControl(this.personModel.persona[0].nacionalidad),
      address: new FormControl(this.personModel.persona[0].direccion),
      civil_status: new FormControl(this.personModel.persona[0].estado_civil),
      married_name: new FormControl(
        this.personModel.persona[0].apellido_casada
      ),
    });
  }
  updatePersonalData() {
    let data_to_save = {
      user: this.current_user.usuario,
      id: this.personModel.persona[0].id_musicartes,
    };
    Object.assign(data_to_save, this.formEditPersonalData.value);

    this.personService
      .updateData({
        user: this.current_user.usuario,
        action: "up-profile",
        params: data_to_save,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.resultado &&
          data.resultado.header &&
          data.resultado.header.type === "success"
        ) {
          this.activeEditPersonalData();
          this.toastr.fieldUpdatedSuccess();
          this.loadUserData();
        } else {
          this.toastr.fieldUpdatedError();
        }
      });
  }

  /*-----BANK DATA -----*/
  activeEditBankData() {
    this.editBankData = !this.editBankData;
  }
  createFormEditBankDataForm() {
    console.log("creando form");
    this.formEditBankData = this.formBuilder.group({
      bank: new FormControl(),
      product: new FormControl(),
      account: new FormControl(),
      coin: new FormControl(),
    });
    this.formEditBankData.controls.bank.setValue(
      this.personModel.banco[0].banco
    );
    this.formEditBankData.controls.product.setValue(
      this.personModel.banco[0].producto
    );
    this.formEditBankData.controls.account.setValue(
      this.personModel.banco[0].cuenta
    );
    this.formEditBankData.controls.coin.setValue(
      this.personModel.banco[0].moneda
    );
  }
  updateBankData() {
    this.personService
      .updateData({
        user: this.current_user.usuario,
        action: "up-bank",
        base_data: {
          id: this.personModel.persona[0].id_musicartes,
          first_name: this.personModel.persona[0].primer_nombre,
          last_name: this.personModel.persona[0].primer_apellido,
        },
        params: this.formEditBankData.value,
      })
      .subscribe((data: any) => {
        if (data) {
          this.activeEditBankData();
          this.toastr.fieldUpdatedSuccess();
          this.loadUserData();
        } else {
          this.toastr.fieldUpdatedError();
        }
      });
  }

  /*-----SOCIAL DATA -----*/
  activeEditSocialData() {
    this.editSocialData = !this.editSocialData;
  }
  createFormEditSocialDataForm() {
    console.log("creando form");
    this.formEditSocialData = this.formBuilder.group({
      social: this.formBuilder.array([
        this.createSocialContact("facebook"),
        this.createSocialContact("twitter"),
        this.createSocialContact("instagram"),
        this.createSocialContact("linkedin"),
      ]),
    });

    if (this.personModel.social.length > 0) {
      this.formEditSocialData.get("social").value.forEach((element, index) => {
        this.personModel.social.forEach((social_network) => {
          if (
            social_network.producto ===
            (this.formEditSocialData.get("social") as FormArray).at(index).value
              .producto
          ) {
            (<FormArray>this.formEditSocialData.controls["social"])
              .at(index)
              .setValue({
                correlativo: social_network.correlativo,
                producto: social_network.producto,
                usuario_red: social_network.usuario_red,
                url: social_network.url,
              });
          }
        });
      });
    }
  }
  createSocialContact(product): FormGroup {
    return this.formBuilder.group({
      correlativo: new FormControl("0"),
      usuario_red: new FormControl(""),
      producto: product,
      url: new FormControl(""),
    });
  }
  updateSocialData() {
    if (this.formEditSocialData.get("social").dirty) {
      let data_to_save = [];
      this.formEditSocialData.get("social").value.forEach((element, index) => {
        if (
          (this.formEditSocialData.get("social") as FormArray).at(index).dirty
        ) {
          data_to_save.push(element);
        }
      });

      this.personService
        .updateData({
          user: this.current_user.usuario,
          action: "up-social",
          params: {
            user: this.current_user.usuario,
            id_musicartes: this.personModel.persona[0].id_musicartes,
            socio_identificacion: this.personModel.persona[0].identificacion,
            info: data_to_save,
          },
        })
        .subscribe((data: any) => {
          if (
            data &&
            data.resultado &&
            data.resultado.header &&
            data.resultado.header.type === "success"
          ) {
            this.activeEditSocialData();
            this.toastr.fieldUpdatedSuccess();
            this.loadUserData();
          } else {
            this.toastr.fieldUpdatedError();
          }
        });
    }
  }
  /*----- BENEFICIARY DATA -----*/
  activeEditBeneficiaryData() {
    this.editBeneficiaryData = !this.editBeneficiaryData;
  }
  createEditBeneficiaryDataForm() {
    console.log("creando form");
    this.formEditBeneficiaryData = this.formBuilder.group({
      beneficiary: this.formBuilder.array([]),
    });

    this.personModel.beneficiary.forEach((beneficiary) => {
      (this.formEditBeneficiaryData.get("beneficiary") as FormArray).push(
        this.formBuilder.group({
          correlativo: new FormControl(beneficiary.correlativo),
          identificador: new FormControl(beneficiary.identificacion),
          primer_nombre: new FormControl(beneficiary.primer_nombre),
          segundo_nombre: new FormControl(beneficiary.segundo_nombre),
          primer_apellido: new FormControl(beneficiary.primer_apellido),
          segundo_apellido: new FormControl(beneficiary.segundo_apellido),
          apellido_casada: new FormControl(beneficiary.apellido_casada),
          genero: new FormControl(beneficiary.genero),
          nacimiento: new FormControl(beneficiary.nacimiento),
          nacionalidad: new FormControl(beneficiary.nacionalidad),
          email: new FormControl(""),
          code_area: [""],
          phone_number: new FormControl(""),
          parentesco: new FormControl(beneficiary.parentesco),
          porcentaje: new FormControl(beneficiary.porcentaje),
          fallecimiento: new FormControl(""),
          flag: "u",
        })
      );
    });
  }
  createBeneficiary(): FormGroup {
    //console.log("creando beneficiario");
    return this.formBuilder.group({
      id: new FormControl(),
      first_name: new FormControl(),
      middle_name: new FormControl(),
      last_name: new FormControl(),
      second_last_name: new FormControl(),
      married_name: new FormControl(),
      gender: new FormControl(),
      birthday: new FormControl(),
      birth_place: new FormControl(),
      email: new FormControl(),
      code_area: [""],
      phone_number: new FormControl(),
      relationship: new FormControl(),
      percent: new FormControl(),
    });
  }
  updateBeneficiaryData() {
    let beneficiaries = [];
    this.formEditBeneficiaryData.value.beneficiary.forEach((element) => {
      if (element.id != "") {
        beneficiaries.push(element);
      }
    });
    this.personService
      .updateData({
        action: "up-beneficiary",
        base_data: {
          user: {
            id: this.personModel.persona[0].id_musicartes,
            first_name: this.personModel.persona[0].primer_nombre,
            last_name: this.personModel.persona[0].primer_apellido,
          },
        },
        params: {
          user: this.current_user.usuario,
          id_musicartes: this.personModel.persona[0].id_musicartes,
          socio_identificacion: this.personModel.persona[0].identificacion,
          info: beneficiaries,
        },
      })
      .subscribe((data: any) => {
        if (data) {
          this.activeEditBeneficiaryData();
          this.toastr.fieldUpdatedSuccess();
          this.loadUserData();
        } else {
          this.toastr.fieldUpdatedError();
        }
      });
  }

  public beneficiaryChange($event: NgbTabChangeEvent) {
    if ($event.nextId === "bar") {
      $event.preventDefault();
      this.addBeneficiary();
    }
  }

  addBeneficiary() {
    const modalRef = this.ngbModalService.open(
      CreatePersonBeneficiaryComponent,
      { size: "sm", windowClass: "hugeModal" }
    );
    modalRef.result
      .catch((err) => {
        console.log(err);
      })
      .then((result) => {
        console.log(result);
        if (result === "Ok") {
          this.toastr.defaultSuccess();
          this.loadUserData();
        }
      });
    modalRef.componentInstance.partner_id =
      this.personModel.persona[0].id_musicartes;
    modalRef.componentInstance.partner_identifier =
      this.personModel.persona[0].identificacion;
  }
  removeBeneficiary(pos) {
    console.log(pos);
    /*this.formEditSocialData.get('social').value.forEach((element, index) => {

            if ((this.formEditSocialData.get('social') as FormArray).at(index).dirty) {
                data_to_save.push(element);
            }
        });*/
    (
      (this.formEditBeneficiaryData.get("beneficiary") as FormArray).at(
        pos
      ) as FormGroup
    ).controls["flag"].setValue("d");
  }
  /*-----CONTACT DATA -----*/
  activeEditContactData() {
    this.editContactData = !this.editContactData;
  }
  createFormEditContactDataForm() {
    this.formEditContactData = this.formBuilder.group({
      electronic: this.formBuilder.array([]),
      voice: this.formBuilder.array([]),
    });
    this.personModel.voz.forEach((voice_contact) => {
      (this.formEditContactData.get("voice") as FormArray).push(
        this.formBuilder.group({
          correlative: new FormControl(voice_contact.correlativo),
          type_contact: "p",
          code_area: "502",
          telephony: new FormControl(voice_contact.telefono, [
            Validators.required,
          ]),
        })
      );
    });
    this.personModel.electronic.forEach((electronic_contact) => {
      (this.formEditContactData.get("electronic") as FormArray).push(
        this.formBuilder.group({
          correlative: new FormControl(electronic_contact.correlativo),
          type_contact: "p",
          type_email: "t",
          email: new FormControl(electronic_contact.correo_electronico, [
            Validators.required,
          ]),
        })
      );
    });
  }

  updateContactData() {
    this.activeEditContactData();

    if (this.formEditContactData.get("electronic").dirty) {
      let data_to_save = [];
      this.formEditContactData
        .get("electronic")
        .value.forEach((element, index) => {
          if (
            (this.formEditContactData.get("electronic") as FormArray).at(index)
              .dirty
          ) {
            data_to_save.push(element);
          }
        });
      this.personService
        .updateData({
          action: "up-electronic",
          params: {
            user: this.current_user.usuario,
            id: this.personModel.persona[0].identificacion,
            detail: data_to_save,
          },
        })
        .subscribe((data: any) => {
          if (
            data &&
            data.resultado &&
            data.resultado.header &&
            data.resultado.header.type === "success"
          ) {
            this.toastr.fieldUpdatedSuccess();
            this.loadUserData();
          } else {
            this.toastr.fieldUpdatedError();
          }
        });
    }

    if (this.formEditContactData.get("voice").dirty) {
      let data_to_save = [];
      this.formEditContactData.get("voice").value.forEach((element, index) => {
        if (
          (this.formEditContactData.get("voice") as FormArray).at(index).dirty
        ) {
          data_to_save.push(element);
        }
      });
      this.personService
        .updateData({
          user: this.current_user.usuario,
          action: "up-voice",
          params: {
            user: this.current_user.usuario,
            id: this.personModel.persona[0].identificacion,
            detail: data_to_save,
          },
        })
        .subscribe((data: any) => {
          if (
            data &&
            data.resultado &&
            data.resultado.header &&
            data.resultado.header.type === "success"
          ) {
            this.toastr.fieldUpdatedSuccess();
            this.loadUserData();
          } else {
            this.toastr.fieldUpdatedError();
          }
        });
    }
  }

  /*-----TAX DATA-----*/
  activeEditTaxData() {
    this.editTaxData = !this.editTaxData;
  }
  createFormEditTaxDataForm() {
    console.log("creando form");
    this.formEditTaxData = this.formBuilder.group({
      address: new FormControl(),
    });
    this.formEditTaxData.controls.address.setValue(
      this.personModel.fiscal[0].direccion
    );
  }
  updateTaxData() {
    this.personService
      .updateData({
        user: this.current_user.usuario,
        action: "up-tax",
        base_data: {
          id: this.personModel.persona[0].id_musicartes,
          first_name: this.personModel.persona[0].primer_nombre,
          last_name: this.personModel.persona[0].primer_apellido,
        },
        params: this.formEditTaxData.value,
      })
      .subscribe((data: any) => {
        if (data) {
          this.activeEditTaxData();
          this.toastr.fieldUpdatedSuccess();
          this.loadUserData();
        } else {
          this.toastr.fieldUpdatedError();
        }
      });
  }
  //#endregion
}
