<section id="works">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Repertorios</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="px-3 mb-3 mt-3">
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  style="padding:8px;"
                  placeholder="buscar"
                  (keyup)="updateFilter($event)"
                />
              </div>
              <div class="col-6 text-right">
                <button
                  class="btn btn-raised btn-primary float-right mr-2"
                  (click)="OpenRegisterWork()"
                >
                  Agregar Obra
                </button>
                <button
                  class="btn btn-raised btn-primary float-right mr-2"
                  (click)="getSongs()"
                >
                  Actualizar
                </button>
              </div>
            </div>

            <div
              class="d-flex justify-content-center mt-5 mb-5"
              *ngIf="main_list_filtered.length == 0"
            >
              <i-feather
                name="loader"
                class="fa fa-spin black text-center feather-loading"
              ></i-feather>
            </div>

            <!-- <i-feather name="loader" class="fa fa-spin black text-center mt-5 mb-5 feather-loading"
              ></i-feather> -->

            <ngx-datatable
              *ngIf="main_list_filtered.length >= 1"
              class="bootstrap"
              [rows]="main_list_filtered"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="50"
              [limit]="10"
              [scrollbarH]="true"
            >
              <!--[count]="page.totalElements" [offset]="page.pageNumber" 
              (page)='setPage($event)'  [scrollbarV]="true" [externalPaging]="true"-->
              <ngx-datatable-column
                prop="obra_codigo"
                name="ID"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="banda_nombre"
                name="Artista"
                [width]="200"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="obra_nombre"
                name="Nombre"
                [width]="200"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="obra_publicacion"
                name="Año"
                [width]="50"
              ></ngx-datatable-column>
              <ngx-datatable-column
                prop="obra_duracion"
                name="Duración"
              ></ngx-datatable-column>

              <ngx-datatable-column
                prop="porcentaje_distribuido"
                name="Convenio Asignado (%)"
              ></ngx-datatable-column>

              <ngx-datatable-column
                prop="fecha"
                name="Fecha Creación"
                [flexGrow]="1"
              ></ngx-datatable-column>

              <ngx-datatable-column name="Acción">
                <ng-template
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <div class="fonticon-container">
                    <div class="fonticon-wrap">
                      <i-feather
                        name="percent"
                        class="success"
                        style="cursor:pointer;"
                        (click)="openWorkDetail(row.obra_codigo)"
                      ></i-feather>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
