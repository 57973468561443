<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Distribución</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"> 

  <div class="main-content">
    <div class="row text-left">
      <div class="col-12 px-5">
        <aw-wizard 
        #wizard2 
        class="custom-wizard" 
        [awNavigationMode]
        navigateBackward="allow"
        navigateForward="allow"
         navigationMode="free" 
         navBarLayout="large-empty-symbols"  >
          <aw-wizard-step [stepTitle]="''" [navigationSymbol]="{ symbol: '1' }">
            <div class="wizard-content">
              <div class="px-3">

                <ngb-alert type="danger" [dismissible]="false" *ngIf="show_percentage_error">
                  {{percentage_error}}
                </ngb-alert>

                <form class="form" #f="ngForm" [formGroup]="formCalculateDistribution" *ngIf="formCalculateDistribution">
                  <div class="row">
                    <!-- <div class="col-12">
                      <div class="form-group">
                        <label>Año a Distribuir</label>
                        <select class="form-control" formControlName="applicate_year">
                          <option [value]="year.anio" *ngFor="let year of monitoring_years">{{year.anio}}</option>
                        </select>
                        <small class="form-text text-muted danger"
                          *ngIf="(formCalculateDistribution.controls.applicate_year.dirty || formCalculateDistribution.controls.applicate_year.touched) && formCalculateDistribution.controls.applicate_year.invalid && formCalculateDistribution.controls.applicate_year.errors.required">
                          ¡Este campo es requerido!
                        </small>
                      </div>
                    </div> -->
                    <div class="col-12">
                      <div class="form-group">
                        <label>Monto a Distribuir</label>
                        <input type="number" class="form-control" formControlName="amount">
                        <small class="form-text text-muted danger"
                          *ngIf="(formCalculateDistribution.controls.amount.dirty || formCalculateDistribution.controls.amount.touched) && formCalculateDistribution.controls.amount.invalid && formCalculateDistribution.controls.amount.errors.required">
                          ¡Este campo es requerido!</small>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Porcentaje <strong>BMAT</strong> (%)</label>
                        <input type="number" class="form-control" formControlName="bmat">
                        <small class="form-text text-muted danger"
                          *ngIf="(formCalculateDistribution.controls.bmat.dirty || formCalculateDistribution.controls.bmat.touched) && formCalculateDistribution.controls.bmat.invalid && formCalculateDistribution.controls.bmat.errors.required">
                          ¡Este campo es requerido!
                        </small>

                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Porcentaje <strong>Encuesta Top Nacional</strong> (%)</label>
                        <input type="number" class="form-control" formControlName="encuesta">
                        <small class="form-text text-muted danger"
                          *ngIf="(formCalculateDistribution.controls.encuesta.dirty || formCalculateDistribution.controls.encuesta.touched) && formCalculateDistribution.controls.encuesta.invalid && formCalculateDistribution.controls.encuesta.errors.required">
                          ¡Este campo es requerido!
                        </small>

                      </div>
                    </div>
                    <div class="col-6" *ngIf="monitoring_years && monitoring_years.BMAT">
                      <div class="form-group">
                        <label>Monitoreo <strong>BMAT</strong></label>
                        <select class="form-control" formControlName="tokenb">
                          <option selected value></option>
                          <option [value]="monitoring_channel.token" *ngFor="let monitoring_channel of monitoring_years.BMAT">
                            {{ monitoring_channel.token  + ' ' + monitoring_channel.fecha }}
                          </option>
                        </select>

                      </div>
                    </div>
                    <div class="col-6" *ngIf="monitoring_years && monitoring_years.ENCUESTA">
                      <div class="form-group">
                        <label>Monitoreo <strong>Encuesta Top Nacional</strong></label>
                        <select class="form-control" formControlName="tokene">
                          <option selected value></option>
                          <option [value]="monitoring_channel.token" *ngFor="let monitoring_channel of monitoring_years.ENCUESTA">
                            {{ monitoring_channel.token + ' ' +  monitoring_channel.fecha }}
                          </option>
                        </select>

                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center" *ngIf="!loading">
                    <button class="btn btn-raised btn-primary float-right mr-2"
                      (click)="calculateDistribution()">Calcular
                      Distribución</button>
                  </div>

                  <div class="d-flex justify-content-center mt-5 mb-5" *ngIf="loading">
                    <i-feather name="loader" class="fa fa-spin black text-center feather-loading"></i-feather>
                  </div>
                </form>
              </div>
            </div>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="''" [navigationSymbol]="{ symbol: '2' }"
            *ngIf="distribution_temp_detail && show_distribution_calculated">
            <div class="wizard-content">
              <div class="px-3">

                <dl class="row">
                  <div class="col-6">
                    <dl class="row">
                      <dt class="col-sm-6 text-right">Año Distribución</dt>
                      <dd class="col-sm-6">{{distribution_temp_detail.distribucion_anio}}</dd>
                    </dl>
                  </div>

                </dl>

                <dl class="row">

                  <div class="col-6">
                    <dl class="row">
                      <dt class="col-sm-6 text-right">Monto a Distribuir</dt>
                      <dd class="col-sm-6">{{distribution_temp_detail.distribucion_monto}}</dd>
                    </dl>
                  </div>

                  <div class="col-6">
                    <dl class="row">
                      <dt class="col-sm-6 text-right">Total de Hits </dt>
                      <dd class="col-sm-6">{{distribution_temp_detail.total_hits}}</dd>
                    </dl>
                  </div>

                </dl>

                <div class='hr mt-3 mb-3'>
                  <span class='hr-title'>BMAT</span>
                </div>

                <dl class="row">
                  <div class="col">

                    <dt class="">Hits</dt>
                    <dd class="">{{distribution_temp_detail.bmat_hits}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Porcentaje Asignado </dt>
                    <dd class="">%{{distribution_temp_detail.distribucion_bmat}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Monto</dt>
                    <dd class="">Q{{distribution_temp_detail.bmat_monto}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Precio Hit Unitario</dt>
                    <dd class="">Q{{distribution_temp_detail.bmat_precio_hit}}</dd>

                  </div>

                </dl>

                <div class='hr mt-3 mb-3'>
                  <span class='hr-title'>Encuesta Top Nacional</span>
                </div>

                <dl class="row">

                  <div class="col">

                    <dt class="">Hits </dt>
                    <dd class="">{{distribution_temp_detail.encuesta_hits}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Porcentaje Asignado </dt>
                    <dd class=""> %{{distribution_temp_detail.distribucion_encuesta}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Monto </dt>
                    <dd class=""> Q{{distribution_temp_detail.encuesta_monto}}</dd>

                  </div>

                  <div class="col">

                    <dt class="">Precio Hit Unitario</dt>
                    <dd class="">Q{{distribution_temp_detail.encuesta_precio_hit}}</dd>

                  </div>

                </dl>

                <div class="d-flex justify-content-center" *ngIf="!loading">
                  <button class="btn btn-raised btn-primary " awNextStep>Siguiente</button>
                </div>

              </div>
            </div>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="''" [navigationSymbol]="{ symbol: '3' }"
            *ngIf="distribution_temp_detail && show_distribution_calculated">
            <div class="wizard-content">
              <div class="px-3">

                <ngx-datatable #table class='bootstrap' [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
                  [rowHeight]="'auto'" [limit]="10" [rows]='distribution_calculated'>
                  <!-- <ngx-datatable-column prop="correlativo" name="ID" [flexGrow]="0.25"></ngx-datatable-column> -->
                  <!-- <ngx-datatable-column prop="anio" name="Año" [flexGrow]="0.25"></ngx-datatable-column> -->
                  <ngx-datatable-column prop="obra" name="Obra" [flexGrow]="1.5"></ngx-datatable-column>
                  <ngx-datatable-column prop="artista" name="Artista" [flexGrow]="1.5"></ngx-datatable-column>
                  <!-- <ngx-datatable-column prop="canal" name="Canal" [flexGrow]="1"></ngx-datatable-column> -->
                  <ngx-datatable-column prop="hits" name="HITS" [flexGrow]="0.50"></ngx-datatable-column>
                  <ngx-datatable-column prop="monto_recaudado" name="Monto Recaudado" [flexGrow]="1">
                  </ngx-datatable-column>
                  <!-- <ngx-datatable-column prop="precio_canal" name="Precio Hit" [flexGrow]="1"></ngx-datatable-column> -->
                </ngx-datatable>

                <div class="d-flex justify-content-center" *ngIf="!loading">
                  <button class="btn btn-raised btn-primary float-right mr-2" (click)="saveDistribution()">Guardar
                    Distribución</button>
                </div>

                <div class="d-flex justify-content-center mt-5 mb-5" *ngIf="loading">
                  <i-feather name="loader" class="fa fa-spin black text-center feather-loading"></i-feather>
                </div>

              </div>
            </div>

          </aw-wizard-step>
        </aw-wizard>

      </div>
    </div>
  </div>

</div>