<section id="companies">
  <div class="row">
    <div class="col-12">
      <h4 class="content-header">Disqueras</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="px-3 mb-3 mt-3">
            <div class="row">
              <div class="col-6">
                <input type='text' class="form-control" style='padding:8px;' placeholder='buscar'
                  (keyup)='updateFilter($event)' />
              </div>
              <div class="col-6 text-right">

                <button class="btn btn-raised btn-primary mr-1" (click)="openRegisterNewCompany()">
                    <i-feather name="user"></i-feather> Crear Disquera
                </button>
                <button class="btn btn-raised btn-primary mr-1" (click)="getCompaniesList()">
                    <i-feather name="refresh-cw"></i-feather> Actualizar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <ngx-datatable #table class="bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                  [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10">
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>