<div class="modal-header">
  <h4 class="modal-title">Distribuciones por Socio</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('x')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-left row">
    <div class="col">
      <dl class="row">
        <dt class="col-sm-3 text-right">Código</dt>
        <dd class="col-sm-9">
          <h3>{{ current_participant_detail.partner_code }}</h3>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-3 text-right">Nombre</dt>
        <dd class="col-sm-9">
          <h3>{{ current_participant_detail.partner_name }}</h3>
        </dd>
      </dl>
    </div>
    <div class="col">
      <dl class="row">
        <dt class="col-sm-3 text-right">Distribución Seleccionada</dt>
        <dd class="col-sm-9">
          <h3>{{ current_participant_detail.selected_distribution }}</h3>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-3 text-right">Monto Recaudado</dt>
        <dd class="col-sm-9">
          <h3>Q {{ formatNumber(current_participant_detail.total) }}</h3>
        </dd>
      </dl>
      <!-- <dl class="row">
        <dt class="col-sm-3 text-right">Monto Pagado</dt>
        <dd class="col-sm-9">
          <h3>Q {{ formatNumber(current_participant_detail.paid) }}</h3>
        </dd>
      </dl> -->
      <!-- <dl class="row">
        <dt class="col-sm-3 text-right">Monto Pendiente</dt>
        <dd class="col-sm-9">
          <h3>
            Q {{ formatNumber(current_participant_detail.monto_nopagado) }}
          </h3>
        </dd>
      </dl> -->
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="">Distribuciones</label>
        <select
          class="form-control"
          (change)="handleParticipantDistributionFilter($event, 'DISTRIBUCION')"
        >
          <option selected value="all">Todos</option>
          <option
            [value]="distribution.distribution_code"
            *ngFor="let distribution of grouped_distributions; let i = index"
          >
            {{ distribution.distribution_name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <ngx-datatable
    #ParticipantDistributions
    class="bootstrap"
    [columnMode]="ColumnMode.force"
    *ngIf="participations_filtered.length >= 1"
    [rows]="participations_filtered"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [rowClass]="getRowClass"
    [limit]="10"
  >
    <ngx-datatable-column
      prop="distribution_name"
      name="Distribución"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="channel_name"
      name="Canal"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="artist_name"
      name="Artista"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="name"
      name="Obra"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="rol_name"
      name="Rol"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="instrument_name"
      name="Instrumento"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column prop="amount" name="Monto(Q)" [width]="100">
      <ng-template
        let-value="value"
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-cell-template
      >
        <span>{{ formatNumber(value) }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      prop="situation"
      name="Situación"
      [width]="100"
    ></ngx-datatable-column>

    <ngx-datatable-column
      prop="publisher_name"
      name="Editorial"
      [width]="100"
    ></ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-raised btn-primary"
    (click)="generateDistributionByPartnerFile()"
  >
    <i-feather name="file-text"></i-feather> Exportar
  </button>
</div>
