<div class="modal-header">
  <h4 class="modal-title text-center">Registro de Disquera</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="recordCompanyForm">
    <div class="form-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Nombre</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Direccion</label>
            <input type="text" class="form-control" formControlName="address">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Télefono</label>
            <input type="text" class="form-control" formControlName="phone">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="saveRecordCompany()"
    *ngIf="recordCompanyForm.valid">
    Crear Disquera
  </button>
</div>