import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AdminService } from 'app/services/admin.service';
import { NGXToastrService } from 'app/services/toastr.service';
import { RouteDataService } from 'app/services/route-data.service';

@Component({
    selector: 'app-create-discography',
    templateUrl: './create-discography.component.html',
    styleUrls: ['./create-discography.component.scss']
})
export class CreateDiscographyComponent implements OnInit {

    current_user: any;

    currentYear = moment().year();
    idArtist: string = "";
    discographies = [];


    newDiscographyForm: FormGroup;
    showDiscographies: boolean = false;

    constructor(
        private route_data_service: RouteDataService,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private admin_service: AdminService,
        private toastr: NGXToastrService, ) { }

    ngOnInit() {
        this.current_user = this.route_data_service.tempData;
        this.newDiscographyForm = this.formBuilder.group({
            code: new FormControl('', Validators.required),//codigo de artista
            name: new FormControl('', [Validators.required]),
            type: new FormControl('', [Validators.required]),
            publication: new FormControl('', [Validators.required])
        });
        this.admin_service.getArtistDiscographies({ user: this.current_user.usuario, id: this.idArtist }).subscribe((data: any) => {
            this.discographies = data.body;
            this.showDiscographies = true;

        });

    }
    addDiscography(): void {
        if (this.idArtist) {
            this.newDiscographyForm.controls.code.setValue(this.idArtist);
            if (this.newDiscographyForm.valid) {
                this.admin_service.saveArtistDiscographies({ data: { user: this.current_user.usuario, info: [this.newDiscographyForm.value] } }).subscribe((data: any) => {
                    //  console.log(data);
                    if (data) {
                        let newDiscography = this.newDiscographyForm.value;

                        this.discographies.push({ codigo: newDiscography.code, nombre: newDiscography.name, fecha: newDiscography.publication, etiquieta: newDiscography.type });
                        this.discographies = [...this.discographies];
                        this.newDiscographyForm.reset();
                        this.toastr.dataSavedSuccess();
                    } else {
                        this.toastr.typeError();
                    }
                });

            }
        }
    }
}
