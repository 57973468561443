<div class="modal-header">
  <h4 class="modal-title text-center">Cambio de Contraseña</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="formChangePassword" (ngSubmit)="changePassword()">
    <div class="form-body">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Nueva Contraseña</label>
          <input type="password" class="form-control" formControlName="new_ps">
        </div>
        <div class="form-group col-md-6">
          <label>Confirmar Nueva Contraseña</label>
          <input type="password" class="form-control" formControlName="confirm_new_ps">
          <small class="form-text text-muted danger"
            *ngIf="formChangePassword.get('confirm_new_ps').errors?.matchPassword && (formChangePassword.get('confirm_new_ps').dirty|| formChangePassword.get('confirm_new_ps').touched)">¡Tu
            contraseña nueva no coincide!</small>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-primary" (click)="changePassword()" *ngIf="formChangePassword.valid">
    <i-feather name="edit"></i-feather> Cambiar Contraseña
  </button>
</div>