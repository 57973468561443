import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { NgbModal, NgbDatepickerConfig, NgbDate, NgbCalendar, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateAppoinmentComponent } from 'app/create-appoinment/create-appoinment.component';
import { NGXToastrService } from 'app/services/toastr.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ViewAppointmentComponent } from 'app/view-appointment/view-appointment.component';
import { AppointmentService } from 'app/services/appointment.service';
import { RouteDataService } from 'app/services/route-data.service';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

    current_user: any;


    view: string = 'month';
    locale: string = 'es-GT';
    viewDate: Date = new Date();
    activeDayIsOpen: boolean = false;
    refresh: Subject<any> = new Subject();
    events: CalendarEvent[] = [];

    currentEvent: any;
    canEditEvent: boolean = false;
    editEventForm: FormGroup;

    today: Date = new Date();
    limit_date: Date = new Date();

    @ViewChild('modalContent') modalContent: TemplateRef<any>;
    modalRef: NgbModalRef;

    constructor(
        private route_data_service: RouteDataService,
        private appointment_service: AppointmentService,
        private ngbModalService: NgbModal,
        private toastr: NGXToastrService,
    ) { }

    ngOnInit() {

        this.current_user = this.route_data_service.tempData;
        console.log("current_user", this.current_user);
        this.updateEvents();
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
    }
    handleEvent(event: CalendarEvent): void {
        // console.log(event);
        this.currentEvent = event;
        let modalRef = this.ngbModalService.open(ViewAppointmentComponent, { size: 'lg' });

        modalRef.componentInstance.currentEvent = event;
        modalRef.componentInstance.current_user = this.current_user;

        modalRef.result.then((result) => {
            console.log("Resultado view appointment:", result);
            if (result === "Ok") {

            } else {
                this.activeDayIsOpen = false;
                this.updateEvents();
            }
        }, (reason) => {
            console.log(reason);
        });


    };
    updateEvents() {
        this.events = [];
        this.appointment_service.getAppointments({ user: this.current_user.usuario, user_cat: this.current_user.perfil_nombre }).subscribe((data: any) => {
            console.log("Obtuvo eventos: ", data);
            data.forEach(element => {
                // console.log(element);
                let hours: any;
                let start_time: any;
                let end_time: any;
                if (element.time === "Todo El Dia") {
                    start_time = "00";
                    end_time = "24";
                } else {
                    hours = element.time.split("-");
                    start_time = hours[0].split(":");
                    end_time = hours[1].split(":");
                }

                element = Object.assign(element, {
                    start: new Date(element.date.year, element.date.month - 1, element.date.day, start_time[0], start_time[1]),
                    end: new Date(element.date.year, element.date.month - 1, element.date.day, end_time[0], end_time[1]),
                    title: element.subject + ' ' + element.time
                });
                this.events.push(element);
            });
            this.refresh.next();
        });

    }


    validateUserCategory() {
        if (this.current_user.perfil == '6' || this.current_user.perfil == '7' || this.current_user.perfil == '9') {
            return true;
        } else {
            return false;
        }
    }

    openCreateAppointment() {
        this.ngbModalService.open(CreateAppoinmentComponent, {
            size: "lg"
        }).result.catch((err) => {
            console.log(err);
        }).then((result) => {
            console.log(result);
            if (result === "Ok") {
                this.toastr.appointmentCreated();
                this.updateEvents();
            }
        });
    }
}
