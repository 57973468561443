import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-layout-page',
  templateUrl: './full-layout-page.component.html',
  styleUrls: ['./full-layout-page.component.scss']
})
export class FullLayoutPageComponent implements OnInit {
  ngOnInit(): void {
    console.log("iniciando full layout");
  }
}
