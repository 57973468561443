import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewEncapsulation,
  EventEmitter,
  Output
} from "@angular/core";
import { Router } from "@angular/router";

import { PersonService } from "app/services/person.service";
import { RouteDataService } from "../../services/route-data.service";
import { NgbDropdownConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangePasswordComponent } from "../change-password/change-password.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [NgbDropdownConfig],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements AfterViewChecked, OnInit {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  username: string = "";
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  constructor(
    private person_service: PersonService,
    private router: Router,
    private router_data_service: RouteDataService,
    config: NgbDropdownConfig,
    private modalService: NgbModal
  ) {
    config.autoClose = false;
  }

  ngOnInit() {
    console.log("Iniciando navbar");
    this.username = this.router_data_service.tempData.usuario;
  }
  doLogout() {
    this.person_service.getSessionPerson().subscribe((data: any) => {
      //console.log(data);
      this.person_service
        .manageAccess({ user: data.usuario, tkn: data.token })
        .subscribe((data: any) => {
          if (
            data &&
            data.header &&
            data.header.type &&
            data.header.type === "success"
          ) {
            if (this.router_data_service.removeLocalToken()) {
              this.router.navigate(["/c/login"]);
            }
          }
        });
    });
  }
  openChangePassword() {
    const modal = this.modalService.open(ChangePasswordComponent, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
      centered: true,
      windowClass: "modalUserAgreements"
    });
    modal.result.then(result => {
      console.log(result);
    });
  }
  ngAfterViewChecked() {
    setTimeout(() => {
      var wrapperDiv = document.getElementsByClassName("wrapper")[0];
      var dir = wrapperDiv.getAttribute("dir");
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    }, 6000);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else this.toggleClass = "ft-maximize";
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
}
