import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  Form,
} from "@angular/forms";

import { RouteDataService } from "app/services/route-data.service";
import { AdminService } from "app/services/admin.service";
import { NGXToastrService } from "app/services/toastr.service";
import { capitalize } from "app/shared/consts";
import * as _ from "lodash";
import { CreatePublisherAgreementComponent } from "app/create-publisher-agreement/create-publisher-agreement.component";

@Component({
  selector: "app-view-work-card",
  templateUrl: "./view-work-card.component.html",
  styleUrls: ["./view-work-card.component.scss"],
})
export class ViewWorkCardComponent implements OnInit {
  current_user: any;

  work_id: any;
  current_work;
  current_work_percentage_assigned;

  editingWork = false;
  assign_method: any = "manual";
  work_duration: NgbTimeStruct = {
    hour: 0,
    minute: 0,
    second: 0,
  };
  editWorkForm: FormGroup;

  saved_artists: any = [];
  saved_record_companies: any = [];
  saved_instruments: any = [];
  saved_artist_roles: any = [];
  selected_artist_discographies: any = [];

  saved_enrolled_partners: any = [];
  searchArtistForAgreement: any;

  publishers: any = [];
  work_participants: any;
  editPublishersAgreement: FormArray;

  workPublisherAgreements: any = [];

  constructor(
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal,
    private route_data_service: RouteDataService,
    private admin_service: AdminService,
    private formBuilder: FormBuilder,
    private toastr: NGXToastrService
  ) {}

  ngOnInit() {
    this.current_user = this.route_data_service.tempData;
    this.loadWorkData();
    this.loadExtras();
  }

  loadWorkData() {
    this.admin_service
      .getWorkAgreement(this.current_user.usuario, this.work_id)
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          console.log("work", data);
          this.current_work = data.body.obra;
          this.current_work_percentage_assigned =
            this.current_work.porcentaje_distribuido;
          this.current_work.artists = data.body.artistas;
          this.current_work.additional = data.body.adicional;

          this.work_participants = _.groupBy(
            this.current_work.artists,
            "artista_codigo"
          );
          this.work_participants = _.map(
            this.work_participants,
            (value, key) => {
              return {
                publisher_code: "",
                artist_code: key,
                artist_name: this.work_participants[key][0].artista_nombre,
                participations: this.work_participants[key].length,
                participations_detail: this.work_participants[key],
              };
            }
          );
        } else {
          this.toastr.toast_error("No se ha recuperado información");
        }
      });

    this.loadPublisherAgreement();
  }
  loadPublisherAgreement() {
    this.admin_service
      .getPublisherAgreement({
        user: this.current_user.usuario,
        correlative: this.work_id,
      })
      .subscribe((data: any) => {
        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          //this.publishers = [...data.response.data];
          this.workPublisherAgreements = [...data.response.data];
        } else {
          this.workPublisherAgreements = [];
        }
      });
  }
  loadExtras() {
    this.admin_service
      .getPublishers({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        console.log(data);
        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          this.publishers = [...data.response.data];
          console.log("publisher", this.publishers);
        }
      });
  }

  editWork() {
    this.editingWork = true;

    this.admin_service
      .getBandsList({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          this.saved_artists = data.body.bandas;
        } else {
          this.toastr.toast_error("No se han recuperado artistas.");
          return;
        }
      });

    this.admin_service
      .getRecordCompanies({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          this.saved_record_companies = data.body;
        } else {
          this.toastr.toast_error("No se han recuperado disqueras.");
          return;
        }
      });

    this.admin_service
      .getInstruments({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          this.saved_instruments = data.body;
        } else {
          this.toastr.toast_error("No se han recuperado instrumentos.");
          return;
        }
      });

    this.admin_service
      .getRoles({ user: this.current_user.usuario })
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          this.saved_artist_roles = data.body;
        } else {
          this.toastr.toast_error("No se han recuperado roles.");
          return;
        }
      });

    this.loadEnrolledPartners();

    this.getArtistDiscography(
      this.current_work.additional.banda_codigo
        ? this.current_work.additional.banda_codigo
        : null
    );

    let duration_split = this.current_work.obra_duracion
      ? this.current_work.obra_duracion
      : "";
    duration_split = duration_split.split(":");
    this.assign_method = this.current_work
      ? this.current_work.metodo_asignacion
      : this.assign_method;
    this.editWorkForm = this.formBuilder.group({
      obra: new FormGroup({
        obra_codigo: new FormControl(
          this.current_work.obra_codigo ? this.current_work.obra_codigo : ""
        ),
        obra_nombre: new FormControl(
          this.current_work.obra_nombre ? this.current_work.obra_nombre : ""
        ),
        obra_genero: new FormControl(
          this.current_work.obra_genero ? this.current_work.obra_genero : ""
        ),
        obra_publicacion: new FormControl(
          this.current_work.obra_publicacion
            ? this.current_work.obra_publicacion
            : ""
        ),
        obj_obra_duracion: new FormControl({
          hour: duration_split.length === 3 ? parseInt(duration_split[0]) : 0,
          minute: duration_split.length === 3 ? parseInt(duration_split[1]) : 0,
          second: duration_split.length === 3 ? parseInt(duration_split[2]) : 0,
        }),
        obra_isrc: new FormControl(
          this.current_work.obra_isrc ? this.current_work.obra_isrc : ""
        ),
        obra_iswc: new FormControl(
          this.current_work.obra_iswc ? this.current_work.obra_iswc : ""
        ),
        obra_bmat: new FormControl(
          this.current_work.obra_bmat ? this.current_work.obra_bmat : ""
        ),
        obra_porcentaje: new FormControl(
          this.current_work ? this.current_work.porcentaje_distribuido : 0
        ),
        metodo_asignacion: new FormControl(
          this.current_work
            ? this.current_work.metodo_asignacion
            : this.assign_method
        ),
      }),
      artistas: this.formBuilder.array([]),
      adicional: new FormGroup({
        banda_codigo: new FormControl(
          this.current_work.additional.banda_codigo
            ? this.current_work.additional.banda_codigo
            : ""
        ),
        discografia_codigo: new FormControl(
          this.current_work.additional.discografia_codigo
            ? this.current_work.additional.discografia_codigo
            : ""
        ),
        disquera_codigo: new FormControl(
          this.current_work.additional.disquera_codigo
            ? this.current_work.additional.disquera_codigo
            : ""
        ),
      }),
    });

    this.current_work.artists.forEach((artist) => {
      (this.editWorkForm.get("artistas") as FormArray).push(
        this.formBuilder.group({
          artista_codigo: new FormControl(artist.artista_codigo),
          artista_nombre: new FormControl(artist.artista_nombre),
          artista_alias: new FormControl(artist.artista_alias),
          artista_rol: new FormControl(capitalize(artist.artista_rol)),
          artista_instrumento: new FormControl(
            capitalize(artist.artista_instrumento)
          ),
          sociedad_codigo: new FormControl(artist.sociedad_codigo),
          sociedad_nombre: new FormControl(artist.sociedad_nombre),

          porcentaje: new FormControl(artist.porcentaje),
          porcentaje_dist: new FormControl(artist.porcentaje_dist),
          porcentaje_fixed: new FormControl(artist.porcentaje),
          porcentaje_dist_fixed: new FormControl(artist.porcentaje_dist),
        })
      );
    });

    this.editPublishersAgreement = this.formBuilder.array([]);
    this.work_participants.forEach((element) => {
      this.editPublishersAgreement.push(this.formBuilder.group({}));
    });
  }
  loadEnrolledPartners() {
    this.admin_service
      .getArtistsNameList(this.current_user.usuario)
      .subscribe((data: any) => {
        if (
          data &&
          data.header &&
          data.header.type &&
          data.header.type == "success"
        ) {
          console.log(data);
          console.log('lista de artistas', data.body)
          this.saved_enrolled_partners = data.body;
        }
      });
  }

  addAgreement(person: any): void {
    this.searchArtistForAgreement = null;
    if (person != "") {
      (this.editWorkForm.get("artistas") as FormArray).push(
        this.formBuilder.group({
          artista_codigo: new FormControl(
            person.codigo ? person.codigo.toString() : ""
          ),
          artista_nombre: new FormControl(
            person.nombre_completo ? person.nombre_completo.toString() : person,
            Validators.required
          ),
          artista_alias: new FormControl(
            person.alias ? person.alias : "",
            Validators.required
          ),
          artista_rol: new FormControl("", Validators.required),
          artista_instrumento: new FormControl("", Validators.required),
          sociedad_codigo: new FormControl(
            person.codigo_sociedad,
            Validators.required
          ),
          sociedad_nombre: new FormControl(
            person.nombre_sociedad,
            Validators.required
          ),

          porcentaje: new FormControl(0, Validators.required),
          porcentaje_dist: new FormControl(0, [Validators.required]),

          porcentaje_fixed: new FormControl(0, [Validators.required]),
          porcentaje_dist_fixed: new FormControl(0),
        })
      );
    }
  }
  removeArtistFromWork(artist_code) {
    let artist_index = this.editWorkForm.value.artistas.findIndex(
      (artist) => artist.artista_codigo === artist_code
    );
    console.log(artist_code, artist_index);

    (this.editWorkForm.get("artistas") as FormArray).removeAt(artist_index);

    this.current_work.artists = this.current_work.artists.filter(function (
      element
    ) {
      return element.artista_codigo != artist_code;
    });

    this.handleAssignMethod();
  }
  calculateAgreementPercentage() {
    this.current_work_percentage_assigned = 0;
    (this.editWorkForm.get("artistas") as FormArray).value.forEach(
      (element) => {
        this.current_work_percentage_assigned += parseFloat(element.porcentaje);
      }
    );
    this.current_work_percentage_assigned =
      this.current_work_percentage_assigned.toFixed(2);
  }

  saveWorkChanges() {
    let data_to_save: any = {
      user: this.current_user.usuario,
    };

    Object.assign(data_to_save, this.editWorkForm.value);

    //data_to_save.user = ;

    let duration =
      this.pad(data_to_save.obra.obj_obra_duracion.hour) +
      ":" +
      this.pad(data_to_save.obra.obj_obra_duracion.minute) +
      ":" +
      this.pad(data_to_save.obra.obj_obra_duracion.second);

    console.log(duration);

    data_to_save.obra.obra_porcentaje = this.current_work_percentage_assigned;
    data_to_save.obra.obra_duracion = duration;

    console.log(data_to_save);

    this.admin_service.saveWorkUpdates(data_to_save).subscribe((data: any) => {
      if (data && data.header) {
        if (data.header.type === "success") {
          this.editingWork = false;
          this.toastr.defaultSuccess();
          this.loadWorkData();
        } else {
          this.toastr.toast_error(data.header.msg);
        }
      } else {
        this.toastr.typeError();
      }
    });
  }

  getArtistDiscography(idArtist: string) {
    if (idArtist) {
      this.admin_service
        .getArtistDiscographies({
          user: this.current_user.usuario,
          id: idArtist,
        })
        .subscribe((data: any) => {
          //console.log(data);
          if (
            data &&
            data.header &&
            data.header.type &&
            data.header.type === "success"
          ) {
            this.selected_artist_discographies = data.body;
          } else {
            //console.log("No se recupero discografia");
            this.selected_artist_discographies = [];
          }
        });
    }
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  handleAssignMethod() {
    console.log(this.assign_method);
    if (this.assign_method === "auto") {
      this.assignAgreements();
      this.assignDistribution();
    } else if (this.assign_method === "manual") {
      this.assignDistribution();
    } else {
      this.toastr.toast_error("Metódo de asignación de convenios no definida.");
    }
    this.calculateAgreementPercentage();
  }

  assignAgreements() {
    let rule_for_independents = 6;
    let percent_from_performers_remain = 0;

    if ((this.editWorkForm.controls["artistas"] as FormArray).length > 0) {
      let interpreters_percent = 0;
      let performers_percent = 0;

      let interpreters = 0;
      let performers = 0;

      this.editWorkForm.get("artistas").value.forEach((partner) => {
        console.log(partner);
        if (partner.artista_rol === "Interprete") {
          interpreters++;
        } else {
          performers++;
        }
      });

      if (performers == 0) {
        interpreters_percent = 100;
        performers_percent = 0;
      } else if (performers > 0) {
        if (performers < rule_for_independents) {
          performers_percent = 40 / rule_for_independents;
          percent_from_performers_remain = 40 - performers_percent * performers;
          interpreters_percent = 60 + percent_from_performers_remain;
          //console.log("apply rule of six", performers_percent, "remain", percent_from_performers_remain);
        } else {
          interpreters_percent = 60;
          performers_percent = 40;
        }
      } else {
        return;
      }

      console.log("Porcentajes: ", performers, interpreters);

      // console.log("Integrantes", interpreters_percent, performers_percent);

      let interpreters_percentages = [interpreters];
      let arr = [performers];

      for (let i = 0; i < interpreters; i++) {
        interpreters_percentages[i] = Number(
          interpreters_percent / interpreters
        );
      }

      for (let i = 0; i < performers; i++) {
        if (performers < rule_for_independents) {
          arr[i] = performers_percent;
        } else {
          arr[i] = Number(performers_percent / performers);
        }
      }

      if (performers < rule_for_independents) {
      }

      let interpreters_percent_assigned_index = 0;
      let performer_percent_assigned_index = 0;

      let overall_percentage = 0;

      this.editWorkForm.get("artistas").value.forEach((partner, index) => {
        let percentage_to_assign = 0;

        if (partner.artista_rol === "Interprete") {
          percentage_to_assign =
            interpreters_percentages[interpreters_percent_assigned_index];
          interpreters_percent_assigned_index++;
        } else {
          percentage_to_assign = arr[performer_percent_assigned_index];
          performer_percent_assigned_index++;
        }

        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje")
          .setValue(percentage_to_assign);
        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje_fixed")
          .setValue(Number(percentage_to_assign.toFixed(2)));

        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje_dist")
          .setValue(percentage_to_assign);

        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje_dist_fixed")
          .setValue(Number(percentage_to_assign.toFixed(2)));

        if (partner.sociedad_codigo === "SOCG4Y3C6L1") {
          (this.editWorkForm.get("artistas") as FormArray)
            .at(index)
            .get("porcentaje_dist")
            .setValue(0);
          (this.editWorkForm.get("artistas") as FormArray)
            .at(index)
            .get("porcentaje_dist_fixed")
            .setValue(0);
          // partner.percent = 0;
        }

        overall_percentage += percentage_to_assign;
      });
      console.log("overall", overall_percentage);
      this.toastr.toast_sucess("Convenios asignados exitosamente.");
      console.log("\n");
    } else {
      return;
    }
  }

  assignDistribution() {
    if ((this.editWorkForm.controls["artistas"] as FormArray).length > 0) {
      let remain_percentage = 0.0;
      let valid_partners = [];
      this.editWorkForm.get("artistas").value.forEach((partner, index) => {
        console.log(partner);
        if (partner.sociedad_codigo == "SOCG4Y3C6L1") {
          // console.log("asignando: ", partner.porcentaje);
          remain_percentage += parseFloat(partner.porcentaje);
        } else if (partner.sociedad_codigo == "SOCQSHPUR2Q") {
          valid_partners.push(index);
        }
      });

      // console.log("valid", valid_partners);
      // console.log("Residuo", remain_percentage);
      let percent_to_assign = remain_percentage / valid_partners.length;

      console.log(
        "Distribuir: ",
        percent_to_assign,
        "en",
        valid_partners.length
      );

      this.editWorkForm.get("artistas").value.forEach((partner, index) => {
        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje_dist")
          .setValue(Number(0).toFixed(2));

        (this.editWorkForm.get("artistas") as FormArray)
          .at(index)
          .get("porcentaje_dist_fixed")
          .setValue(Number(0).toFixed(2));
        valid_partners.forEach((valid_partner_index) => {
          if (index === valid_partner_index) {
            // partner.percent = Number(partner.percent_origin) + percent_to_assign;

            (this.editWorkForm.get("artistas") as FormArray)
              .at(index)
              .get("porcentaje_dist")
              .setValue(
                (Number(partner.porcentaje) + percent_to_assign).toFixed(13)
              );

            // console.log(
            //   "ADFADFAD",
            //   Number(partner.porcentaje),
            //   percent_to_assign
            // );

            // let v =
            //   parseFloat(partner.porcentaje) +
            //   parseFloat(percent_to_assign.toString());
            // console.log(v.toFixed(13));

            // console.log(partner.porcentaje, percent_to_assign);
            // console.log(partner.porcentaje + percent_to_assign);

            (this.editWorkForm.get("artistas") as FormArray)
              .at(index)
              .get("porcentaje_dist_fixed")
              .setValue(
                Number(Number(partner.porcentaje) + percent_to_assign).toFixed(
                  2
                )
              );
          } else {
          }
          return;
        });
      });
      this.toastr.toast_sucess("Distribuciones asignados exitosamente.");
    } else {
      return;
    }
  }

  handlePublisherAgreement() {
    //modalHandlePublisherAgreement
    let modalRef = this.ngbModalService.open(
      CreatePublisherAgreementComponent,
      {
        backdrop: "static",
        keyboard: false,
        size: "lg",
        centered: true,
        windowClass: "hugeModal",
      }
    );

    modalRef.componentInstance.participants = this.work_participants;
    modalRef.componentInstance.publishers = this.publishers;
    modalRef.result.then((result) => {
      // console.log(result);
      if (result === "Ok") {
        this.loadPublisherAgreement();
      }
    });
  }

  removePublisherAgreement(agreement) {
    console.log("agreement to remove", agreement);
    this.admin_service
      .deletePublisherAgreement(agreement)
      .subscribe((data: any) => {
        if (
          data &&
          data.response &&
          data.response.db &&
          data.response.db.typ === "success"
        ) {
          this.toastr.dataSavedSuccess();
          this.loadPublisherAgreement();
          //this.activeModal.close("Ok");
        }
      });
  }
}
