<div class="modal-header">
  <h4 class="modal-title text-center">{{currentEvent.subject}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('x')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body px-3 ">
  <div class="row">
    <div class="col">

      <div class="card-text text-left" *ngIf="!canEditEvent">
        <div class="row">
          <div class="col">
            <dl>
              <dt>Fecha</dt>
              <dd>{{currentEvent.date.day}} / {{currentEvent.date.month}} / {{currentEvent.date.year}} </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <dt>Horario</dt>
              <dd>{{currentEvent.time}} </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <dt>Creador</dt>
              <dd>{{currentEvent.creator}} </dd>
            </dl>
          </div>
        </div>
        <hr>
        <dl class="row">
          <dt class="col-sm-3">Destinatarios</dt>
          <dd class="col-sm-9">
            <span *ngFor="let recipient of currentEvent.recipients;let i = index">{{((i >0 )? ',':'')+
              (
              (recipient === 'admin')? 'Administración' :
              (recipient === 'assistant')? 'Asitencia':
              (recipient === 'monitor')? 'Monitoreo':
              (recipient === 'interpreter')? 'Socios':
              (recipient === 'specific')? 'Socio Específico':
              (recipient === 'all')? 'Todos Los Usuarios': ''
              )}}</span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Nombre de Evento</dt>
          <dd class="col-sm-9">{{currentEvent.subject}}</dd>
        </dl>

        <dl class="row" *ngIf="currentEvent.recipients.includes('specific')">
          <dt class="col-sm-3">Correo Electrónico</dt>
          <dd class="col-sm-9">{{currentEvent.email}}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Comentario</dt>
          <dd class="col-sm-9">{{currentEvent.comment}}</dd>
        </dl>

      </div>
      <div class="card-text text-left" *ngIf="canEditEvent && editEventForm">
        <form class="form" [formGroup]="editEventForm">
          <div class="row">
            <div class="col">
              <dl>
                <dt>Fecha</dt>
                <dd>
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker #d2="ngbDatepicker"
                      formControlName="date">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="d2.toggle()">
                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
            <div class="col">
              <dl>
                <dt>Horario</dt>
                <select class="form-control" formControlName="time">
                  <option>09:30-10:00</option>
                  <option>10:30-11:00</option>
                  <option>11:30-12:00</option>
                  <option>12:30-13:00</option>
                </select>
              </dl>
            </div>
            <div class="col">
              <dl>
                <dt>Creador</dt>
                <dd>{{currentEvent.creator}} </dd>
              </dl>
            </div>
          </div>
          <hr>
          <dl class="row">
            <dt class="col-sm-3">Usuarios</dt>
            <dt class="col-sm-9">
              <div class="form-group">
                <label for="">Usuarios</label>
                <select class="form-control" multiple="multiple" size="6" formControlName="recipients">
                  <option value="{{recipient_user}}"
                    *ngFor="let recipient_user of  ['admin','assistant','monitor','interpreter','specific','all'];let i = index">
                    {{
                    (recipient_user === 'admin')? 'Administración ' :
                    (recipient_user === 'assistant')? 'Asitencia ':
                    (recipient_user === 'monitor')? 'Monitoreo ':
                    (recipient_user === 'interpreter')? 'Socios ':
                    (recipient_user === 'specific')? 'Socio Específico ':
                    (recipient_user === 'all')? 'Todos Los Usuarios ': ''
                    }}
                  </option>

                </select>
              </div>
            </dt>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Nombre</dt>
            <dd class="col-sm-9"><input type="text" class="form-control" formControlName="subject"></dd>
          </dl>
          <dl class="row" *ngIf="currentEvent.recipients.includes('specific')">
            <dt class="col-sm-3">Correo Electrónico</dt>
            <dd class="col-sm-9"><input type="email" class="form-control" formControlName="email"></dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">Comentario</dt>
            <dd class="col-sm-9"><textarea cols="30" rows="3" class="form-control" formControlName="comment"></textarea>
            </dd>
          </dl>
        </form>
      </div>

    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="show_mark_seen">
  <button type="button" class="btn btn-raised btn-raised btn-primary btn-block" (click)="markAsSeen()">
    <i-feather name="check"></i-feather> Marcar Como Leida
  </button>
</div>

<div class="modal-footer" *ngIf="show_buttons && current_user.usuario === currentEvent.creator">

  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="updateCurrentEvent()"
    *ngIf="canEditEvent">
    <i-feather name="check"></i-feather> Guardar Cambios
  </button>

  <button type="button" class="btn btn-raised btn-raised btn-danger" (click)="cancelEditEvent();" *ngIf="canEditEvent">
    <i-feather name="x"></i-feather> Cancelar Edición
  </button>

  <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="editEvent(currentEvent)"
    *ngIf="!canEditEvent">
    <i-feather name="edit"></i-feather> Editar Evento
  </button>
  <!-- 
        <button type="button" class="btn btn-raised btn-raised btn-primary" (click)="sendCurrentEventConfirmation()"
          *ngIf="!canEditEvent">
          Enviar Confirmación
        </button> -->

  <button type="button" class="btn btn-raised btn-raised btn-danger" (click)="cancelCurrentEvent()"
    *ngIf="!canEditEvent">
    Cancelar Evento
  </button>


</div>